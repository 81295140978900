import React from "react";

interface TypeStatusColorProps {
  type: string;
}

const getColorFromType = (type: string) => {
  return type === "C" ? "text-info" : "text-danger";
};

export const TypeStatusColor: React.FC<TypeStatusColorProps> = ({ type }) => {

    const classNameFromStatus =
    type === ""
      ? "text-dark"
      : getColorFromType(type);
  
    return (
      <span className={`${classNameFromStatus}`}>
        {type}
      </span>
    );
  };
