import { EditUserService } from "@domain/services/users";
import { HttpRepository } from "@data/repositories";
import { HttpRequestBuilder } from "@data/builders";
import { HttpRequestTypes } from "@data/enums";

export class HttpEditUserService implements EditUserService {
  constructor(
    private readonly httpRepository: HttpRepository
  ) { }

  async handle(payload: EditUserService.Payload): Promise<boolean> {
    const request = HttpRequestBuilder
    .requestToUrl('/users')
    .withMethod(HttpRequestTypes.PUT)
    .withData(payload)
    .build();

    const { status } = await this.httpRepository.makeRequest<EditUserService.Payload>(request);

    return status === 200;
  }
}
