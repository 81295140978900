import React from 'react';

import { MoneyFormatStrategy } from '@ui/strategy';
import { BillsTypes } from '@domain/enums';

interface MoneyStatusColorProps {
  value: number;
  type?: BillsTypes;
}

const getColorFromType = (type: BillsTypes, value: number) => {
  if (type === BillsTypes.TO_PAY) {
    return value < 0 ? "text-success" : "text-danger";
  }

  return value > 0 ? "text-success" : "text-danger";
};

export const MoneyStatusColor: React.FC<MoneyStatusColorProps> = ({ value, type = BillsTypes.TO_RECEIVE }) => {

  const classNameFromStatus =
    value === 0
      ? "text-dark"
      : getColorFromType(type, value);

  return (
    <span className={`${classNameFromStatus}`}>
      {MoneyFormatStrategy.format(value)}
    </span>
  );
};
