import React, { useMemo } from "react";

import { ForgotPasswordView } from "@ui/views/auth";
import { makeForgotPasswordPresenter } from "@main/factories";

export const ForgotPassword: React.FC = () => {
  const forgotPasswordPresenter = useMemo(() => makeForgotPasswordPresenter(), []);
  
  return <ForgotPasswordView forgotPasswordPresenter={forgotPasswordPresenter} />;
};
