import { ProviderEvents } from "@domain/events";
import { EditProviderService } from "@domain/services/provider/EditProviderService";
import { FormValidation } from "@presentation/validation/interfaces";
import { EditProviderPresenterContract } from "@ui/presenters/provider";
import { EditProviderViewContract } from "@presentation/views/providers/EditProviderViewContract";
import { EventEmitter } from "@utils/events";

export class EditProviderPresenter implements EditProviderPresenterContract {
  private view!: EditProviderViewContract;

  constructor(
    private readonly validation: FormValidation,
    private readonly editProviderService: EditProviderService
  ) {}

  setView(view: EditProviderViewContract): void {
    this.view = view;
  }

  async edit(payload: EditProviderPresenterContract.Payload): Promise<boolean> {
    this.view.setLoading(true);
    this.view.openSimilarityFeedback(false);

    const validationSuccessfull = this.validation.allValid(payload);

    if (!validationSuccessfull) {
      this.view.updateValidationState(this.validation.errors(payload));
      this.view.setLoading(false);
      return false;
    }

    const { ok: isProviderUpdated, errors } = await this.editProviderService.handle(payload);

    if (isProviderUpdated) {
      EventEmitter.emit(ProviderEvents.PROVIDER_UPDATED);
    } else {
      this.view.setSimilarity(errors);
      this.view.openSimilarityFeedback(true);
    }

    this.view.setLoading(false);
    this.view.updateValidationState([]);
    return true;
  }
}
