import { HttpRequestBuilder } from "@data/builders";
import { HttpRequestTypes } from "@data/enums";
import { HttpRepository } from "@data/repositories";
import { EditNonEffectiveBillsService } from "@domain/services/bills";

export class HttpEditNonEffectiveBillsService implements EditNonEffectiveBillsService {
    constructor(
        private readonly httpRepository: HttpRepository
    ) { }

    async handle(payload: EditNonEffectiveBillsService.Payload): Promise<boolean> {
        const httpRequest = HttpRequestBuilder.requestToUrl('/bills/noneffective')
            .withMethod(HttpRequestTypes.PUT)
            .withData(payload)
            .build();

        const { status } = await this.httpRepository.makeRequest<EditNonEffectiveBillsService>(httpRequest);

        return status === 200;
    }
}
