import { City, State } from "@domain/entities";
import { AddPrefectureService } from "@domain/services/prefecture";
import { FormValidation } from "@presentation/validation/interfaces";
import { AddPrefectureViewContract } from "@presentation/views/prefecture";
import { AddPrefecturePresenterContract } from "@ui/presenters/prefecture";
import { EventEmitter } from "@utils/events";
import { PrefectureEvents } from "@domain/events";
import { GetStatesService } from "@domain/services/states";
import { GetCitiesService } from "@domain/services/cities";

export class AddPrefecturePresenter implements AddPrefecturePresenterContract {
    private view!: AddPrefectureViewContract;

    constructor(
        private readonly validation: FormValidation,
        private readonly addPrefectureService: AddPrefectureService,
        private readonly getStateService: GetStatesService,
        private readonly getCityService: GetCitiesService
    ) { }

    setView(view: AddPrefectureViewContract): void {
        this.view = view;
    }

    async add(payload: AddPrefecturePresenterContract.Payload): Promise<boolean> {
        this.view.setLoading(true);

        const validationSuccessfull = this.validation.allValid(payload);

        if (!validationSuccessfull) {
            this.view.updateValidationState(this.validation.errors(payload));
            this.view.setLoading(false);
            return false;
        }

        const isPrefecturepAdded = await this.addPrefectureService.handle(payload);

        if (isPrefecturepAdded) {
            EventEmitter.emit(PrefectureEvents.PREFECTURE_ADDED);
        }

        this.view.updateValidationState([]);
        this.view.setLoading(false);

        return isPrefecturepAdded;
    }

    async getState(): Promise<State[]> {
        const states = await this.getStateService.handle();
        this.view.setStates(states);
        return states;
    }

    async getCity(state: number): Promise<City[]> {
        const cities = await this.getCityService.handle(state);
        this.view.setCities(cities);
        return cities;
    }
}
