import { HttpRequestBuilder } from "@data/builders";
import { HttpRequestTypes } from "@data/enums";
import { HttpRepository } from "@data/repositories";
import { RemoveGroupService } from "@domain/services/groups/RemoveGroupService";

export class HttpRemovegroupService implements RemoveGroupService {
   constructor(
      private readonly httpRepository: HttpRepository
   ) {}

   async handle(groupId: number): Promise<boolean> {
      const httpRequest = HttpRequestBuilder
      .requestToUrl(`/groups/${groupId}`)
      .withMethod(HttpRequestTypes.DELETE)
      .build();

      const { status } = await this.httpRepository.makeRequest(httpRequest);

      return status === 200;
   }
}
