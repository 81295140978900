import { HttpGetConsolidatedReportService } from "@data/services/consolidated-report/HttpGetConsolidatedReportService";
import { AxiosHttpRepository } from "@infra/http";
import { API_BASE_URL } from "@main/constants/config";
import { LastThreeMonthsPresenter } from "@presentation/presenters/dashboard";
import { LastThreeMonthsPresenterContract } from "@ui/presenters/dashboard";

export const makeLastThreeMonthsPresenter = (): LastThreeMonthsPresenterContract => {
  const httpRepository = new AxiosHttpRepository(API_BASE_URL);
  const getConsolidatedReportService = new HttpGetConsolidatedReportService(httpRepository);

  return new LastThreeMonthsPresenter(getConsolidatedReportService);
};
