import React, { Fragment, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Modal, Row, Col, Label, FormGroup } from "reactstrap";

import { useValidation } from "@ui/hooks";
import { AddUserPresenterContract } from "@ui/presenters/users";
import { AccessProfile, CashFlow, Prefecture } from "@domain/entities";
import { SelectItem } from "@ui/interfaces";

interface AddUserViewProps {
   t?: any;
   isOpen: boolean;
   onDismiss(): void;
   addUserPresenter: AddUserPresenterContract;
}

const INITIAL_FORM_STATE: AddUserPresenterContract.Payload = {
   name: "",
   email: "",
   password: "",
   group: "",
   prefectureId: 0,
   cashflows: []
};

const AddUserView: React.FC<AddUserViewProps> = ({ t, isOpen, onDismiss, addUserPresenter }) => {
   const [isLoading, setIsLoading] = useState<boolean>(false);
   const [payload, setPayload] = useState<AddUserPresenterContract.Payload>(INITIAL_FORM_STATE);
   const [prefectures, setPrefecturesList] = useState<Prefecture[]>([]);
   const [cashflows, setCashflowsList] = useState<CashFlow[]>([]);
   const [accessProfiles, setAccessProfilesList] = useState<AccessProfile[]>([]);

   const { ValidationFeedback, ValidationInput, updateValidationState, validationState, ValidationSelect, ValidationCheckbox } = useValidation();

   useEffect(() => {
      addUserPresenter.getCashflows();
      addUserPresenter.getPrefectures();
      addUserPresenter.getAccessProfiles();
      addUserPresenter.setView({ updateValidationState, setPrefectures, setCashflows, setAccessProfiles, setLoading });
   }, []);

   useEffect(() => {
      if (!isOpen) {
         updateValidationState([]);
         setPayload(INITIAL_FORM_STATE);
      } else {
         addUserPresenter.getCashflows();
         addUserPresenter.getPrefectures();
         addUserPresenter.getAccessProfiles();
      }
   }, [isOpen]);

   const setPrefectures = (prefecture: Prefecture[]) => setPrefecturesList(prefecture);
   const setCashflows = (cashflow: CashFlow[]) => setCashflowsList(cashflow);
   const setAccessProfiles = (accessProfiles: AccessProfile[]) => setAccessProfilesList(accessProfiles);
   const setLoading = (isLoading: boolean): void => setIsLoading(isLoading);

   const updateField = (fieldName: string, value: string) => {
      setPayload((oldState) => ({
         ...oldState,
         [fieldName]: value
      }));
   };

   const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      addUserPresenter.add({
         cashflows: payload.cashflows,
         email: payload.email,
         group: payload.group,
         name: payload.name,
         password: payload.password,
         prefectureId: Number(payload.prefectureId)
      });
   };

   const handleCashflowChecked = (cashflowId: string, checked: boolean) => {
      if (checked) {
         setPayload({
            ...payload,
            cashflows: [...payload.cashflows, cashflowId]
         });
      } else {
         setPayload({
            ...payload,
            cashflows: payload.cashflows.filter((id) => id !== cashflowId)
         });
      }
   };

   const optionsPrefecture: SelectItem[] = prefectures.map(({ id, cityName }) => ({ label: cityName, value: id }));
   const optionsAccessProfiles: SelectItem[] = accessProfiles.map(({ id, name }) => ({ label: name, value: id }));

   return (
      <Modal
         isOpen={isOpen}
         toggle={isLoading ? undefined : onDismiss}
         scrollable={true}
         id="staticBackdrop"
         size="lg"
      >
         <div className="modal-header">
            <h5 className="modal-title">{t("Add user")}</h5>
            <button
               type="button"
               className="btn-close"
               onClick={isLoading ? undefined : onDismiss}
               aria-label="Close"
            />
         </div>

         <form onSubmit={handleSubmit}>
            <div className="modal-body">
               <Row>
                  <Col md={12} className="mb-2">
                     <FormGroup>
                        <Label>{t("users.add.name")}</Label>
                        <ValidationInput
                           field="name"
                           validationState={validationState}
                           name="name"
                           className="form-control"
                           placeholder={`${t("users.add.name.placeholder")}`}
                           value={payload.name}
                           onChange={({ target: { value, name } }) => updateField(name, value)}
                        />

                        <ValidationFeedback field="name" />
                     </FormGroup>
                  </Col>

                  <Col md={12} className="mb-2">
                     <FormGroup>
                        <Label>{t("users.add.email")}</Label>
                        <ValidationInput
                           field="email"
                           validationState={validationState}
                           name="email"
                           className="form-control"
                           placeholder={`${t("users.add.email.placeholder")}`}
                           value={payload.email}
                           onChange={({ target: { value, name } }) => updateField(name, value)}
                        />

                        <ValidationFeedback field="email" />
                     </FormGroup>
                  </Col>

                  <Col md={12} className="mb-2">
                     <FormGroup>
                        <Label>{t("users.add.password")}</Label>
                        <ValidationInput
                           field="password"
                           validationState={validationState}
                           name="password"
                           className="form-control"
                           placeholder={`${t("users.add.password.placeholder")}`}
                           value={payload.password}
                           onChange={({ target: { value, name } }) => updateField(name, value)}
                           type="password"
                        />

                        <ValidationFeedback field="password" />
                     </FormGroup>
                  </Col>

                  <Col md={6} className="mb-3">
                     <FormGroup>
                        <Label>{t("users.add.prefecture")}</Label>

                        <ValidationSelect
                           field="prefectureId"
                           options={optionsPrefecture}
                           validationState={validationState}
                           name="prefectureId"
                           value={payload.prefectureId}
                           className="form-control"
                           placeholder={t('users.add.prefecture.placeholder')}
                           onChange={({ target: { value, name } }) => {
                              updateField(name, value);
                           }}
                        />

                        <ValidationFeedback field="prefectureId" />
                     </FormGroup>
                  </Col>

                  <Col md={6} className="mb-3">
                     <FormGroup>
                        <Label>{t("users.add.profile")}</Label>

                        <ValidationSelect
                           field="group"
                           options={optionsAccessProfiles}
                           validationState={validationState}
                           name="group"
                           className="form-control"
                           placeholder={t('users.add.profile.placeholder')}
                           onChange={({ target: { value, name } }) => updateField(name, value)}
                        />

                        <ValidationFeedback field="group" />
                     </FormGroup>
                  </Col>

                  <Col>
                     <FormGroup>
                        <Label style={{ display: "block" }}>{t("users.add.cashflow")}</Label>
                        {cashflows.map(({ name, id }) => (
                           <Fragment key={id}>
                              <ValidationCheckbox
                                 validationState={validationState}
                                 field="cashflows"
                                 name="cashflows"
                                 className="form-control"
                                 onChange={({ target: { checked } }) => handleCashflowChecked(id, checked)} />
                              <Label>{name}</Label>
                           </Fragment>
                        ))}

                        <ValidationFeedback field="cashflows" />
                     </FormGroup>
                  </Col>
               </Row>
            </div>

            <div className="modal-footer">
               <button
                  type="button"
                  className="btn btn-danger me-2"
                  onClick={isLoading ? undefined : onDismiss}
                  disabled={isLoading}
               >
                  {t("Close")}
               </button>

               <button className="btn btn-primary" disabled={isLoading}>
                  {t(isLoading ? "loading" : "Save")}
               </button>
            </div>
         </form>
      </Modal>
   );
};

export default withTranslation()(AddUserView);
