import React, { useMemo } from "react";
import AddPrefectureView from "@ui/views/prefecture/AddPrefectureView";
import { makeAddPrefecturePresenter } from "@main/factories/presenters/prefecture";

interface AddPrefectureProps {
  isOpen: boolean;
  onDismiss(): void;
}

export const AddPrefecture: React.FC<AddPrefectureProps> = (props) => {
  const addPrefecturePresenter = useMemo(() => makeAddPrefecturePresenter(), []);

  return <AddPrefectureView {...props} addPrefecturePresenter={addPrefecturePresenter} />;
};
