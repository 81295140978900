import { SubgroupsEvents } from "@domain/events";
import { AddSubgroupService } from "@domain/services/revenues-expenses";
import { FormValidation } from "@presentation/validation/interfaces";
import { AddSubgroupViewContract } from "@presentation/views/revenues-expenses";
import { AddSubgroupPresenterContract } from "@ui/presenters/revenues-expenses";
import { EventEmitter } from "@utils/events";

export class AddSubgroupPresenter implements AddSubgroupPresenterContract {
  private view!: AddSubgroupViewContract;

  constructor(
    private readonly validation: FormValidation,
    private readonly addSubgroupService: AddSubgroupService
  ) { }

  setView(view: AddSubgroupViewContract): void {
    this.view = view;
  }

  async add(payload: AddSubgroupPresenterContract.Payload): Promise<boolean> {
    this.view.setLoading(true);
    this.view.openSimilarityFeedback(false);

    const validationSuccessfull = this.validation.allValid(payload);

    if (!validationSuccessfull) {
      this.view.updateValidationState(this.validation.errors(payload));
      this.view.setLoading(false);
      return false;
    }

    const { ok: isSubgroupAdded, errors } = await this.addSubgroupService.handle(payload);

    if (isSubgroupAdded) {
      EventEmitter.emit(SubgroupsEvents.SUBGROUP_ADDED);
    } else {
      this.view.setSimilarity(errors);
      this.view.openSimilarityFeedback(true);
    }

    this.view.setLoading(false);
    this.view.updateValidationState([]);
    return isSubgroupAdded;
  }
}
