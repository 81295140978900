import React, { useMemo } from "react";

import { CashflowsView } from "@ui/views/reports";
import { makeCashflowsPresenter } from "@main/factories/presenters/reports";

export const Cashflows: React.FC = () => {
   const cashflowsPresenter = useMemo(() => makeCashflowsPresenter() ,[] );

   return <CashflowsView cashflowsPresenter={cashflowsPresenter}  />;
};
