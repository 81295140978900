import { GetBankAccountService } from "@domain/services/account/GetBankAccountService";
import { RemoveBankAccountService } from "@domain/services/account/RemoveBankAccount";
import { BankAccountViewContract } from "@presentation/views/account/BankAccountViewContract";
import { BankAccountPresenterContract } from "@ui/presenters/account/BankAccountPresenterContract";
import { GroupsByFlowsList } from "@domain/interfaces/accounts/GroupsByFlowsList";

export class BankAccountPresenter implements BankAccountPresenterContract {
  private view!: BankAccountViewContract;

  constructor(
    private readonly getBankAccountService: GetBankAccountService,
    private readonly removeBankAccountService: RemoveBankAccountService
  ) { }

  async removeBankAccount(accountId: number): Promise<void> {
    this.view.setLoadingState(true);

    const isRemoved = await this.removeBankAccountService.handle(accountId);

    if (isRemoved) {
      this.get();
    } else {
      this.view.setLoadingState(false);
    }
  }

  async get(): Promise<GroupsByFlowsList[]> {
    this.view.setLoadingState(true);

    const response = await this.getBankAccountService.handle();

    this.view.setBankAccount(response);
    this.view.setLoadingState(false);
    return response;
  }

  setView(view: BankAccountViewContract): void {
    this.view = view;
  }
}
