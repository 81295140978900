import React, { useState } from "react";

import { withTranslation } from "react-i18next";
import { Modal, Row, Col, FormGroup, Label, Input } from "reactstrap";

import { TreasuryPresenterContract } from "@ui/presenters/treasury";

interface SetHourProps {
  isOpen: boolean;
  onDismiss(): void;
  onSubmit(payload: TreasuryPresenterContract.SetHourPayload): void;
  t: any;
}

const SetHour: React.FC<SetHourProps> = ({
  t,
  isOpen,
  onDismiss,
  onSubmit,
}) => {
  const [hour, setHour] = useState("");

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    onSubmit({ hour });
    onDismiss();
    setHour("");
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={onDismiss}
      scrollable={true}
      id="staticBackdrop"
      size="lg"
    >
      <div className="modal-header">
        <h5 className="modal-title">{t("treasury.modalTitle")}</h5>
        <button
          type="button"
          className="btn-close"
          onClick={onDismiss}
          aria-label="Close"
        />
      </div>

      <form onSubmit={handleSubmit}>
        <div className="modal-body">
          <Row>
            <Col md={6} className="mb-2">
              <FormGroup>
                <Label>{t("treasury.dateField")}</Label>

                <Input
                  type="time"
                  name="hour"
                  className="form-control"
                  onChange={({ target: { value } }) => {
                    setHour(value);
                  }}
                  value={hour}
                />
              </FormGroup>
            </Col>
          </Row>
        </div>

        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-danger me-2"
            onClick={onDismiss}
          >
            {t("Close")}
          </button>

          <button className="btn btn-primary" disabled={!hour}>
            {t("Save")}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default withTranslation()(SetHour);
