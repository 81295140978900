import { HttpRequestBuilder } from "@data/builders";
import { HttpRequestTypes } from "@data/enums";
import { HttpRepository } from "@data/repositories";
import { Similarity } from "@domain/interfaces/similarity";
import { AddGroupService } from "@domain/services/groups";

interface HttpAddGroupResponse {
  message?: Similarity[];
}

interface HttpAddGroupRequest {
  titulo: string;
  mensagem: string;
}

export class HttpAddGroupService implements AddGroupService {
  constructor(
    private readonly httpRepository: HttpRepository
  ) { }

  async handle(payload: AddGroupService.Payload): Promise<AddGroupService.Response> {
    const httpRequest = HttpRequestBuilder.requestToUrl('/groups')
      .withMethod(HttpRequestTypes.POST)
      .withData(payload)
      .build();

    const { data, status } = await this.httpRepository.makeRequest<HttpAddGroupRequest, HttpAddGroupResponse>(httpRequest);

    return {
      ok: status === 201,
      errors: Array.isArray(data.message) ? data.message : []
    };
  }
}
