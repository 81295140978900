import React, { Fragment } from "react";

import { Table } from "reactstrap";
import { withTranslation } from "react-i18next";

import { MoneyFormatStrategy } from "@ui/strategy";
import { CashflowsHelper } from "@ui/utils/reports/CashflowsHelper";
import { CashflowsTransaction, Foressen } from "@domain/entities";

interface OverviewProps {
   t?: any;
   forecastTransactions: Foressen[];
   transactionsCarriedOut: CashflowsTransaction[];
}

const Overview: React.FC<OverviewProps> = ({ t, forecastTransactions, transactionsCarriedOut }) => (
   <Fragment>
      <div className="table-responsive mt-5">
         <Table className="align-middle mb-0" bordered>
            <thead>
               <tr>
                  <th colSpan={5} className="text-center">{t("Overview")}</th>
               </tr>
               <tr>
                  <th style={{ width: "60%" }}>{t("Description")}</th>
                  <th className="text-center">{t("Foreseen")}</th>
                  <th className="text-center">{t("Realized")}</th>
               </tr>
            </thead>
            <tbody>
               <tr>
                  <td>{t("Total revenue")}</td>
                  <td className="text-center">
                     {MoneyFormatStrategy.format(
                        CashflowsHelper.sumTotalRevenues(forecastTransactions)
                     )}
                  </td>
                  <td className="text-center">
                     {MoneyFormatStrategy.format(
                        CashflowsHelper.sumTotalRevenues(transactionsCarriedOut)
                     )}
                  </td>
               </tr>
               <tr>
               <td>{t("Total expense")}</td>
                  <td className="text-center">
                     {MoneyFormatStrategy.format(
                        CashflowsHelper.sumTotalExpenses(forecastTransactions)
                     )}
                  </td>
                  <td className="text-center">
                     {MoneyFormatStrategy.format(
                        CashflowsHelper.sumTotalExpenses(transactionsCarriedOut)
                     )}
                  </td>
               </tr>
               <tr>
               <th>{t("Period result")}</th>
                  <th className="text-center">
                     {MoneyFormatStrategy.format(
                        CashflowsHelper.sumTotalResult(forecastTransactions)
                     )}
                  </th>
                  <th className="text-center">
                     {MoneyFormatStrategy.format(
                        CashflowsHelper.sumTotalResult(transactionsCarriedOut)
                     )}
                  </th>
               </tr>
            </tbody>
         </Table>
      </div>
   </Fragment>
);

export default withTranslation()(Overview);
