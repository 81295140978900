import { HttpGetPrefectureService, HttpRemovePrefectureService } from "@data/services/prefecture";
import { AxiosHttpRepository } from "@infra/http";
import { API_BASE_URL } from "@main/constants/config";
import { PrefecturePresenter } from "@presentation/presenters/prefecture";
import { PrefecturePresenterContract } from "@ui/presenters/prefecture";

export const makePrefecturePresenter = (): PrefecturePresenterContract => {
    const httpRepository = new AxiosHttpRepository(API_BASE_URL);
    const getPrefectureService = new HttpGetPrefectureService(httpRepository);
    const removePrefectureService = new HttpRemovePrefectureService(httpRepository);

    return new PrefecturePresenter( getPrefectureService, removePrefectureService );
};
