import { HttpRequestBuilder } from "@data/builders";
import { HttpRequestTypes } from "@data/enums";
import { HttpRepository } from "@data/repositories";
import { Source } from "@domain/entities";
import { GetSourcesService } from "@domain/services/sources";


export class HttpGetSourcesService implements GetSourcesService {
    constructor(
        private readonly httpRepository: HttpRepository
    ) { }

    async handle(): Promise<Source[]> {
        const request = HttpRequestBuilder
            .requestToUrl('/sources')
            .withMethod(HttpRequestTypes.GET)
            .build();

        const { data } = await this.httpRepository.makeRequest<any, Source[]>(request);

        return data;
    }
}
