import React, { useEffect, useState } from "react";

import Loading from "@ui/components/Loading";

import { Container, Table, Row, Col } from "reactstrap";

import { withTranslation } from "react-i18next";
import { Breadcrumbs } from "@ui/components/Common";

import {
  EditSubgroupPresenterContract,
  RevenuesAndExpensesPresenterContract,
} from "@ui/presenters/revenues-expenses";

import { AddSubgroup, EditSubgroup } from "@main/pages/revenues-expenses";
import { EventEmitter } from "@utils/events";
import { SubgroupsEvents } from "@domain/events";
import { Subgroup } from "@domain/entities";
import AddActionButton from "@ui/components/AddActionButton";
import EditActionButton from "@ui/components/EditActionButton";
import RemoveActionButton from "@ui/components/RemoveActionButton";

interface RevenuesAndExpensesViewProps {
  t?: any;
  revenuesAndExpensesPresenter: RevenuesAndExpensesPresenterContract;
}

const RevenuesAndExpensesView: React.FC<RevenuesAndExpensesViewProps> = ({
  t,
  revenuesAndExpensesPresenter,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [subgroups, setSubgroupsList] = useState<Subgroup[]>([]);
  const [isAddingSubgroup, setIsAddingSubgroup] = useState<boolean>(false);
  const [subgroupToEdit, setSubgroupToEdit] =
    useState<EditSubgroupPresenterContract.Payload | null>(null);

  useEffect(() => {
    revenuesAndExpensesPresenter.setView({
      setLoadingState,
      setSubgroups,
    });

    revenuesAndExpensesPresenter.get();

    EventEmitter.subscribe(SubgroupsEvents.SUBGROUP_ADDED, onSubgroupAdded);
    EventEmitter.subscribe(SubgroupsEvents.SUBGROUP_UPDATED, onSubgroupUpdated);

    return () => {
      EventEmitter.unsubscribe(SubgroupsEvents.SUBGROUP_ADDED, onSubgroupAdded);
      EventEmitter.unsubscribe(
        SubgroupsEvents.SUBGROUP_UPDATED,
        onSubgroupUpdated
      );
    };
  }, []);

  const setLoadingState = (isLoading: boolean): void => setLoading(isLoading);

  const setSubgroups = (subgroups: Subgroup[]): void =>
    setSubgroupsList(subgroups);

  const onSubgroupAdded = () => {
    revenuesAndExpensesPresenter.get();
    setIsAddingSubgroup(false);
  };

  const onSubgroupUpdated = () => {
    revenuesAndExpensesPresenter.get();
    setSubgroupToEdit(null);
  };

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs
          breadcrumbItem={t("Revenues/Expenses")}
          title={t("Accounts Plan")}
        />
        <Row>
          {loading ? (
            <Col xl={12} className="mt-4">
              <Loading />
            </Col>
          ) : (
            <Col xl={12} className="mt-4">
              <div className="d-sm-flex align-items-center justify-content-end">
                <AddActionButton
                  onClick={() => setIsAddingSubgroup(true)}
                />
              </div>

              <div className="table-responsive">
                <Table className="align-middle mb-4" bordered>
                  <thead>
                    <tr>
                      <th style={{ width: "85%" }}>{t("Description")}</th>
                      <th style={{ width: "15%" }} className="text-center">
                        {t("Actions")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {subgroups.map(({ name, id }, key) => (
                      <tr key={key}>
                        <td scope="row">{name}</td>
                        <td className="text-center">
                          <EditActionButton
                            isLastOfList={false}
                            label="Editar"
                            onClick={() => {
                              setSubgroupToEdit({
                                id,
                                name,
                              });
                            }}
                          />

                          <RemoveActionButton
                            onClick={() => {
                              revenuesAndExpensesPresenter.removeSubgroup(id);
                            }}
                            isLastOfList={true}
                            label="Excluir"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Col>
          )}
        </Row>
      </Container>

      <AddSubgroup
        isOpen={isAddingSubgroup}
        onDismiss={() => setIsAddingSubgroup(false)}
      />

      <EditSubgroup
        onDismiss={() => setSubgroupToEdit(null)}
        subgroupToEdit={subgroupToEdit}
      />
    </div>
  );
};

export default withTranslation()(RevenuesAndExpensesView);
