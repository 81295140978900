import { HttpAddSubgroupService } from "@data/services/revenues-expenses";
import { AxiosHttpRepository } from "@infra/http";
import { API_BASE_URL } from "@main/constants/config";
import { AddSubgroupPresenter } from "@presentation/presenters/revenues-expenses";
import { FieldValidationBuilder } from "@presentation/validation/builders";
import { ValidationComposite } from "@presentation/validation/validators";
import { AddSubgroupPresenterContract } from "@ui/presenters/revenues-expenses";

export const makeAddSubgroupPresenter = (): AddSubgroupPresenterContract => {
  const validation = new ValidationComposite([
    ...FieldValidationBuilder.field("name").required().build(),
  ]);

  const httpRepository = new AxiosHttpRepository(API_BASE_URL);
  const addSubgroupService = new HttpAddSubgroupService(httpRepository);

  return new AddSubgroupPresenter(validation, addSubgroupService);
};
