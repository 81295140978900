import React, { Fragment, useState } from "react";

import { Card, CardBody, Col, FormGroup, Label, Row } from "reactstrap";

import { withTranslation } from "react-i18next";

import { FieldValidationBuilder } from "@presentation/validation/builders";
import { ValidationComposite } from "@presentation/validation/validators";
import { useValidation } from "@ui/hooks";
import { SelectItem } from "@ui/interfaces";
import { BankStatementPresenterContract } from "@ui/presenters/reports";
import { GroupsByFlowsList } from "@domain/interfaces/accounts";

interface SearchFormProps {
  t: any;
  accounts: GroupsByFlowsList[];
  onSubmit(period: BankStatementPresenterContract.Params): void;
}

const validation = new ValidationComposite([
  ...FieldValidationBuilder.field('beginOfPeriod').required().build(),
  ...FieldValidationBuilder.field('endOfPeriod').required().build(),
  ...FieldValidationBuilder.field('accountId').required().build(),
]);

const SearchForm: React.FC<SearchFormProps> = ({ t, onSubmit, accounts }) => {
  const { ValidationFeedback, validationState, ValidationSelect, ValidationInput, updateValidationState } = useValidation();

  const [payload, setPayload] = useState<BankStatementPresenterContract.Params>({
    beginOfPeriod: "",
    endOfPeriod: "",
    cashFlow: 0,
    accountId: 0,
    accountName: ""
  });

  const updateField = (fieldName: string, value: string) => {
    setPayload((oldState) => ({
      ...oldState,
      [fieldName]: value
    }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (validation.allValid(payload)) {
      updateValidationState([]);
      onSubmit(payload);
    } else {
      updateValidationState(validation.errors(payload));
    }
  };

  const optionsAccounts: SelectItem[] = accounts
    .map(({ name, id }) => ({ label: name, value: id }));

  return (
    <Fragment>
      <form onSubmit={handleSubmit}>
        <Card>
          <CardBody>
            <Row>
              <Col md={12} className="mb-1">
                <FormGroup>
                  <Label>{t("Bank accounts")}</Label>

                  <ValidationSelect
                    field="accountId"
                    options={optionsAccounts}
                    validationState={validationState}
                    name="accountId"
                    className="form-control"
                    placeholder={t('Select')}
                    onChange={({ target: { value } }) => {
                      const accountName = optionsAccounts
                        .find(({ value: accountId }) => accountId == value)?.label ?? "";

                      setPayload({
                        ...payload,
                        accountId: parseInt(value),
                        accountName
                      });
                    }}
                  />

                  <ValidationFeedback field="accountId" />
                </FormGroup>
              </Col>

              <Col className="mb-3" md={6} sm={12}>
                <Label className="form-Label">
                  {t("Beginning of period")}
                </Label>

                <ValidationInput
                  field="beginOfPeriod"
                  validationState={validationState}
                  className="form-control"
                  type="date"
                  name="beginOfPeriod"
                  onChange={({ target: { name, value } }) => updateField(name, value)}
                />

                <ValidationFeedback field="beginOfPeriod" />
              </Col>

              <Col className="mb-3" md={6} sm={12}>
                <Label className="form-Label">{t("End of period")}</Label>

                <ValidationInput
                  field="endOfPeriod"
                  name="endOfPeriod"
                  validationState={validationState}
                  className="form-control"
                  type="date"
                  onChange={({ target: { name, value } }) => updateField(name, value)}
                />

                <ValidationFeedback field="endOfPeriod" />
              </Col>

              <Col sm={11} />
              <Col sm={1}>
                <button
                  className="btn btn-primary"
                >
                  {t("Search")}
                </button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </form>
    </Fragment>
  );
};

export default withTranslation()(SearchForm);
