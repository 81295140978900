import React, { useMemo } from "react";

import EditEffectiveBillsView from "@ui/views/bills/EditEffectiveBillsView";

import { makeEditEffectiveBillsPresenter } from "@main/factories/presenters/bills";
import { EditEffectiveBillsPresenterContract } from "@ui/presenters/bills";

interface EditEffectiveBillsProps {
   billsToEdit: EditEffectiveBillsPresenterContract.Payload | null;
   type: string;
   onDismiss(): void;
}

const EditEffectiveBills: React.FC<EditEffectiveBillsProps> = (props) => {
   const editEffectiveBillsPresenter = useMemo(() => makeEditEffectiveBillsPresenter(), []);

   return <EditEffectiveBillsView { ...props } editEffectiveBillsPresenter={editEffectiveBillsPresenter} />;
};

export default EditEffectiveBills;
