import React, { Fragment, useState } from "react";

import { Card, CardBody, Col, FormGroup, Label, Row } from "reactstrap";

import { withTranslation } from "react-i18next";

import { CashFlow, Group, Subgroup, Provider } from "@domain/entities";
import { FieldValidationBuilder } from "@presentation/validation/builders";
import { ValidationComposite } from "@presentation/validation/validators";
import { useValidation } from "@ui/hooks";
import { SelectItem } from "@ui/interfaces";
import { CashflowsPresenterContract } from "@ui/presenters/reports";
import { GroupsByFlowsList } from "@domain/interfaces/accounts";

interface SearchFormProps {
  t: any;
  cashflows: CashFlow[];
  groups: Group[];
  subgroups: Subgroup[];
  accounts: GroupsByFlowsList[];
  providers: Provider[];
  onSubmit(params: CashflowsPresenterContract.Params): void;
}

const validation = new ValidationComposite([
  ...FieldValidationBuilder.field("beginOfPeriod").required().build(),
  ...FieldValidationBuilder.field("endOfPeriod").required().build(),
  ...FieldValidationBuilder.field("cashFlow").required().build(),
]);

const SearchForm: React.FC<SearchFormProps> = ({
  t,
  onSubmit,
  cashflows,
  groups,
  subgroups,
  accounts,
  providers,
}) => {
  const {
    ValidationFeedback,
    validationState,
    ValidationSelect,
    ValidationInput,
    updateValidationState,
  } = useValidation();

  const [payload, setPayload] = useState<CashflowsPresenterContract.Params>({
    beginOfPeriod: "",
    cashFlow: "",
    endOfPeriod: "",
    groupId: 0,
    providerId: 0,
    subgroup: 0,
    value: 0,
    accountId: 0,
    date: "",
    flowsType: 0,
    groups: 0,
    observation: "",
    transactionsEvents: 0,
    transactionsType: 0,
  });

  const updateField = (fieldName: string, value: string) => {
    setPayload((oldState) => ({
      ...oldState,
      [fieldName]: value,
    }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (validation.allValid(payload)) {
      updateValidationState([]);
      onSubmit(payload);
    } else {
      updateValidationState(validation.errors(payload));
    }
  };

  const optionsCashflows: SelectItem[] = cashflows.map(({ id, name }) => ({
    label: name,
    value: id,
  }));

  const optionsGroups: SelectItem[] = groups.map(({ id, name }) => ({
    label: name,
    value: id,
  }));

  const optionsSubgroups: SelectItem[] = subgroups.map(({ id, name }) => ({
    label: name,
    value: id,
  }));

  const optionsAccount: SelectItem[] = accounts.map(({ id, name }) => ({
    label: name,
    value: id,
  }));

  const optionsProvider: SelectItem[] = providers.map(({ id, name }) => ({
    label: name,
    value: id,
  }));

  return (
    <Fragment>
      <form onSubmit={handleSubmit}>
        <Card>
          <CardBody>
            <Row>
              <Col md={12} className="mb-1">
                <FormGroup>
                  <Label>{t("Cashflows")}</Label>

                  <ValidationSelect
                    field="cashFlow"
                    options={optionsCashflows}
                    validationState={validationState}
                    name="cashFlow"
                    className="form-control"
                    placeholder={t("Select")}
                    onChange={({ target: { name, value } }) =>
                      updateField(name, value)
                    }
                  />

                  <ValidationFeedback field="cashFlow" />
                </FormGroup>
              </Col>

              <Col md={12} className="mb-1">
                <FormGroup>
                  <Label>{t("Group")}</Label>
                  <ValidationSelect
                    field="groupId"
                    options={optionsGroups}
                    validationState={validationState}
                    name="groupId"
                    className="form-control"
                    placeholder={t("Group placeholder")}
                    onChange={({ target: { value, name } }) =>
                      updateField(name, value)
                    }
                  />

                  <ValidationFeedback field="groupId" />
                </FormGroup>
              </Col>

              <Col md={12} className="mb-1">
                <FormGroup>
                  <Label>{t("Subgroup")}</Label>
                  <ValidationSelect
                    field="subgroupId"
                    options={optionsSubgroups}
                    validationState={validationState}
                    name="subgroupId"
                    className="form-control"
                    placeholder={t("Subgroup placeholder")}
                    onChange={({ target: { value, name } }) =>
                      updateField(name, value)
                    }
                  />

                  <ValidationFeedback field="subgroupId" />
                </FormGroup>
              </Col>

              <Col md={12} className="mb-1">
                <FormGroup>
                  <Label>{t("Bank accounts")}</Label>

                  <ValidationSelect
                    field="accountId"
                    options={optionsAccount}
                    validationState={validationState}
                    name="accountId"
                    className="form-control"
                    placeholder={t("Account placeholder")}
                    onChange={({ target: { value, name } }) =>
                      updateField(name, value)
                    }
                  />

                  <ValidationFeedback field="accountId" />
                </FormGroup>
              </Col>

              <Col md={12} className="mb-1">
                <FormGroup>
                  <Label>{t("Provider")}</Label>
                  <ValidationSelect
                    field="providerId"
                    options={optionsProvider}
                    validationState={validationState}
                    name="providerId"
                    className="form-control"
                    placeholder={t("Provider placeholder")}
                    onChange={({ target: { value, name } }) =>
                      updateField(name, value)
                    }
                  />

                  <ValidationFeedback field="providerId" />
                </FormGroup>
              </Col>

              <Col className="mb-3" md={6} sm={12}>
                <Label htmlFor="example-date-input" className="form-Label">
                  {t("Beginning of period")}
                </Label>

                <ValidationInput
                  field="beginOfPeriod"
                  validationState={validationState}
                  className="form-control"
                  type="date"
                  name="beginOfPeriod"
                  onChange={({ target: { name, value } }) =>
                    updateField(name, value)
                  }
                />

                <ValidationFeedback field="beginOfPeriod" />
              </Col>

              <Col className="mb-3" md={6} sm={12}>
                <Label htmlFor="example-date-input" className="form-Label">
                  {t("End of period")}
                </Label>

                <ValidationInput
                  field="endOfPeriod"
                  name="endOfPeriod"
                  validationState={validationState}
                  className="form-control"
                  type="date"
                  onChange={({ target: { name, value } }) =>
                    updateField(name, value)
                  }
                />

                <ValidationFeedback field="endOfPeriod" />
              </Col>

              <Col sm={11} />
              <Col sm={1}>
                <button className="btn btn-primary">{t("Search")}</button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </form>
    </Fragment>
  );
};

export default withTranslation()(SearchForm);
