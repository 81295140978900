import { HttpEditProviderService } from "@data/services/provider/HttpEditProviderService";
import { AxiosHttpRepository } from "@infra/http";
import { API_BASE_URL } from "@main/constants/config";
import { EditProviderPresenter } from "@presentation/presenters/provider/EditProviderPresenter";
import { FieldValidationBuilder } from "@presentation/validation/builders";
import { ValidationComposite } from "@presentation/validation/validators";
import { EditProviderPresenterContract } from "@ui/presenters/provider";

export const makeEditProviderPresenter = (): EditProviderPresenterContract => {
  const validation = new ValidationComposite([
    ...FieldValidationBuilder.field("id").required().build(),
    ...FieldValidationBuilder.field("name").required().build(),
    ...FieldValidationBuilder.field("document").document().build(),
  ]);

  const httpRepository = new AxiosHttpRepository(API_BASE_URL);
  const editProviderService = new HttpEditProviderService(httpRepository);

  return new EditProviderPresenter(validation, editProviderService);
};
