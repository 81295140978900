import { Component } from "react";

interface Props {
  children: React.ReactChild;
}

class ComponentToPrint extends Component<Props> {
  render() {
    const { children } = this.props;

    return <div className="p-10">{children}</div>;
  }
}

export default ComponentToPrint;
