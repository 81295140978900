import React, { Fragment, useState } from "react";

import { Card, CardBody, Col, Label, Row } from "reactstrap";

import { withTranslation } from "react-i18next";

import { FieldValidationBuilder } from "@presentation/validation/builders";
import { ValidationComposite } from "@presentation/validation/validators";
import { useValidation } from "@ui/hooks";
import { EffectiveBillsPresenterContract } from "@ui/presenters/bills";
import { useParams } from "react-router-dom";

interface SearchFormProps {
  t: any;
  type: string;
  onSubmit(period: EffectiveBillsPresenterContract.Params): void;
}

const validation = new ValidationComposite([
  ...FieldValidationBuilder.field('start').required().build(),
  ...FieldValidationBuilder.field('end').required().build(),
]);

const SearchForm: React.FC<SearchFormProps> = ({ t, onSubmit }) => {
  const { type } = useParams<{ type: string }>();
  const { ValidationFeedback, validationState, ValidationInput, updateValidationState } = useValidation();

  const [payload, setPayload] = useState<EffectiveBillsPresenterContract.Params>({
    start: "",
    end: "",
    transactionType: type
  });

  const updateField = (fieldName: string, value: string) => {
    setPayload((oldState) => ({
      ...oldState,
      [fieldName]: value
    }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (validation.allValid(payload)) {
      updateValidationState([]);
      onSubmit(payload);
    } else {
      updateValidationState(validation.errors(payload));
    }
  };

  return (
    <Fragment>
      <form onSubmit={handleSubmit}>
        <Card>
          <CardBody>
            <Row>
              <Col className="mb-3" md={6} sm={12}>
                <Label
                  htmlFor="example-date-input"
                  className="form-Label"
                >
                  {t("Beginning of period")}
                </Label>

                <ValidationInput
                  field="start"
                  validationState={validationState}
                  className="form-control"
                  type="date"
                  name="start"
                  onChange={({ target: { name, value } }) => updateField(name, value)}
                />

                <ValidationFeedback field="start" />
              </Col>

              <Col className="mb-3" md={6} sm={12}>
                <Label
                  htmlFor="example-date-input"
                  className="form-Label">
                  {t("End of period")}
                </Label>

                <ValidationInput
                  field="end"
                  name="end"
                  validationState={validationState}
                  className="form-control"
                  type="date"
                  onChange={({ target: { name, value } }) => updateField(name, value)}
                />

                <ValidationFeedback field="end" />
              </Col>

              <Col sm={12}>
                <div className="d-sm-flex align-items-center justify-content-end">
                  <button
                    className="btn btn-primary"
                  >
                    {t("Search")}
                  </button>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </form>
    </Fragment >
  );
};

export default withTranslation()(SearchForm);
