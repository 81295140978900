import React, { useMemo } from 'react';

import { EditProviderPresenterContract } from '@ui/presenters/provider';
import { makeEditProviderPresenter } from '@main/factories/presenters/provider';
import EditProviderView from '@ui/views/providers/EditProviderView';

interface EditProviderProps {
   providerToEdit: EditProviderPresenterContract.Payload | null;
   onDismiss(): void;
 }
 
 export const EditProvider: React.FC<EditProviderProps> = (props) => {
   const editProviderPresenter = useMemo(() => makeEditProviderPresenter(), []);
 
   return <EditProviderView {...props} editProviderPresenter={editProviderPresenter} />;
 };
