import { AccountGroup, Bank, CashFlow, Source } from "@domain/entities";

import { AccountsEvents } from "@domain/events";

import {
  AddAccountService,
  GetAccountGroupsService,
  GetBankService,
} from "@domain/services/account";

import { GetCashflowsService } from "@domain/services/cashflows";
import { GetSourcesService } from "@domain/services/sources";
import { FormValidation } from "@presentation/validation/interfaces";
import { AddAccountViewContract } from "@presentation/views/account";
import { AddAccountPresenterContract } from "@ui/presenters/account";
import { EventEmitter } from "@utils/events";

export class AddAccountPresenter implements AddAccountPresenterContract {
  private view!: AddAccountViewContract;

  constructor(
    private readonly validation: FormValidation,
    private readonly getBanksService: GetBankService,
    private readonly getAccountGroupsService: GetAccountGroupsService,
    private readonly getCashflowsService: GetCashflowsService,
    private readonly getSourcesService: GetSourcesService,
    private readonly addAccountService: AddAccountService
  ) {}

  async add(payload: AddAccountPresenterContract.Payload): Promise<boolean> {
    this.view.setLoading(true);

    const validationSuccessfull = this.validation.allValid(payload);

    if (!validationSuccessfull) {
      this.view.updateValidationState(this.validation.errors(payload));
      this.view.setLoading(false);
      return false;
    }

    const isAccountAdded = await this.addAccountService.handle(payload);

    if (isAccountAdded) {
      EventEmitter.emit(AccountsEvents.ACCOUNT_ADDED);
    }

    this.view.setLoading(false);
    this.view.updateValidationState([]);
    return isAccountAdded;
  }

  setView(view: AddAccountViewContract): void {
    this.view = view;
  }

  async getBanks(): Promise<Bank[]> {
    const banks = await this.getBanksService.handle();
    this.view.setBank(banks);
    return banks;
  }

  async getCashflows(): Promise<CashFlow[]> {
    const cashflows = await this.getCashflowsService.handle();
    this.view.setCashflows(cashflows);
    return cashflows;
  }

  async getAccountGroups(): Promise<AccountGroup[]> {
    const accountGroups = await this.getAccountGroupsService.handle();
    this.view.setAccountGroups(accountGroups);
    return accountGroups;
  }

  async getSources(): Promise<Source[]> {
    const sources = await this.getSourcesService.handle();
    this.view.setSources(sources);
    return sources;
  }
}
