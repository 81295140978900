import React, { useMemo } from "react";

import { UpdateProfileView } from "@ui/views/profiles";
import { makeUpdateProfilePresenter } from "@main/factories/presenters/profile";

interface UpdateProfileProps {
  userInfo: any | null;
  onDismiss(): void;
}

export const UpdateProfile: React.FC<UpdateProfileProps> = (props) => {
  const updateProfilePresenter = useMemo(() => makeUpdateProfilePresenter(), []);

  return <UpdateProfileView {...props} updateProfilePresenter={updateProfilePresenter} />;
};
