import React, { useMemo } from "react";

import EditNonEffectiveBillsView from "@ui/views/bills/EditNonEffectiveBillsView";

import { makeEditNonEffectiveBillsPresenter } from "@main/factories/presenters/bills";
import { EditNonEffectiveBillsPresenterContract } from "@ui/presenters/bills";

interface EditNonEffectiveBillsProps {
   billsToEdit: EditNonEffectiveBillsPresenterContract.Payload | null;
   type: string;
   onDismiss(): void;
}

const EditNonEffectiveBills: React.FC<EditNonEffectiveBillsProps> = (props) => {
   const editNonEffectiveBillsPresenter = useMemo(() => makeEditNonEffectiveBillsPresenter(), []);

   return <EditNonEffectiveBillsView { ...props } editNonEffectiveBillsPresenter={editNonEffectiveBillsPresenter} />;
};

export default EditNonEffectiveBills;
