import React, { useEffect, useState } from "react";

import { Container, Table, Row, Col } from "reactstrap";

import { withTranslation } from "react-i18next";

import {
  EditGroupPresenterContract,
  GroupsPresenterContract,
} from "@ui/presenters/groups";

import { Breadcrumbs } from "@ui/components/Common";
import { Group } from "@domain/entities";
import { AddGroup, EditGroup } from "@main/pages/groups";
import { EventEmitter } from "@utils/events";
import { GroupsEvents } from "@domain/events";

import Loading from "@ui/components/Loading";
import AddActionButton from "@ui/components/AddActionButton";
import EditActionButton from "@ui/components/EditActionButton";
import RemoveActionButton from "@ui/components/RemoveActionButton";

interface GroupsViewProps {
  t?: any;
  groupsPresenter: GroupsPresenterContract;
}

const GroupsView: React.FC<GroupsViewProps> = ({ t, groupsPresenter }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [groups, setListGroups] = useState<Group[]>([]);
  const [isAddingGroup, setIsAddingGroup] = useState<boolean>(false);
  const [groupToEdit, setGroupToEdit] =
    useState<EditGroupPresenterContract.Payload | null>(null);

  useEffect(() => {
    groupsPresenter.setView({
      setGroups,
      setLoadingState,
    });
    groupsPresenter.get();

    EventEmitter.subscribe(GroupsEvents.GROUP_ADDED, onGroupAdded);
    EventEmitter.subscribe(GroupsEvents.GROUP_EDITED, onGroupEdited);

    return () => {
      EventEmitter.unsubscribe(GroupsEvents.GROUP_ADDED, onGroupAdded);
      EventEmitter.unsubscribe(GroupsEvents.GROUP_EDITED, onGroupEdited);
    };
  }, []);

  const onGroupAdded = () => {
    setIsAddingGroup(false);
    groupsPresenter.get();
  };

  const onGroupEdited = () => {
    setGroupToEdit(null);
    groupsPresenter.get();
  };

  const setGroups = (groups: Group[]): void => setListGroups(groups);
  const setLoadingState = (isLoading: boolean): void => setLoading(isLoading);

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs breadcrumbItem={t("Groups")} title={t("Accounts Plan")} />
        <Row>
          {loading ? (
            <Col xl={12} className="mt-4">
              <Loading />
            </Col>
          ) : (
            <Col xl={12} className="mt-4">
              <div className="d-sm-flex align-items-center justify-content-end">
                <AddActionButton
                  onClick={() => setIsAddingGroup(true)}
                />
              </div>

              <div className="table-responsive">
                <Table className="align-middle mb-4" bordered>
                  <thead>
                    <tr>
                      <th style={{ width: "85%" }}>{t("Group")}</th>
                      <th style={{ width: "15%" }} className="text-center">
                        {t("Actions")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {groups.map(({ id, name }) => (
                      <tr key={id}>
                        <td scope="row">{name}</td>
                        <td className="text-center">
                          <EditActionButton
                            isLastOfList={false}
                            label={t("Edit")}
                            onClick={() =>
                              setGroupToEdit({
                                id: id,
                                name: name,
                              })
                            }
                          />
                          <RemoveActionButton
                            onClick={() => groupsPresenter.removeGroup(id)}
                            isLastOfList
                            label="Excluir"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Col>
          )}
        </Row>
      </Container>

      <AddGroup
        isOpen={isAddingGroup}
        onDismiss={() => setIsAddingGroup(false)}
      />

      <EditGroup
        groupToEdit={groupToEdit}
        onDismiss={() => setGroupToEdit(null)}
      />
    </div>
  );
};

export default withTranslation()(GroupsView);
