import { HttpGetCashflowsService } from "@data/services/cashflows";
import { HttpGetConsolidatedReportService } from "@data/services/consolidated-report/HttpGetConsolidatedReportService";
import { AxiosHttpRepository } from "@infra/http";
import { API_BASE_URL } from "@main/constants/config";
import { CreditRadarByFlowPresenter } from "@presentation/presenters/dashboard";
import { CreditRadarByFlowPresenterContract } from "@ui/presenters/dashboard";

export const makeCreditRadarByFlowPresenter = (): CreditRadarByFlowPresenterContract => {
    const httpRepository = new AxiosHttpRepository(API_BASE_URL);
    const getConsolidatedReportService = new HttpGetConsolidatedReportService(httpRepository);
    const getCashflowsService = new HttpGetCashflowsService(httpRepository);

    return new CreditRadarByFlowPresenter(
        getConsolidatedReportService,
        getCashflowsService,
    );
};
