import { MoneyFormat } from "@ui/interfaces";
import { MoneyFormatBr } from "@ui/utils/MoneyFormatBr";

interface FormatterList {
   lang: string;
   formatter: MoneyFormat;
}

const formatters: FormatterList[] = [
   {
      lang: "pt-BR",
      formatter: new MoneyFormatBr()
   }
];

export class MoneyFormatStrategy implements MoneyFormat {
   handle(value: number): string {
      const chosenFormatter = formatters.find(
         ({ lang }) => lang === localStorage.getItem("I18N_LANGUAGE")
      );

      if (!chosenFormatter) {
         return new MoneyFormatBr().handle(value);
      }

      return chosenFormatter?.formatter.handle(value) ?? "";
   }

   static format(value: number): string {
      return new MoneyFormatStrategy().handle(value);
   }
}
