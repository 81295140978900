import { HttpGetFlowsService } from "@data/services/reports/HttpGetFlowsService";
import { HttpGetCashflowsService } from "@data/services/cashflows";
import { HttpGetGroupsService } from "@data/services/groups";
import { HttpGetSubgroupsService } from "@data/services/revenues-expenses";
import { HttpGetBankAccountService } from "@data/services/account/HttpGetBankAccountService";
import { HttpGetProviderService } from "@data/services/provider/HttpGetProvidersService";
import { CashflowsPresenter } from "@presentation/presenters/reports";
import { CashflowsPresenterContract } from "@ui/presenters/reports";
import { AxiosHttpRepository } from "@infra/http";
import { API_BASE_URL } from "@main/constants/config";

export const makeCashflowsPresenter = (): CashflowsPresenterContract => {
   const httpRepository = new AxiosHttpRepository(API_BASE_URL);
   const getFlowsService = new HttpGetFlowsService(httpRepository);
   const getCashflowsService = new HttpGetCashflowsService(httpRepository);
   const getGroupsService = new HttpGetGroupsService(httpRepository);
   const getSubgroupsService = new HttpGetSubgroupsService(httpRepository);
   const getAcoountsService = new HttpGetBankAccountService(httpRepository);
   const getProviderService = new HttpGetProviderService(httpRepository);

   return new CashflowsPresenter(
      getFlowsService,
      getCashflowsService,
      getGroupsService,
      getSubgroupsService,
      getAcoountsService,
      getProviderService
   );
};
