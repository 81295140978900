import React, { Fragment, useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";

import { Container, Row, Col, Table, Button } from "reactstrap";
import ComponentToPrint from "@ui/components/Print";
import { useReactToPrint } from "react-to-print";

import { Breadcrumbs } from "@ui/components/Common";
import Loading from "@ui/components/Loading";

import logo from "@ui/assets/images/logo.jpg";
import { CashFlow, Group, Subgroup } from "@domain/entities";
import { ConsolidatedTabledPresenterContract } from "@ui/presenters/reports";
import { SearchForm } from "@ui/components/reports";
import { DateFormatStrategy, MoneyFormatStrategy } from "@ui/strategy";
import { GetUserInfo } from "@ui/utils/reports";

interface ConsolidatedTabledViewProps {
  t: any;
  consolidatedTabledPresenter: ConsolidatedTabledPresenterContract;
}

interface ConsolidatedTabledSubgroupItem {
  month: number;
  year: number;
  subgroup: number;
}

const ConsolidatedTabledView: React.FC<ConsolidatedTabledViewProps> = ({
  t,
  consolidatedTabledPresenter,
}) => {
  const [activeCashflowId, setActiveCashflowId] = useState<number>(0);
  const [cashflows, setCashflowsList] = useState<any[]>([]);
  const [groups, setGroups] = useState<Group[]>([]);
  const [subgroups, setSubgroups] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [downloadReport, setDownloadReportList] =
    useState<ConsolidatedTabledPresenterContract.Params>({
      beginOfPeriod: "",
      endOfPeriod: "",
      cashFlow: 0,
      cityId: GetUserInfo.userInfo().prefectureId,
      groupId: 0,
      subgroupId: 0,
    });

  const [consolidatedReport, setConsolidatedReportState] =
    useState<ConsolidatedTabledPresenterContract.ConsolidatedData>({
      beginOfPeriod: "",
      endOfPeriod: "",
      cashFlow: "",
      forecastTransactions: [],
      prefecture: "",
      transactionsCarriedOut: [],
    });

  useEffect(() => {
    consolidatedTabledPresenter.setView({
      setCashflows,
      setConsolidatedReport,
      setGroupsList,
      setLoadingState,
      setSubgroupsList,
      setDownloadReport,
    });

    consolidatedTabledPresenter.getCashflows();
    consolidatedTabledPresenter.getGroups();
    consolidatedTabledPresenter.getSubgroups();
  }, []);

  const printRef = useRef(null);

  useEffect(() => {
    consolidatedTabledPresenter.getCashflows();
    consolidatedTabledPresenter.getGroups();
    consolidatedTabledPresenter.getSubgroups();
  }, []);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `Saldo de Contas`,
    onPrintError: () => false,
    pageStyle: `
            .noPrint {
                display:none;
            }
        `,
  });

  const setLoadingState = (isLoading: boolean): void => setLoading(isLoading);
  const setGroupsList = (groups: Group[]): void =>
    setGroups(groups);
  const setSubgroupsList = (subgroups: any[]): void =>
    setSubgroups(subgroups);
  const setCashflows = (cashflows: any[]): void =>
    setCashflowsList(cashflows);
  const setConsolidatedReport = (
    consolidatedReport: ConsolidatedTabledPresenterContract.ConsolidatedData
  ): void => setConsolidatedReportState(consolidatedReport);

  const setDownloadReport = (
    params: ConsolidatedTabledPresenterContract.Params
  ): void => setDownloadReportList(params);

  const flowToShowInReport = cashflows.find(
    ({ id }) => id == activeCashflowId
  )?.name;

  const subgroupsTransactionsCarriedOut =
    consolidatedReport.transactionsCarriedOut
      .map(
        ({
          transacoes_eventos_id,
          mes,
          ano,
        }): ConsolidatedTabledSubgroupItem => ({
          month: mes,
          subgroup: transacoes_eventos_id,
          year: ano,
        })
      )
      .map((item) => JSON.stringify(item));

  const subgroupsTransactionsForecast = consolidatedReport.forecastTransactions
    .map(
      ({
        transacoes_eventos_id,
        mes,
        ano,
      }): ConsolidatedTabledSubgroupItem => ({
        month: mes,
        subgroup: transacoes_eventos_id,
        year: ano,
      })
    )
    .map((item) => JSON.stringify(item));

  const mergeSubgroupsItens = [
    ...subgroupsTransactionsCarriedOut,
    ...subgroupsTransactionsForecast,
  ];
  const subgroupsItens: ConsolidatedTabledSubgroupItem[] = Array.from(
    new Set(mergeSubgroupsItens)
  ).map((item) => JSON.parse(item));

  const optionsMonths = [
    { id: 1, label: "Janeiro" },
    { id: 2, label: "Fevereiro" },
    { id: 3, label: "Março" },
    { id: 4, label: "Abril" },
    { id: 5, label: "Maio" },
    { id: 6, label: "Junho" },
    { id: 7, label: "Julho" },
    { id: 8, label: "Agosto" },
    { id: 9, label: "Setembro" },
    { id: 10, label: "Outubro" },
    { id: 11, label: "Novembro" },
    { id: 12, label: "Dezembro" },
  ];

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs
          title={t("Consolidated report")}
          breadcrumbItem={t("Consolidated Tabled")}
        />
        <Row>
          <Col xl={12} className="mt-4">
            <SearchForm
              cashflows={cashflows}
              groups={groups}
              subgroups={subgroups}
              onSubmit={(params) => {
                consolidatedTabledPresenter.get(params);
                setActiveCashflowId(params.cashFlow);
                setDownloadReport(params);
              }}
            />

            <div className="d-sm-flex align-items-center justify-content-end">
              <Button
                color="primary"
                className="btn btn-primary float-right"
                onClick={handlePrint}
              >
                {t("Print")}
              </Button>

              <Button
                color="primary"
                className="btn btn-primary float-right"
                style={{ marginLeft: 15 }}
                onClick={() =>
                  consolidatedTabledPresenter.download(downloadReport)
                }
              >
                {t("Generate spreadsheet")}
              </Button>
            </div>

            {loading ? (
              <div className="mt-5">
                <Loading />
              </div>
            ) : (
              <Fragment>
                {Boolean(activeCashflowId) && (
                  <ComponentToPrint ref={printRef}>
                    <div className="font-arial-rlt">
                      <div className="d-sm-flex flex-column align-items-center justify-content-center mt-5 mb-3">
                        <img
                          style={{ objectFit: "contain" }}
                          className=""
                          src={logo}
                          height="150"
                          width="150"
                        />

                        <p className="align-self-end">
                          PERÍODO:{" "}
                          {DateFormatStrategy.format(
                            consolidatedReport.beginOfPeriod
                          )}
                          À{" "}
                          {DateFormatStrategy.format(
                            consolidatedReport.endOfPeriod
                          )}
                        </p>

                        <h5 className="text-center">
                          {flowToShowInReport} - {consolidatedReport.prefecture}
                        </h5>
                      </div>

                      <div className="table-responsive">
                        <Table bordered>
                          <tbody>
                            <tr>
                              <th>{t("Prefecture")}</th>
                              <th>{t("Year")}</th>
                              <th>{t("Month")}</th>
                              <th>{t("Flow")}</th>
                              <th>{t("Operation")}</th>
                              <th>{t("Description")}</th>
                              <th>{t("Foreseen")}</th>
                              <th>{t("Realized")}</th>
                              <th>{t("Group")}</th>
                            </tr>

                            {subgroupsItens.map(
                              ({ month, subgroup, year }, key) => {
                                const subgroupData = subgroups.find(
                                  ({ id }) => id == subgroup
                                );
                                const monthName = optionsMonths.find(
                                  ({ id }) => id === month
                                )?.label;
                                const flowName = cashflows.find(
                                  ({ id }) =>
                                    id == subgroupData?.tipos_fluxos_id
                                )?.name;
                                const group = groups.find(
                                  ({ id }) =>
                                    id == subgroupData?.transacoes_grupo_id
                                )?.name;

                                const foreseen =
                                  consolidatedReport.forecastTransactions
                                    .filter(
                                      ({ ano, mes, transacoes_eventos_id }) =>
                                        transacoes_eventos_id == subgroup &&
                                        ano == year &&
                                        mes == month
                                    )
                                    .reduce(
                                      (previous, current) =>
                                        previous + parseFloat(current.total),
                                      0
                                    );

                                const carriedOut =
                                  consolidatedReport.transactionsCarriedOut
                                    .filter(
                                      ({ ano, mes, transacoes_eventos_id }) =>
                                        transacoes_eventos_id == subgroup &&
                                        ano == year &&
                                        mes == month
                                    )
                                    .reduce(
                                      (previous, current) =>
                                        previous + parseFloat(current.total),
                                      0
                                    );

                                return (
                                  <tr key={key}>
                                    <td>{consolidatedReport.prefecture}</td>
                                    <td>{year}</td>
                                    <td>{monthName}</td>
                                    <td>{flowName}</td>
                                    <td>{subgroupData?.tipo_transacao.name}</td>
                                    <td>{subgroupData?.name}</td>
                                    <td>
                                      {MoneyFormatStrategy.format(foreseen)}
                                    </td>
                                    <td>
                                      {MoneyFormatStrategy.format(carriedOut)}
                                    </td>
                                    <td>{group}</td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </ComponentToPrint>
                )}
              </Fragment>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default withTranslation()(ConsolidatedTabledView);
