import { HttpRequestBuilder } from "@data/builders";
import { HttpRequestTypes } from "@data/enums";
import { HttpRepository } from "@data/repositories";
import { ConsolidatedReportTransaction } from "@domain/entities";
import { GetConsolidatedReportService } from "@domain/services/consolidated-report/GetConsolidatedReportService";

interface HttpGetConsolidatedResponse {
   dataFim: string;
   dataInicio: string;
   prefeitura: string;
   previsoes: ConsolidatedReportTransaction[];
   tipo: string;
   transacoes: ConsolidatedReportTransaction[];
}

export class HttpGetConsolidatedReportService implements GetConsolidatedReportService {
   constructor(
      private readonly httpRepository: HttpRepository
   ) { }

   async handle(params: GetConsolidatedReportService.Params): Promise<GetConsolidatedReportService.Response> {
      // const request = HttpRequestBuilder
      //    .requestToUrl('/api/auth/consolidadoPorData')
      //    .withMethod(HttpRequestTypes.GET)
      //    .withParams({
      //       dataInicio: params.beginOfPeriod,
      //       dataFim: params.endOfPeriod,
      //       tipo: params.cashFlow,
      //       transacoes_grupo_id: params.groupId,
      //       transacoes_eventos_id: params.subgroupId
      //    })
      //    .build();

      // const { data } = await this.httpRepository.makeRequest<any, HttpGetConsolidatedResponse>(request);

      // return {
      //    beginOfPeriod: data.dataInicio,
      //    endOfPeriod: data.dataFim,
      //    cashFlow: data.tipo,
      //    forecastTransactions: data.previsoes,
      //    prefecture: data.prefeitura,
      //    transactionsCarriedOut: data.transacoes
      // };
      return{
         endOfPeriod: "",
         beginOfPeriod: "",
         prefecture: "",
         forecastTransactions:[],
         transactionsCarriedOut:[],
         cashFlow: ""
      };
      }
}
