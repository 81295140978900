import React, { useMemo } from "react";
import { AddGroupView } from "@ui/views/groups";
import { makeAddGroupPresenter } from "@main/factories/presenters/groups/makeAddGroupPresenter";

interface AddGroupProps {
  isOpen: boolean;
  onDismiss(): void;
}

export const AddGroup: React.FC<AddGroupProps> = (props) => {
  const addGroupPresenter = useMemo(() => makeAddGroupPresenter(), []);

  return <AddGroupView {...props} addGroupPresenter={addGroupPresenter} />;
};
