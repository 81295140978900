import React, { useState } from 'react';

import Dropzone from "react-dropzone";
import { FormFeedback, Input, InputProps } from 'reactstrap';
import InputMask from 'react-input-mask';

import { Validation } from '@presentation/validation/interfaces';
import { SelectItem } from '@ui/interfaces';

interface ValidationInputType extends InputProps, ValidationItemProps {
  validationState: Validation.ValidationResult[];
}

interface ValidationSelectType extends ValidationItemProps, React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> {
  options: SelectItem[];
  validationState: Validation.ValidationResult[];
}

interface ValidationUploadType extends ValidationItemProps, InputProps {
  validationState: Validation.ValidationResult[];
  uploadText: string;
  onDropFile: (acceptedFiles: any[]) => void;
}
interface ValidationCheckboxType extends InputProps, ValidationInputType {
  validationState: Validation.ValidationResult[];
}

interface ValidationHook {
  validationState: Validation.ValidationResult[];
  updateValidationState: (validationResult: Validation.ValidationResult[]) => void;
  ValidationFeedback: React.FC<ValidationItemProps>;
  ValidationInput: React.FC<ValidationInputType>;
  ValidationSelect: React.FC<ValidationSelectType>;
  ValidationUpload: React.FC<ValidationUploadType>;
  ValidationCheckbox: React.FC<ValidationCheckboxType>;
  ValidationInputMask: React.FC<ValidationInputType>;
}

interface ValidationItemProps {
  field: string;
}

const ValidationInput: React.FC<ValidationInputType> = ({ field, validationState, ...props }) => {
  const invalid = validationState.some(({ isValid, fieldName }) => !isValid && fieldName === field);
  return (
    <Input
      {...props}
      invalid={invalid}
    />
  );
};

const ValidationInputMask: React.FC<ValidationInputType> = ({ field, validationState, ...props}) => {
  const invalid = validationState.some(({ isValid, fieldName }) => !isValid && fieldName === field);

  return (
    <InputMask
      {...props}
    >{(inputProps: any) => <Input invalid={invalid} {...inputProps} id="cpfCnpj" />}</InputMask>
  );
};

const ValidationSelect: React.FC<ValidationSelectType> = ({ field, validationState, options, placeholder, ...props }) => {
  const invalid = validationState.some(({ isValid, fieldName }) => !isValid && fieldName === field);
  return (
    <>
      <Input style={{ display: 'none' }} invalid={invalid} />
      <select {...props}>
        {placeholder ? <option value="">{placeholder}</option> : null}

        {options.map(({ label, value }) => (
          <option value={value} key={value}>{label}</option>
        ))}
      </select>
    </>
  );
};

const ValidationCheckbox: React.FC<ValidationCheckboxType> = ({ field, validationState, ...props }) => {
  const invalid = validationState.some(({ isValid, fieldName }) => !isValid && fieldName === field);

  return (
    <>
      <Input
        type='checkbox' style={{ marginLeft: '10px', marginRight: '10px', display: "inline" }}
        invalid={invalid}
        {...props}
      />
    </>
  );
};

const ValidationUpload: React.FC<ValidationUploadType> = ({ field, validationState, uploadText, onDropFile }) => {
  const invalid = validationState.some(({ isValid, fieldName }) => !isValid && fieldName === field);
  return (
    <>
      <Input style={{ display: 'none' }} invalid={invalid} />

      <Dropzone
        onDrop={(acceptedFiles) => {
          onDropFile(acceptedFiles);
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div className="dropzone">
            <div
              className="dz-message needsclick mt-2"
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              <div className="mb-3">
                <i className="display-4 text-muted bx bx-cloud-upload" />
              </div>
              <h4>{uploadText}</h4>
            </div>
          </div>
        )}
      </Dropzone>
    </>
  );
};

export const useValidation = (): ValidationHook => {
  const [validationState, setValidationState] = useState<Validation.ValidationResult[]>([]);

  const ValidationFeedback: React.FC<ValidationItemProps> = ({ field }) => {
    const messageError = validationState.find(({ isValid, fieldName }) => !isValid && fieldName === field)?.message;
    return !messageError ? null : (
      <FormFeedback type="invalid">{messageError}</FormFeedback>
    );
  };

  const updateValidationState = (validationResult: Validation.ValidationResult[]): void => {
    setValidationState(validationResult);
  };

  return {
    validationState,
    updateValidationState,
    ValidationFeedback,
    ValidationInput,
    ValidationSelect,
    ValidationUpload,
    ValidationCheckbox,
    ValidationInputMask
  };
};
