import { Validation } from "@presentation/validation/interfaces";

export class RequiredValidation implements Validation {
  constructor(
    private readonly fieldName: string, 
    private readonly emptyMessage: string
  ) {}

  private getMessage(isValid: boolean): string {
    if(isValid) return 'not_empty';

    return this.emptyMessage;
  }

  validate(input: any): Validation.ValidationResult[] {
    const isValid = !!input[this.fieldName];
    const message = this.getMessage(isValid);

    return [{ isValid, message, fieldName: this.fieldName }];
  }
}
