import { OtpEmailStatus } from "@domain/enums";
import { GetOtpEmailStatusMessageStrategy } from "@data/interfaces";

export class PtBrGetOtpEmailStatusMessageStrategy implements GetOtpEmailStatusMessageStrategy {
  async get(status: OtpEmailStatus): Promise<string> {
    switch (status) {
      case OtpEmailStatus.INVALID_CODE:
        return 'Código inválido.';

      case OtpEmailStatus.TIME_EXPIRED:
        return 'O tempo expirou, tente novamente.';

      case OtpEmailStatus.VALID_CODE:
        return 'Código válido.';

      default:
        return 'Código inválido.';
    }
  }
}
