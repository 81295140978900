import { CashFlow, ConsolidatedReportTransaction } from "@domain/entities";
import { GetConsolidatedReportService } from "@domain/services/consolidated-report";
import { GetCashflowsService } from "@domain/services/cashflows";
import { CreditRadarByFlowViewContract } from "@presentation/views/dashboard";
import { CreditRadarByFlowPresenterContract } from "@ui/presenters/dashboard";
import { GetLastThreeMonthsPeriod } from "@presentation/utils";

export class CreditRadarByFlowPresenter implements CreditRadarByFlowPresenterContract {
    private view!: CreditRadarByFlowViewContract;

    constructor(
        private readonly getConsolidatedReportService: GetConsolidatedReportService,
        private readonly getCashflowsService: GetCashflowsService,
    ) { }

    setView(view: CreditRadarByFlowViewContract): void {
        this.view = view;
    }

    async getChartData(): Promise<CreditRadarByFlowPresenterContract.Credit[]> {
        const cashflows = await this.getCashflowsService.handle();

        const { begin, end } = GetLastThreeMonthsPeriod.getPeriod();

        const { transactionsCarriedOut, forecastTransactions } = await this.getConsolidatedReportService.handle({
            beginOfPeriod: begin,
            endOfPeriod: end,
        });

        const credits = this.getCredits(cashflows, transactionsCarriedOut, forecastTransactions);

        this.view.setChartData(credits);

        return credits;
    }

    private getCredits(
        cashflows: any[],
        transactions: ConsolidatedReportTransaction[],
        transactionsPrev: ConsolidatedReportTransaction[],
    ): CreditRadarByFlowPresenterContract.Credit[] {
        return cashflows.map(({ id, name }) => {
            const credits = transactions.filter(({ tipos_transacoes_id, tipos_fluxos_id }) =>
                tipos_transacoes_id == 1 &&
                tipos_fluxos_id == id
            );

            const foreseenCredits = transactionsPrev.filter(({ tipos_transacoes_id, tipos_fluxos_id }) =>
                tipos_transacoes_id == 1 &&
                tipos_fluxos_id == id
            );

            return {
                flow: id,
                flowName: name,
                total: credits.reduce((prev, current) => prev + parseFloat(current.total), 0),
                totalForeseen: foreseenCredits.reduce((prev, current) => prev + parseFloat(current.total), 0)
            };
        });
    }
}
