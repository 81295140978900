import { HttpRequestBuilder } from "@data/builders";
import { HttpRequestTypes } from "@data/enums";
import { HttpRepository } from "@data/repositories";
import { RemoveSourcesService } from "@domain/services/sources";

export class HttpRemoveSourcesService implements RemoveSourcesService {
  constructor(private readonly httpRepository: HttpRepository) {}

  async handle(SourcesId: number): Promise<boolean> {
    const httpRequest = HttpRequestBuilder.requestToUrl(`/sources/${SourcesId}`)
      .withMethod(HttpRequestTypes.DELETE)
      .build();

    const { data } = await this.httpRepository.makeRequest(httpRequest);

    return data === 200;
  }
}
