import { HttpGetCitiesService } from "@data/services/cities";
import { HttpUpdateProfileService } from "@data/services/profile";
import { HttpGetStatesService } from "@data/services/states";
import { AxiosHttpRepository } from "@infra/http";
import { API_BASE_URL } from "@main/constants/config";
import { UpdateProfilePresenter } from "@presentation/presenters/profiles";
import { FieldValidationBuilder } from "@presentation/validation/builders";
import { ValidationComposite } from "@presentation/validation/validators";
import { UpdateProfilePresenterContract } from "@ui/presenters/profiles";

export const makeUpdateProfilePresenter = (): UpdateProfilePresenterContract => {
  const validation = new ValidationComposite([
    ...FieldValidationBuilder.field('name').required().build(),
    ...FieldValidationBuilder.field('email').required().build(),
    ...FieldValidationBuilder.field('state').requiredWhenOtherFieldHasSomeValue('userGroup', [1, 5]).build(),
    ...FieldValidationBuilder.field('city').requiredWhenOtherFieldHasSomeValue('userGroup', [1, 5]).build(),
  ]);

  const httpRepository = new AxiosHttpRepository(API_BASE_URL);
  const getStatesService = new HttpGetStatesService(httpRepository);
  const getCitiesService = new HttpGetCitiesService(httpRepository);
  const updateProfileService = new HttpUpdateProfileService(httpRepository);

  return new UpdateProfilePresenter(
    validation,
    getStatesService,
    getCitiesService,
    updateProfileService
  );
};
