import { AxiosHttpRepository } from "@infra/http";
import { API_BASE_URL } from "@main/constants/config";
import { AddPrefecturePresenter } from "@presentation/presenters/prefecture";
import { FieldValidationBuilder } from "@presentation/validation/builders";
import { ValidationComposite } from "@presentation/validation/validators";
import { HttpGetStatesService } from "@data/services/states";
import { AddPrefecturePresenterContract } from "@ui/presenters/prefecture";
import { HttpAddPrefectureService } from "@data/services/prefecture/HttpAddPrefectureService";
import { HttpGetCitiesService } from "@data/services/cities";

export const makeAddPrefecturePresenter = (): AddPrefecturePresenterContract => {
    const validation = new ValidationComposite([
        ...FieldValidationBuilder.field('cityName').required().build(),
        ...FieldValidationBuilder.field('stateName').required().build()
    ]);

    const httpRepository = new AxiosHttpRepository(API_BASE_URL);
    const addPrefecturePresenter = new HttpAddPrefectureService(httpRepository);
    const getStatesService = new HttpGetStatesService(httpRepository);
    const getCitiesService = new HttpGetCitiesService(httpRepository);

    return new AddPrefecturePresenter(
        validation,
        addPrefecturePresenter,
        getStatesService,
        getCitiesService
    );
};
