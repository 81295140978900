import { Subgroup } from "@domain/entities/Subgroup";
import { RemoveSubGroupsService } from "@domain/services/revenues-expenses/RemoveSubgroupsService";
import { GetSubgroupsService } from "@domain/services/revenues-expenses/GetSubgroupsService";
import { RevenuesAndExpensesViewContract } from "@presentation/views/revenues-expenses";
import { RevenuesAndExpensesPresenterContract } from "@ui/presenters/revenues-expenses";

export class RevenuesAndExpensesPresenter
  implements RevenuesAndExpensesPresenterContract
{
  private view!: RevenuesAndExpensesViewContract;

  constructor(
    private readonly getSubgroupsService: GetSubgroupsService,
    private readonly removeSubGroupsService: RemoveSubGroupsService
  ) {}

  async removeSubgroup(subgroupId: number): Promise<void> {
    this.view.setLoadingState(true);

    const isRemoved = await this.removeSubGroupsService.handle(subgroupId);

    if (isRemoved) {
      this.get();
    } else {
      this.view.setLoadingState(false);
    }
  }

  async get(): Promise<Subgroup[]> {
    this.view.setLoadingState(true);

    const response = await this.getSubgroupsService.handle();

    this.view.setSubgroups(response);
    this.view.setLoadingState(false);
    return response;
  }

  setView(view: RevenuesAndExpensesViewContract): void {
    this.view = view;
  }
}
