import { ProviderEvents } from "@domain/events";
import { AddProviderService } from "@domain/services/provider/AddProviderService";
import { FormValidation } from "@presentation/validation/interfaces";
import { AddProviderViewContract } from "@presentation/views/providers/AddProviderViewContract";
import { AddProviderPresenterContract } from "@ui/presenters/provider/AddProviderPresenterContract";
import { EventEmitter } from "@utils/events";

export class AddProviderPresenter implements AddProviderPresenterContract {
  private view!: AddProviderViewContract;

  constructor(
    private readonly validation: FormValidation,
    private readonly addProviderService: AddProviderService
  ) { }

  setView(view: AddProviderViewContract): void {
    this.view = view;
  }

  async add(payload: AddProviderPresenterContract.Payload): Promise<boolean> {
    this.view.setLoading(true);
    this.view.openSimilarityFeedback(false);

    const validationSuccessfull = this.validation.allValid(payload);

    if (!validationSuccessfull) {
      this.view.updateValidationState(this.validation.errors(payload));
      this.view.setLoading(false);
      return false;
    }

    const { ok: isProviderAdded, errors } = await this.addProviderService.handle(payload);

    if (isProviderAdded) {
      EventEmitter.emit(ProviderEvents.PROVIDER_ADDED);
    } else {
      this.view.setSimilarity(errors);
      this.view.openSimilarityFeedback(true);
    }

    this.view.setLoading(false);
    this.view.updateValidationState([]);
    return isProviderAdded;
  }
}
