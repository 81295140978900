import { AddSourcesService } from "@domain/services/sources";
import { FormValidation } from "@presentation/validation/interfaces";
import { AddSourceViewContract } from "@presentation/views/sources";
import { AddSourcePresenterContract } from "@ui/presenters/sources";
import { EventEmitter } from "@utils/events";
import { SourceEvents } from "@domain/events/SourcesEvents";
import { CashFlow, AccountGroup } from "@domain/entities";
import { GetCashflowsService } from "@domain/services/cashflows";
import { GetAccountGroupsService } from "@domain/services/account";

export class AddSourcePresenter implements AddSourcePresenterContract {
  private view!: AddSourceViewContract;

  constructor(
    private readonly validation: FormValidation,
    private readonly addSourceService: AddSourcesService,
    private readonly getCashflowsService: GetCashflowsService,
    private readonly getGroupsService: GetAccountGroupsService
  ) {}

  async getCashflows(): Promise<CashFlow[]> {
    const cashflows = await this.getCashflowsService.handle();
    this.view.setCashflows(cashflows);
    return cashflows;
  }

  async getGroups(): Promise<AccountGroup[]> {
    const groups = await this.getGroupsService.handle();
    this.view.setGroups(groups);
    return groups;
  }

  setView(view: AddSourceViewContract): void {
    this.view = view;
  }

  async add(payload: AddSourcePresenterContract.Payload): Promise<boolean> {
    this.view.setLoading(true);

    const validationSuccessfull = this.validation.allValid(payload);

    if (!validationSuccessfull) {
      this.view.updateValidationState(this.validation.errors(payload));
      this.view.setLoading(false);
      return false;
    }

    const isSourceAdded = await this.addSourceService.handle(payload);

    if (isSourceAdded) {
      EventEmitter.emit(SourceEvents.SOURCE_ADDED);
    }

    this.view.setLoading(false);
    this.view.updateValidationState([]);
    return isSourceAdded;
  }
}
