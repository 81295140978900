import React, { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { ProfileMenuPresenterContract } from "@ui/presenters/profiles";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { User } from "@domain/entities";
import { UpdateProfile } from "@main/pages/profiles";
import { UpdateProfilePresenterContract } from "@ui/presenters/profiles/UpdateProfilePresenterContract";
import { EventEmitter } from "@utils/events";
import { UserEvents } from "@domain/events";
import UpdateUserPassword from "@main/pages/users/UpdateUserPassword";
import { UpdateUserPasswordPresenterContract } from "@ui/presenters/users";
import { TbPasswordUser } from "react-icons/tb";

interface ProfileMenuProps {
  t: any;
  profilePresenter: ProfileMenuPresenterContract;
}

const ProfileMenu: React.FC<ProfileMenuProps> = ({ t, profilePresenter }) => {
  const [menu, setMenu] = useState<boolean>(false);
  const [userInfo, setUserInfoState] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userProfile, setUserProfile] = useState<UpdateProfilePresenterContract.Payload | null>(null);
  const [userToUpdated, setUserToUpdated] = useState<UpdateUserPasswordPresenterContract.Payload | null>(null);

  useEffect(() => {
    profilePresenter.setView({ setLoading, setUserInfo });

    const userInfoFromStorage = JSON.parse(localStorage.getItem('user_logged') ?? "null");

    if (!userInfoFromStorage) {
      profilePresenter.getUserInfo();
    } else {
      setUserInfo(userInfoFromStorage);
    }

    EventEmitter.subscribe(UserEvents.PROFILE_UPDATED, onProfileUpdated);
    EventEmitter.subscribe(UserEvents.PASSWORD_UPDATED, onPasswordUpdated);

    return () => {
      EventEmitter.unsubscribe(UserEvents.PROFILE_UPDATED, onProfileUpdated);
      EventEmitter.unsubscribe(UserEvents.PASSWORD_UPDATED, onPasswordUpdated);
    };
  }, []);

  const setUserInfo = (userInfo: User): void => {
    setUserInfoState(userInfo);
    localStorage.setItem('user_group', String(userInfo.group));
    localStorage.setItem('user_logged', JSON.stringify(userInfo));
    EventEmitter.emit(UserEvents.AUTH_USER_INFO_LOADED);
  };

  const setLoading = (isLoading: boolean): void => setIsLoading(isLoading);

  const onProfileUpdated = () => {
    setUserProfile(null);

    setTimeout(() => location.reload(), 1000);
  };

  const onPasswordUpdated = () => {
    setUserToUpdated(null);
  };

  const userNameLabel = isLoading
    ? t('loading')
    : userInfo?.name;

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item bg-soft-light border-start border-end"
          id="page-header-user-dropdown"
          tag="button"
        >
          <span className="d-none d-xl-inline-block ms-2 me-1">{userNameLabel}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="div">
            <Link
              to="#"
              onClick={() => setUserProfile({
                email: userInfo?.email ?? '',
                name: userInfo?.name ?? ''
              })}
            >
              {" "}
              <i className="bx bx-user font-size-16 align-middle me-1" />
              {t("Update Profile")}{" "}
            </Link>
          </DropdownItem>
          <DropdownItem tag="div">
            <Link
              to="#"
              onClick={() => setUserToUpdated({
                password: "",
                oldPassword: ""
              })}
            >
              {" "}
              <TbPasswordUser />{" "}
              {t("Edit Password")}{" "}
            </Link>
          </DropdownItem>
          <div className="dropdown-divider" />
          <Link to="#" onClick={() => profilePresenter.logout()} className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>

      <UpdateProfile
        userInfo={userProfile}
        onDismiss={() => setUserProfile(null)}
      />

      <UpdateUserPassword
        onDismiss={() => setUserToUpdated(null)}
        user={userToUpdated}
      />

    </React.Fragment>
  );
};

export default withTranslation()(ProfileMenu);
