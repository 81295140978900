import { GetFlowsService } from "@domain/services/reports";
import { CashflowsPresenterContract } from "@ui/presenters/reports";
import { CashflowsViewContract } from "@presentation/views/reports/CashflowsViewContract";
import {
  CashFlow,
  Group,
  Provider,
  Subgroup
} from "@domain/entities";
import { GetCashflowsService } from "@domain/services/cashflows";
import { GetGroupsService } from "@domain/services/groups";
import { GetSubgroupsService } from "@domain/services/revenues-expenses";
import { GetBankAccountService } from "@domain/services/account/GetBankAccountService";
import { GetProviderService } from "@domain/services/provider";
import { GroupsByFlowsList } from "@domain/interfaces/accounts";

export class CashflowsPresenter implements CashflowsPresenterContract {
  public view!: CashflowsViewContract;

  constructor(
    private readonly getFlowsService: GetFlowsService,
    private readonly getCashflowsService: GetCashflowsService,
    private readonly getGroupsService: GetGroupsService,
    private readonly getSubgroupsService: GetSubgroupsService,
    private readonly getBankAccountService: GetBankAccountService,
    private readonly getProviderService: GetProviderService
  ) {}

  setView(view: CashflowsViewContract): void {
    this.view = view;
  }

  async getCashflows(): Promise<CashFlow[]> {
    const cashflows = await this.getCashflowsService.handle();
    this.view.setCashflows(cashflows);
    return cashflows;
  }

  async getGroups(): Promise<Group[]> {
    const groups = await this.getGroupsService.handle();
    this.view.setGroupsList(groups);
    return groups;
  }

  async getSubgroups(): Promise<Subgroup[]> {
    const subgroups = await this.getSubgroupsService.handle();
    this.view.setSubgroupsList(subgroups);
    return subgroups;
  }

  async getAccounts(): Promise<GroupsByFlowsList[]> {
    const account = await this.getBankAccountService.handle();
    this.view.setAccountsList(account);
    return account;
  }

  async getProvider(): Promise<Provider[]> {
    const provider = await this.getProviderService.handle();
    this.view.setProviderList(provider);
    return provider;
  }

  async get(params: CashflowsPresenterContract.Params): Promise<CashflowsPresenterContract.Response> {
    this.view.setLoadingState(true);

    const response = await this.getFlowsService.handle(params);

    this.view.setFlowsList(response);
    this.view.setLoadingState(false);
    return response;
  }
}
