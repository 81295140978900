import React, { useMemo } from "react";
import BankAccountView from "@ui/views/account/BankAccountView";
import { makeBankAccountPresenter } from "@main/factories/presenters/accounts/makeBankAccountPresenter";

interface BankAccountProps {
   onDismiss(): void;
}

const BankAccount: React.FC<BankAccountProps> = (props) => {
   const bankAccountPresenter = useMemo(() => makeBankAccountPresenter(), []);

   return <BankAccountView {...props} bankAccountPresenter={bankAccountPresenter} />;
};

export default BankAccount;
