import React, {useMemo} from "react";

import { makeAddProviderPresenter } from "@main/factories/presenters/provider/makeAddProviderPresenter";
import AddProviderView from "@ui/views/providers/AddProviderView";

interface AddProviderProps {
   isOpen: boolean;
   onDismiss(): void;
 }
 
 export const AddProvider: React.FC<AddProviderProps> = (props) => {
   const addProviderPresenter = useMemo(() => makeAddProviderPresenter(), []);
 
   return <AddProviderView {...props} addProviderPresenter={addProviderPresenter} />;
 };
