import { HttpRequestBuilder } from "@data/builders";
import { HttpRequestTypes } from "@data/enums";
import { HttpRepository } from "@data/repositories";
import { Treasury } from "@domain/entities";
import { GetTreasuryService } from "@domain/services/treasury";

export class HttpGetTreasuryService implements GetTreasuryService {
  constructor(private readonly httpRepository: HttpRepository) {}

  async handle(day: string): Promise<Treasury | null> {
    const request = HttpRequestBuilder.requestToUrl(
      "/treasury"
    )
      .withMethod(HttpRequestTypes.GET)
      .withParams({ day })
      .build();

    const { data } = await this.httpRepository.makeRequest<
      any,
      Treasury | null
    >(request);

    return data;
  }
}
