import { HttpRequestBuilder } from "@data/builders";
import { HttpRequestTypes } from "@data/enums";
import { HttpRepository } from "@data/repositories";
import { AddEffectiveBillsService } from "@domain/services/bills";

export class HttpAddEffectiveBillsService implements AddEffectiveBillsService {
    constructor(
      private readonly httpRepository: HttpRepository
    ) { }
  
    async handle(payload: AddEffectiveBillsService.Payload): Promise<boolean> {
      const httpRequest = HttpRequestBuilder.requestToUrl('/bills/effective')
        .withMethod(HttpRequestTypes.POST)
        .withData(payload)
        .build();
  
      const { status } = await this.httpRepository.makeRequest<AddEffectiveBillsService.Payload>(httpRequest);
  
      return status === 201;
    }
  }
