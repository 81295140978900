import { addMonths, format, lastDayOfMonth, eachMonthOfInterval } from 'date-fns';

interface Quarter {
  begin: string;
  end: string;
  months: string[];
}

export class GetLastThreeMonthsPeriod {
  static getPeriod(): Quarter {
    const today = new Date();
    const begin = addMonths(today, -2);
    const end = lastDayOfMonth(today);

    const months = eachMonthOfInterval({ end, start: begin }).map((date) => format(date, 'yyyy-MM'));

    return {
      begin: format(begin, 'yyyy-MM') + '-01',
      end: format(end, 'yyyy-MM-dd'),
      months
    };
  }
}
