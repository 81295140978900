import { format, parseISO } from "date-fns";
import { DateFormat } from "@ui/interfaces";

export class DateFormatBr implements DateFormat {
   handle(date: string): string {
      const newDate = format(parseISO(date), "dd/MM/yyyy");

      return newDate;
   }
}
