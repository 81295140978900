import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Modal, Row, Col, Label, FormGroup } from "reactstrap";

import { useValidation } from "@ui/hooks";
import { AddGroupPresenterContract } from "@ui/presenters/groups";
import { Similarity } from "@domain/interfaces/similarity";
import SimilarityAlert from "@ui/components/similarity/SimilarityAlert";

interface AddGroupViewProps {
  t?: any;
  isOpen: boolean;
  onDismiss(): void;
  addGroupPresenter: AddGroupPresenterContract;
}

const INITIAL_FORM_STATE: AddGroupPresenterContract.Payload = {
  name: "",
};

const AddGroupView: React.FC<AddGroupViewProps> = ({
  t,
  isOpen,
  onDismiss,
  addGroupPresenter,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [similarities, setSimilaritiesList] = useState<Similarity[]>([]);
  const [isOpenFeedBack, setIsOpenFeedBack] = useState<boolean>(false);
  const [payload, setPayload] =
    useState<AddGroupPresenterContract.Payload>(INITIAL_FORM_STATE);

  const {
    ValidationFeedback,
    ValidationInput,
    updateValidationState,
    validationState,
  } = useValidation();

  useEffect(() => {
    addGroupPresenter.setView({
      setLoading,
      updateValidationState,
      setSimilarity,
      openSimilarityFeedback
    });
  }, []);

  useEffect(() => {
    if (!isOpen) {
      updateValidationState([]);
      setPayload(INITIAL_FORM_STATE);
    }
  }, [isOpen]);

  const setLoading = (isLoading: boolean) => setIsLoading(isLoading);

  const setSimilarity = (similarity: Similarity[]): void => setSimilaritiesList(similarity);

  const openSimilarityFeedback = (isOpenFeedBack: boolean): void => setIsOpenFeedBack(isOpenFeedBack);

  const updateField = (fieldName: string, value: string) => {
    setPayload((oldState) => ({
      ...oldState,
      [fieldName]: value,
    }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    addGroupPresenter.add(payload);
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={isLoading ? undefined : onDismiss}
      scrollable={true}
      id="staticBackdrop"
      size="lg"
    >
      <div className="modal-header">
        <h5 className="modal-title">{t("Add group")}</h5>
        <button
          type="button"
          className="btn-close"
          onClick={isLoading ? undefined : onDismiss}
          aria-label="Close"
        />
      </div>

      <form onSubmit={handleSubmit}>
        <div className="modal-body">
          <SimilarityAlert
            isOpen={isOpenFeedBack}
            similarities={similarities}
          />

          <Row>
            <Col md={12} className="mb-2">
              <FormGroup>
                <Label>{t("Group")}</Label>
                <ValidationInput
                  field="name"
                  validationState={validationState}
                  name="name"
                  className="form-control"
                  placeholder={`${t("Digit Here")}`}
                  value={payload.name}
                  onChange={({ target: { value, name } }) =>
                    updateField(name, value)
                  }
                />

                <ValidationFeedback field="name" />
              </FormGroup>
            </Col>
          </Row>
        </div>

        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-danger me-2"
            onClick={isLoading ? undefined : onDismiss}
            disabled={isLoading}
          >
            {t("Close")}
          </button>

          <button className="btn btn-primary" disabled={isLoading}>
            {t(isLoading ? "loading" : "Save")}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default withTranslation()(AddGroupView);
