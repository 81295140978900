import React, { useMemo } from "react";
import EditPrefectureView from "@ui/views/prefecture/EditPrefectureView";
import { makeEditPrefecturePresenter } from "@main/factories/presenters/prefecture";
import { EditPrefecturePresenterContract } from "@ui/presenters/prefecture";

interface EditPrefectureProps {
  prefectureToEdit: EditPrefecturePresenterContract.Payload | null;
  onDismiss(): void;
}

export const EditPrefecture: React.FC<EditPrefectureProps> = (props) => {
  const editPrefecturePresenter = useMemo(() => makeEditPrefecturePresenter(), []);

  return <EditPrefectureView {...props} editPrefecturePresenter={editPrefecturePresenter} />;
};
