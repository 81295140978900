import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";

import { Container, Table, Row, Col } from "reactstrap";

import { Breadcrumbs } from "@ui/components/Common";
import { Provider } from "@domain/entities/Provider";
import Loading from "@ui/components/Loading";
import { ProviderListPresenterContract } from "@ui/presenters/provider/ProviderListPresenterContract";
import { AddProvider } from "@main/pages/provider/AddProvider";
import { EventEmitter } from "@utils/events";
import { ProviderEvents } from "@domain/events";
import { EditProvider } from "@main/pages/provider/EditProvider";
import { EditProviderPresenterContract } from "@ui/presenters/provider";
import AddActionButton from "@ui/components/AddActionButton";
import EditActionButton from "@ui/components/EditActionButton";
import RemoveActionButton from "@ui/components/RemoveActionButton";

interface ProvidersListViewProp {
  t: any;
  providerListPresenter: ProviderListPresenterContract;
}

const ProvidersListView: React.FC<ProvidersListViewProp> = ({
  t,
  providerListPresenter,
}) => {
  const [loading, setLoading] = useState(false);
  const [provider, setProvidersList] = useState<Provider[]>([]);
  const [isAddingProvider, setIsAddingProvider] = useState<boolean>(false);
  const [providerToEdit, setProviderToEdit] =
    useState<EditProviderPresenterContract.Payload | null>(null);

  useEffect(() => {
    const setProvider = (providers: Provider[]) => setProvidersList(providers);
    const setLoadingState = (isLoading: boolean) => setLoading(isLoading);

    providerListPresenter.setView({ setProvider, setLoadingState });
    providerListPresenter.get();

    const onProviderAdded = () => {
      setIsAddingProvider(false);
      providerListPresenter.get();
    };

    const onProviderUpdated = () => {
      setProviderToEdit(null);
      providerListPresenter.get();
    };

    EventEmitter.subscribe(ProviderEvents.PROVIDER_ADDED, onProviderAdded);
    EventEmitter.subscribe(ProviderEvents.PROVIDER_UPDATED, onProviderUpdated);

    return () => {
      EventEmitter.unsubscribe(ProviderEvents.PROVIDER_ADDED, onProviderAdded);
      EventEmitter.unsubscribe(
        ProviderEvents.PROVIDER_UPDATED,
        onProviderUpdated
      );
    };
  }, [providerListPresenter]);

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title={t("records")} breadcrumbItem={t("Providers")} />
        <Row>
          {loading ? (
            <Col xl={12} className="mt-4">
              <Loading />
            </Col>
          ) : (
            <Col xl={12} className="mt-4">
              <div className="d-sm-flex align-items-center justify-content-end">
                <AddActionButton
                  type="button"
                  className="btn btn-primary mb-3"
                  onClick={() => setIsAddingProvider(true)}
                />
              </div>

              <div className="table-responsive">
                <Table className="align-middle mb-0" bordered>
                  <thead>
                    <tr>
                      <th>{t("Name")}</th>
                      <th className="text-center">{t("provider.document")}</th>
                      <th style={{ width: "20%" }} className="text-center">
                        {t("Actions")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {provider.map(({ name, document, id }, key) => (
                      <tr key={key}>
                        <th scope="row">{name}</th>
                        <th className="text-center">
                          {document === null || "" ? "Documento não informado" : document}
                        </th>
                        <td className="text-center">
                          <EditActionButton
                            isLastOfList={false}
                            label={t("Edit")}
                            onClick={() =>
                              setProviderToEdit({
                                id: id,
                                name: name,
                                document
                              })
                            }
                          />
                          <RemoveActionButton
                            onClick={() => {
                              providerListPresenter.removeProvider(id);
                              providerListPresenter.get();
                            }}
                            isLastOfList={true}
                            label={t("Remove")}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Col>
          )}
        </Row>
      </Container>

      <AddProvider
        isOpen={isAddingProvider}
        onDismiss={() => setIsAddingProvider(false)}
      />

      <EditProvider
        providerToEdit={providerToEdit}
        onDismiss={() => setProviderToEdit(null)}
      />
    </div>
  );
};

export default withTranslation()(ProvidersListView);
