import React from "react";
import { Spinner } from "reactstrap";

const Loading: React.FC = () => {
   return (
      <div className="d-sm-flex align-items-center justify-content-center">
         <Spinner color="primary" />
      </div>
   );
};

export default Loading;
