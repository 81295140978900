import React, { Fragment, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Modal, Row, Col, Label, FormGroup } from "reactstrap";

import { useValidation } from "@ui/hooks";
import { EditUserViewContract } from "@presentation/views/users";
import { AccessProfile, CashFlow } from "@domain/entities";
import { SelectItem } from "@ui/interfaces";
import { EditUserPresenterContract } from "@ui/presenters/users";

interface EditUserViewProps {
  t?: any;
  user?: EditUserViewContract.Payload | null;
  onDismiss(): void;
  editUserPresenter: EditUserPresenterContract;
}

const INITIAL_FORM_STATE: EditUserViewContract.Payload = {
  id: 0,
  email: "",
  group: "",
  name: "",
  cashflows: []
};

const EditUserView: React.FC<EditUserViewProps> = ({ user, onDismiss, t, editUserPresenter }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [payload, setPayload] = useState<EditUserViewContract.Payload>(INITIAL_FORM_STATE);
  const [cashflows, setCashflowsList] = useState<CashFlow[]>([]);
  const [accessProfiles, setAccessProfilesList] = useState<AccessProfile[]>([]);

  const {
    ValidationFeedback,
    ValidationInput,
    updateValidationState,
    validationState,
    ValidationSelect,
    ValidationCheckbox
  } = useValidation();

  useEffect(() => {
    editUserPresenter.getCashflows();
    editUserPresenter.getAccessProfiles();
    editUserPresenter.setView({ setAccessProfiles, setLoading, updateValidationState, setCashflows });
  }, []);

  useEffect(() => {
    if (!user) {
      updateValidationState([]);
      setPayload(INITIAL_FORM_STATE);
    } else {
      setPayload(user);

      editUserPresenter.getCashflows();
      editUserPresenter.getAccessProfiles();
    }
  }, [user]);

  const setAccessProfiles = (accessProfiles: AccessProfile[]) => setAccessProfilesList(accessProfiles);
  const setLoading = (isLoading: boolean): void => setIsLoading(isLoading);
  const setCashflows = (cashflow: CashFlow[]) => setCashflowsList(cashflow);

  const updateField = (fieldName: string, value: string) => {
    setPayload((oldState) => ({
      ...oldState,
      [fieldName]: value
    }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    editUserPresenter.edit(payload);
  };

  const handleCashflowChecked = (cashflowId: string, checked: boolean) => {
    if (checked) {
       setPayload({
          ...payload,
          cashflows: [...payload.cashflows, cashflowId]
       });
    } else {
       setPayload({
          ...payload,
          cashflows: payload.cashflows.filter((id) => id !== cashflowId)
       });
    }
 };

  const optionsAccessProfiles: SelectItem[] = accessProfiles.map(({ id, name }) => ({ label: name, value: id }));

  return (
    <Modal
      isOpen={Boolean(user)}
      toggle={isLoading ? undefined : onDismiss}
      scrollable={true}
      id="staticBackdrop"
      size="lg"
    >
      <div className="modal-header">
        <h5 className="modal-title">{t("Edit user")}</h5>
        <button
          type="button"
          className="btn-close"
          onClick={isLoading ? undefined : onDismiss}
          aria-label="Close"
        />
      </div>

      <form onSubmit={handleSubmit}>
        <div className="modal-body">
          <Row>
            <Col md={12} className="mb-2">
              <FormGroup>
                <Label>{t("users.add.name")}</Label>
                <ValidationInput
                  field="name"
                  validationState={validationState}
                  name="name"
                  className="form-control"
                  placeholder={`${t("users.add.name.placeholder")}`}
                  value={payload.name}
                  onChange={({ target: { value, name } }) => updateField(name, value)}
                />

                <ValidationFeedback field="name" />
              </FormGroup>
            </Col>

            <Col md={12} className="mb-2">
              <FormGroup>
                <Label>{t("users.add.email")}</Label>
                <ValidationInput
                  field="email"
                  validationState={validationState}
                  name="email"
                  className="form-control"
                  placeholder={`${t("users.add.email.placeholder")}`}
                  value={payload.email}
                  onChange={({ target: { value, name } }) => updateField(name, value)}
                />

                <ValidationFeedback field="email" />
              </FormGroup>
            </Col>

            <Col md={12} className="mb-3">
              <FormGroup>
                <Label>{t("users.add.profile")}</Label>

                <ValidationSelect
                  field="group"
                  options={optionsAccessProfiles}
                  validationState={validationState}
                  name="group"
                  className="form-control"
                  placeholder={t('users.add.profile.placeholder')}
                  onChange={({ target: { value, name } }) => updateField(name, value)}
                  value={payload.group}
                />

                <ValidationFeedback field="group" />
              </FormGroup>
            </Col>

            <Col>
              <FormGroup>
                <Label style={{ display: "block" }}>{t("users.add.cashflow")}</Label>
                {cashflows.map(({ name, id }) => (
                  <Fragment key={id}>
                    <ValidationCheckbox
                      validationState={validationState}
                      field="cashflows"
                      name="cashflows"
                      className="form-control"
                      onChange={({ target: { checked } }) => handleCashflowChecked(id, checked)} />
                    <Label>{name}</Label>
                  </Fragment>
                ))}

                <ValidationFeedback field="cashflows" />
              </FormGroup>
            </Col>
          </Row>
        </div>

        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-danger me-2"
            onClick={isLoading ? undefined : onDismiss}
            disabled={isLoading}
          >
            {t("Close")}
          </button>

          <button className="btn btn-primary" disabled={isLoading}>
            {t(isLoading ? "loading" : "Save")}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default withTranslation()(EditUserView);
