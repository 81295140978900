import { HttpRequestBuilder } from "@data/builders";
import { HttpRequestTypes } from "@data/enums";
import { HttpRepository } from "@data/repositories";
import { RemoveEffectiveBillsService } from "@domain/services/bills";

export class HttpRemoveEffectiveBillsService implements RemoveEffectiveBillsService {
   constructor(
      private readonly httpRepository: HttpRepository
   ) {}

   async handle(billId: number): Promise<boolean> {
      const httpRequest = HttpRequestBuilder
      .requestToUrl(`/bills/effective/${billId}`)
      .withMethod(HttpRequestTypes.DELETE)
      .build();

      const { status } = await this.httpRepository.makeRequest(httpRequest);

      return status === 200;
   }
}
