import React, { useEffect, useState } from "react";

import { Row, Col, Container, Form, Label, Alert } from "reactstrap";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import logo from "@ui/assets/images/logo.jpg";

import { CarouselPage } from "@ui/components";
import { useValidation } from "@ui/hooks";
import { ForgotPasswordPresenterContract } from "@ui/presenters/auth";

interface ForgotPasswordViewProps {
  t: any;
  forgotPasswordPresenter: ForgotPasswordPresenterContract;
}

const ForgotPasswordView: React.FC<ForgotPasswordViewProps> = ({ t, forgotPasswordPresenter }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [payload, setPayload] = useState<ForgotPasswordPresenterContract.Payload>({ email: '' });

  const { ValidationFeedback, updateValidationState, ValidationInput, validationState } = useValidation();

  useEffect(() => {
    forgotPasswordPresenter.setView({
      removeErrorMessage,
      setLoadingState,
      showErrorMessage,
      updateValidationState
    });
  }, []);

  const updateField = (fieldName: string, value: string) => {
    setPayload((oldState) => ({
      ...oldState,
      [fieldName]: value
    }));
  };

  const removeErrorMessage = () => setErrorMessage('');
  const showErrorMessage = (message: string) => setErrorMessage(message);
  const setLoadingState = (isLoading: boolean) => setLoading(isLoading);

  return (
    <React.Fragment>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4} md={5} className="col-xxl-3">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5 text-center">
                      <Link to="/" className="d-block auth-logo">
                        <img src={logo} alt="" height="200" width="300" />{" "}
                      </Link>
                    </div>
                    <div className="auth-content my-auto">
                      <div className="text-center">
                        <h5 className="mb-0">{t("forgotpass.welcome")}</h5>
                        <p className="text-muted mt-2">
                          {t("forgotpass.welcome.subtitle")}
                        </p>
                      </div>
                      <Form
                        className="custom-form mt-4 pt-2"
                        onSubmit={(e) => {
                          e.preventDefault();

                          forgotPasswordPresenter.sendEmail(payload);
                        }}
                      >
                        {errorMessage ? <Alert color="danger"></Alert> : null}
                        <div className="mb-3">
                          <Label className="form-label">{t("forgotpass.emailInputLabel")}</Label>
                          <ValidationInput
                            field="email"
                            validationState={validationState}
                            name="email"
                            className="form-control"
                            placeholder={`${t("forgotpass.emailInputPlaceholder")}`}
                            value={payload.email}
                            onChange={({ target: { value, name } }) => updateField(name, value)}
                          />

                          <ValidationFeedback field="email" />
                        </div>

                        <div className="row mb-4">
                          <div className="col">
                            <div className="mt-3 d-grid">
                              <button
                                className="btn btn-primary btn-block"
                                type="submit"
                                disabled={loading}
                              >
                                {loading ? t("forgotpass.loadingText") : t("forgotpass.submitButtonText")}
                              </button>
                            </div>
                          </div>
                        </div>
                      </Form>

                      <div className="mt-5 text-center">
                        <p className="text-muted mb-0">
                          {t("forgotpass.remember")}{" "}
                          <Link
                            to="/login"
                            className="text-primary fw-semibold"
                          >
                            {" "}
                            {t("forgotpass.rememberTextBoldLink")}{" "}
                          </Link>{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <CarouselPage />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(ForgotPasswordView);
