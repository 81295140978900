import { HttpGetProviderService } from "@data/services/provider/HttpGetProvidersService";
import { HttpRemoveProviderService } from "@data/services/provider/HttpRemoveProviderService";
import { AxiosHttpRepository } from "@infra/http";
import { API_BASE_URL } from "@main/constants/config";
import { ProviderListPresenter } from "@presentation/presenters/provider/ProviderListPresenter";
import { ProviderListPresenterContract } from "@ui/presenters/provider/ProviderListPresenterContract";

export const makeProviderListPresenter = (): ProviderListPresenterContract => {
   const httpRepository = new AxiosHttpRepository(API_BASE_URL);
   const getProviderListService = new HttpGetProviderService(httpRepository);
   const removeProviderService = new HttpRemoveProviderService(httpRepository);
   return new ProviderListPresenter(getProviderListService, removeProviderService);
 };
