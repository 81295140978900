import { HttpGetCashflowsService } from "@data/services/cashflows";
import { HttpEditUserService, HttpGetUserAccessService } from "@data/services/users";
import { AxiosHttpRepository } from "@infra/http";
import { API_BASE_URL } from "@main/constants/config";
import { EditUserPresenter } from "@presentation/presenters/users";
import { FieldValidationBuilder } from "@presentation/validation/builders";
import { ValidationComposite } from "@presentation/validation/validators";
import { EditUserPresenterContract } from "@ui/presenters/users";

export const makeEditUserPresenter = (): EditUserPresenterContract => {
  const validation = new ValidationComposite([
    ...FieldValidationBuilder.field('id').required().build(),
    ...FieldValidationBuilder.field('email').required().email().build(),
    ...FieldValidationBuilder.field('group').required().build(),
    ...FieldValidationBuilder.field('name').required().build(),
    ...FieldValidationBuilder.field('cashflows').required().build(),
  ]);

  const httpRepository = new AxiosHttpRepository(API_BASE_URL);
  const getUserAccessService = new HttpGetUserAccessService(httpRepository);
  const getCashflowsService = new HttpGetCashflowsService(httpRepository);
  const editUserService = new HttpEditUserService(httpRepository);

  return new EditUserPresenter(
    validation,
    getUserAccessService,
    getCashflowsService,
    editUserService
  );
};
