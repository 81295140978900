import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Modal, Row, Col, Label, FormGroup } from "reactstrap";

import { useValidation } from "@ui/hooks";
import { SelectItem } from "@ui/interfaces";
import { City, State } from "@domain/entities";
import { AddPrefecturePresenterContract } from "@ui/presenters/prefecture/AddPrefecturePresenterContract";

interface AddPrefectureViewProps {
    t?: any;
    isOpen: boolean;
    onDismiss(): void;
    addPrefecturePresenter: AddPrefecturePresenterContract;
}

interface FormPayload {
    cityId: number;
    stateId: number;
}

const INITIAL_FORM_STATE: FormPayload = {
    cityId: 0,
    stateId: 0
};

const AddPrefectureView: React.FC<AddPrefectureViewProps> = ({ t, isOpen, onDismiss, addPrefecturePresenter }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [states, setStatesList] = useState<State[]>([]);
    const [cities, setCitiesList] = useState<City[]>([]);
    const [payload, setPayload] = useState<FormPayload>(INITIAL_FORM_STATE);

    const { ValidationFeedback, updateValidationState, validationState, ValidationSelect } = useValidation();

    useEffect(() => {
        addPrefecturePresenter.setView({ setStates, setLoading, setCities, updateValidationState });
    }, []);

    useEffect(() => {
        if (!isOpen) {
            updateValidationState([]);
            setPayload(INITIAL_FORM_STATE);
        } else {
            addPrefecturePresenter.getState();
        }
    }, [isOpen]);

    const setLoading = (isLoading: boolean) => setIsLoading(isLoading);
    const setStates = (states: State[]) => setStatesList(states);
    const setCities = (cities: City[]) => setCitiesList(cities);

    const updateField = (fieldName: string, value: string) => {
        setPayload((oldState) => ({
            ...oldState,
            [fieldName]: value
        }));
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const state = states.find(({ id }) => id == payload.stateId);
        const city = cities.find(({ id }) => id == payload.cityId);

        addPrefecturePresenter.add({
            stateName: state?.name || "",
            cityCode: String(payload.cityId),
            cityName: city?.name || ""
        });
    };

    const optionsStates: SelectItem[] = states.map(({ id, name }) => ({ label: name, value: id }));
    const optionsCity: SelectItem[] = cities.map(({ id, name }) => ({ label: name, value: id }));

    return (
        <Modal
            isOpen={isOpen}
            toggle={isLoading ? undefined : onDismiss}
            scrollable={true}
            id="staticBackdrop"
            size="lg"
        >
            <div className="modal-header">
                <h5 className="modal-title">{t("Add prefecture")}</h5>
                <button
                    type="button"
                    className="btn-close"
                    onClick={isLoading ? undefined : onDismiss}
                    aria-label="Close"
                />
            </div>

            <form onSubmit={handleSubmit}>
                <div className="modal-body">
                    <Row>
                        <Col md={12} className="mb-2">
                            <FormGroup>
                                <Label>{t("State")}</Label>
                                <ValidationSelect
                                    field="stateName"
                                    options={optionsStates}
                                    validationState={validationState}
                                    name="stateId"
                                    className="form-control"
                                    placeholder={`${t("Select")} ${t("State")}`}
                                    value={payload.stateId}
                                    onChange={({ target: { value, name } }) => {
                                        updateField(name, value);
                                        addPrefecturePresenter.getCity(parseInt(value));
                                    }}
                                />

                                <ValidationFeedback field="stateName" />
                            </FormGroup>
                        </Col>

                        <Col md={12} className="mb-2">
                            <FormGroup>
                                <Label>{t("City")}</Label>
                                <ValidationSelect
                                    field="cityCode"
                                    options={optionsCity}
                                    validationState={validationState}
                                    name="cityId"
                                    className="form-control"
                                    placeholder={`${t("Select")} ${t("City")}`}
                                    value={payload.cityId}
                                    onChange={({ target: { value, name } }) => updateField(name, value)}
                                />

                                <ValidationFeedback field="cityCode" />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>

                <div className="modal-footer">
                    <button
                        type="button"
                        className="btn btn-danger me-2"
                        onClick={isLoading ? undefined : onDismiss}
                        disabled={isLoading}
                    >
                        {t("Close")}
                    </button>

                    <button className="btn btn-primary" disabled={isLoading}>
                        {t(isLoading ? "loading" : "Save")}
                    </button>
                </div>
            </form>
        </Modal>
    );
};

export default withTranslation()(AddPrefectureView);
