import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translationPTBR from "./locales/pt-BR/translation.json";

const resources : any = {
  'pt-BR': {
    translation: translationPTBR,
  }
};

const language : any = localStorage.getItem("I18N_LANGUAGE");
if (!language) {
  localStorage.setItem("I18N_LANGUAGE", "pt-BR");
}

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    lng: localStorage.getItem("I18N_LANGUAGE") || "pt-BR",
    fallbackLng: "pt-BR",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
