import { AccountGroup, Transaction } from "@domain/entities";
import { GeneralBankStatementPrensenterContract } from "@ui/presenters/reports";

interface StatementTransaction {
  flow: string;
  group: string;
  description: string;
  operation: string;
  date: string;
  value: string;
  type: string;
  account: string;
  provider: string;
}

export function joinGeneralBankStatementTransactions(
  statement: GeneralBankStatementPrensenterContract.Response,
  accountGroups: AccountGroup[]
): StatementTransaction[] {
  const transactions: StatementTransaction[] = [];

  statement.transfers.forEach((repasse) => {
    transactions.push({
      flow: repasse.fluxo_saida_nome,
      group:
        accountGroups.find(({ id }) => id == repasse.saida_conta)?.name || "",
      description: `Da conta: ${repasse.saida_conta} - para: ${repasse.entrada_conta}`,
      operation: repasse.tipo === "E" ? "Empréstimo" : "Transferencia",
      date: repasse.data,
      value: repasse.valor,
      type: repasse.tipo === "E" ? "D" : "C",
      account: repasse.saida_conta,
      provider: repasse.entrada_conta,
    });

    transactions.push({
      flow: repasse.fluxo_entrada_nome,
      group:
        accountGroups.find(({ id }) => id == repasse.entrada_conta)?.name || "",
      description: `Para a conta: ${repasse.entrada_conta} - da conta: ${repasse.saida_conta}`,
      operation: repasse.tipo === "E" ? "Empréstimo" : "Transferência",
      date: repasse.data,
      value: repasse.valor,
      account: repasse.entrada_conta,
      type: repasse.tipo === "E" ? "C" : "D",
      provider: repasse.saida_conta,
    });
  });

  statement.transactions.forEach((transacao: Transaction) => {
    transactions.push({
      flow: transacao.tipo_fluxo.nome,
      group: transacao.evento.name,
      description: transacao.evento.name || "Sem descrição",
      operation: transacao.tipo_transacao.name,
      date: transacao.data,
      value: transacao.valor,
      type: transacao.tipos_transacoes_id == 1 ? "C" : "D",
      account: transacao.conta.name,
      provider: transacao.fornecedor.name,
    });
  });

  return transactions;
}
