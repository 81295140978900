import { CheckForgotPasswordEmailService } from "@domain/services/auth";
import { FormValidation } from "@presentation/validation/interfaces";
import { ForgotPasswordRecoverViewContract } from "@presentation/views";
import { ForgotPasswordRecoverPresenterContract } from "@ui/presenters";

export class ForgotPasswordRecoverPresenter implements ForgotPasswordRecoverPresenterContract {
  private view!: ForgotPasswordRecoverViewContract;

  constructor(
    private readonly validation: FormValidation,
    private readonly checkForgotPasswordEmailService: CheckForgotPasswordEmailService,
  ) { }

  setView(view: ForgotPasswordRecoverViewContract): void {
    this.view = view;
  }

  async recover(payload: ForgotPasswordRecoverPresenterContract.Payload): Promise<void> {
    this.view.setLoadingState(true);
    this.view.removeErrorMessage();

    const validationSuccessfull = this.validation.allValid(payload);

    if (!validationSuccessfull) {
      this.view.updateValidationState(this.validation.errors(payload));
      this.view.setLoadingState(false);
      return;
    }

    const message = await this.checkForgotPasswordEmailService.handle({
      code: payload.code,
      email: payload.email,
      password: payload.password,
    });

    this.view.updateValidationState([]);
    this.view.setLoadingState(false);
    this.view.showErrorMessage(message);
  }
}
