import React, { useEffect, useState } from "react";

import { Container, Table, Row, Col, Spinner } from "reactstrap";

import { withTranslation } from "react-i18next";
import { UsersListPresenterContract } from "@ui/presenters/users";
import { User } from "@domain/entities";

import { Breadcrumbs } from "@ui/components/Common";
import { AddUser, EditUser } from "@main/pages/users";
import { EventEmitter } from "@utils/events";
import { UserEvents } from "@domain/events";
import { EditUserViewContract } from "@presentation/views/users";
import AddActionButton from "@ui/components/AddActionButton";
import EditActionButton from "@ui/components/EditActionButton";
import RemoveActionButton from "@ui/components/RemoveActionButton";

interface UserListViewProps {
  t?: any;
  usersListPresenter: UsersListPresenterContract;
}

const UserListView: React.FC<UserListViewProps> = ({
  t,
  usersListPresenter,
}) => {
  const [isLoading, setLoading] = useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const [isAddingUser, setIsAddingUser] = useState<boolean>(false);
  const [userToEdit, setUserToEdit] =
    useState<EditUserViewContract.Payload | null>(null);

  useEffect(() => {
    usersListPresenter.setView({ setLoadingState, setUsersList });

    usersListPresenter.get();

    EventEmitter.subscribe(UserEvents.USER_ADDED, userAddedCallback);
    EventEmitter.subscribe(UserEvents.USER_UPDATED, userUpdatedCallback);

    return () => {
      EventEmitter.unsubscribe(UserEvents.USER_ADDED, userAddedCallback);
      EventEmitter.unsubscribe(UserEvents.USER_UPDATED, userUpdatedCallback);
    };
  }, []);

  const userUpdatedCallback = () => {
    setUserToEdit(null);
    usersListPresenter.get();
  };

  const userAddedCallback = () => {
    setIsAddingUser(false);
    usersListPresenter.get();
  };

  const setLoadingState = (isLoading: boolean): void => {
    setLoading(isLoading);
  };

  const setUsersList = (users: User[]) => {
    setUsers(users);
  };

  const goToAddUserPage = () => setIsAddingUser(true);
  const dismissAddUserPage = () => setIsAddingUser(false);

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title={t("records")} breadcrumbItem={t("Users")} />

        <Row>
          {isLoading ? (
            <Col xl={12} className="mt-4">
              <div className="d-sm-flex align-items-center justify-content-center">
                <Spinner color="primary" />
              </div>
            </Col>
          ) : (
            <Col xl={12} className="mt-4">
              <div className="d-sm-flex align-items-center justify-content-end">
                <AddActionButton
                  type="button"
                  className="btn btn-primary mb-3"
                  onClick={goToAddUserPage}
                />
              </div>

              <div className="table-responsive">
                <Table className="align-middle mb-0" bordered>
                  <thead>
                    <tr>
                      <th>{t("Name")}</th>
                      <th style={{ width: "20%" }} className="text-center">
                        {t("Actions")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map(({ id, name, cashflows, email, group }, key) => (
                      <tr key={key}>
                        <td>{name}</td>
                        <td className="text-center">
                          {
                            <EditActionButton
                              isLastOfList={false}
                              label={t("Edit")}
                              onClick={() =>
                                setUserToEdit({
                                  id,
                                  name,
                                  cashflows: cashflows,
                                  email: email,
                                  group: group,
                                })
                              }
                            />
                          }
                          <RemoveActionButton
                            onClick={() =>
                              usersListPresenter.removeUser(id)
                            }
                            isLastOfList
                            label={t("Remove")}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Col>
          )}
        </Row>
      </Container>

      <AddUser isOpen={isAddingUser} onDismiss={dismissAddUserPage} />

      <EditUser onDismiss={() => setUserToEdit(null)} user={userToEdit} />
    </div>
  );
};

export default withTranslation()(UserListView);
