import { HttpRequestBuilder } from "@data/builders";
import { HttpRequestTypes } from "@data/enums";
import { CacheRepository, HttpRepository, NavigateRepository } from "@data/repositories";
import { GetOtpEmailStatusMessageHelper } from "@data/utils";
import { AppRoutes, OtpEmailStatus } from "@domain/enums";
import { CheckForgotPasswordEmailService } from "@domain/services/auth";

interface CheckForgotPasswordResponse {
  status: OtpEmailStatus;
}

export class HttpCheckForgotPasswordEmailService implements CheckForgotPasswordEmailService {
  constructor(
    private readonly httpRepository: HttpRepository,
    private readonly navigateRepository: NavigateRepository,
    private readonly cacheRepository: CacheRepository
  ) { }

  async handle(payload: CheckForgotPasswordEmailService.Payload): Promise<string> {
    const httpRequest = HttpRequestBuilder
      .requestToUrl('/api/auth/forgotPass/checkOtpEmailCode')
      .withParams(payload)
      .withMethod(HttpRequestTypes.GET)
      .build();

    const { status, data } = await this.httpRepository.makeRequest<any, CheckForgotPasswordResponse>(httpRequest);

    const emailStatusMessageHelper = new GetOtpEmailStatusMessageHelper(this.cacheRepository);
    const statusMessage = await emailStatusMessageHelper.get(data.status);

    if (this.isSuccessFullResponse(status)) {
      this.navigateRepository.navigate(AppRoutes.LOGIN);
    }

    return statusMessage;
  }

  isSuccessFullResponse(statusCode: number) {
    return statusCode === 204;
  }
}
