import React, { useEffect, useState } from "react";
import { Modal, Row, Col, Label, FormGroup } from "reactstrap";
import { withTranslation } from "react-i18next";

import { AccountGroup, CashFlow } from "@domain/entities";

import { useValidation } from "@ui/hooks";
import { SelectItem } from "@ui/interfaces";
import { AddSourcePresenterContract } from "@ui/presenters/sources";

interface AddSourcesViewProps {
  t?: any;
  isOpen: boolean;
  onDismiss(): void;
  addSourcesPresenter: AddSourcePresenterContract;
}

const INITIAL_FORM_STATE: AddSourcePresenterContract.Payload = {
  name: "",
  cashflow: "",
  code: "",
  group: ""
};

const AddSourcesView: React.FC<AddSourcesViewProps> = ({
  t,
  isOpen,
  onDismiss,
  addSourcesPresenter,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [cashflowList, setCashflowList] = useState<CashFlow[]>([]);
  const [group, setGroupList] = useState<AccountGroup[]>([]);

  const [payload, setPayload] =
    useState<AddSourcePresenterContract.Payload>(INITIAL_FORM_STATE);

  const {
    ValidationFeedback,
    ValidationInput,
    updateValidationState,
    validationState,
    ValidationSelect,
  } = useValidation();

  useEffect(() => {
    addSourcesPresenter.setView({
      setLoading,
      updateValidationState,
      setCashflows,
      setGroups
    });

    addSourcesPresenter.getCashflows();
    addSourcesPresenter.getGroups();
  }, []);

  useEffect(() => {
    if (!isOpen) {
      updateValidationState([]);
      setPayload(INITIAL_FORM_STATE);
    }
  }, [isOpen]);

  const setLoading = (isLoading: boolean): void => setIsLoading(isLoading);

  const setGroups = (group: AccountGroup[]): void => setGroupList(group);

  const setCashflows = (cashflows: CashFlow[]): void =>
    setCashflowList(cashflows);

  const updateField = (fieldName: string, value: string | number) => {
    setPayload((oldState) => ({
      ...oldState,
      [fieldName]: value,
    }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    addSourcesPresenter.add(payload);
  };

  const optionsCashflows: SelectItem[] = cashflowList.map(
    ({ id, name }) => ({ label: name, value: id })
  );

  const optionsGroups: SelectItem[] = group.map(
    ({ id, name }) => ({ label: name, value: id })
  );

  return (
    <Modal
      isOpen={isOpen}
      toggle={isLoading ? undefined : onDismiss}
      scrollable={true}
      id="staticBackdrop"
      size="lg"
    >
      <div className="modal-header">
        <h5 className="modal-title">{t("Add Sources")}</h5>
        <button
          type="button"
          className="btn-close"
          onClick={isLoading ? undefined : onDismiss}
          aria-label="Close"
        />
      </div>

      <form onSubmit={handleSubmit}>
        <div className="modal-body">
          <Row>
            <Col md={12} className="mb-2">
              <FormGroup>
                <Label>{t("Source")}</Label>
                <ValidationInput
                  field="code"
                  validationState={validationState}
                  name="code"
                  className="form-control"
                  placeholder={`${t("Digit Here")}`}
                  value={payload.code}
                  onChange={({ target: { value, name } }) =>
                    updateField(name, value)
                  }
                />

                <ValidationFeedback field="code" />
              </FormGroup>
            </Col>

            <Col md={12} className="mb-2">
              <FormGroup>
                <Label>{t("Source Description")}</Label>
                <ValidationInput
                  field="name"
                  validationState={validationState}
                  name="name"
                  className="form-control"
                  placeholder={`${t("Digit Here")}`}
                  value={payload.name}
                  onChange={({ target: { value, name } }) =>
                    updateField(name, value)
                  }
                />

                <ValidationFeedback field="name" />
              </FormGroup>
            </Col>

            <Col md={6} className="mb-2">
              <FormGroup>
                <Label>{t("Flow")}</Label>
                <ValidationSelect
                  field="cashflow"
                  name="cashflow"
                  className="form-control"
                  placeholder={t("Flow placeholder")}
                  options={optionsCashflows}
                  validationState={validationState}
                  value={payload.cashflow}
                  onChange={({ target: { value, name } }) =>
                    updateField(name, value)
                  }
                />

                <ValidationFeedback field="cashflow" />
              </FormGroup>
            </Col>

            <Col md={6} className="mb-2">
              <FormGroup>
                <Label>{t("Group")}</Label>
                <ValidationSelect
                  field="group"
                  name="group"
                  className="form-control"
                  placeholder={t("source.group.placeholder")}
                  options={optionsGroups}
                  validationState={validationState}
                  value={payload.group}
                  onChange={({ target: { value, name } }) =>
                    updateField(name, value)
                  }
                />

                <ValidationFeedback field="group" />
              </FormGroup>
            </Col>
          </Row>
        </div>

        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-danger me-2"
            onClick={isLoading ? undefined : onDismiss}
            disabled={isLoading}
          >
            {t("Close")}
          </button>

          <button className="btn btn-primary" disabled={isLoading}>
            {t(isLoading ? "loading" : "Save")}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default withTranslation()(AddSourcesView);
