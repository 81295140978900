import React from "react";
import { FaEdit } from "react-icons/fa";

interface EditActionButtonProps {
   isLastOfList?: boolean;
   label: string;
}

const EditActionButton: React.FC<
   EditActionButtonProps &
   React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
> = ({ isLastOfList, ...props }) => {
   return (
      <button
         type="button"
         className={`btn btn-light ${isLastOfList ? "" : "me-1"}`}
         {...props}
      >
         <FaEdit/>
      </button>
   );
};

export default EditActionButton;
