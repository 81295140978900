import { Treasury } from "@domain/entities";
import { GroupsByFlowsList } from "@domain/interfaces/accounts";
import { GetBankAccountService } from "@domain/services/account/GetBankAccountService";

import {
  GetTreasuryService,
  PublishTreasuryService,
  SetHourTreasuryService,
} from "@domain/services/treasury";

import { TreasuryViewContract } from "@presentation/views/treasury";
import { TreasuryPresenterContract } from "@ui/presenters/treasury";

export class TreasuryPresenter implements TreasuryPresenterContract {
  private view!: TreasuryViewContract;

  constructor(
    private readonly getBankAccountService: GetBankAccountService,
    private readonly getTreasuryService: GetTreasuryService,
    private readonly publishTreasuryService: PublishTreasuryService,
    private readonly setHourTreasuryService: SetHourTreasuryService
  ) {}

  setView(view: TreasuryViewContract): void {
    this.view = view;
  }

  async getTreasury(day: string): Promise<Treasury | null> {
    this.view.setLoading(true);
    const treasury = await this.getTreasuryService.handle(day);
    this.view.setLoading(false);
    this.view.setTreasury(treasury);
    return treasury;
  }

  async getAccounts(): Promise<GroupsByFlowsList[]> {
    this.view.setLoading(true);
    const accounts = await this.getBankAccountService.handle();
    this.view.setAccounts(accounts);
    this.view.setLoading(false);
    return accounts;
  }

  async publish(payload: TreasuryPresenterContract.Payload): Promise<void> {
    this.view.setLoading(true);

    await this.publishTreasuryService.handle(payload);

    await this.getTreasury(payload.day);
  }

  async setHour(
    payload: TreasuryPresenterContract.SetHourPayload
  ): Promise<void> {
    this.view.setLoading(true);

    await this.setHourTreasuryService.handle(payload);

    this.view.setLoading(false);
  }
}
