import { Source, Provider, GroupedPlanning } from "@domain/entities";
import { BillsEvents } from "@domain/events/BillsEvents";
import { GroupsByFlowsList } from "@domain/interfaces/accounts";
import { GetBankAccountService } from "@domain/services/account/GetBankAccountService";
import { EditNonEffectiveBillsService } from "@domain/services/bills";
import { GetGroupedPlanningService } from "@domain/services/planning";
import { GetProviderService } from "@domain/services/provider";
import { GetSourcesService } from "@domain/services/sources";
import { FormValidation } from "@presentation/validation/interfaces";
import { EditNonEffectiveBillsViewContract } from "@presentation/views/bills";
import { EditNonEffectiveBillsPresenterContract } from "@ui/presenters/bills";
import { EventEmitter } from "@utils/events";

export class EditNonEffectiveBillsPresenter implements EditNonEffectiveBillsPresenterContract {
    private view!: EditNonEffectiveBillsViewContract;

    constructor(
        private readonly validation: FormValidation,
        private readonly editNonEffectiveBillsService: EditNonEffectiveBillsService,
        private readonly getAccountsService: GetBankAccountService,
        private readonly getSourcesService: GetSourcesService,
        private readonly getProviderService: GetProviderService,
        private readonly getPlanningService: GetGroupedPlanningService
    ) { }

    setView(view: EditNonEffectiveBillsViewContract): void {
        this.view = view;
    }

    async edit(payload: EditNonEffectiveBillsPresenterContract.Payload): Promise<boolean> {
        this.view.setLoading(true);

        const validationSuccessfull = this.validation.allValid(payload);

        if (!validationSuccessfull) {
            this.view.updateValidationState(this.validation.errors(payload));
            this.view.setLoading(false);
            return false;
        }

        const isNonEffectiveBillsEdited = await this. editNonEffectiveBillsService.handle(payload);

        if (isNonEffectiveBillsEdited) {
            EventEmitter.emit(BillsEvents.BILLS_UPDATED);
        }

        this.view.updateValidationState([]);
        this.view.setLoading(false);

        return isNonEffectiveBillsEdited;
    }
    
    async getAccounts(): Promise<GroupsByFlowsList[]> {
        const accounts = await this.getAccountsService.handle();
        this.view.setAccounts(accounts);
        return accounts;
    }

    async getSources(): Promise<Source[]> {
        const sources = await this.getSourcesService.handle();
        this.view.setSources(sources);
        return sources;
    }

    async getProvider(): Promise<Provider[]> {
        const providers = await this.getProviderService.handle();
        this.view.setProviders(providers);
        return providers;
    }

    async getPlanning(): Promise<GroupedPlanning[]> {
        const planning = await this.getPlanningService.handle();
        this.view.setPlanning(planning);
        return planning;
    }
}
