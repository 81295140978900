import React, { Fragment } from "react";

import { Table } from "reactstrap";
import { withTranslation } from "react-i18next";

import { CashflowsTransaction, Transaction } from "@domain/entities";
import { DateFormatStrategy, MoneyFormatStrategy } from "@ui/strategy";
import { CashflowsHelper } from "@ui/utils/reports/CashflowsHelper";
import { BillsTypes } from "@domain/enums";
interface CashflowsRevenuesAndExpensesProps {
  t: any;
  transactionType: BillsTypes;
  transaction: Transaction[];
  transactionsCarriedOut: CashflowsTransaction[];
  title: string;
}

const CashflowsRevenuesAndExpenses: React.FC<
  CashflowsRevenuesAndExpensesProps
> = ({ t, transaction, title, transactionType, transactionsCarriedOut }) => {
  return (
    <Fragment>
      <h4 className="text-center mt-5">{title}</h4>
      <div className="table-responsive mt-4">
        <Table className="align-middle mb-0" bordered>
          <thead>
            <tr>
              <th style={{ width: "40%" }}>{t("Resource")}</th>
              <th className="text-center">{t("Realizated date")}</th>
              <th className="text-center">{t("Realized value")}</th>
              <th className="text-center">{t("Account")}</th>
            </tr>
          </thead>
          <tbody>
            {transaction
              .filter(({ tipos_transacoes_id }) => {
                if (transactionType === BillsTypes.TO_PAY)
                  return tipos_transacoes_id !== 1;
                else return tipos_transacoes_id === 1;
              })
              .map(({ valor, data, evento, conta }) => {
                return (
                  <tr>
                    <td>{evento.name}</td>
                    <td className="text-center">
                      {DateFormatStrategy.format(data)}
                    </td>
                    <td className="text-center">
                      {MoneyFormatStrategy.format(parseFloat(valor))}
                    </td>
                    <td className="text-center">{conta.name}</td>
                  </tr>
                );
              })}
            <tr>
              <th>{t("Total")}</th>
              <td className="text-center">{"-"}</td>
              <th className="text-center">
                {transactionType === BillsTypes.TO_RECEIVE
                  ? MoneyFormatStrategy.format(
                      CashflowsHelper.sumTotalRevenues(transactionsCarriedOut)
                    )
                  : MoneyFormatStrategy.format(
                      CashflowsHelper.sumTotalExpenses(transactionsCarriedOut)
                    )}
              </th>
              <td className="text-center">{"-"}</td>
            </tr>
          </tbody>
        </Table>
      </div>
    </Fragment>
  );
};

export default withTranslation()(CashflowsRevenuesAndExpenses);
