import Emitter from 'eventemitter3';

const emitter = new Emitter();

type EventCallback = (eventData?: any) => void;

export class EventEmitter {
  static emit(event: string, data?: any) {
    emitter.emit(event, data);
  }

  static subscribe(event: string, callback: EventCallback) {
    emitter.addListener(event, callback);
  }

  static unsubscribe(event: string, callback: EventCallback) {
    emitter.removeListener(event, callback);
  }
}
