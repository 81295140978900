import { PercentFormatDecorator } from "@ui/decorators/PercentFormatDecorator";
import { PercentFormat } from "@ui/interfaces";
import { PercentFormatBr } from "@ui/utils";

interface FormatterList {
   lang: string;
   formatter: PercentFormat;
}

const formatters: FormatterList[] = [
   {
      lang: "pt-BR",
      formatter: new PercentFormatBr()
   }
];

export class PercentFormatStrategy implements PercentFormat {
   handle(value: number): string {
      const chosenFormatter = formatters.find(
         ({ lang }) => lang === localStorage.getItem("I18N_LANGUAGE")
      );

      if (!chosenFormatter) {
         return new PercentFormatBr().handle(value);
      }

      return chosenFormatter?.formatter.handle(value);
   }

   static format(value: number): string {
      return new PercentFormatDecorator(
         new PercentFormatStrategy()
      ).handle(value);
   }
}
