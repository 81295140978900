import { HttpGetCashflowsService } from "@data/services/cashflows";
import { HttpGetPrefectureService } from "@data/services/prefecture";
import { HttpAddUserService, HttpGetUserAccessService } from "@data/services/users";
import { AxiosHttpRepository } from "@infra/http";
import { API_BASE_URL } from "@main/constants/config";
import { AddUserPresenter } from "@presentation/presenters/users";
import { FieldValidationBuilder } from "@presentation/validation/builders";
import { ValidationComposite } from "@presentation/validation/validators";
import { AddUserPresenterContract } from "@ui/presenters/users";

export const makeAddUserPresenter = (): AddUserPresenterContract => {
  const validation = new ValidationComposite([
    ...FieldValidationBuilder.field('name').required().build(),
    ...FieldValidationBuilder.field('email').required().email().build(),
    ...FieldValidationBuilder.field('password').required().password().build(),
    ...FieldValidationBuilder.field('prefectureId').required().build(),
    ...FieldValidationBuilder.field('group').required().build(),
    ...FieldValidationBuilder.field('cashflows').required().build(),
  ]);

  const httpRepository = new AxiosHttpRepository(API_BASE_URL);
  const getPrefecturesService = new HttpGetPrefectureService(httpRepository);
  const getCashflowService = new HttpGetCashflowsService(httpRepository);
  const getUserAccessService = new HttpGetUserAccessService(httpRepository);
  const addUserService = new HttpAddUserService(httpRepository);

  return new AddUserPresenter(
    validation,
    getPrefecturesService,
    getCashflowService,
    getUserAccessService,
    addUserService
  );
};
