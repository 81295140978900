import React, { Fragment, useEffect, useRef, useState } from "react";

import { BalanceCheckPresenterContract } from "@ui/presenters/account";
import { CashFlow } from "@domain/entities";

import { Breadcrumbs } from "@ui/components/Common";
import logo from "@ui/assets/images/logo.jpg";

import { Button, Col, Container, Row, Table } from "reactstrap";
import ComponentToPrint from "@ui/components/Print";
import { useReactToPrint } from "react-to-print";

import { withTranslation } from "react-i18next";
import BalanceCheckFormSearch from "@ui/components/accounts/BalanceCheckFormSearch";
import Loading from "@ui/components/Loading";
import { DateFormatStrategy, MoneyFormatStrategy } from "@ui/strategy";
import { GroupsByFlowsList } from "@domain/interfaces/accounts";

interface BalanceCheckViewProps {
  t: any;
  balanceCheckPresenter: BalanceCheckPresenterContract;
}

const BalanceCheckView: React.FC<BalanceCheckViewProps> = ({
  t,
  balanceCheckPresenter,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [cashflows, setCashflow] = useState<CashFlow[]>([]);
  const [bankAccounts, setBankAccount] = useState<GroupsByFlowsList[]>([]);
  const [balanceChecksData, setBalanceCheckDataList] =
    useState<BalanceCheckPresenterContract.Data | null>(null);

  useEffect(() => {
    balanceCheckPresenter.setView({
      setBalanceCheckData,
      setBankAccounts,
      setCashflows,
      setLoadingState,
    });

    balanceCheckPresenter.getBankAccounts();
    balanceCheckPresenter.getCashflows();
  }, []);

  const printRef = useRef(null);

  useEffect(() => {
    balanceCheckPresenter.getBankAccounts();
    balanceCheckPresenter.getCashflows();
  }, []);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `Saldo de Contas`,
    onPrintError: () => false,
    pageStyle: `
            .noPrint {
                display:none;
            }
        `,
  });

  const setLoadingState = (isLoading: boolean): void => setLoading(isLoading);
  const setBankAccounts = (bankAccount: GroupsByFlowsList[]): void =>
    setBankAccount(bankAccount);
  const setCashflows = (cashflows: any[]): void => setCashflow(cashflows);
  const setBalanceCheckData = (
    balance: BalanceCheckPresenterContract.Data
  ): void => setBalanceCheckDataList(balance);

  return (
    <div>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={t("Bank accounts")}
            breadcrumbItem={t("Balance check")}
          />
          <Row>
            <Col xl={12} className="mt-4">
              <BalanceCheckFormSearch
                accounts={bankAccounts}
                cashflows={cashflows}
                onSubmit={(formData) => {
                  balanceCheckPresenter.get(formData);
                }}
              />
              
              <div className="d-sm-flex align-items-center justify-content-end">
                <Button
                  color="primary"
                  className="btn btn-primary float-right"
                  onClick={handlePrint}
                >
                  {t("Print")}
                </Button>
              </div>

              {loading ? (
                <div className="mt-5">
                  <Loading />
                </div>
              ) : (
                <Fragment>
                  <ComponentToPrint ref={printRef}>
                    <div className="font-arial-rlt ">
                      <div className="d-sm-flex flex-column align-items-center justify-content-center mt-5 mb-3">
                        <img
                          style={{ objectFit: "contain" }}
                          className=""
                          src={logo}
                          height="150"
                          width="150"
                        />
                        <h4>{balanceChecksData?.account.name}</h4>
                      </div>

                      {balanceChecksData && (
                        <Table bordered>
                          <thead>
                            <tr>
                              <th style={{ width: "40%" }}>{t("Account")}</th>
                              <th className="text-center">
                                {t("Searched date")}
                              </th>
                              <th className="text-center">{t("Balance")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td style={{ width: "40%" }}>
                                {balanceChecksData.account.name}
                              </td>
                              <td className="text-center">
                                {DateFormatStrategy.format(
                                  balanceChecksData.requested_date
                                )}
                              </td>
                              <td className="text-center">
                                {MoneyFormatStrategy.format(
                                  balanceChecksData.final_value
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      )}
                    </div>
                  </ComponentToPrint>
                </Fragment>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default withTranslation()(BalanceCheckView);
