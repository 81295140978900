import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Modal, Row, Col, Label, FormGroup } from "reactstrap";

import { UpdateUserPasswordPresenterContract } from "@ui/presenters/users";

import { useValidation } from "@ui/hooks";
import ErrorAlert from "@ui/components/alerts/ErrorAlert";
import { Error } from "@domain/interfaces/errors";

interface UpdateUserPasswordViewProps {
  t?: any;
  user?: UpdateUserPasswordPresenterContract.Payload | null;
  onDismiss(): void;
  updateUserPasswordPresenter: UpdateUserPasswordPresenterContract;
}

const INITIAL_FORM_STATE: UpdateUserPasswordPresenterContract.Payload = {
  password: '',
  oldPassword: ''
};

const UpdateUserPasswordView: React.FC<UpdateUserPasswordViewProps> = ({ user, onDismiss, t, updateUserPasswordPresenter }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [payload, setPayload] = useState<UpdateUserPasswordPresenterContract.Payload>(INITIAL_FORM_STATE);
  const [errors, setErrorsList] = useState<Error[]>([]);
  const [isOpenFeedBack, setIsOpenFeedBack] = useState<boolean>(false);

  const { ValidationFeedback, ValidationInput, updateValidationState, validationState, } = useValidation();

  useEffect(() => {
    updateUserPasswordPresenter.setView({ setLoading, updateValidationState, setErrors, openErrorFeedback });
  }, []);

  useEffect(() => {
    if (!user) {
      updateValidationState([]);
      setPayload(INITIAL_FORM_STATE);
    } else {
      setPayload(user);
    }
  }, [user]);

  const setLoading = (isLoading: boolean): void => setIsLoading(isLoading);
  const setErrors = (errors: Error[]): void => setErrorsList(errors);
  const openErrorFeedback = (isOpenFeedBack: boolean): void => setIsOpenFeedBack(isOpenFeedBack);

  const updateField = (fieldName: string, value: string) => {
    setPayload((oldState) => ({
      ...oldState,
      [fieldName]: value
    }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    updateUserPasswordPresenter.edit(payload);
  };

  return (
    <Modal
      isOpen={Boolean(user)}
      toggle={isLoading ? undefined : onDismiss}
      scrollable={true}
      id="staticBackdrop"
      size="lg"
    >
      <div className="modal-header">
        <h5 className="modal-title">{t("users.edit.password.from")}</h5>
        <button
          type="button"
          className="btn-close"
          onClick={isLoading ? undefined : onDismiss}
          aria-label="Close"
        />
      </div>

      <form onSubmit={handleSubmit}>
        <div className="modal-body">
          <ErrorAlert 
            errors={errors}
            isOpen={isOpenFeedBack}
          />

          <Row>
            <Col md={12} className="mb-2">
              <FormGroup>
                <Label>{t("users.add.oldPassword")}</Label>
                <ValidationInput
                  field="oldPassword"
                  validationState={validationState}
                  name="oldPassword"
                  className="form-control"
                  placeholder={`${t("users.add.oldPassword.placeholder")}`}
                  value={payload.oldPassword}
                  onChange={({ target: { value, name } }) => updateField(name, value)}
                  type="password"
                />

                <ValidationFeedback field="oldPassword" />
              </FormGroup>
            </Col>

            <Col md={12} className="mb-2">
              <FormGroup>
                <Label>{t("users.add.newPassword")}</Label>
                <ValidationInput
                  field="password"
                  validationState={validationState}
                  name="password"
                  className="form-control"
                  placeholder={`${t("users.add.newPassword.placeholder")}`}
                  value={payload.password}
                  onChange={({ target: { value, name } }) => updateField(name, value)}
                  type="password"
                />

                <ValidationFeedback field="password" />
              </FormGroup>
            </Col>
          </Row>
        </div>

        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-danger me-2"
            onClick={isLoading ? undefined : onDismiss}
            disabled={isLoading}
          >
            {t("Close")}
          </button>

          <button className="btn btn-primary" disabled={isLoading}>
            {t(isLoading ? "loading" : "Save")}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default withTranslation()(UpdateUserPasswordView);
