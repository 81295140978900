import { HttpEditGroupService } from "@data/services/groups";
import { AxiosHttpRepository } from "@infra/http";
import { API_BASE_URL } from "@main/constants/config";
import { EditGroupPresenter } from "@presentation/presenters/groups/EditGroupPresenter";
import { FieldValidationBuilder } from "@presentation/validation/builders";
import { ValidationComposite } from "@presentation/validation/validators";
import { EditGroupPresenterContract } from "@ui/presenters/groups";

export const makeEditGroupPresenter = (): EditGroupPresenterContract => {
  const validation = new ValidationComposite([
    ...FieldValidationBuilder.field("id").required().build(),
    ...FieldValidationBuilder.field("name").required().build(),
  ]);

  const httpRepository = new AxiosHttpRepository(API_BASE_URL);
  const editGroupService = new HttpEditGroupService(httpRepository);

  return new EditGroupPresenter(validation, editGroupService);
};
