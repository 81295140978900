import { HttpRequestBuilder } from "@data/builders";
import { HttpRequestTypes } from "@data/enums";
import { HttpRepository } from "@data/repositories";
import { RemovePrefectureService } from "@domain/services/prefecture";

export class HttpRemovePrefectureService implements RemovePrefectureService {
   constructor(
      private readonly httpRepository: HttpRepository
   ) { }

   async handle(prefectureId: number): Promise<boolean> {
      const httpRequest = HttpRequestBuilder
         .requestToUrl(`/prefecture/${prefectureId}`)
         .withMethod(HttpRequestTypes.DELETE)
         .build();

      const { status } = await this.httpRepository.makeRequest(httpRequest);

      return status === 200;
   }
}
