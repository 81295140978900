import { HttpRequestBuilder } from "@data/builders";
import { HttpRequestTypes } from "@data/enums";
import { HttpRepository } from "@data/repositories";
import { RemoveSubGroupsService } from "@domain/services/revenues-expenses/RemoveSubgroupsService";

export class HttpRemoveSubgroupsService implements RemoveSubGroupsService {
  constructor(private readonly httpRepository: HttpRepository) {}

  async handle(subgroupId: number): Promise<boolean> {
    const httpRequest = HttpRequestBuilder.requestToUrl(
      `/subgroups/${subgroupId}`
    )
      .withMethod(HttpRequestTypes.DELETE)
      .build();

    const { status } = await this.httpRepository.makeRequest(httpRequest);

    return status === 200;
  }
}
