import React, { Fragment, useEffect, useRef, useState } from "react";

import { Button, Col, Container, Row } from "reactstrap";
import { withTranslation } from "react-i18next";
import ComponentToPrint from "@ui/components/Print";
import { useReactToPrint } from "react-to-print";

import {
  CashFlow,
  Group,
  Provider,
  Subgroup,
} from "@domain/entities";

import { Breadcrumbs } from "@ui/components/Common";
import { CashflowsSearchForm } from "@ui/components/reports";
import Loading from "@ui/components/Loading";
import logo from "@ui/assets/images/logo.jpg";

import { CashflowsPresenterContract } from "@ui/presenters/reports";
import Overview from "@ui/components/reports/cashflows/Overview";
import CashflowsRevenuesAndExpenses from "@ui/components/reports/cashflows/CashflowsRevenuesAndExpenses";
import { BillsTypes } from "@domain/enums";
import { GroupsByFlowsList } from "@domain/interfaces/accounts";

interface CashflowsViewProps {
  t?: any;
  cashflowsPresenter: CashflowsPresenterContract;
}

const CashflowsView: React.FC<CashflowsViewProps> = ({
  t,
  cashflowsPresenter,
}) => {
  const [activeCashflow, setActiveCashflow] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [cashflows, setCashflowsList] = useState<CashFlow[]>([]);
  const [groups, setGroups] = useState<Group[]>([]);
  const [subgroups, setSubgroups] = useState<Subgroup[]>([]);
  const [accounts, setAccounts] = useState<GroupsByFlowsList[]>([]);
  const [providers, setProviders] = useState<Provider[]>([]);
  const [flows, setFlows] = useState<CashflowsPresenterContract.Response>({
    transaction: [],
    foressen: [],
    transactionsData: [],
  });

  useEffect(() => {
    cashflowsPresenter.setView({
      setLoadingState,
      setFlowsList,
      setCashflows,
      setGroupsList,
      setSubgroupsList,
      setAccountsList,
      setProviderList,
    });

    cashflowsPresenter.getCashflows();
    cashflowsPresenter.getGroups();
    cashflowsPresenter.getSubgroups();
    cashflowsPresenter.getAccounts();
    cashflowsPresenter.getProvider();
  }, []);

  const printRef = useRef(null);

  useEffect(() => {
    cashflowsPresenter.getCashflows();
    cashflowsPresenter.getGroups();
    cashflowsPresenter.getSubgroups();
    cashflowsPresenter.getAccounts();
    cashflowsPresenter.getProvider();
  }, []);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `Saldo de Contas`,
    onPrintError: () => false,
    pageStyle: `
            .noPrint {
                display:none;
            }
        `,
  });

  const setLoadingState = (isLoading: boolean): void => setLoading(isLoading);
  const setGroupsList = (groups: Group[]): void =>
    setGroups(groups);
  const setSubgroupsList = (subgroups: any[]): void =>
    setSubgroups(subgroups);
  const setCashflows = (cashflows: any[]): void =>
    setCashflowsList(cashflows);
  const setAccountsList = (accounts: GroupsByFlowsList[]): void =>
    setAccounts(accounts);
  const setProviderList = (providers: Provider[]): void =>
    setProviders(providers);
  const setFlowsList = (flow: CashflowsPresenterContract.Response): void =>
    setFlows(flow);

  const flowToShowInReport = cashflows.find(
    ({ id }) => id == activeCashflow
  )?.name;

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs breadcrumbItem={t("CashFlows")} title={t("Flow")} />
        <Row>
          <Col xl={12} className="mt-4">
            <CashflowsSearchForm
              cashflows={cashflows}
              groups={groups}
              subgroups={subgroups}
              accounts={accounts}
              providers={providers}
              onSubmit={async (params) => {
                await cashflowsPresenter.get(params);
                setActiveCashflow(params.cashFlow);
              }}
            />

            {loading ? (
              <div className="mt-5">
                <Loading />
              </div>
            ) : (
              <Fragment>
                <div className="d-sm-flex align-items-center justify-content-end">
                  <Button
                    color="primary"
                    className="btn btn-primary float-right"
                    onClick={handlePrint}
                  >
                    {t("Print")}
                  </Button>
                </div>
                {Boolean(activeCashflow) && (
                  <ComponentToPrint ref={printRef}>
                    <div className="font-arial-rlt">
                      <div className="d-sm-flex flex-column align-items-center justify-content-center mt-5 mb-3">
                        <img
                          style={{ objectFit: "contain" }}
                          className=""
                          src={logo}
                          height="150"
                          width="150"
                        />
                        <h5 className="text-center">
                          {t("Overview") + " - " + flowToShowInReport}
                        </h5>
                      </div>

                      <Overview
                        forecastTransactions={flows.foressen}
                        transactionsCarriedOut={flows.transactionsData}
                      />

                      <CashflowsRevenuesAndExpenses
                        transaction={flows.transaction}
                        title={t("Revenues")}
                        transactionType={BillsTypes.TO_RECEIVE}
                        transactionsCarriedOut={flows.transactionsData}
                      />

                      <CashflowsRevenuesAndExpenses
                        transaction={flows.transaction}
                        title={t("Expenses")}
                        transactionType={BillsTypes.TO_PAY}
                        transactionsCarriedOut={flows.transactionsData}
                      />
                    </div>
                  </ComponentToPrint>
                )}
              </Fragment>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default withTranslation()(CashflowsView);
