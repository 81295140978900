import { MonthAndDayFormat } from "@ui/interfaces";
import { MonthAndDayFormatBr } from "@ui/utils/MonthAndDayFormatBr";

interface FormatterList {
   lang: string;
   formatter: MonthAndDayFormat;
}

const formatters: FormatterList[] = [
   {
      lang: "pt-BR",
      formatter: new MonthAndDayFormatBr()
   }
];

export class MonthAndDayFormatStrategy implements MonthAndDayFormat {
   handle(date: Date): string {
      const chosenFormatter = formatters.find(
         ({ lang }) => lang === localStorage.getItem("I18N_LANGUAGE")
      );

      if (!chosenFormatter) {
         return new MonthAndDayFormatBr().handle(date);
      }

      return chosenFormatter?.formatter.handle(date);
   }

   static format(date: Date): string {
      return new MonthAndDayFormatStrategy().handle(date);
   }
}
