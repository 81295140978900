import { HttpRequestBuilder } from "@data/builders";
import { HttpRequestTypes } from "@data/enums";
import { HttpRepository } from "@data/repositories";
import { PlanningItem } from "@domain/entities";
import { GetPlanningService } from "@domain/services/planning";

export class HttpGetPlanningService implements GetPlanningService {
  constructor(private readonly httpRepository: HttpRepository) {}

  async handle(prefectureId: number): Promise<PlanningItem[]> {
    const request = HttpRequestBuilder.requestToUrl("planning")
      .withMethod(HttpRequestTypes.GET)
      .withParams({ prefectureId })
      .build();

    const { data } = await this.httpRepository.makeRequest<any, PlanningItem[]>(
      request
    );

    return data;
  }
}
