import { Foressen } from "@domain/entities";

export class CashflowsHelper {
   static sumTotalRevenues(transactions: Foressen[]) {
      const revenues = transactions.filter(({ tipos_transacoes_id }) => tipos_transacoes_id === 1);
      return revenues.reduce((current, { total }) => parseFloat(total) + current, 0);
   }

   static sumTotalExpenses(transactions: Foressen[]) {
      const expenses = transactions.filter(({ tipos_transacoes_id }) => tipos_transacoes_id !== 1);
      return expenses.reduce((current, { total }) => parseFloat(total) + current, 0);
   }

   static sumTotalResult(transactions: Foressen[]) {
      const revenues = this.sumTotalRevenues(transactions);
      const expenses = this.sumTotalExpenses(transactions);
      return revenues - expenses;
   }
}
