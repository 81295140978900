import { HttpRequestBuilder } from "@data/builders";
import { HttpRequestTypes } from "@data/enums";
import { HttpRepository } from "@data/repositories";
import { RemoveUserService } from "@domain/services/users";

export class HttpRemoveUsersService implements RemoveUserService {
   constructor(
      private readonly httpRepository: HttpRepository
   ) {}

   async handle(userId: number): Promise<boolean> {
      const httpRequest = HttpRequestBuilder
      .requestToUrl(`/users/${userId}`)
      .withMethod(HttpRequestTypes.DELETE)
      .build();

      const { data } = await this.httpRepository.makeRequest(httpRequest);

      return data === 200;
   }
}
