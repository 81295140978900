import { HttpRequestBuilder } from "@data/builders";
import { HttpRequestTypes } from "@data/enums";
import { HttpRepository } from "@data/repositories";
import { RemoveBankAccountService } from "@domain/services/account/RemoveBankAccount";

export class HttpRemoveBankAccountsService implements RemoveBankAccountService {
   constructor(
      private readonly httpRepository: HttpRepository
   ) {}

   async handle(accountId: number): Promise<boolean> {
      const httpRequest = HttpRequestBuilder
      .requestToUrl(`bank/account/${accountId}`)
      .withMethod(HttpRequestTypes.DELETE)
      .build();

      const { status } = await this.httpRepository.makeRequest(httpRequest);

      return status === 200;
   }
}
