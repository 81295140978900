import { HttpGetGroupsService } from "@data/services/groups";
import { AxiosHttpRepository } from "@infra/http";
import { GroupsPresenter } from "@presentation/presenters/groups";
import { GroupsPresenterContract } from "@ui/presenters/groups";
import { HttpRemovegroupService } from "@data/services/groups/HttpRemoveGroupsService";
import { API_BASE_URL } from "@main/constants/config";

export const makeGroupsPresenter = (): GroupsPresenterContract => {
  const httpRepository = new AxiosHttpRepository(API_BASE_URL);
  const getGroupsService = new HttpGetGroupsService(httpRepository);
  const removeGroupService = new HttpRemovegroupService(httpRepository);
  return new GroupsPresenter(getGroupsService, removeGroupService);
};
