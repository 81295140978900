import React from "react";
import { FaTrash } from "react-icons/fa";

interface RemoveActionButtonProps {
   isLastOfList?: boolean;
   label: string;
}

const RemoveActionButton: React.FC<
   RemoveActionButtonProps &
   React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
> = ({ isLastOfList, ...props }) => {
   return (
      <button
         type="button"
         className={`btn btn-danger ${isLastOfList ? "" : "me-1"}`}
         {...props}
      >
         <FaTrash/>
      </button>
   );
};

export default RemoveActionButton;
