import { HttpRequestBuilder } from "@data/builders";
import { HttpRequestTypes } from "@data/enums";
import { HttpRepository } from "@data/repositories";
import { TransactionType } from "@domain/entities";
import { GetTransactionsTypesService } from "@domain/services/transactionsTypes";

export class HttpGetTransactionsTypesService
  implements GetTransactionsTypesService
{
  constructor(private readonly httpRepository: HttpRepository) {}

  async handle(): Promise<TransactionType[]> {
    const request = HttpRequestBuilder.requestToUrl(
      "/transactions/details/types"
    )
      .withMethod(HttpRequestTypes.GET)
      .build();

    const { data } = await this.httpRepository.makeRequest<
      any,
      TransactionType[]
    >(request);

    return data;
  }
}
