import { Prefecture } from "@domain/entities";
import { GetPrefectureService, RemovePrefectureService } from "@domain/services/prefecture";
import { PrefectureViewContract } from "@presentation/views/prefecture";
import { PrefecturePresenterContract } from "@ui/presenters/prefecture";

export class PrefecturePresenter implements PrefecturePresenterContract {
    private view!: PrefectureViewContract;

  constructor(
    private readonly getPrefectureService: GetPrefectureService,
    private readonly removePrefectureService: RemovePrefectureService
  ) { }

  async removePrefecture(prefectureId: number): Promise<void> {
    this.view.setLoadingState(true);

    const isRemoved = await this.removePrefectureService.handle(prefectureId);

    if (isRemoved) {
      this.get();
    } else {
      this.view.setLoadingState(false);
    }
  }

  setView(view: PrefectureViewContract): void {
    this.view = view;
  }

  async get(): Promise<Prefecture[]> {
    this.view.setLoadingState(true);

    const groups = await this.getPrefectureService.handle();

    this.view.setPrefectures(groups);
    this.view.setLoadingState(false);
    return groups;
  }
}
