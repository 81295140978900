import { HttpRequestBuilder } from "@data/builders";
import { HttpRequestTypes } from "@data/enums";
import { HttpRepository } from "@data/repositories";
import { AccountAdjustmentService } from "@domain/services/account";

export class HttpAccountAdjustmentService implements AccountAdjustmentService {
   constructor(
      private readonly httpRepository: HttpRepository
   ) { }

   async handle(payload: AccountAdjustmentService.Payload): Promise<boolean> {
      const httpRequest = HttpRequestBuilder
         .requestToUrl('/bank/account/adjustment')
         .withMethod(HttpRequestTypes.POST)
         .withData(payload)
         .build();

      const { status } = await this.httpRepository.makeRequest<AccountAdjustmentService.Payload>(httpRequest);

      return status === 201;
   }
}
