import React from "react";
import { FaPrint } from "react-icons/fa";

interface PrintActionButtonProps {
   isLastOfList?: boolean;
}

const PrintActionButton: React.FC<
   PrintActionButtonProps &
   React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
> = ({ isLastOfList, ...props }) => {
   return (
      <button
         type="button"
         className={`btn btn-primary ${isLastOfList ? "" : "me-1"}`}
         {...props}
      >
         <FaPrint/>
      </button>
   );
};

export default PrintActionButton;
