import { HttpRequestBuilder } from "@data/builders";
import { HttpRequestTypes } from "@data/enums";
import { HttpRepository } from "@data/repositories";
import { UpdateProfileService } from "@domain/services/profile";

interface HttpUpdateProfileRequest {
  name: string;
  email: string;
  estado_id?: number;
  cidade_id?: number;
}

interface HttpUpdateProfileResponse {
  ok: boolean;
}

export class HttpUpdateProfileService implements UpdateProfileService {
  constructor(
    private readonly httpRepository: HttpRepository
  ) { }

  async handle(payload: UpdateProfileService.Payload): Promise<boolean> {
    const requestData: any = {
      name: payload.name,
      email: payload.email,
    };

    if (payload.state && payload.city) {
      requestData.estado_id = payload.state;
      requestData.cidade_id = payload.city;
    }

    const request = HttpRequestBuilder
      .requestToUrl('/api/auth/updateProfile')
      .withMethod(HttpRequestTypes.POST)
      .withData(requestData)
      .build();

    const { data } = await this.httpRepository.makeRequest<HttpUpdateProfileRequest, HttpUpdateProfileResponse>(
      request
    );

    return data.ok;
  }
}
