import { Validation } from "@presentation/validation/interfaces";

export class EmailValidation implements Validation {
  constructor(
    private readonly fieldName: string, 
    private readonly emailInvalidMessage: string
  ) {}

  private getMessage(isValid: boolean): string {
    if(isValid) return 'valid_email';

    return this.emailInvalidMessage;
  }

  validate(input: any): Validation.ValidationResult[] {
    // eslint-disable-next-line no-useless-escape
    const regexEmail = /^(?:[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+\.)*[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!\.)){0,61}[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/;
    const isValid = regexEmail.test(input[this.fieldName]);
    const message = this.getMessage(isValid);

    return [{ isValid, message, fieldName: this.fieldName }];
  }
}
