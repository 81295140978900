import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Modal, Row, Col, Label, FormGroup } from "reactstrap";

import { useValidation } from "@ui/hooks";
import { SelectItem } from "@ui/interfaces";

import { UpdateProfilePresenterContract } from '@ui/presenters/profiles';
import { City, State } from "@domain/entities";

interface UpdateProfileViewProps {
  t: any;
  userInfo: UpdateProfilePresenterContract.Payload | null;
  updateProfilePresenter: UpdateProfilePresenterContract;
  onDismiss(): void;
}

const INITIAL_FORM_STATE: UpdateProfilePresenterContract.Payload = {
  email: '',
  name: '',
};

const UpdateProfileView: React.FC<UpdateProfileViewProps> = ({ t, updateProfilePresenter, userInfo, onDismiss }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [payload, setPayload] = useState<UpdateProfilePresenterContract.Payload>(INITIAL_FORM_STATE);
  const [states, setStatesList] = useState<State[]>([]);
  const [cities, setCitiesList] = useState<City[]>([]);

  const { ValidationFeedback, ValidationInput, updateValidationState, validationState, ValidationSelect } = useValidation();

  const isAbleToUpdateStateAndCity = [1, 5].includes(userInfo?.userGroup ?? 0);

  useEffect(() => {
    updateProfilePresenter.setView({
      setCities,
      setLoading,
      setStates,
      updateValidationState
    });
  }, []);

  useEffect(() => {
    if (!userInfo) {
      updateValidationState([]);
      setPayload(INITIAL_FORM_STATE);
    } else {
      setPayload(userInfo);

      updateProfilePresenter.getStates();
      if (userInfo.state) {
        updateProfilePresenter.getCities(userInfo.state);
      }
    }
  }, [userInfo, isAbleToUpdateStateAndCity]);

  const setStates = (states: State[]) => setStatesList(states);
  const setCities = (cities: City[]) => setCitiesList(cities);
  const setLoading = (isLoading: boolean): void => setIsLoading(isLoading);

  const updateField = (fieldName: string, value: string) => {
    setPayload((oldState) => ({
      ...oldState,
      [fieldName]: value
    }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    updateProfilePresenter.updateProfile(payload);
  };

  const optionsStates: SelectItem[] = states.map(({ id, name }) => ({ label: name, value: id }));
  const optionsCities: SelectItem[] = cities.map(({ id, name }) => ({ label: name, value: id }));

  return (
    <Modal
      isOpen={Boolean(userInfo)}
      toggle={isLoading ? undefined : onDismiss}
      scrollable={true}
      id="staticBackdrop"
      size="lg"
    >
      <div className="modal-header">
        <h5 className="modal-title">{t("Update Profile")}</h5>
        <button
          type="button"
          className="btn-close"
          onClick={isLoading ? undefined : onDismiss}
          aria-label="Close"
        />
      </div>

      <form onSubmit={handleSubmit}>
        <div className="modal-body">
          <Row>
            <Col md={12} className="mb-2">
              <FormGroup>
                <Label>{t("users.add.name")}</Label>
                <ValidationInput
                  field="name"
                  validationState={validationState}
                  name="name"
                  className="form-control"
                  placeholder={`${t("users.add.name.placeholder")}`}
                  value={payload.name}
                  onChange={({ target: { value, name } }) => updateField(name, value)}
                />

                <ValidationFeedback field="name" />
              </FormGroup>
            </Col>

            <Col md={12} className="mb-2">
              <FormGroup>
                <Label>{t("users.add.email")}</Label>
                <ValidationInput
                  field="email"
                  validationState={validationState}
                  name="email"
                  className="form-control"
                  placeholder={`${t("users.add.email.placeholder")}`}
                  value={payload.email}
                  onChange={({ target: { value, name } }) => updateField(name, value)}
                />

                <ValidationFeedback field="email" />
              </FormGroup>
            </Col>

            {isAbleToUpdateStateAndCity && (
              <Col md={6} className="mb-3">
                <FormGroup>
                  <Label>{t("users.add.state")}</Label>

                  <ValidationSelect
                    field="state"
                    options={optionsStates}
                    validationState={validationState}
                    name="state"
                    className="form-control"
                    placeholder={t('users.add.state.placeholder')}
                    onChange={({ target: { value, name } }) => {
                      updateField(name, value);
                      updateProfilePresenter.getCities(parseInt(value));
                    }}
                    value={payload.state}
                  />

                  <ValidationFeedback field="state" />
                </FormGroup>
              </Col>
            )}

            {!!cities.length && isAbleToUpdateStateAndCity && (
              <Col md={6} className="mb-3">
                <FormGroup>
                  <Label>{t("users.add.city")}</Label>

                  <ValidationSelect
                    field="city"
                    options={optionsCities}
                    validationState={validationState}
                    name="city"
                    className="form-control"
                    placeholder={t('users.add.city.placeholder')}
                    onChange={({ target: { value, name } }) => updateField(name, value)}
                    value={payload.city}
                  />

                  <ValidationFeedback field="city" />
                </FormGroup>
              </Col>
            )}
          </Row>
        </div>

        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-danger me-2"
            onClick={isLoading ? undefined : onDismiss}
            disabled={isLoading}
          >
            {t("Close")}
          </button>

          <button className="btn btn-primary" disabled={isLoading}>
            {t(isLoading ? "loading" : "Save")}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default withTranslation()(UpdateProfileView);
