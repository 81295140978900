import { HttpRequestBuilder } from "@data/builders";
import { HttpRequestTypes } from "@data/enums";
import { HttpRepository } from "@data/repositories";
import { Subgroup } from "@domain/entities/Subgroup";
import { GetSubgroupsService } from "@domain/services/revenues-expenses/GetSubgroupsService";

export class HttpGetSubgroupsService implements GetSubgroupsService {
  constructor(private readonly httpRepository: HttpRepository) {}

  async handle(): Promise<Subgroup[]> {
    const request = HttpRequestBuilder.requestToUrl("/subgroups")
      .withMethod(HttpRequestTypes.GET)
      .build();

    const { data } = await this.httpRepository.makeRequest<any, Subgroup[]>(
      request
    );

    return data;
  }
}
