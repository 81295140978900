import React, { Fragment, useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";

import { Container, Row, Col, Button } from "reactstrap";
import { useReactToPrint } from "react-to-print";

import { Breadcrumbs } from "@ui/components/Common";
import Loading from "@ui/components/Loading";
import { QuartelyConsolidatedPresenterContract } from "@ui/presenters/reports";

import logo from "@ui/assets/images/logo.jpg";
import { CashFlow, Group, Subgroup } from "@domain/entities";
import {
  FormSearchPeriod,
  QuartelyConsolidatedTable,
} from "@ui/components/reports";

import {
  getLastThreeMonths,
  getPeriodFromMonthAndYear,
} from "@ui/utils/reports";

import ComponentToPrint from "@ui/components/Print";

import { DateStringToISO } from "@ui/utils";
import { MonthAndDayFormatStrategy } from "@ui/strategy/MonthAndDayFormatStrategy";

interface QuartelyConsolidatedViewProps {
  t: any;
  quartelyConsolidatedPresenter: QuartelyConsolidatedPresenterContract;
}

const QuartelyConsolidatedView: React.FC<QuartelyConsolidatedViewProps> = ({
  t,
  quartelyConsolidatedPresenter,
}) => {
  const { firstDayOfPeriod, lastDayOfPeriod } = getLastThreeMonths();
  const [activeCashflowId, setActiveCashflowId] = useState<number>(0);
  const [cashflows, setCashflowsList] = useState<any[]>([]);
  const [groups, setGroups] = useState<Group[]>([]);
  const [subgroups, setSubgroups] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [quartelyConsolidated, setQuartelyConsolidatedList] =
    useState<QuartelyConsolidatedPresenterContract.Response | null>(null);

  const [search, setSearch] =
    useState<QuartelyConsolidatedPresenterContract.Payload>({
      beginOfPeriod: firstDayOfPeriod,
      endOfPeriod: lastDayOfPeriod,
      cashFlow: 0,
      groupId: 0,
      subgroupId: 0,
      month: "",
      year: "",
    });

  useEffect(() => {
    quartelyConsolidatedPresenter.setView({
      setQuartelyConsolidated,
      setGroupsList,
      setSubgroupsList,
      setCashflows,
      setLoadingState,
    });

    quartelyConsolidatedPresenter.getCashflows();
    quartelyConsolidatedPresenter.getGroups();
    quartelyConsolidatedPresenter.getSubgroups();
  }, []);

  useEffect(() => {
    const { firstDayOfPeriod, lastDayOfPeriod } = getPeriodFromMonthAndYear(
      search.month,
      search.year
    );

    setSearch({
      ...search,
      beginOfPeriod: firstDayOfPeriod,
      endOfPeriod: lastDayOfPeriod,
    });
  }, []);

  const printRef = useRef(null);

  useEffect(() => {
    quartelyConsolidatedPresenter.getCashflows();
    quartelyConsolidatedPresenter.getGroups();
    quartelyConsolidatedPresenter.getSubgroups();
  }, []);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `Saldo de Contas`,
    onPrintError: () => false,
    pageStyle: `
            .noPrint {
                display:none;
            }
        `,
  });

  const setLoadingState = (isLoading: boolean): void => setLoading(isLoading);

  const setGroupsList = (groups: Group[]): void =>
    setGroups(groups);

  const setSubgroupsList = (subgroups: any[]): void =>
    setSubgroups(subgroups);

  const setCashflows = (cashflows: any[]): void =>
    setCashflowsList(cashflows);

  const setQuartelyConsolidated = (
    quartelyConsolidated: QuartelyConsolidatedPresenterContract.Response
  ): void => setQuartelyConsolidatedList(quartelyConsolidated);

  const flowToShowInReport = cashflows.find(
    ({ id }) => id == activeCashflowId
  )?.name;

  const transactions = quartelyConsolidated?.transactions || [];
  const predictions = quartelyConsolidated?.predictions || [];

  const creditSubgroups = subgroups.filter(({ id }) =>
    Array.from(
      new Set([
        ...transactions
          .filter(({ tipos_transacoes_id }) => tipos_transacoes_id == 1)
          .map(({ transacoes_eventos_id }) => transacoes_eventos_id),
        ...predictions
          .filter(({ tipos_transacoes_id }) => tipos_transacoes_id == 1)
          .map(({ transacoes_eventos_id }) => transacoes_eventos_id),
      ])
    ).includes(id)
  );

  const debitSubgroups = subgroups.filter(({ id }) =>
    Array.from(
      new Set([
        ...transactions
          .filter(({ tipos_transacoes_id }) => tipos_transacoes_id != 1)
          .map(({ transacoes_eventos_id }) => transacoes_eventos_id),
        ...predictions
          .filter(({ tipos_transacoes_id }) => tipos_transacoes_id != 1)
          .map(({ transacoes_eventos_id }) => transacoes_eventos_id),
      ])
    ).includes(id)
  );

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs
          title={t("Report balance")}
          breadcrumbItem={t("Quartely consolidated")}
        />
        <Row>
          <Col xl={12} className="mt-4">
            <FormSearchPeriod
              cashflows={cashflows}
              groups={groups}
              subgroups={subgroups}
              onSubmit={(params) => {
                quartelyConsolidatedPresenter.get(params);
                setActiveCashflowId(params.cashFlow);
              }}
            />

            {loading ? (
              <div className="mt-5">
                <Loading />
              </div>
            ) : (
              <Fragment>
                <div className="d-sm-flex align-items-center justify-content-end">
                  <Button
                    color="primary"
                    className="btn btn-primary float-right"
                    onClick={handlePrint}
                  >
                    {t("Print")}
                  </Button>
                </div>

                <ComponentToPrint ref={printRef}>
                  <div className="font-arial-rlt">
                    <div className="d-sm-flex flex-column align-items-center justify-content-center mt-5">
                      <img
                        style={{ objectFit: "contain" }}
                        src={logo}
                        height="150"
                        width="150"
                      />

                      <h4>{t("Quartely consolidated report")}</h4>
                      <h5 className="font-arial-rlt">{flowToShowInReport}</h5>
                      <h6 className="font-arial-rlt">
                        {MonthAndDayFormatStrategy.format(
                          quartelyConsolidated?.beginOfPeriod
                            ? DateStringToISO.toISO(
                                String(quartelyConsolidated?.beginOfPeriod)
                              )
                            : new Date()
                        ) +
                          " - " +
                          MonthAndDayFormatStrategy.format(
                            quartelyConsolidated?.endOfPeriod
                              ? DateStringToISO.toISO(
                                  String(quartelyConsolidated?.endOfPeriod)
                                )
                              : new Date()
                          )}
                      </h6>
                    </div>

                    {quartelyConsolidated && (
                      <>
                        <QuartelyConsolidatedTable
                          label={t("Recipes - Origin")}
                          beginOfPeriod={DateStringToISO.toISO(
                            quartelyConsolidated.beginOfPeriod
                          )}
                          endOfPeriod={DateStringToISO.toISO(
                            quartelyConsolidated.endOfPeriod
                          )}
                          subgroups={creditSubgroups}
                          transactions={quartelyConsolidated.transactions.filter(
                            ({ tipos_transacoes_id }) =>
                              tipos_transacoes_id == 1
                          )}
                          predictions={quartelyConsolidated.predictions.filter(
                            ({ tipos_transacoes_id }) =>
                              tipos_transacoes_id == 1
                          )}
                        />

                        <QuartelyConsolidatedTable
                          label={t("Expenses - Origin")}
                          beginOfPeriod={DateStringToISO.toISO(
                            quartelyConsolidated.beginOfPeriod
                          )}
                          endOfPeriod={DateStringToISO.toISO(
                            quartelyConsolidated.endOfPeriod
                          )}
                          subgroups={debitSubgroups}
                          transactions={quartelyConsolidated.transactions.filter(
                            ({ tipos_transacoes_id }) =>
                              tipos_transacoes_id != 1
                          )}
                          predictions={quartelyConsolidated.predictions.filter(
                            ({ tipos_transacoes_id }) =>
                              tipos_transacoes_id != 1
                          )}
                        />
                      </>
                    )}
                  </div>
                </ComponentToPrint>
              </Fragment>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default withTranslation()(QuartelyConsolidatedView);
