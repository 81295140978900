import { MoneyFormat } from "@ui/interfaces";

export class MoneyFormatBr implements MoneyFormat {
   handle(value: number): string {
      return new Intl.NumberFormat("pt-Br", {
         currency: "BRL",
         style: "currency",
       }).format(value);
   }
}
