import { HttpRequestBuilder } from "@data/builders";
import { HttpRequestTypes } from "@data/enums";
import { HttpRepository } from "@data/repositories";
import { Similarity } from "@domain/interfaces/similarity";
import { AddSubgroupService } from "@domain/services/revenues-expenses";

interface HttpAddGroupResponse {
  message?: Similarity[];
}

interface HttpAddGroupRequest {
  titulo: string;
  mensagem: string;
}
export class HttpAddSubgroupService implements AddSubgroupService {
  constructor(private readonly httpRepository: HttpRepository) { }

  async handle(payload: AddSubgroupService.Payload): Promise<AddSubgroupService.Response> {
    const httpRequest = HttpRequestBuilder.requestToUrl("/subgroups")
      .withMethod(HttpRequestTypes.POST)
      .withData(payload)
      .build();

    const { data, status } =
      await this.httpRepository.makeRequest<HttpAddGroupRequest, HttpAddGroupResponse>(httpRequest);

    return {
      ok: status === 201,
      errors: Array.isArray(data.message) ? data.message : []
    };
  }
}
