import { eachMonthOfInterval, format, lastDayOfMonth, subMonths } from "date-fns";

export const getLastThreeMonths = (date?: Date) => {
  const lastDayOfPeriod = lastDayOfMonth(date ?? new Date());
  const firstDayOfPeriod = subMonths(lastDayOfPeriod, 2);

  const lastDayOfPeriodFormatted = format(lastDayOfPeriod, "dd/MM/yyyy");
  const firstDayOfPeriodFormatted = "01/" + format(firstDayOfPeriod, "MM/yyyy");

  const months = eachMonthOfInterval({
    start: firstDayOfPeriod,
    end: lastDayOfPeriod,
  }).map((date: number | Date) => ({
    label: format(date, "MMMM"),
    value: format(date, "yyyy-MM"),
    month: format(date, "MM"),
    year: format(date, "yyyy"),
  }));

  return {
    firstDayOfPeriod: format(firstDayOfPeriod, "yyyy-MM") + "-01",
    lastDayOfPeriod: format(lastDayOfPeriod, "yyyy-MM-dd"),
    lastDayOfPeriodFormatted,
    firstDayOfPeriodFormatted,
    months,
  };
};

export const getPeriodFromMonthAndYear = (month: string, year: string) => {
    const monthChecked = month ? month : format(new Date(), 'MM');
    const yearChecked = year ? year : format(new Date(), 'yyyy');
  
    const lastDayOfPeriod = lastDayOfMonth(new Date(parseInt(yearChecked), parseInt(monthChecked) - 1));
    const firstDayOfPeriod = subMonths(lastDayOfPeriod, 2);
  
    return {
      firstDayOfPeriod: format(firstDayOfPeriod, 'yyyy-MM') + '-01',
      lastDayOfPeriod: format(lastDayOfPeriod, 'yyyy-MM-dd')
    };
  };
  
  export const getMonthsFromPeriod = ( start: Date, end: Date ) => {
    const months = eachMonthOfInterval({ start, end })
      .map((date) => ({
        id: 0,
        label: format(date, 'MMMM/yyyy'),
        value: format(date, 'yyyy-MM'),
        month: format(date, 'MM'),
        year: format(date, 'yyyy'),
      }));
    
    return { months };
  };
