import React, { useEffect, useState } from "react";

import { withTranslation } from "react-i18next";
import { Modal, Row, Col, Label, FormGroup } from "reactstrap";

import { CopyPlanningPresenterContract } from "@ui/presenters/planning";
import { useValidation } from "@ui/hooks";
import { Prefecture } from "@domain/entities";

interface CopyPlanningViewProps {
    t?: any;
    isOpen: boolean;
    onDismiss(): void;
    copyPlanningPresenter: CopyPlanningPresenterContract;
}

interface FormPayload {
    prefectureToBeCopied: number;
    prefectureToInsert: number;
}

const INITIAL_FORM_STATE: FormPayload = {
    prefectureToBeCopied: 0,
    prefectureToInsert: 0
};

const CopyPlanningView: React.FC<CopyPlanningViewProps> = ({ t, isOpen, onDismiss, copyPlanningPresenter }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [prefecture, setPrefectureList] = useState<Prefecture[]>([]);
    const [payload, setPayload] = useState<FormPayload>(INITIAL_FORM_STATE);

    const { ValidationFeedback, updateValidationState, validationState, ValidationSelect } = useValidation();

    useEffect(() => {
        copyPlanningPresenter.setView({ setLoadingState, updateValidationState, setPrefectures });
    }, []);

    useEffect(() => {
        if (!isOpen) {
            updateValidationState([]);
            setPayload(INITIAL_FORM_STATE);
        } else{
            copyPlanningPresenter.getPrefecture();
        }
    }, [isOpen]);

    const setLoadingState = (isLoading: boolean) => setIsLoading(isLoading);
    const setPrefectures = (prefecture: Prefecture[]) => setPrefectureList(prefecture);

    const updateField = (fieldName: string, value: string) => {
        setPayload((oldState) => ({
            ...oldState,
            [fieldName]: value
        }));
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        copyPlanningPresenter.copy({
            prefectureToBeCopiedId: Number(payload.prefectureToBeCopied),
            prefectureToInsert: Number(payload.prefectureToInsert)
        });
    };

    const optionsPrefecture = prefecture.map(({ id, cityName }) => ({ label: cityName, value: id }));

    return (
        <Modal
            isOpen={isOpen}
            toggle={isLoading ? undefined : onDismiss}
            scrollable={true}
            id="staticBackdrop"
            size="lg"
        >
            <div className="modal-header">
                <h5 className="modal-title">{t("Copy chart of accounts")}</h5>
                <button
                    type="button"
                    className="btn-close"
                    onClick={isLoading ? undefined : onDismiss}
                    aria-label="Close"
                />
            </div>

            <form onSubmit={handleSubmit}>
                <div className="modal-body">
                    <Row>
                        <Col md={12} className="mb-2">
                            <FormGroup>
                                <Label>{t("Prefecture to be copied")}</Label>
                                <ValidationSelect
                                    field="prefectureToBeCopied"
                                    options={optionsPrefecture}
                                    validationState={validationState}
                                    name="prefectureToBeCopied"
                                    className="form-control"
                                    placeholder={`${t("Select")} ${t("Prefecture")}`}
                                    value={payload.prefectureToBeCopied}
                                    onChange={({ target: { value, name } }) => {
                                        updateField(name, value);
                                    }}
                                />

                                <ValidationFeedback field="prefectureToBeCopied" />
                            </FormGroup>
                        </Col>

                        <Col md={12} className="mb-2">
                            <FormGroup>
                                <Label>{t("Prefecture to insert")}</Label>
                                <ValidationSelect
                                    field="prefectureToInsert"
                                    options={optionsPrefecture}
                                    validationState={validationState}
                                    name="prefectureToInsert"
                                    className="form-control"
                                    placeholder={`${t("Select")} ${t("Prefecture")}`}
                                    value={payload.prefectureToInsert}
                                    onChange={({ target: { value, name } }) => {
                                        updateField(name, value);
                                    }}
                                />

                                <ValidationFeedback field="prefectureToInsert" />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>

                <div className="modal-footer">
                    <button
                        type="button"
                        className="btn btn-danger me-2"
                        onClick={isLoading ? undefined : onDismiss}
                        disabled={isLoading}
                    >
                        {t("Close")}
                    </button>

                    <button className="btn btn-primary" disabled={isLoading}>
                        {t(isLoading ? "loading" : "Save")}
                    </button>
                </div>
            </form>
        </Modal>
    );
};

export default withTranslation()(CopyPlanningView);
