import React, { useMemo } from "react";

import AddEffectiveBillsView from "@ui/views/bills/AddEffectiveBillsView";

import { makeAddEffectiveBillsPresenter } from "@main/factories/presenters/bills";

interface AddEffectiveBillsProps {
   isOpen: boolean;
   type: string;
   onDismiss(): void;
}

const AddEffectiveBills: React.FC<AddEffectiveBillsProps> = (props) => {
   const addEffectiveBillsPresenter = useMemo(() => makeAddEffectiveBillsPresenter(), []);

   return <AddEffectiveBillsView { ...props } addEffectiveBillsPresenter={addEffectiveBillsPresenter} />;
};

export default AddEffectiveBills;
