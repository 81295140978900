import { ListAccount, ListGroup } from "@domain/entities";

export class BalanceBankHelper {
  static sumListAccounts(balance: ListAccount[]) {
    return balance.reduce((previous, current) => previous + current.total, 0);
  }

  static sumListGroups(groups: ListGroup[]): number {
    let totalFromFlow = 0;

    groups.forEach(({ accounts }) => {
      accounts.forEach(({ total }) => {
        totalFromFlow += total;
      });
    });

    return totalFromFlow;
  }
}
