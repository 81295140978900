import axios from 'axios';

import { HttpRepository } from '@data/repositories';

export class AxiosHttpRepository implements HttpRepository {
  constructor(
    private readonly baseUrl: string
  ) {
    this.startRequestInterceptors();
  }

  async makeRequest<Data = any>(request: HttpRepository.Request<Data>): Promise<HttpRepository.Response> {
    try {
      const { data, status } = await axios.request({
        data: request.data,
        params: request.params,
        url: request.url,
        method: request.type,
        baseURL: this.baseUrl,
        responseType: request.responseType,
      });

      return { data, status };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return {
          status: Number(error.response?.status),
          data: error.response?.data
        };
      }

      console.log(error);

      return { status: 500, data: {} };
    }
  }

  private startRequestInterceptors() {
    axios.interceptors.request.use((config) => {
      const token = localStorage.getItem('authUser');

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    });
  }
}
