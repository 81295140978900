import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Modal, Row, Col, Label, FormGroup } from "reactstrap";
import { NumericFormat } from "react-number-format";

import { useValidation } from "@ui/hooks";
import { AddAccountPresenterContract } from "@ui/presenters/account";

import { AccountGroup, Bank, CashFlow, Source } from "@domain/entities";
import { SelectItem } from "@ui/interfaces";

interface AddAccountViewProps {
  t?: any;
  isOpen: boolean;
  onDismiss(): void;
  addAccountPresenter: AddAccountPresenterContract;
}

const INITIAL_FORM_STATE: AddAccountPresenterContract.Payload = {
  name: "",
  group: "",
  cashflow: "",
  bankId: 0,
  balance: 0,
  sourceId: 0,
  initialDate: "",
};

const AddAccountView: React.FC<AddAccountViewProps> = ({
  addAccountPresenter,
  isOpen,
  onDismiss,
  t,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [payload, setPayload] =
    useState<AddAccountPresenterContract.Payload>(INITIAL_FORM_STATE);

  const [accountGroups, setAccountGroupsList] = useState<AccountGroup[]>([]);
  const [banks, setBanksList] = useState<Bank[]>([]);
  const [cashflow, setCashflowList] = useState<CashFlow[]>([]);
  const [sources, setSourcesList] = useState<Source[]>([]);

  const {
    ValidationFeedback,
    ValidationInput,
    updateValidationState,
    validationState,
    ValidationSelect,
  } = useValidation();

  useEffect(() => {
    addAccountPresenter.setView({
      setLoading,
      updateValidationState,
      setAccountGroups,
      setBank,
      setCashflows,
      setSources,
    });
  }, []);

  useEffect(() => {
    if (!isOpen) {
      updateValidationState([]);
      setPayload(INITIAL_FORM_STATE);
    } else {
      addAccountPresenter.getAccountGroups();
      addAccountPresenter.getBanks();
      addAccountPresenter.getCashflows();
      addAccountPresenter.getSources();
    }
  }, [isOpen]);

  const setLoading = (isLoading: boolean): void => setIsLoading(isLoading);

  const setAccountGroups = (accountGroups: AccountGroup[]): void =>
    setAccountGroupsList(accountGroups);

  const setBank = (banks: Bank[]): void => setBanksList(banks);

  const setCashflows = (cashflows: CashFlow[]): void =>
    setCashflowList(cashflows);

  const setSources = (sources: Source[]): void => setSourcesList(sources);

  const updateField = (fieldName: string, value: string) => {
    setPayload((oldState) => ({
      ...oldState,
      [fieldName]: value,
    }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    addAccountPresenter.add({
      balance: payload.balance,
      bankId: Number(payload.bankId),
      group: payload.group,
      name: payload.name,
      cashflow: payload.cashflow,
      sourceId: Number(payload.sourceId),
      initialDate: payload.initialDate,
    });
  };

  const optionsCashflows: SelectItem[] = cashflow.map(({ id, name }) => ({
    label: name,
    value: id,
  }));

  const optionsBanks: SelectItem[] = banks.map(({ name, id }) => ({
    label: name,
    value: id,
  }));

  const optionsAccountGroups: SelectItem[] = accountGroups.map(
    ({ id, name }) => ({ label: name, value: id })
  );

  const optionsSources: SelectItem[] = sources.map(({ id, name, code }) => ({
    label: code + " - " + name,
    value: id,
  }));

  return (
    <Modal
      isOpen={isOpen}
      toggle={isLoading ? undefined : onDismiss}
      scrollable={true}
      id="staticBackdrop"
      size="lg"
    >
      <div className="modal-header">
        <h5 className="modal-title">{t("Add Account")}</h5>
        <button
          type="button"
          className="btn-close"
          onClick={isLoading ? undefined : onDismiss}
          aria-label="Close"
        />
      </div>

      <form onSubmit={handleSubmit}>
        <div className="modal-body">
          <Row>
            <Col md={12} className="mb-2">
              <FormGroup>
                <Label>{t("Account name")}</Label>
                <ValidationInput
                  field="name"
                  validationState={validationState}
                  name="name"
                  className="form-control"
                  placeholder={t("Account name")}
                  value={payload.name}
                  onChange={({ target: { value, name } }) =>
                    updateField(name, value)
                  }
                />

                <ValidationFeedback field="name" />
              </FormGroup>
            </Col>

            <Col md={12} className="mb-2">
              <FormGroup>
                <Label>{t("Bank Name")}</Label>
                <ValidationSelect
                  field="code"
                  name="bankId"
                  className="form-control"
                  placeholder={t("Bank Name")}
                  options={optionsBanks}
                  validationState={validationState}
                  value={payload.bankId}
                  onChange={({ target: { value, name } }) =>
                    updateField(name, value)
                  }
                />

                <ValidationFeedback field="bankId" />
              </FormGroup>
            </Col>

            <Col md={6} className="mb-2">
              <FormGroup>
                <Label>{t("Flow")}</Label>
                <ValidationSelect
                  field="cashflow"
                  options={optionsCashflows}
                  validationState={validationState}
                  name="cashflow"
                  className="form-control"
                  placeholder={t("Select")}
                  onChange={({ target: { value, name } }) =>
                    updateField(name, value)
                  }
                  value={payload.cashflow}
                />

                <ValidationFeedback field="cashflow" />
              </FormGroup>
            </Col>

            <Col md={6} className="mb-2">
              <FormGroup>
                <Label>{t("Source")}</Label>
                <ValidationSelect
                  field="sourceId"
                  options={optionsSources}
                  validationState={validationState}
                  name="sourceId"
                  className="form-control"
                  placeholder={t("Select")}
                  onChange={({ target: { value, name } }) =>
                    updateField(name, value)
                  }
                  value={payload.sourceId}
                />

                <ValidationFeedback field="sourceId" />
              </FormGroup>
            </Col>

            <Col md={6} className="mb-2">
              <FormGroup>
                <Label>{t("Group")}</Label>
                <ValidationSelect
                  field="group"
                  options={optionsAccountGroups}
                  validationState={validationState}
                  name="group"
                  className="form-control"
                  placeholder={t("Select")}
                  onChange={({ target: { value, name } }) =>
                    updateField(name, value)
                  }
                  value={payload.group}
                />

                <ValidationFeedback field="group" />
              </FormGroup>
            </Col>

            <Col md={6} className="mb-3">
              <FormGroup>
                <Label>{t("Opening date")}</Label>

                <ValidationInput
                  field="initialDate"
                  type="date"
                  name="initialDate"
                  className="form-control"
                  placeholder={`${t("Date placeholder")}`}
                  validationState={validationState}
                  onChange={({ target: { name, value } }) => {
                    updateField(name, value);
                  }}
                  value={payload.initialDate}
                />

                <ValidationFeedback field="initialDate" />
              </FormGroup>
            </Col>

            <Col md={6} className="mb-3">
              <FormGroup>
                <Label>{t("Opening balance")}</Label>

                <NumericFormat
                  field="balance"
                  validationState={validationState}
                  name="balance"
                  className="form-control"
                  placeholder={t("Balance")}
                  thousandSeparator="."
                  customInput={ValidationInput}
                  decimalSeparator=","
                  decimalScale={2}
                  fixedDecimalScale
                  onValueChange={({ floatValue }) => {
                    setPayload({
                      ...payload,
                      balance: floatValue ?? 0,
                    });
                  }}
                />

                <ValidationFeedback field="balance" />
              </FormGroup>
            </Col>
          </Row>
        </div>

        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-danger me-2"
            onClick={isLoading ? undefined : onDismiss}
            disabled={isLoading}
          >
            {t("Close")}
          </button>

          <button className="btn btn-primary" disabled={isLoading}>
            {t(isLoading ? "loading" : "Save")}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default withTranslation()(AddAccountView);
