import React, { useMemo } from "react";

import { Container, Row } from "reactstrap";

import { Breadcrumbs } from "@ui/components/Common";
import { withTranslation } from "react-i18next";

import {
  makeAccountsBalancePresenter,
  makeLastThreeMonthsPresenter,
  makeRevenuesFromGroupsPresenter,
  makeExpensesFromGroupsPresenter,
  makeCreditRadarByFlowPresenter,
  makeDebitRadarByFlowPresenter,
} from "@main/factories/presenters/dashboard";

import {
  AccountsBalanceView,
  LastThreeMonthView,
  RevenuesFromGroupsView,
  ExpensesFromGroupsView,
  CreditRadarByFlowView,
  DebitRadarByFlowView,
} from "@ui/views/dashboard";

interface DashboardProps {
  t: any;
}

const Dashboard: React.FC<DashboardProps> = ({ t }) => {
  const accountsBalancePresenter = useMemo(() => makeAccountsBalancePresenter(), []);
  const lastThreeMonthPresenter = useMemo(() => makeLastThreeMonthsPresenter(), []);
  const revenuesFromGroupsPresenter = useMemo(() => makeRevenuesFromGroupsPresenter(), []);
  const expensesFromGroupsPresenter = useMemo(() => makeExpensesFromGroupsPresenter(), []);
  const creditRadarByFlowPresenter = useMemo(() => makeCreditRadarByFlowPresenter(), [ ]);
  const debitRadarByFlowPresenter = useMemo(() => makeDebitRadarByFlowPresenter(), []);

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title={t("Dashboard")} breadcrumbItem={t("Dashboard subtitle")} />

        <Row className="mt-4">
          <LastThreeMonthView lastThreeMonthPresenter={lastThreeMonthPresenter} />
          <CreditRadarByFlowView creditRadarByFlowPresenter={creditRadarByFlowPresenter} />
          <DebitRadarByFlowView debitRadarByFlowPresenter={debitRadarByFlowPresenter} />
          <RevenuesFromGroupsView revenuesFromGroupsPresenter={revenuesFromGroupsPresenter} />
          <ExpensesFromGroupsView expensesFromGroupsPresenter={expensesFromGroupsPresenter} />
          <AccountsBalanceView accountsBalacePresenter={accountsBalancePresenter} />
        </Row>
      </Container>
    </div>
  );
};

export default withTranslation()(Dashboard);
