import { HttpRequestBuilder } from "@data/builders";
import { HttpRequestTypes } from "@data/enums";
import { HttpRepository } from "@data/repositories";
import { User } from "@domain/entities";
import { GetUsersService } from "@domain/services/users";

export class HttpGetUsersService implements GetUsersService {
  constructor(
    private readonly httpRepository: HttpRepository
  ) { }

  async handle(): Promise<User[]> {
    const request = HttpRequestBuilder
      .requestToUrl('/users')
      .withMethod(HttpRequestTypes.GET)
      .build();

    const { data } = await this.httpRepository.makeRequest<any, User[]>(request);

    return data;
  }
}
