import { HttpRequestBuilder } from "@data/builders";
import { HttpRequestTypes } from "@data/enums";
import { HttpRepository } from "@data/repositories";
import { Provider } from "@domain/entities/Provider";
import { GetProviderService } from "@domain/services/provider";

export class HttpGetProviderService implements GetProviderService {
   constructor(
      private readonly httpRepository: HttpRepository
   ) { }

   async handle(): Promise<Provider[]> {
      const request = HttpRequestBuilder
         .requestToUrl('/providers')
         .withMethod(HttpRequestTypes.GET)
         .build();

      const { data } = await this.httpRepository.makeRequest<any, Provider[]>(request);

      return data;
   }
}
