import { HttpGetCashflowsService } from "@data/services/cashflows";
import { HttpGetGroupsService } from "@data/services/groups";
import {
  HttpGetPlanningService,
  HttpMountPlanningService,
} from "@data/services/planning";
import { HttpGetPrefectureService } from "@data/services/prefecture";
import { HttpGetSubgroupsService } from "@data/services/revenues-expenses";
import { HttpGetTransactionsTypesService } from "@data/services/transactionsTypes";
import { AxiosHttpRepository } from "@infra/http";
import { API_BASE_URL } from "@main/constants/config";
import { PlanningPresenter } from "@presentation/presenters/planning";
import { PlanningPresenterContract } from "@ui/presenters/planning";

export const makePlanningPresenter = (): PlanningPresenterContract => {
  const httpRepository = new AxiosHttpRepository(API_BASE_URL);
  const getPrefecturesService = new HttpGetPrefectureService(httpRepository);
  const getPlanningService = new HttpGetPlanningService(httpRepository);
  const getGroupsService = new HttpGetGroupsService(httpRepository);
  const getSubgroupsService = new HttpGetSubgroupsService(httpRepository);
  const getCashflowsService = new HttpGetCashflowsService(httpRepository);

  const getTransactionsTypesService = new HttpGetTransactionsTypesService(
    httpRepository
  );

  const mountPlanningService = new HttpMountPlanningService(httpRepository);

  return new PlanningPresenter(
    getPrefecturesService,
    getPlanningService,
    getGroupsService,
    getSubgroupsService,
    getTransactionsTypesService,
    getCashflowsService,
    mountPlanningService
  );
};
