import { EditSourcesService } from "@domain/services/sources";
import { FormValidation } from "@presentation/validation/interfaces";
import { EditSourceViewContract } from "@presentation/views/sources";
import { EditSourcePresenterContract } from "@ui/presenters/sources";
import { EventEmitter } from "@utils/events";
import { SourceEvents } from "@domain/events/SourcesEvents";
import { CashFlow, AccountGroup } from "@domain/entities";
import { GetCashflowsService } from "@domain/services/cashflows";
import { GetAccountGroupsService } from "@domain/services/account";

export class EditSourcePresenter implements EditSourcePresenterContract {
  private view!: EditSourceViewContract;

  constructor(
    private readonly validation: FormValidation,
    private readonly editSourceService: EditSourcesService,
    private readonly getCashflowsService: GetCashflowsService,
    private readonly getGroupsService: GetAccountGroupsService
  ) {}

  async getCashflows(): Promise<CashFlow[]> {
    const cashflows = await this.getCashflowsService.handle();
    this.view.setCashflows(cashflows);
    return cashflows;
  }

  async getGroups(): Promise<AccountGroup[]> {
    const groups = await this.getGroupsService.handle();
    this.view.setGroups(groups);
    return groups;
  }

  setView(view: EditSourceViewContract): void {
    this.view = view;
  }

  async edit(payload: EditSourcePresenterContract.Payload): Promise<boolean> {
    this.view.setLoading(true);

    const validationSuccessfull = this.validation.allValid(payload);

    if (!validationSuccessfull) {
      this.view.updateValidationState(this.validation.errors(payload));
      this.view.setLoading(false);
      return false;
    }

    const isSourceUpdated = await this.editSourceService.handle(payload);

    if (isSourceUpdated) {
      EventEmitter.emit(SourceEvents.SOURCE_EDITED);
    }

    this.view.setLoading(false);
    this.view.updateValidationState([]);
    return true;
  }
}
