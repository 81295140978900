import { HttpGetEffectiveBillsService, HttpRemoveEffectiveBillsService } from "@data/services/bills";
import { AxiosHttpRepository } from "@infra/http";
import { API_BASE_URL } from "@main/constants/config";
import { EffectiveBillsPresenter } from "@presentation/presenters/bills";
import { EffectiveBillsPresenterContract } from "@ui/presenters/bills";

export const makeEffectiveBillsPresenter = (): EffectiveBillsPresenterContract => {
    const httpRepository = new AxiosHttpRepository(API_BASE_URL);
    const getEffectiveBillsService = new HttpGetEffectiveBillsService(httpRepository);
    const removeEffectiveBillsService = new HttpRemoveEffectiveBillsService(httpRepository);
    return new EffectiveBillsPresenter(
        getEffectiveBillsService,
        removeEffectiveBillsService
    );
};
