import { Validation } from "@presentation/validation/interfaces";

import {
  EmailValidation,
  EqualsToValidation,
  PasswordValidation,
  RequiredArrayValidation,
  RequiredValidation,
  RequiredWhenOtherFieldHasSomeValue
} from "@presentation/validation/validators";

export class FieldValidationBuilder {
  private readonly validations: Validation[] = [];

  constructor(
    private readonly fieldName: string,
  ) { }

  public static field(fieldName: string): FieldValidationBuilder {
    return new FieldValidationBuilder(fieldName);
  }

  public required(): FieldValidationBuilder {
    this.validations.push(new RequiredValidation(
      this.fieldName,
      'O campo é obrigatório'
    ));

    return this;
  }

  public requiredWhenOtherFieldHasSomeValue(
    fieldToCheck: string,
    values: string[] | number[]
  ): FieldValidationBuilder {
    this.validations.push(new RequiredWhenOtherFieldHasSomeValue(
      this.fieldName,
      'O campo é obrigatório',
      fieldToCheck,
      values
    ));

    return this;
  }

  public password(): FieldValidationBuilder {
    this.validations.push(new PasswordValidation(
      this.fieldName,
      'A senha precisa conter ao menos :min caracteres',
      'A senha precisa conter letras e números.'
    ));

    return this;
  }

  public email(): FieldValidationBuilder {
    this.validations.push(new EmailValidation(
      this.fieldName,
      'O e-mail é inválido'
    ));

    return this;
  }

  public requiredArray(): FieldValidationBuilder {
    this.validations.push(new RequiredArrayValidation(
      this.fieldName,
      'O campo é obrigatório'
    ));

    return this;
  }

  public equalsTo(fieldToCompare: string, fieldName: string): FieldValidationBuilder {
    this.validations.push(new EqualsToValidation(
      this.fieldName,
      fieldToCompare,
      `Precisa ser igual ao campo ${fieldName}`
    ));

    return this;
  }

  public build(): Validation[] {
    return this.validations;
  }

  public document(): FieldValidationBuilder {
    this.validations.push(new RequiredValidation(
      this.fieldName,
      'Documento inválido'
    ));

    return this;
  }
}
