import { CashFlow, ConsolidatedReportTransaction } from "@domain/entities";
import { GetCashflowsService } from "@domain/services/cashflows";
import { GetConsolidatedReportService } from "@domain/services/consolidated-report";
import { GetLastThreeMonthsPeriod } from "@presentation/utils";
import { DebitRadarByFlowViewContract } from "@presentation/views/dashboard";
import { DebitRadarByFlowPresenterContract } from "@ui/presenters/dashboard";

export class DebitRadarByFlowPresenter implements DebitRadarByFlowPresenterContract {
    private view!: DebitRadarByFlowViewContract;

    constructor(
        private readonly getConsolidatedReportService: GetConsolidatedReportService,
        private readonly getCashflowsService: GetCashflowsService,
    ) { }

    setView(view: DebitRadarByFlowViewContract): void {
        this.view = view;
    }

    async getChartData(): Promise<DebitRadarByFlowPresenterContract.Debit[]> {
        const cashflows = await this.getCashflowsService.handle();

        const { begin, end } = GetLastThreeMonthsPeriod.getPeriod();

        const { transactionsCarriedOut, forecastTransactions } = await this.getConsolidatedReportService.handle({
            beginOfPeriod: begin,
            endOfPeriod: end,
        });

        const credits = this.getDebits(cashflows, transactionsCarriedOut, forecastTransactions);

        this.view.setChartData(credits);

        return credits;
    }

    private getDebits(
        cashflows: any[],
        transactions: ConsolidatedReportTransaction[],
        transactionsPrev: ConsolidatedReportTransaction[],
    ): DebitRadarByFlowPresenterContract.Debit[] {
        return cashflows.map(({ id, name }) => {
            const debits = transactions.filter(({ tipos_transacoes_id, tipos_fluxos_id }) =>
                tipos_transacoes_id != 1 &&
                tipos_fluxos_id == id
            );

            const foreseenDebits = transactionsPrev.filter(({ tipos_transacoes_id, tipos_fluxos_id }) =>
                tipos_transacoes_id != 1 &&
                tipos_fluxos_id == id
            );

            return {
                flow: id,
                flowName: name,
                total: debits.reduce((prev, current) => prev + parseFloat(current.total), 0),
                totalForeseen: foreseenDebits.reduce((prev, current) => prev + parseFloat(current.total), 0)
            };
        });
    }
}
