import React, { useMemo } from "react";

import GroupsView from "@ui/views/groups/GroupsView";

import { makeGroupsPresenter } from "@main/factories/presenters/groups";

const Groups: React.FC = () => {
   const groupsPresenter = useMemo(() => makeGroupsPresenter(), []);

   return <GroupsView groupsPresenter={groupsPresenter} />;
};

export default Groups;
