import { Alert } from "reactstrap";
import { Similarity } from "@domain/interfaces/similarity";

import { withTranslation } from "react-i18next";
import { Fragment } from "react";

interface SimilarityAlertProps {
    t: any;
    similarities: Similarity[];
    isOpen: boolean;
}

const SimilarityAlert: React.FC<SimilarityAlertProps> = ({ t, similarities, isOpen }) => {
    return !isOpen ? null : (
        <Fragment>
            {similarities.length === 0 ? (
                <Alert color="danger">
                    <h6>{t("alert.message.error")}</h6>
                </Alert>
            ) : (
                <Alert color="danger">
                    {
                        <div>
                            <p>{t("alert.message")}</p>
                            <p>{similarities.map(({ Input, Similarity }, key) =>
                                <h6 key={key}>
                                    {Input} - {t("alert.component.description")} {Similarity.toFixed(2)}% {t("alert.component.similarity")}
                                </h6>)}
                            </p>
                            <p>{t("alert.message.continuity")}</p>
                        </div>
                    }
                </Alert>
            )

            }
        </Fragment>
    );
};

export default withTranslation()(SimilarityAlert);
