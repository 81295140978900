import { GroupsEvents } from "@domain/events";
import { EditGroupService } from "@domain/services/groups";
import { FormValidation } from "@presentation/validation/interfaces";
import { EditGroupViewContract } from "@presentation/views/groups";
import { EditGroupPresenterContract } from "@ui/presenters/groups";
import { EventEmitter } from "@utils/events";

export class EditGroupPresenter implements EditGroupPresenterContract {
  private view!: EditGroupViewContract;

  constructor(
    private readonly validation: FormValidation,
    private readonly editGroupService: EditGroupService
  ) { }

  async edit(payload: EditGroupPresenterContract.Payload): Promise<boolean> {
    this.view.setLoading(true);
    this.view.openSimilarityFeedback(false);

    const validationSuccessfull = this.validation.allValid(payload);

    if (!validationSuccessfull) {
      this.view.updateValidationState(this.validation.errors(payload));
      this.view.setLoading(false);
      return false;
    }

    const { ok: isGroupEdited, errors } = await this.editGroupService.handle(payload);

    if (isGroupEdited) {
      EventEmitter.emit(GroupsEvents.GROUP_EDITED);
    } else {
      this.view.setSimilarity(errors);
      this.view.openSimilarityFeedback(true);
    }

    this.view.updateValidationState([]);
    this.view.setLoading(false);

    return isGroupEdited;
  }

  setView(view: EditGroupViewContract): void {
    this.view = view;
  }
}
