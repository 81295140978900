import { HttpRequestBuilder } from "@data/builders";
import { HttpRequestTypes } from "@data/enums";
import { HttpRepository } from "@data/repositories";
import { State } from "@domain/entities";
import { GetStatesService } from "@domain/services/states";

interface IBGEState {
  id: number;
  nome: string;
  sigla: string;
}
export class HttpGetStatesService implements GetStatesService {
  constructor(
    private readonly httpRepository: HttpRepository
  ) { }

  async handle(): Promise<State[]> {
    const request = HttpRequestBuilder
      .requestToUrl('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
      .withMethod(HttpRequestTypes.GET)
      .build();

    const { data } = await this.httpRepository.makeRequest<any, IBGEState[]>(request);

    return data.map(({ id, nome, sigla }) => ({
      id: id,
      name: nome,
      acronym: sigla
    }));
  }
}

