import { HttpRequestBuilder } from "@data/builders";
import { HttpRequestTypes } from "@data/enums";
import { HttpRepository } from "@data/repositories";
import { EditBankAccountService } from "@domain/services/account/EditBankAccountService";

export class HttpEditBankAccountService implements EditBankAccountService {
  constructor(private readonly httpRepository: HttpRepository) { }

  async handle(payload: EditBankAccountService.Payload): Promise<boolean> {
    const httpRequest = HttpRequestBuilder.requestToUrl("/bank/account")
      .withMethod(HttpRequestTypes.PUT)
      .withData(payload)
      .build();

    const { status } = await this.httpRepository.makeRequest<EditBankAccountService.Payload>(httpRequest);

    return status === 200;
  }
}
