import { HttpRequestBuilder } from "@data/builders";
import { HttpRequestTypes } from "@data/enums";
import { HttpRepository } from "@data/repositories";
import { Group } from "@domain/entities";
import { GetGroupsService } from "@domain/services/groups/GetGroupsService";


export class HttpGetGroupsService implements GetGroupsService {
  constructor(
    private readonly httpRepository: HttpRepository
  ) { }

  async handle(): Promise<Group[]> {
    const request = HttpRequestBuilder
      .requestToUrl('/groups')
      .withMethod(HttpRequestTypes.GET)
      .build();

    const { data } = await this.httpRepository.makeRequest<any, Group[]>(request);

    return data;
  }
}
