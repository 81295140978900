import { HttpRequestBuilder } from "@data/builders";
import { HttpRequestTypes } from "@data/enums";
import { HttpRepository } from "@data/repositories";
import { CashFlow } from "@domain/entities";
import { GetCashflowsService } from "@domain/services/cashflows";

export class HttpGetCashflowsService implements GetCashflowsService {
  constructor(
    private readonly httpRepository: HttpRepository
  ) { }

  async handle(): Promise<CashFlow[]> {
    const request = HttpRequestBuilder
      .requestToUrl('/transactions/details/cashflows')
      .withMethod(HttpRequestTypes.GET)
      .build();

    const { data } = await this.httpRepository.makeRequest<any, CashFlow[]>(request);

    return data;
  }
}
