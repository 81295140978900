import React, { useMemo } from "react";

import PrefectureView from "@ui/views/prefecture/PrefectureView";

import { makePrefecturePresenter } from "@main/factories/presenters/prefecture";
const Prefecture: React.FC = () => {
   const prefecturePresenter = useMemo(() => makePrefecturePresenter(), []);

   return <PrefectureView prefecturePresenter={prefecturePresenter} />;
};

export default Prefecture;
