import React, { useMemo } from "react";

import { AddSubgroupView } from "@ui/views/revenues-expenses";
import { makeAddSubgroupPresenter } from "@main/factories/presenters/revenues-expenses";

interface AddSubgroupProps {
  isOpen: boolean;
  onDismiss(): void;
}

export const AddSubgroup: React.FC<AddSubgroupProps> = (props) => {
  const addSubgroupPresenter = useMemo(() => makeAddSubgroupPresenter(), []);

  return <AddSubgroupView {...props} addSubgroupPresenter={addSubgroupPresenter} />;
};
