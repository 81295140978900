import { useMemo } from "react";

import { makeCopyPlanningPresenter } from "@main/factories/presenters/planning";
import CopyPlanningView from "@ui/views/planning/CopyPlanningView";

interface CopyPlanningProps {
    isOpen: boolean;
    onDismiss(): void;
  }

const CopyPlanning: React.FC<CopyPlanningProps> = (props) => {
    const presenter = useMemo(() => makeCopyPlanningPresenter(), []);

    return <CopyPlanningView {...props} copyPlanningPresenter={presenter} />;
};

export default CopyPlanning;
