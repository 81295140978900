import React from "react";
import { FaPlus } from "react-icons/fa";

interface AddActionButtonProps {
   isLastOfList?: boolean;
}

const AddActionButton: React.FC<
   AddActionButtonProps &
   React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
> = ({ isLastOfList, ...props }) => {
   return (
      <button
         type="button"
         className={`btn btn-primary mb-3 ms-1 ${isLastOfList ? "" : "me-1"}`}
         {...props}
      >
         <FaPlus/>
      </button>
   );
};

export default AddActionButton;
