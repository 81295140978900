import { HttpGetBankAccountService } from "@data/services/account/HttpGetBankAccountService";
import { HttpGetCashflowsService } from "@data/services/cashflows";
import { AxiosHttpRepository } from "@infra/http";
import { API_BASE_URL } from "@main/constants/config";
import { AccountsBalancePresenter } from "@presentation/presenters/dashboard";
import { AccountsBalancePresenterContract } from "@ui/presenters/dashboard";

export const makeAccountsBalancePresenter = (): AccountsBalancePresenterContract => {
  const httpRepository = new AxiosHttpRepository(API_BASE_URL);
  const getCashflowsService = new HttpGetCashflowsService(httpRepository);
  const getBankAccountService = new HttpGetBankAccountService(httpRepository);

  return new AccountsBalancePresenter(getBankAccountService, getCashflowsService);
};
