import { HttpRequestBuilder } from "@data/builders";
import { HttpRequestTypes } from "@data/enums";
import { HttpRepository } from "@data/repositories";
import { Similarity } from "@domain/interfaces/similarity";
import { EditGroupService } from "@domain/services/groups";

interface HttpEditGroupResponse {
  message?: Similarity[];
}

interface HttpEditGroupRequest {
  titulo: string;
  mensagem: string;
}

export class HttpEditGroupService implements EditGroupService {
  constructor(
    private readonly httpRepository: HttpRepository
  ) { }

  async handle(payload: EditGroupService.Payload): Promise<EditGroupService.Response> {
    const httpRequest = HttpRequestBuilder.requestToUrl('/groups')
      .withMethod(HttpRequestTypes.PUT)
      .withData(payload)
      .build();

    const { data, status } =
      await this.httpRepository.makeRequest<HttpEditGroupRequest, HttpEditGroupResponse>(httpRequest);

    return {
      ok: status === 200,
      errors: Array.isArray(data.message) ? data.message : []
    };
  }
}
