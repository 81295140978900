import { HttpRequestBuilder } from "@data/builders";
import { HttpRequestTypes } from "@data/enums";
import { HttpRepository } from "@data/repositories";
import { AddSourcesService } from "@domain/services/sources";

export class HttpAddSourcesService implements AddSourcesService {
   constructor(
      private readonly httpRepository: HttpRepository
   ) { }

   async handle(payload: AddSourcesService.Payload): Promise<boolean> {
      const request = HttpRequestBuilder
         .requestToUrl('/sources')
         .withMethod(HttpRequestTypes.POST)
         .withData(payload)
         .build();

      const { status } = await this.httpRepository.makeRequest<AddSourcesService.Payload>(request);

      return status === 200;
   }
}
