import { HttpGetBankAccountService } from "@data/services/account";
import { HttpEditNonEffectiveBillsService } from "@data/services/bills";
import { HttpGetGroupedPlanningService } from "@data/services/planning";
import { HttpGetProviderService } from "@data/services/provider";
import { HttpGetSourcesService } from "@data/services/sources";
import { AxiosHttpRepository } from "@infra/http";
import { API_BASE_URL } from "@main/constants/config";
import { EditNonEffectiveBillsPresenter } from "@presentation/presenters/bills";
import { FieldValidationBuilder } from "@presentation/validation/builders";
import { ValidationComposite } from "@presentation/validation/validators";
import { EditNonEffectiveBillsPresenterContract } from "@ui/presenters/bills";

export const makeEditNonEffectiveBillsPresenter = (): EditNonEffectiveBillsPresenterContract => {
    const validation = new ValidationComposite([
        ...FieldValidationBuilder.field('date').required().build(),
        ...FieldValidationBuilder.field('bankAccountId').required().build(),
        ...FieldValidationBuilder.field('cashflow').required().build(),
        ...FieldValidationBuilder.field('providerId').required().build(),
        ...FieldValidationBuilder.field('sourceId').required().build(),
        ...FieldValidationBuilder.field('transactionGroupId').required().build(),
        ...FieldValidationBuilder.field('transactionSubgroupId').required().build(),
        ...FieldValidationBuilder.field('amount').required().build()
    ]);

    const httpRepository = new AxiosHttpRepository(API_BASE_URL);
    const editNonEffectiveBillsService = new HttpEditNonEffectiveBillsService(httpRepository);
    const getAccountService = new HttpGetBankAccountService(httpRepository);
    const getSourceService = new HttpGetSourcesService(httpRepository);
    const getProviderService = new HttpGetProviderService(httpRepository);
    const getPlanningService = new HttpGetGroupedPlanningService(httpRepository);

    return new EditNonEffectiveBillsPresenter(
        validation,
        editNonEffectiveBillsService,
        getAccountService,
        getSourceService,
        getProviderService,
        getPlanningService
    );
};
