import React from "react";

import { Switch, BrowserRouter as Router } from "react-router-dom";

import { userRoutes, authRoutes } from "./routes/allRoutes";
import Authmiddleware from "./routes/middleware/Authmiddleware";
import NonAuthLayout from "../ui/components/NonAuthLayout";
import VerticalLayout from "../ui/components/VerticalLayout";

import "@ui/assets/scss/theme.scss";
import "@ui/assets/scss/preloader.scss";

import "./i18n";

const App = () => {
  return (
    <React.Fragment>
      <Router>
        <Switch>
          {authRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              exact
            />
          ))}

          {userRoutes.map((route: any, idx: number) => (
            <Authmiddleware
              path={route.path}
              layout={VerticalLayout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
            />
          ))}
        </Switch>
      </Router>
    </React.Fragment>
  );
};

export default App;
