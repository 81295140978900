import { HttpAddGroupService } from "@data/services/groups/HttpAddGroupService";
import { AxiosHttpRepository } from "@infra/http";
import { API_BASE_URL } from "@main/constants/config";
import { AddGroupPresenter } from "@presentation/presenters/groups";
import { FieldValidationBuilder } from "@presentation/validation/builders";
import { ValidationComposite } from "@presentation/validation/validators";
import { AddGroupPresenterContract } from "@ui/presenters/groups";

export const makeAddGroupPresenter = (): AddGroupPresenterContract => {
  const validation = new ValidationComposite([
    ...FieldValidationBuilder.field("name").required().build(),
  ]);

  const httpRepository = new AxiosHttpRepository(API_BASE_URL);
  const addGroupPresenter = new HttpAddGroupService(httpRepository);

  return new AddGroupPresenter(validation, addGroupPresenter);
};
