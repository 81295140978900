import { HttpCheckForgotPasswordEmailService } from "@data/services";
import { LocalStorageCacheRepository } from "@infra/cache";
import { AxiosHttpRepository } from "@infra/http";
import { HistoryNavigateRepository } from "@infra/navigate";
import { API_BASE_URL } from "@main/constants/config";
import { ForgotPasswordRecoverPresenter } from "@presentation/presenters/auth";
import { FieldValidationBuilder } from "@presentation/validation/builders";
import { ValidationComposite } from "@presentation/validation/validators";
import { ForgotPasswordRecoverPresenterContract } from "@ui/presenters";

export const makeForgotPasswordRecoverPresenter = (): ForgotPasswordRecoverPresenterContract => {
  const validation = new ValidationComposite([
    ...FieldValidationBuilder.field('email').email().build(),
    ...FieldValidationBuilder.field('code').required().build(),
    ...FieldValidationBuilder.field('password').required().build(),
    ...FieldValidationBuilder.field('confirmPassword').required().equalsTo('password', 'senha').build(),
  ]);

  const httpRepository = new AxiosHttpRepository(API_BASE_URL);
  const navigateRepository = new HistoryNavigateRepository();
  const cacheRepository = new LocalStorageCacheRepository();

  const checkForgotPasswordEmailService = new HttpCheckForgotPasswordEmailService(
    httpRepository,
    navigateRepository,
    cacheRepository
  );

  return new ForgotPasswordRecoverPresenter(
    validation,
    checkForgotPasswordEmailService
  );
};
