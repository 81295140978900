import React, { useMemo } from "react";

import { AddAccountView } from "@ui/views/account";
import { makeAddAccountPresenter } from "@main/factories/presenters/accounts";

interface AddAccountProps {
  isOpen: boolean;
  onDismiss(): void;
}

export const AddAccount: React.FC<AddAccountProps> = (props) => {
  const addAccountPresenter = useMemo(() => makeAddAccountPresenter(), []);

  return <AddAccountView {...props} addAccountPresenter={addAccountPresenter} />;
};
