import { UserEvents } from "@domain/events";
import { UpdateUserPasswordService } from "@domain/services/users";
import { FormValidation } from "@presentation/validation/interfaces";
import { UpdateUserPasswordViewContract } from "@presentation/views/users";
import { UpdateUserPasswordPresenterContract } from "@ui/presenters/users";
import { EventEmitter } from "@utils/events";

export class UpdateUserPasswordPresenter implements UpdateUserPasswordPresenterContract {
  private view!: UpdateUserPasswordViewContract;

  constructor(
    private readonly validation: FormValidation,
    private readonly updateUserPasswordService: UpdateUserPasswordService,
  ) { }

  setView(view: UpdateUserPasswordViewContract): void {
    this.view = view;
  }

  async edit(payload: UpdateUserPasswordPresenterContract.Payload): Promise<boolean> {
    this.view.setLoading(true);
    this.view.openErrorFeedback(false);

    const validationSuccessfull = this.validation.allValid(payload);

    if (!validationSuccessfull) {
      this.view.updateValidationState(this.validation.errors(payload));
      this.view.setLoading(false);
      return false;
    }

    const { ok: isUserUpdated, errors } = await this.updateUserPasswordService.handle(payload);

    if (isUserUpdated) {
      EventEmitter.emit(UserEvents.USER_UPDATED);
      EventEmitter.emit(UserEvents.PASSWORD_UPDATED);
    } else {
      this.view.setErrors(errors);
      this.view.openErrorFeedback(true);
    }

    this.view.updateValidationState([]);
    this.view.setLoading(false);

    return isUserUpdated;
  }
}
