import { Validation } from "@presentation/validation/interfaces";

export class RequiredWhenOtherFieldHasSomeValue implements Validation {
  constructor(
    private readonly fieldName: string,
    private readonly emptyMessage: string,
    private readonly fieldToCompare: string,
    private readonly valuesToCompare: any[]
  ) { }

  private getMessage(isValid: boolean): string {
    if (isValid) return 'not_empty';

    return this.emptyMessage;
  }

  validate(input: any): Validation.ValidationResult[] {
    const fieldValue = input[this.fieldToCompare];
    const hasSomeValueEqualFieldValue = this.valuesToCompare.some((value) => value == fieldValue);

    if (!hasSomeValueEqualFieldValue) {
      return [{ isValid: true, message: this.getMessage(true), fieldName: this.fieldName }];
    }

    const isValid = !!input[this.fieldName];
    const message = this.getMessage(isValid);

    return [{ isValid, message, fieldName: this.fieldName }];
  }
}
