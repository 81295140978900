import { HttpGetCashflowsService } from "@data/services/cashflows";
import { HttpGetConsolidatedReportService } from "@data/services/consolidated-report/HttpGetConsolidatedReportService";
import { AxiosHttpRepository } from "@infra/http";
import { API_BASE_URL } from "@main/constants/config";
import { DebitRadarByFlowPresenter } from "@presentation/presenters/dashboard";
import { DebitRadarByFlowPresenterContract } from "@ui/presenters/dashboard";

export const makeDebitRadarByFlowPresenter = (): DebitRadarByFlowPresenterContract => {
    const httpRepository = new AxiosHttpRepository(API_BASE_URL);
    const getConsolidatedReportService = new HttpGetConsolidatedReportService(httpRepository);
    const getCashflowsService = new HttpGetCashflowsService(httpRepository);

    return new DebitRadarByFlowPresenter(
        getConsolidatedReportService,
        getCashflowsService
    );
};
