import { HttpGetUsersService } from "@data/services/users";
import { AxiosHttpRepository } from "@infra/http";
import { UsersListPresenter } from "@presentation/presenters/users";
import { UsersListPresenterContract } from "@ui/presenters/users";
import { API_BASE_URL } from "@main/constants/config";
import { HttpRemoveUsersService } from "@data/services/users/HttpRemoveUsersServices";

export const makeUsersListPresenter = (): UsersListPresenterContract => {
  const httpRepository = new AxiosHttpRepository(API_BASE_URL);
  const getUsersService = new HttpGetUsersService(httpRepository);
  const removeUserService = new HttpRemoveUsersService(httpRepository);
  const usersListPresenter = new UsersListPresenter(getUsersService, removeUserService);

  return usersListPresenter;
};
