import { HttpRequestBuilder } from "@data/builders";
import { HttpRequestTypes } from "@data/enums";
import { HttpRepository } from "@data/repositories";
import { GetBankAccountService } from "@domain/services/account/GetBankAccountService";
import { GroupsByFlowsList } from "@domain/interfaces/accounts/GroupsByFlowsList";

export class HttpGetBankAccountService implements GetBankAccountService {
  constructor(
    private readonly httpRepository: HttpRepository
  ) { }

  async handle(): Promise<GroupsByFlowsList[]> {
    const request = HttpRequestBuilder
      .requestToUrl('/bank/account')
      .withMethod(HttpRequestTypes.GET)
      .build();

    const { data } = await this.httpRepository.makeRequest<any, GroupsByFlowsList[]>(request);

    return data;
  }
}
