import { HttpRequestBuilder } from "@data/builders";
import { HttpRequestTypes } from "@data/enums";
import { HttpRepository } from "@data/repositories";
import { MountPlanningService } from "@domain/services/planning";

export class HttpMountPlanningService implements MountPlanningService {
  constructor(private readonly httpRepository: HttpRepository) {}

  async handle(data: MountPlanningService.MountPlanningData): Promise<void> {
    const httpRequest = HttpRequestBuilder.requestToUrl("/planning/add")
      .withMethod(HttpRequestTypes.POST)
      .withData(data)
      .build();

    await this.httpRepository.makeRequest<MountPlanningService.MountPlanningData>(
      httpRequest
    );
  }
}
