import { ProfileMenuPresenterContract } from "@ui/presenters/profiles";
import { GetUserInfoService, LogoutService } from "@domain/services/profile";
import { User } from "@domain/entities";
import { ProfileMenuViewContract } from "@presentation/views/profiles";

export class ProfileMenuPresenter implements ProfileMenuPresenterContract {
   private view!: ProfileMenuViewContract;

   constructor(
      private readonly logoutService: LogoutService,
      private readonly getUserInfoService: GetUserInfoService
   ) { }

   setView(view: ProfileMenuViewContract): void {
      this.view = view;
   }

   async getUserInfo(): Promise<User> {
      this.view.setLoading(true);

      const userInfo = await this.getUserInfoService.handle();

      this.view.setUserInfo(userInfo);
      this.view.setLoading(false);
      
      return userInfo;
   }

   async logout(): Promise<void> {
      await this.logoutService.handle();
   }
}
