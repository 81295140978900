import React, { useMemo } from "react";
import EditSourcesView from "@ui/views/sources/EditSourcesView";
import { makeEditSourcesPresenter } from "@main/factories/presenters/sources";
import { EditSourcePresenterContract } from "@ui/presenters/sources";

interface EditSourcesProps {
    sourcesToEdit: EditSourcePresenterContract.Payload | null;
    onDismiss(): void;
}

const EditSources: React.FC<EditSourcesProps> = (props) => {
    const editSourcesPresenter = useMemo(() => makeEditSourcesPresenter(), []);

    return <EditSourcesView {...props} editSourcePresenter={editSourcesPresenter} />;
};

export default EditSources;
