import React, { Fragment, useEffect, useRef, useState } from "react";

import { BalanceBankPresenterContract } from "@ui/presenters/account";
import { CashFlow } from "@domain/entities";

import { Button, Col, Container, Row, Table } from "reactstrap";
import ComponentToPrint from "@ui/components/Print";
import { useReactToPrint } from "react-to-print";

import { Breadcrumbs } from "@ui/components/Common";
import logo from "@ui/assets/images/logo.jpg";
import Loading from "@ui/components/Loading";

import { DateFormatStrategy, MoneyFormatStrategy } from "@ui/strategy";
import { withTranslation } from "react-i18next";
import { BalanceBankFormSearch } from "@ui/components/accounts";
import { BalanceBankHelper } from "@ui/utils/account/BalanceBankHelper";

interface BalanceBankViewProps {
  t: any;
  balanceBankPresenter: BalanceBankPresenterContract;
}

const BalanceBankView: React.FC<BalanceBankViewProps> = ({
  t,
  balanceBankPresenter,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [cashflows, setCashflow] = useState<any[]>([]);
  const [balance, setBalanceBank] =
    useState<BalanceBankPresenterContract.Response | null>(null);

  useEffect(() => {
    balanceBankPresenter.setView({
      setBalances,
      setCashflows,
      setLoadingState,
    });

    balanceBankPresenter.getCashflows();
  }, []);

  const printRef = useRef(null);

  useEffect(() => {
    balanceBankPresenter.getCashflows();
  }, []);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `Saldo de Contas`,
    onPrintError: () => false,
    pageStyle: `
            .noPrint {
                display:none;
            }
        `,
  });

  const setLoadingState = (isLoading: boolean): void => setLoading(isLoading);
  const setCashflows = (cashflows: any[]): void => setCashflow(cashflows);
  const setBalances = (balance: BalanceBankPresenterContract.Response): void =>
    setBalanceBank(balance);

  return (
    <div>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={t("Bank accounts")}
            breadcrumbItem={t("Balance bank")}
          />
          <Row>
            <Col xl={12} className="mt-4">
              <BalanceBankFormSearch
                cashflows={cashflows}
                onSubmit={(formData) => {
                  balanceBankPresenter.get(formData);
                }}
              />
              <div className="d-sm-flex align-items-center justify-content-end">
                <Button
                  color="primary"
                  className="btn btn-primary float-right"
                  onClick={handlePrint}
                >
                  {t("Print")}
                </Button>
              </div>

              {loading ? (
                <div className="mt-5">
                  <Loading />
                </div>
              ) : (
                <Fragment>
                  <ComponentToPrint ref={printRef}>
                    <div className="font-arial-rlt">
                      {balance && (
                        <div className="d-sm-flex flex-column align-items-center justify-content-center mt-5 mb-3">
                          <img
                            style={{ objectFit: "contain" }}
                            className=""
                            src={logo}
                            height="150"
                            width="150"
                          />
                          <h4>{t("Balance bank")}</h4>
                          <p className="align-self-end">
                            {t(`Period`) +
                              ": " +
                              DateFormatStrategy.format(balance.date)}
                          </p>
                        </div>
                      )}
                      {balance && (
                        <Table bordered>
                          <thead>
                            <tr style={{ backgroundColor: "#ccc" }}>
                              <th className="text-center" colSpan={2}>
                                {t("Bank balance summarized by cash flow")}
                              </th>
                            </tr>

                            <tr>
                              <th>{t("Description")}</th>
                              <th
                                style={{ width: "20%" }}
                                className="text-center"
                              >
                                {t("Current balance")}
                              </th>
                            </tr>
                          </thead>
                          {balance.list.map(({ cashflowName, groups }) => (
                            <>
                              <thead>
                                <tr style={{ backgroundColor: "#ccc" }}>
                                  <th style={{ paddingLeft: "40px" }}>
                                    {cashflowName}
                                  </th>
                                  <th className="text-center">
                                    {MoneyFormatStrategy.format(
                                      BalanceBankHelper.sumListGroups(groups)
                                    )}
                                  </th>
                                </tr>
                              </thead>
                              {groups.map(({ groupName, accounts }) => (
                                <>
                                  <thead>
                                    <tr>
                                      <th style={{ paddingLeft: "60px" }}>
                                        {groupName}
                                      </th>
                                      <th className="text-center">
                                        {MoneyFormatStrategy.format(
                                          BalanceBankHelper.sumListAccounts(
                                            accounts
                                          )
                                        )}
                                      </th>
                                    </tr>
                                  </thead>
                                  {accounts.map(({ accountName, total }) => (
                                    <tbody>
                                      <tr>
                                        <td style={{ paddingLeft: "80px" }}>
                                          {accountName}
                                        </td>
                                        <td className="text-center">
                                          {MoneyFormatStrategy.format(total)}
                                        </td>
                                      </tr>
                                    </tbody>
                                  ))}
                                </>
                              ))}
                            </>
                          ))}
                        </Table>
                      )}
                    </div>
                  </ComponentToPrint>
                </Fragment>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default withTranslation()(BalanceBankView);
