import { AxiosHttpRepository } from "@infra/http";
import { API_BASE_URL } from "@main/constants/config";
import { FieldValidationBuilder } from "@presentation/validation/builders";
import { ValidationComposite } from "@presentation/validation/validators";
import { AddEffectiveBillsPresenterContract } from "@ui/presenters/bills";
import { HttpAddEffectiveBillsService } from "@data/services/bills/";
import { AddEffectiveBillsPresenter } from "@presentation/presenters/bills";
import { HttpGetBankAccountService } from "@data/services/account";
import { HttpGetSourcesService } from "@data/services/sources";
import { HttpGetProviderService } from "@data/services/provider/HttpGetProvidersService";
import { HttpGetGroupedPlanningService } from "@data/services/planning";

export const makeAddEffectiveBillsPresenter = (): AddEffectiveBillsPresenterContract => {
    const validation = new ValidationComposite([
        ...FieldValidationBuilder.field('date').required().build(),
        ...FieldValidationBuilder.field('bankAccountId').required().build(),
        ...FieldValidationBuilder.field('cashflow').required().build(),
        ...FieldValidationBuilder.field('providerId').required().build(),
        ...FieldValidationBuilder.field('sourceId').required().build(),
        ...FieldValidationBuilder.field('transactionGroupId').required().build(),
        ...FieldValidationBuilder.field('transactionSubgroupId').required().build(),
        ...FieldValidationBuilder.field('transactionType').required().build(),
        ...FieldValidationBuilder.field('amount').required().build(),
    ]);

    const httpRepository = new AxiosHttpRepository(API_BASE_URL);
    const addEffectiveBillsPresenter = new HttpAddEffectiveBillsService(httpRepository);
    const getAccountService = new HttpGetBankAccountService(httpRepository);
    const getSourceService = new HttpGetSourcesService(httpRepository);
    const getProviderService = new HttpGetProviderService(httpRepository);
    const getPlanningService = new HttpGetGroupedPlanningService(httpRepository);

    return new AddEffectiveBillsPresenter(
        validation,
        addEffectiveBillsPresenter,
        getAccountService,
        getSourceService,
        getProviderService,
        getPlanningService
    );
};
