import React, { useMemo } from "react";
import AddSourcesView from "@ui/views/sources/AddSourcesView";
import { makeAddSourcesPresenter } from "@main/factories/presenters/sources";

interface AddSourcesProps {
    isOpen: boolean;
    onDismiss(): void;
  }

const AddSources: React.FC<AddSourcesProps> = (props) => {
   const addSourcesPresenter = useMemo(() => makeAddSourcesPresenter(), []);
 
   return <AddSourcesView {...props} addSourcesPresenter={addSourcesPresenter} />;
};

export default AddSources;
