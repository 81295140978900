import { Validation } from "@presentation/validation/interfaces";

export class EqualsToValidation implements Validation {
  constructor(
    private readonly fieldName: string,
    private readonly fieldToCompare: string,
    private readonly notEqualMessage: string
  ) { }

  private getMessage(isValid: boolean): string {
    if (isValid) return 'equal';

    return this.notEqualMessage;
  }

  validate(input: any): Validation.ValidationResult[] {
    const isValid = input[this.fieldName] === input[this.fieldToCompare];
    const message = this.getMessage(isValid);

    return [{ isValid, message, fieldName: this.fieldName }];
  }
}
