import React, { useMemo } from "react";
import ProvidersListView from "@ui/views/providers/ProvidersListView";
import { makeProviderListPresenter } from "@main/factories/presenters/provider/makeProvidersListPresenter";

const ProviderList: React.FC = () => {
   const ProviderListPresenter = useMemo(() => makeProviderListPresenter(), []);

   return <ProvidersListView providerListPresenter={ProviderListPresenter} />;
};

export default ProviderList;
