import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Modal, Row, Col, Label, FormGroup } from "reactstrap";

import { useValidation } from "@ui/hooks";
import { EditProviderPresenterContract } from "@ui/presenters/provider";
import { DocumentKind } from "@domain/enums";
import { SelectItem } from "@ui/interfaces";
import { Similarity } from "@domain/interfaces/similarity";
import SimilarityAlert from "@ui/components/similarity/SimilarityAlert";
import { DocumentValidator } from "@ui/utils/provider";

interface EditProviderViewProps {
  t?: any;
  providerToEdit: EditProviderPresenterContract.Payload | null;
  onDismiss(): void;
  editProviderPresenter: EditProviderPresenterContract;
}

const INITIAL_FORM_STATE: EditProviderPresenterContract.Payload = {
  id: 0,
  name: "",
  document: ""
};

const EditProviderView: React.FC<EditProviderViewProps> = ({
  t,
  editProviderPresenter,
  onDismiss,
  providerToEdit,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [documentKind, setDocumentKind] = useState<DocumentKind>(DocumentKind.CPF);
  const [similarities, setSimilaritiesList] = useState<Similarity[]>([]);
  const [isOpenFeedBack, setIsOpenFeedBack] = useState<boolean>(false);
  const [payload, setPayload] =
    useState<EditProviderPresenterContract.Payload>(INITIAL_FORM_STATE);

  const {
    ValidationFeedback,
    ValidationInput,
    updateValidationState,
    validationState,
    ValidationInputMask,
    ValidationSelect
  } = useValidation();

  useEffect(() => {
    editProviderPresenter.setView({
      setLoading,
      updateValidationState,
      setSimilarity,
      openSimilarityFeedback
    });
  }, []);

  useEffect(() => {
    if (!providerToEdit) {
      updateValidationState([]);
      setPayload(INITIAL_FORM_STATE);
    } else {
      setPayload(providerToEdit);
    }
  }, [providerToEdit]);

  const setLoading = (isLoading: boolean): void => setIsLoading(isLoading);
  const setSimilarity = (similarity: Similarity[]): void => setSimilaritiesList(similarity);
  const openSimilarityFeedback = (isOpenFeedBack: boolean): void => setIsOpenFeedBack(isOpenFeedBack);
  const updateField = (fieldName: string, value: string) => {
    setPayload((oldState) => ({
      ...oldState,
      [fieldName]: value,
    }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    editProviderPresenter.edit({
      id: payload.id,
      name: payload.name,
      document:
        DocumentValidator.isCPFValid(payload.document) ||
          DocumentValidator.isCNPJValid(payload.document) ?
          payload.document : ""
    });
  };

  const getMask = () => {
    if (documentKind === DocumentKind.CNPJ) {
      return '99.999.999/9999-99';
    }

    if (documentKind === DocumentKind.CPF) {
      return '999.999.999-99';
    }
  };

  const optionsKinds: SelectItem[] = [
    {
      label: t("provider.company"),
      value: DocumentKind.CNPJ
    },
    {
      label: t("provider.person"),
      value: DocumentKind.CPF
    }
  ];

  return (
    <Modal
      isOpen={Boolean(providerToEdit)}
      toggle={isLoading ? undefined : onDismiss}
      scrollable={true}
      id="staticBackdrop"
      size="lg"
    >
      <div className="modal-header">
        <h5 className="modal-title">{t("Provider text")}</h5>
        <button
          type="button"
          className="btn-close"
          onClick={isLoading ? undefined : onDismiss}
          aria-label="Close"
        />
      </div>

      <form onSubmit={handleSubmit}>
        <div className="modal-body">
          <SimilarityAlert
            isOpen={isOpenFeedBack}
            similarities={similarities}
          />

          <Row>
            <Col md={12} className="mb-2">
              <FormGroup>
                <Label>{t("Name")}</Label>
                <ValidationInput
                  field="name"
                  validationState={validationState}
                  name="name"
                  className="form-control"
                  placeholder={`${t("Provider text placeholder")}`}
                  value={payload.name}
                  onChange={({ target: { value, name } }) =>
                    updateField(name, value)
                  }
                />

                <ValidationFeedback field="name" />
              </FormGroup>
            </Col>

            <Col>
              <FormGroup>
                <Label>{t("provider.document.kind")}</Label>

                <ValidationSelect
                  validationState={validationState}
                  field="documentKind"
                  name="documentKind"
                  options={optionsKinds}
                  className="form-control"
                  onChange={({ target: { value } }) => {
                    setDocumentKind(value as DocumentKind);
                    updateField("document", "");
                  }}
                  value={documentKind}
                />

                <ValidationFeedback field="documentKind" />
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <Label for="cpfCnpj">{t("provider.document")}</Label>

                <ValidationInputMask
                  mask={getMask()}
                  field="document"
                  name="document"
                  className="form-control"
                  placeholder={t("provider.document.placeholder")}
                  validationState={validationState}
                  value={payload.document}
                  onChange={({ target: { value, name } }) =>
                    updateField(name, value)
                  }
                />

                <ValidationFeedback field="document" />
              </FormGroup>
            </Col>
          </Row>
        </div>

        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-danger me-2"
            onClick={isLoading ? undefined : onDismiss}
            disabled={isLoading}
          >
            {t("Close")}
          </button>

          <button className="btn btn-primary" disabled={isLoading}>
            {t(isLoading ? "loading" : "Save")}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default withTranslation()(EditProviderView);
