import { Provider } from "@domain/entities/Provider";
import { GetProviderService, RemoveProviderService } from "@domain/services/provider";
import { ProviderListViewContract } from "@presentation/views/providers/ProvidersListViewContract";
import { ProviderListPresenterContract } from "@ui/presenters/provider/ProviderListPresenterContract";

export class ProviderListPresenter implements ProviderListPresenterContract {
   private view!: ProviderListViewContract;
 
   constructor(
     private readonly getProviderService: GetProviderService,
     private readonly removeProviderService: RemoveProviderService
   ) { }
 
   async removeProvider(providerId: number): Promise<void> {
     this.view.setLoadingState(true);
 
     const isRemoved = await this.removeProviderService.handle(providerId);
     
     if(isRemoved) {
       this.get();
     } else {
       this.view.setLoadingState(false);
     }
   }
 
   setView(view: ProviderListViewContract): void {
     this.view = view;
   }
 
   async get(): Promise<Provider[]> {
     this.view.setLoadingState(true);
 
     const provider = await this.getProviderService.handle();
 
     this.view.setProvider(provider);
     this.view.setLoadingState(false);
     return provider;
   }
 }
