import { HttpEditBankAccountService } from "@data/services/account/HttpEditBankAccountService";
import { HttpGetAccountGroupsService } from "@data/services/account/HttpGetAccountGroupsService";
import { HttpGetBankService } from "@data/services/account/HttpGetBankService";
import { HttpGetCashflowsService } from "@data/services/cashflows";
import { HttpGetSourcesService } from "@data/services/sources";
import { AxiosHttpRepository } from "@infra/http";
import { API_BASE_URL } from "@main/constants/config";
import { EditBankAccountPresenter } from "@presentation/presenters/account/EditBankAccountPresenter";
import { FieldValidationBuilder } from "@presentation/validation/builders";
import { ValidationComposite } from "@presentation/validation/validators";
import { EditBankAccountPresenterContract } from "@ui/presenters/account/EditBankAccountPresenterContract";

export const makeEditBankAccountPresenter =
  (): EditBankAccountPresenterContract => {
    const validation = new ValidationComposite([
      ...FieldValidationBuilder.field("id").required().build(),
      ...FieldValidationBuilder.field("name").required().build(),
      ...FieldValidationBuilder.field("group").required().build(),
      ...FieldValidationBuilder.field("cashflow").required().build(),
      ...FieldValidationBuilder.field("bankId").required().build(),
      ...FieldValidationBuilder.field("sourceId").required().build(),
      ...FieldValidationBuilder.field("initialDate").required().build(),
      ...FieldValidationBuilder.field("initialBalance").required().build(),
    ]);

    const httpRepository = new AxiosHttpRepository(API_BASE_URL);
    const editBankAccountService = new HttpEditBankAccountService(
      httpRepository
    );
    const getCashflowsService = new HttpGetCashflowsService(httpRepository);
    const getBanksService = new HttpGetBankService(httpRepository);
    const getSourcesService = new HttpGetSourcesService(httpRepository);
    const getAccountGroupsService = new HttpGetAccountGroupsService(
      httpRepository
    );

    return new EditBankAccountPresenter(
      validation,
      editBankAccountService,
      getCashflowsService,
      getBanksService,
      getSourcesService,
      getAccountGroupsService
    );
  };
