import { HttpRequestBuilder } from "@data/builders";
import { HttpRequestTypes } from "@data/enums";
import { HttpRepository } from "@data/repositories";
import { CopyPlanningService } from "@domain/services/planning";

export class HttpCopyPlanningService implements CopyPlanningService {
    constructor(
        private readonly httpRepository: HttpRepository
    ) { }

    async handle(payload: CopyPlanningService.Payload): Promise<boolean> {
        const httpRequest = HttpRequestBuilder.requestToUrl('/planning/copy')
        .withMethod(HttpRequestTypes.POST)
        .withData(payload)
        .build();
  
      const { status } = await this.httpRepository.makeRequest<CopyPlanningService.Payload>(httpRequest);
  
      return status === 200;
    }
}
