import { HttpRequestBuilder } from "@data/builders";
import { HttpRequestTypes } from "@data/enums";
import { HttpRepository, NavigateRepository } from "@data/repositories";
import { AppRoutes, EmailServiceScenarios } from "@domain/enums";
import { SendEmailForgotPasswordService } from "@domain/services/auth";

interface SendEmailForgotPasswordResponse {
  email: string;
  scenario: EmailServiceScenarios;
}

export class HttpSendEmailForgotPasswordService implements SendEmailForgotPasswordService {
  constructor(
    private readonly httpRepository: HttpRepository,
    private readonly navigateRepository: NavigateRepository,
  ) { }

  async handle(payload: SendEmailForgotPasswordService.Payload): Promise<void> {
    const httpRequest = HttpRequestBuilder
      .requestToUrl('/api/auth/sendForgotPasswordEmail')
      .withData(payload)
      .withMethod(HttpRequestTypes.POST)
      .build();

    const { status, data } = await this.httpRepository.makeRequest<
      SendEmailForgotPasswordService.Payload,
      SendEmailForgotPasswordResponse
    >(httpRequest);

    if (this.isSuccessFullStatus(status)) {
      await this.navigateRepository.navigate(`${AppRoutes.FORGOT_PASSWORD_RECOVER}/${data.email}`);
    }
  }

  private isSuccessFullStatus(status: number) {
    return status === 200;
  }
}
