import { Validation, FormValidation } from "@presentation/validation/interfaces";

export class ValidationComposite implements FormValidation {
  constructor(private readonly validations: Validation[]) { }

  errors(input: any): Validation.ValidationResult[] {
    const errors = this.validate(input).filter(({ isValid }) => !isValid);
    return errors;
  }

  allValid(input: any): boolean {
    return !this.validate(input).find(({ isValid }) => !isValid);
  }

  validate(input: any): Validation.ValidationResult[] {
    const results: Validation.ValidationResult[] = [];

    this.validations.forEach((validation) => {
      const validationsResults = validation.validate(input);
      validationsResults.forEach((result) => {
        results.push(result);
      });
    });

    return results;
  }
}
