import { HttpGetCashflowsService } from "@data/services/cashflows";
import { HttpGetConsolidatedReportService } from "@data/services/consolidated-report/HttpGetConsolidatedReportService";
import { HttpGetGroupsService } from "@data/services/groups";
import { HttpGetSubgroupsService } from "@data/services/revenues-expenses";
import { AxiosHttpRepository } from "@infra/http";
import { API_BASE_URL } from "@main/constants/config";
import { ExpensesFromGroupsPresenter } from "@presentation/presenters/dashboard";
import { ExpensesFromGroupsPresenterContract } from "@ui/presenters/dashboard";

export const makeExpensesFromGroupsPresenter = (): ExpensesFromGroupsPresenterContract => {
  const httpRepository = new AxiosHttpRepository(API_BASE_URL);
  const getConsolidatedReportService = new HttpGetConsolidatedReportService(httpRepository);
  const getGroupsService = new HttpGetGroupsService(httpRepository);
  const getSubgroupsService = new HttpGetSubgroupsService(httpRepository);
  const getCashflowsService = new HttpGetCashflowsService(httpRepository);

  return new ExpensesFromGroupsPresenter(
    getConsolidatedReportService,
    getGroupsService,
    getSubgroupsService,
    getCashflowsService
  );
};
