export enum AppRoutes {
  LOGIN = "/login",
  FORGOT_PASSWORD = "/forgot-password",
  FORGOT_PASSWORD_RECOVER = "/forgot-password/recover",
  DASHBOARD = "/dashboard",
  USERS = "/users",
  HEALTHBLOCKS = "/healthblocks",
  REVENUESANDEXPENSES = "/revenuesandexpenses",
  GROUPS = "/groups",
  PROVIDERS = "/providers",
  ACCOUNTSGROUPS = "/accountsgroups",
  MESSAGES = "/messages",
  BANKACCOUNT = "/bankaccount",
  CONSOLIDATEDREPORT = "/consolidatedreport",
  TRANSACTIONS = "/transactions",
  CONSOLIDATED_TABLED = "/consolidated_tabled",
  BANK_STATEMENT = "/bank_statement",
  LATEST_UPDATES = "/latest_updates",
  CASHFLOWS = "/cashflows",
  TRANSFERS = "/transfers",
  BANK_ACCOUNT_FILES = "/bank_account_files",
  BALANCECHECK = "/balancecheck",
  RECONCILEBALANCE = "/reconcilebalance",
  GENERALBANKSTATEMENT = "/generalbankstatement",
  BANKBALANCE = "/bankbalance",
  QUARTELYCONSOLIDATED = "/quartelyConsolidated",
  EXPENSESPAID = "/expensesPaid",
  PREFECTURE = "/prefecture",
  SOURCES = "/sources",
  PLANNING = "/planning",
  TREASURY = "/treasury",
  EFFECTIVEBILLS = "/effectivebills",
  NONEFFECTIVEBILLS = "/noneffectivebills"
}
