import { HttpRequestBuilder } from "@data/builders";
import { HttpRequestTypes } from "@data/enums";
import { HttpRepository } from "@data/repositories";
import { PublishTreasuryService } from "@domain/services/treasury";

export class HttpPublishTreasuryService implements PublishTreasuryService {
  constructor(private readonly httpRepository: HttpRepository) {}

  async handle(payload: PublishTreasuryService.Payload): Promise<void> {
    const request = HttpRequestBuilder.requestToUrl("/treasury")
      .withMethod(HttpRequestTypes.POST)
      .withData(payload)
      .build();

    await this.httpRepository.makeRequest(request);
  }
}
