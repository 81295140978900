import React, { useMemo } from "react";

import EditBankAccountView from "@ui/views/account/EditBankAccountView";
import { makeEditBankAccountPresenter } from "@main/factories/presenters/accounts";
import { EditBankAccountPresenterContract } from "@ui/presenters/account/EditBankAccountPresenterContract";

interface EditBankAccountProps {
  onDismiss(): void;
  bankToEdit: EditBankAccountPresenterContract.Payload | null;
}

export const EditBankAccount: React.FC<EditBankAccountProps> = (props) => {
  const editBankAccountPresenter = useMemo(() => makeEditBankAccountPresenter(), []);

  return <EditBankAccountView {...props} editBankAccountPresenter={editBankAccountPresenter} />;
};
