import { BillsEvents } from "@domain/events/BillsEvents";
import { GetNonEffectiveBillsService, RemoveNonEffectiveBillsService } from "@domain/services/bills";
import { NonEffectiveBillsViewContract } from "@presentation/views/bills";
import { NonEffectiveBillsPresenterContract } from "@ui/presenters/bills";
import { EventEmitter } from "@utils/events/EventEmitter";

export class NonEffectiveBillsPresenter implements NonEffectiveBillsPresenterContract {
    private view!: NonEffectiveBillsViewContract;

    constructor(
        private readonly getNonEffectiveBillsService: GetNonEffectiveBillsService,
        private readonly removeNonEffectiveBillsService: RemoveNonEffectiveBillsService
    ) { }

    async get(params: NonEffectiveBillsPresenterContract.Params): Promise<NonEffectiveBillsPresenterContract.Response[]> {
        this.view.setLoadingState(true);

        const bills = await this.getNonEffectiveBillsService.handle(params);

        this.view.setParams(params);
        this.view.setResponse(bills);
        this.view.setLoadingState(false);
        return bills;
    }

    setView(view: NonEffectiveBillsViewContract): void {
        this.view = view;
    }

    async removeBills(billsId: number): Promise<void> {
        this.view.setLoadingState(true);

        const isRemoved = await this.removeNonEffectiveBillsService.handle(billsId);

        if (isRemoved) {
            EventEmitter.emit(BillsEvents.BILLS_REMOVED);
        }
    }
}
