import { AccountGroup, Bank, CashFlow, Source } from "@domain/entities";

import { BankEvents } from "@domain/events";

import {
  GetAccountGroupsService,
  GetBankService,
} from "@domain/services/account";

import { EditBankAccountService } from "@domain/services/account/EditBankAccountService";
import { GetCashflowsService } from "@domain/services/cashflows";
import { GetSourcesService } from "@domain/services/sources";
import { FormValidation } from "@presentation/validation/interfaces";
import { EditBankAccountViewContract } from "@presentation/views/account/EditBankAccountViewContract";
import { EditBankAccountPresenterContract } from "@ui/presenters/account/EditBankAccountPresenterContract";
import { EventEmitter } from "@utils/events";

export class EditBankAccountPresenter
  implements EditBankAccountPresenterContract
{
  private view!: EditBankAccountViewContract;

  constructor(
    private readonly validation: FormValidation,
    private readonly editBankAccountService: EditBankAccountService,
    private readonly getCashflowsService: GetCashflowsService,
    private readonly getBanksService: GetBankService,
    private readonly getSourcesService: GetSourcesService,
    private readonly getAccountGroupsService: GetAccountGroupsService
  ) {}

  async edit(
    payload: EditBankAccountPresenterContract.Payload
  ): Promise<boolean> {
    this.view.setLoading(true);

    const validationSuccessfull = this.validation.allValid(payload);

    if (!validationSuccessfull) {
      this.view.updateValidationState(this.validation.errors(payload));
      this.view.setLoading(false);
      return false;
    }

    const isAccountEdited = await this.editBankAccountService.handle(payload);

    if (isAccountEdited) {
      EventEmitter.emit(BankEvents.BANK_UPDATED);
    }

    this.view.setLoading(false);
    this.view.updateValidationState([]);
    return isAccountEdited;
  }

  setView(view: EditBankAccountViewContract): void {
    this.view = view;
  }

  async getAccountGroups(): Promise<AccountGroup[]> {
    const accountGroups = await this.getAccountGroupsService.handle();
    this.view.setAccountGroups(accountGroups);

    return accountGroups;
  }
  async getBanks(): Promise<Bank[]> {
    const banks = await this.getBanksService.handle();
    this.view.setBanks(banks);

    return banks;
  }
  async getCashflows(): Promise<CashFlow[]> {
    const cashflows = await this.getCashflowsService.handle();
    this.view.setCashflows(cashflows);

    return cashflows;
  }

  async getSources(): Promise<Source[]> {
    const sources = await this.getSourcesService.handle();
    this.view.setSources(sources);
    return sources;
  }
}
