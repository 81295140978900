import { HttpAddProviderService } from "@data/services/provider/HttpAddProviderService";
import { AxiosHttpRepository } from "@infra/http";
import { API_BASE_URL } from "@main/constants/config";
import { AddProviderPresenter } from "@presentation/presenters/provider/AddProviderPresenter";
import { FieldValidationBuilder } from "@presentation/validation/builders";
import { ValidationComposite } from "@presentation/validation/validators";
import { AddProviderPresenterContract } from "@ui/presenters/provider/AddProviderPresenterContract";

export const makeAddProviderPresenter = (): AddProviderPresenterContract => {
  const validation = new ValidationComposite([
    ...FieldValidationBuilder.field("name").required().build(),
    ...FieldValidationBuilder.field("document").document().build(),
  ]);

  const httpRepository = new AxiosHttpRepository(API_BASE_URL);
  const addProviderService = new HttpAddProviderService(httpRepository);

  return new AddProviderPresenter(validation, addProviderService);
};
