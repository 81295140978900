import React, { Fragment, useEffect, useState } from "react";

import { withTranslation } from "react-i18next";
import { Card, CardBody, Col, FormGroup, Label, Row } from "reactstrap";

import { CashFlow, Group, Subgroup } from "@domain/entities";
import { FieldValidationBuilder } from "@presentation/validation/builders";
import { ValidationComposite } from "@presentation/validation/validators";
import { useValidation } from "@ui/hooks";
import { SelectItem } from "@ui/interfaces";
import { QuartelyConsolidatedPresenterContract } from "@ui/presenters/reports";
import {
  getLastThreeMonths,
  getPeriodFromMonthAndYear,
} from "@ui/utils/reports";

interface FormSearchPeriodProps {
  t: any;
  cashflows: any[];
  groups: Group[];
  subgroups: any[];
  onSubmit(period: QuartelyConsolidatedPresenterContract.Payload): void;
}

const validation = new ValidationComposite([
  ...FieldValidationBuilder.field("endOfPeriod").required().build(),
  ...FieldValidationBuilder.field("cashFlow").required().build(),
]);

const FormSearchPeriod: React.FC<FormSearchPeriodProps> = ({
  t,
  onSubmit,
  cashflows,
  groups,
  subgroups,
}) => {
  const {
    ValidationFeedback,
    validationState,
    ValidationSelect,
    ValidationInput,
    updateValidationState,
  } = useValidation();

  const [payload, setPayload] =
    useState<QuartelyConsolidatedPresenterContract.Payload>({
      beginOfPeriod: "",
      endOfPeriod: "",
      groupId: 0,
      subgroupId: 0,
      month: "",
      year: "",
      cashFlow: 0,
    });

  const updateField = (fieldName: string, value: string) => {
    setPayload((oldState) => ({
      ...oldState,
      [fieldName]: value,
    }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (validation.allValid(payload)) {
      const { firstDayOfPeriod, lastDayOfPeriod } = getLastThreeMonths(
        new Date(parseInt(payload.year), parseInt(payload.month) -1, 1)
      );
      updateValidationState([]);
      onSubmit({
        ...payload,
        beginOfPeriod: firstDayOfPeriod,
        endOfPeriod: lastDayOfPeriod
      });
    } else {
      updateValidationState(validation.errors(payload));
    }
  };

  useEffect(() => {
    const { firstDayOfPeriod, lastDayOfPeriod } = getPeriodFromMonthAndYear(
      payload.month,
      payload.year
    );

    setPayload({
      ...payload,
      beginOfPeriod: firstDayOfPeriod,
      endOfPeriod: lastDayOfPeriod,
    });
  }, []);

  const optionsCashflows: SelectItem[] = cashflows.map(({ id, name }) => ({
    label: name,
    value: id,
  }));

  const optionsGroups: SelectItem[] = groups
    // .filter(({ tipos_fluxos_id }) => payload.cashFlow == tipos_fluxos_id)
    .map(({ id, name }) => ({ label: name, value: id }));

  const optionsSubgroups: SelectItem[] = subgroups
    .filter(({ transacoes_grupo_id }) => payload.groupId == transacoes_grupo_id)
    .map(({ id, name }) => ({ label: name, value: id }));

  const optionsMonths: SelectItem[] = [
    { value: 1, label: "Janeiro" },
    { value: 2, label: "Fevereiro" },
    { value: 3, label: "Março" },
    { value: 4, label: "Abril" },
    { value: 5, label: "Maio" },
    { value: 6, label: "Junho" },
    { value: 7, label: "Julho" },
    { value: 8, label: "Agosto" },
    { value: 9, label: "Setembro" },
    { value: 10, label: "Outubro" },
    { value: 11, label: "Novembro" },
    { value: 12, label: "Dezembro" },
  ];

  return (
    <Fragment>
      <form onSubmit={handleSubmit}>
        <Card>
          <CardBody>
            <Row>
              <Col md={12} className="mb-1">
                <FormGroup>
                  <Label>{t("Cashflows")}</Label>

                  <ValidationSelect
                    field="cashFlow"
                    options={optionsCashflows}
                    validationState={validationState}
                    name="cashFlow"
                    className="form-control"
                    placeholder={t("Select")}
                    onChange={({ target: { name, value } }) =>
                      updateField(name, value)
                    }
                  />

                  <ValidationFeedback field="cashFlow" />
                </FormGroup>
              </Col>

              <Col md={12} className="mb-1">
                <FormGroup>
                  <Label>{t("Group")}</Label>
                  <ValidationSelect
                    field="groupId"
                    options={optionsGroups}
                    validationState={validationState}
                    name="groupId"
                    className="form-control"
                    placeholder={t("Group placeholder")}
                    onChange={({ target: { value, name } }) =>
                      updateField(name, value)
                    }
                  />

                  <ValidationFeedback field="groupId" />
                </FormGroup>
              </Col>

              <Col md={12} className="mb-1">
                <FormGroup>
                  <Label>{t("Subgroup")}</Label>
                  <ValidationSelect
                    field="subgroupId"
                    options={optionsSubgroups}
                    validationState={validationState}
                    name="subgroupId"
                    className="form-control"
                    placeholder={t("Subgroup placeholder")}
                    onChange={({ target: { value, name } }) =>
                      updateField(name, value)
                    }
                  />

                  <ValidationFeedback field="subgroupId" />
                </FormGroup>
              </Col>

              <Col className="mb-3" md={6} sm={12}>
                <Label className="form-Label">{t("Month")}</Label>

                <ValidationSelect
                  field="month"
                  options={optionsMonths}
                  validationState={validationState}
                  className="form-control"
                  name="month"
                  placeholder={t("End of period of month placeholder")}
                  onChange={({ target: { name, value } }) =>
                    updateField(name, value)
                  }
                />

                <ValidationFeedback field="" />
              </Col>

              <Col sm="6" className="mb-3">
                <Label className="form-Label">{t("Year")}</Label>

                <ValidationInput
                  type="number"
                  value={payload.year}
                  validationState={validationState}
                  placeholder={t("End of period year placeholder")}
                  onChange={({ target: { value } }) => {
                    setPayload({
                      ...payload,
                      year: value,
                    });
                  }}
                  field={""}
                />
              </Col>

              <Col sm={11} />
              <Col sm={1}>
                <button className="btn btn-primary">{t("Search")}</button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </form>
    </Fragment>
  );
};

export default withTranslation()(FormSearchPeriod);
