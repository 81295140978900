import { HttpRequestBuilder } from "@data/builders";
import { HttpRequestTypes } from "@data/enums";
import { HttpRepository } from "@data/repositories";
import { AccountGroup } from "@domain/entities/AccountGroup";
import { GetAccountGroupsService } from "@domain/services/account";

export class HttpGetAccountGroupsService implements GetAccountGroupsService {
   constructor(
      private readonly httpRepository: HttpRepository
   ) { }

   async handle(): Promise<AccountGroup[]> {
      const request = HttpRequestBuilder
         .requestToUrl('/bank/groups')
         .withMethod(HttpRequestTypes.GET)
         .build();

      const { data } = await this.httpRepository.makeRequest<any, AccountGroup[]>(request);

      return data;
   }
}
