import { MonthAndDayFormat } from "@ui/interfaces";
import { format } from "date-fns";
import pt from "date-fns/locale/pt-BR";

export class MonthAndDayFormatBr implements MonthAndDayFormat {
  handle(date: Date): string {
    const newDate = format(date, "MMMM/yyyy", { locale: pt });

    return newDate;
  }
}
