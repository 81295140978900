import React, { useMemo } from "react";
import SourcesView from "@ui/views/sources/SourcesView";
import { makeSourcesPresenter } from "@main/factories/presenters/sources";

const Sources: React.FC = () => {
   const SourcesPresenter = useMemo(() => makeSourcesPresenter(), []);

   return <SourcesView sourcesPresenter={SourcesPresenter} />;
};

export default Sources;
