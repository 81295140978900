import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Modal, Row, Col, Label, FormGroup } from "reactstrap";

import { useValidation } from "@ui/hooks";
import { SelectItem } from "@ui/interfaces";
import { City, State } from "@domain/entities";
import { EditPrefecturePresenterContract } from "@ui/presenters/prefecture";

interface EditPrefectureViewProps {
    t?: any;
    prefectureToEdit: EditPrefecturePresenterContract.Payload | null;
    onDismiss(): void;
    editPrefecturePresenter: EditPrefecturePresenterContract;
}

interface FormPayload {
    id: number;
    cityId: number;
    stateId: number;
}

const INITIAL_FORMPAYLOAD_STATE: FormPayload = {
    id: 0,
    cityId: 0,
    stateId: 0
};

const EditPrefectureView: React.FC<EditPrefectureViewProps> = ({ t, prefectureToEdit, onDismiss, editPrefecturePresenter }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [states, setStatesList] = useState<State[]>([]);
    const [cities, setCitiesList] = useState<City[]>([]);
    const [payload, setPayload] = useState<FormPayload>(INITIAL_FORMPAYLOAD_STATE);

    const { ValidationFeedback, updateValidationState, validationState, ValidationSelect } = useValidation();

    useEffect(() => {
        editPrefecturePresenter.setView({ setStates, setLoading, setCities, updateValidationState });
    }, []);

    useEffect(() => {
        if (!prefectureToEdit) {
            updateValidationState([]);
            setPayload(INITIAL_FORMPAYLOAD_STATE);
        } else {
            editPrefecturePresenter.getState();
        }
    }, [prefectureToEdit]);

    useEffect(() => {
        if (prefectureToEdit) {
            const state = states.find(({ name }) => name === prefectureToEdit.stateName);

            if (state) {
                editPrefecturePresenter.getCity(state.id);

                setPayload({
                    id: prefectureToEdit.id,
                    cityId: parseInt(prefectureToEdit.cityCode),
                    stateId: state.id
                });
            }
        }
    }, [states]);

    const setLoading = (isLoading: boolean) => setIsLoading(isLoading);
    const setStates = (states: State[]) => setStatesList(states);
    const setCities = (cities: City[]) => setCitiesList(cities);

    const updateField = (fieldName: string, value: string) => {
        setPayload((oldState) => ({
            ...oldState,
            [fieldName]: value
        }));
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const state = states.find(({ id }) => id == payload.stateId);
        const city = cities.find(({ id }) => id == payload.cityId);

        editPrefecturePresenter.edit({
            id: payload.id,
            cityCode: String(city?.id),
            cityName: city?.name || "",
            stateName: state?.name || ""
        });
    };

    const optionsStates: SelectItem[] = states.map(({ id, name }) => ({ label: name, value: id }));
    const optionsCity: SelectItem[] = cities.map(({ id, name }) => ({ label: name, value: id }));

    return (
        <Modal
            isOpen={Boolean(prefectureToEdit)}
            toggle={isLoading ? undefined : onDismiss}
            scrollable={true}
            id="staticBackdrop"
            size="lg"
        >
            <div className="modal-header">
                <h5 className="modal-title">{t("Edit prefecture")}</h5>
                <button
                    type="button"
                    className="btn-close"
                    onClick={isLoading ? undefined : onDismiss}
                    aria-label="Close"
                />
            </div>

            <form onSubmit={handleSubmit}>
                <div className="modal-body">
                    <Row>
                        <Col md={12} className="mb-3">
                            <FormGroup>
                                <Label>{t("States")}</Label>

                                <ValidationSelect
                                    field="stateName"
                                    options={optionsStates}
                                    validationState={validationState}
                                    name="stateId"
                                    className="form-control"
                                    placeholder={`${t('Select')}`}
                                    onChange={({ target: { value, name } }) => {
                                        updateField(name, value);
                                        editPrefecturePresenter.getCity(parseInt(value));
                                    }}
                                    value={payload.stateId}
                                />

                                <ValidationFeedback field="stateId" />
                            </FormGroup>
                        </Col>

                        <Col md={12} className="mb-2">
                            <FormGroup>
                                <Label>{t("City")}</Label>

                                <ValidationSelect
                                    field="cityCode"
                                    options={optionsCity}
                                    validationState={validationState}
                                    name="cityId"
                                    className="form-control"
                                    placeholder={`${t("Select")} ${t("City")}`}
                                    onChange={({ target: { value, name } }) => updateField(name, value)}
                                    value={payload.cityId}
                                />

                                <ValidationFeedback field="cityCode" />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>

                <div className="modal-footer">
                    <button
                        type="button"
                        className="btn btn-danger me-2"
                        onClick={isLoading ? undefined : onDismiss}
                        disabled={isLoading}
                    >
                        {t("Close")}
                    </button>

                    <button className="btn btn-primary" disabled={isLoading}>
                        {t(isLoading ? "loading" : "Save")}
                    </button>
                </div>
            </form>
        </Modal>
    );
};

export default withTranslation()(EditPrefectureView);
