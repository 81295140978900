import { HttpRequestBuilder } from "@data/builders";
import { HttpRequestTypes } from "@data/enums";
import { HttpRepository } from "@data/repositories";
import { User } from "@domain/entities";
import { GetUserInfoService } from "@domain/services/profile";

interface GetUserRequest {
  user: User;
}

export class HttpGetUserInfoService implements GetUserInfoService {
  constructor(
    private readonly httpRepository: HttpRepository
  ) { }

  async handle(): Promise<User> {
    // const request = HttpRequestBuilder
    //   .requestToUrl('/api/auth/me')
    //   .withMethod(HttpRequestTypes.GET)
    //   .build();

    // const { data } = await this.httpRepository.makeRequest<any, GetUserRequest>(request);

    // return data.user;
    return {
      cashflows: [],
      email: "",
      group: "",
      id: 0,
      name: "",
      prefectureId: 0,
      prefectureName: "",
    };
  }
}
