import React, { Fragment, useEffect, useRef, useState } from "react";

import { Button, Col, Container, Row, Table } from "reactstrap";
import { withTranslation } from "react-i18next";
import ComponentToPrint from "@ui/components/Print";
import { useReactToPrint } from "react-to-print";

import { Breadcrumbs } from "@ui/components/Common";
import { BankStatementSearchForm } from "@ui/components/reports";
import { BankStatementPresenterContract } from "@ui/presenters/reports";

import Loading from "@ui/components/Loading";

import logo from "@ui/assets/images/logo.jpg";
import { MoneyStatusColor } from "@ui/components";
import { DateFormatStrategy } from "@ui/strategy";
import { GroupsByFlowsList } from "@domain/interfaces/accounts";

interface BankStatementViewProps {
  t: any;
  bankStatementPresenter: BankStatementPresenterContract;
}

const BankStatementView: React.FC<BankStatementViewProps> = ({
  t,
  bankStatementPresenter,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [accounts, setAccounts] = useState<GroupsByFlowsList[]>([]);
  const [statement, setBankStatement] =
    useState<BankStatementPresenterContract.Response>({
      initialStatement: 0,
      transactions: [],
      accountName: "",
    });

  const [params, setParams] = useState<BankStatementPresenterContract.Params>({
    accountId: 0,
    beginOfPeriod: "",
    endOfPeriod: "",
    accountName: "",
    cashFlow: 0,
  });

  useEffect(() => {
    bankStatementPresenter.setView({
      setBankAccounts,
      setLoadingState,
      setBankStatementList,
      setDownloadStatementList,
    });

    bankStatementPresenter.getAccounts();
  }, []);

  const printRef = useRef(null);

  useEffect(() => {
    bankStatementPresenter.getAccounts();
  }, []);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `Saldo de Contas`,
    onPrintError: () => false,
    pageStyle: `
            .noPrint {
                display:none;
            }
        `,
  });

  const setLoadingState = (isLoading: boolean): void => setIsLoading(isLoading);
  const setBankAccounts = (accounts: GroupsByFlowsList[]): void =>
    setAccounts(accounts);

  const setBankStatementList = (
    statement: BankStatementPresenterContract.Response
  ): void => setBankStatement(statement);

  const setDownloadStatementList = (
    params: BankStatementPresenterContract.Params
  ): void => setParams(params);

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs
          title={t("Bank accounts")}
          breadcrumbItem={t("Bank Statement")}
        />

        <Row>
          <Col xl={12} className="mt-4">
            <BankStatementSearchForm
              accounts={accounts}
              onSubmit={(params) => {
                bankStatementPresenter.getBankStatement(params);
                setParams(params);
              }}
            />

            <div className="d-sm-flex align-items-center justify-content-end">
              <Button
                color="primary"
                className="btn btn-primary float-right"
                onClick={handlePrint}
              >
                {t("Print")}
              </Button>

              <Button
                color="primary"
                className="btn btn-primary float-right"
                style={{ marginLeft: 15 }}
                onClick={() => bankStatementPresenter.downloadStatement(params)}
              >
                {t("Generate spreadsheet")}
              </Button>
            </div>

            {isLoading ? (
              <div className="mt-5">
                <Loading />
              </div>
            ) : (
              <Fragment>
                <ComponentToPrint ref={printRef}>
                  <div className="font-arial-rlt">
                    <div className="d-sm-flex flex-column align-items-center justify-content-center mt-5 mb-3">
                      <img
                        style={{ objectFit: "contain" }}
                        className=""
                        src={logo}
                        height="150"
                        width="150"
                      />

                      <h4>{t("Bank Statement")}</h4>
                      <h4>{statement.accountName}</h4>
                    </div>

                    <Table bordered>
                      <tbody>
                        <tr>
                          <th>{t("Description")}</th>
                          <th className="text-center">{t("Date")}</th>
                          <th className="text-center">{t("Value")}</th>
                          <th className="text-center">{t("Statement")}</th>
                        </tr>

                        <tr>
                          <td>{t("Bank Statement initial balance")}</td>
                          <td className="text-center">-</td>
                          <td className="text-center">-</td>
                          <td className="text-center">
                            <MoneyStatusColor
                              value={statement.initialStatement}
                            />
                          </td>
                        </tr>

                        {statement.transactions.map(
                          ({ date, description, value, statement }, key) => (
                            <tr key={key}>
                              <td>{description}</td>
                              <td className="text-center">
                                {DateFormatStrategy.format(date)}
                              </td>
                              <td className="text-center">
                                <MoneyStatusColor value={value} />
                              </td>
                              <td className="text-center">
                                <MoneyStatusColor value={statement ?? 0} />
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                      {statement.transactions.length > 0 && (
                        <tbody>
                          <tr>
                            <td>{t("Bank Statement ending balance")}</td>
                            <td className="text-center">-</td>
                            <td className="text-center">-</td>
                            <td className="text-center">
                              <MoneyStatusColor
                                value={
                                  statement.transactions[
                                    statement.transactions.length - 1
                                  ].statement ?? 0
                                }
                              />
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </Table>
                  </div>
                </ComponentToPrint>
              </Fragment>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default withTranslation()(BankStatementView);
