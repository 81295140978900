import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Modal, Row, Col, Label, FormGroup } from "reactstrap";

import { useValidation } from "@ui/hooks";
import { Subgroup } from "@domain/entities";
import { SelectItem } from "@ui/interfaces";
import { ValidationComposite } from "@presentation/validation/validators";
import { FieldValidationBuilder } from "@presentation/validation/builders";

export interface IncreaseSubgroupParams {
  cashflowLevelKey: number;
  transactionTypeLevelKey: number;
  groupLevelKey: number;
}

export interface IncreaseSubgroupSubmitData extends IncreaseSubgroupParams {
  subgroupId: number;
}

interface IncreaseSubgroupProps {
  t?: any;
  open: IncreaseSubgroupParams | null;
  onDismiss(): void;
  onSubmit(data: IncreaseSubgroupSubmitData): void;
  subgroupList: Subgroup[];
}

interface Form {
  subgroupId: number;
}

const INITIAL_FORM: Form = { subgroupId: 0 };

const validation = new ValidationComposite([
  ...FieldValidationBuilder.field("subgroupId").required().build(),
]);

const IncreaseSubgroup: React.FC<IncreaseSubgroupProps> = ({
  open,
  onDismiss,
  onSubmit,
  subgroupList,
  t,
}) => {
  const [payload, setPayload] = useState<Form>(INITIAL_FORM);

  const {
    ValidationFeedback,
    validationState,
    ValidationSelect,
    updateValidationState,
  } = useValidation();

  const updateField = (fieldName: string, value: string) => {
    setPayload((oldState) => ({
      ...oldState,
      [fieldName]: value,
    }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (open) {
      updateValidationState([]);

      if (validation.allValid(payload)) {
        onSubmit({
          cashflowLevelKey: open.cashflowLevelKey,
          subgroupId: Number(payload.subgroupId),
          groupLevelKey: open.groupLevelKey,
          transactionTypeLevelKey: open.transactionTypeLevelKey,
        });
      } else {
        updateValidationState(validation.errors(payload));
      }
    }
  };

  useEffect(() => {
    if (!open) {
      setPayload(INITIAL_FORM);
    }
  }, [open]);

  const optionsSubgroups: SelectItem[] = subgroupList.map(({ id, name }) => ({
    label: name,
    value: id,
  }));

  return (
    <Modal
      isOpen={Boolean(open)}
      toggle={onDismiss}
      scrollable={true}
      id="staticBackdrop"
      size="lg"
    >
      <div className="modal-header">
        <h5 className="modal-title">{t("planning.increase.group")}</h5>
        <button
          type="button"
          className="btn-close"
          onClick={onDismiss}
          aria-label="Close"
        />
      </div>

      <form onSubmit={handleSubmit}>
        <div className="modal-body">
          <Row>
            <Col md={12} className="mb-2">
              <FormGroup>
                <Label>{t("Description")}</Label>
                <ValidationSelect
                  field="subgroupId"
                  name="subgroupId"
                  className="form-control"
                  placeholder={t("Select Description")}
                  options={optionsSubgroups}
                  validationState={validationState}
                  value={payload.subgroupId}
                  onChange={({ target: { value, name } }) =>
                    updateField(name, value)
                  }
                />

                <ValidationFeedback field="subgroupId" />
              </FormGroup>
            </Col>
          </Row>
        </div>

        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-danger me-2"
            onClick={onDismiss}
          >
            {t("Close")}
          </button>

          <button className="btn btn-primary">{t("Include")}</button>
        </div>
      </form>
    </Modal>
  );
};

export default withTranslation()(IncreaseSubgroup);
