import {
  Prefecture,
  PlanningItem,
  Group,
  Subgroup,
  TransactionType,
  CashFlow,
} from "@domain/entities";

import { GetCashflowsService } from "@domain/services/cashflows";

import { GetGroupsService } from "@domain/services/groups";

import {
  GetPlanningService,
  MountPlanningService,
} from "@domain/services/planning";

import { GetPrefectureService } from "@domain/services/prefecture";
import { GetSubgroupsService } from "@domain/services/revenues-expenses";
import { GetTransactionsTypesService } from "@domain/services/transactionsTypes";
import { PlanningViewContract } from "@presentation/views/planning";
import { PlanningPresenterContract } from "@ui/presenters/planning";

export class PlanningPresenter implements PlanningPresenterContract {
  private view!: PlanningViewContract;

  constructor(
    private readonly getPrefecturesService: GetPrefectureService,
    private readonly getPlanningService: GetPlanningService,
    private readonly getGroupsService: GetGroupsService,
    private readonly getSubgroupsService: GetSubgroupsService,
    private readonly getTransactionsTypesService: GetTransactionsTypesService,
    private readonly getCashflowsService: GetCashflowsService,
    private readonly mountPlanningService: MountPlanningService
  ) {}

  async getCashflows(): Promise<CashFlow[]> {
    const cashflows = await this.getCashflowsService.handle();

    this.view.setCashflows(cashflows);

    return cashflows;
  }

  async getTransactionTypes(): Promise<TransactionType[]> {
    const transactionTypes = await this.getTransactionsTypesService.handle();

    this.view.setTransactionTypes(transactionTypes);

    return transactionTypes;
  }

  async getGroups(): Promise<Group[]> {
    const groups = await this.getGroupsService.handle();

    this.view.setGroups(groups);

    return groups;
  }

  async getSubgroups(): Promise<Subgroup[]> {
    const subgroups = await this.getSubgroupsService.handle();

    this.view.setSubgroups(subgroups);

    return subgroups;
  }

  setView(view: PlanningViewContract): void {
    this.view = view;
  }

  async getPrefectures(): Promise<Prefecture[]> {
    const prefectures = await this.getPrefecturesService.handle();

    this.view.setPrefectures(prefectures);

    return prefectures;
  }

  async getPrefecturePlanning(prefectureId: number): Promise<PlanningItem[]> {
    this.view.setLoadingState(true);
    const planning = await this.getPlanningService.handle(prefectureId);

    this.view.setPrefecturePlanning(planning);
    this.view.setLoadingState(false);

    return planning;
  }

  async mountPlanning(
    data: MountPlanningService.MountPlanningData
  ): Promise<void> {
    this.view.setLoadingState(true);

    await this.mountPlanningService.handle(data);
    await this.getPrefecturePlanning(data.prefectureId);
  }
}
