import { HttpRequestTypes } from "@data/enums";

type ResponseType =
  | 'arraybuffer'
  | 'blob'
  | 'document'
  | 'json'
  | 'text'
  | 'stream';
interface HttpRequest {
  type: HttpRequestTypes;
  data?: any;
  url: string;
  params?: any;
  responseType?: ResponseType;
}

export class HttpRequestBuilder {
  private url: string;
  private method!: HttpRequestTypes;
  private data: any;
  private params: any = {};
  private responseType!: ResponseType;


  private constructor(url: string) {
    this.url = url;
  }

  public static requestToUrl(url: string): HttpRequestBuilder {
    return new HttpRequestBuilder(url);
  }

  public withMethod(method: HttpRequestTypes): HttpRequestBuilder {
    this.method = method;
    return this;
  }

  public withData<Params = any>(data: Params): HttpRequestBuilder {
    this.data = data;
    return this;
  }

  public withParams<Params = any>(params: Params): HttpRequestBuilder {
    this.params = params;
    return this;
  }

  public withResponseType(responseType: ResponseType): HttpRequestBuilder {
    this.responseType = responseType;
    return this;
  }

  public build() {
    const httpRequest: HttpRequest = {
      url: this.url,
      type: this.method,
      data: this.data,
      params: this.params
    };

    if (this.responseType) {
      httpRequest.responseType = this.responseType;
    }

    return httpRequest;
  }
}
