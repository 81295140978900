import { useMemo } from "react";

import { makeTreasuryPresenter } from "@main/factories/presenters/treasury";
import { TreasuryView } from "@ui/views/treasury";

const Treasury: React.FC = () => {
  const treasuryPresenter = useMemo(() => makeTreasuryPresenter(), []);

  return <TreasuryView treasuryPresenter={treasuryPresenter} />;
};

export default Treasury;
