import { SendEmailForgotPasswordService } from "@domain/services/auth";
import { FormValidation } from "@presentation/validation/interfaces";
import { ForgotPasswordViewContract } from "@presentation/views";
import { ForgotPasswordPresenterContract } from "@ui/presenters";

export class ForgotPasswordPresenter implements ForgotPasswordPresenterContract {
  private view!: ForgotPasswordViewContract;

  constructor(
    private readonly validation: FormValidation,
    private readonly sendEmailForgotPasswordService: SendEmailForgotPasswordService
  ) { }

  setView(view: ForgotPasswordViewContract): void {
    this.view = view;
  }

  async sendEmail(payload: ForgotPasswordPresenterContract.Payload): Promise<void> {
    this.view.setLoadingState(true);

    const validationSuccessfull = this.validation.allValid(payload);

    if (!validationSuccessfull) {
      this.view.updateValidationState(this.validation.errors(payload));
      this.view.setLoadingState(false);
      return;
    }

    await this.sendEmailForgotPasswordService.handle(payload);

    this.view.updateValidationState([]);
    this.view.setLoadingState(false);
  }
}
