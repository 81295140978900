import { AccountAdjustmentEvents } from "@domain/events";
import { AccountAdjustmentService } from "@domain/services/account";
import { FormValidation } from "@presentation/validation/interfaces";
import { AccountAdjustmentViewContract } from "@presentation/views/account";
import { AccountAdjustmentPresenterContract } from "@ui/presenters/account";
import { EventEmitter } from "@utils/events";

export class AccountAdjustmentPresenter implements AccountAdjustmentPresenterContract {
    private view!: AccountAdjustmentViewContract;

    constructor(
        private readonly validation: FormValidation,
        private readonly accountAdjustmentService: AccountAdjustmentService
    ) { }

    async add(payload: AccountAdjustmentPresenterContract.Payload): Promise<boolean> {
        this.view.setLoadingState(true);

        const validationSuccessfull = this.validation.allValid(payload);

        if (!validationSuccessfull) {
            this.view.updateValidationState(this.validation.errors(payload));
            this.view.setLoadingState(false);
            return false;
        }

        const isAdjustmentAdded = await this.accountAdjustmentService.handle(payload);

        if (isAdjustmentAdded) {
            EventEmitter.emit(AccountAdjustmentEvents.ADJUSTMENT_ADDED);
        }

        this.view.updateValidationState([]);
        this.view.setLoadingState(false);

        return isAdjustmentAdded;
    }

    setView(view: AccountAdjustmentViewContract): void {
        this.view = view;
    }
}
