import { useMemo } from "react";

import { makePlanningPresenter } from "@main/factories/presenters/planning";

import PlanningView from "@ui/views/planning/PlanningView";

const Planning: React.FC = () => {
  const presenter = useMemo(() => makePlanningPresenter(), []);

  return <PlanningView planningPresenter={presenter} />;
};

export default Planning;
