import { HttpRequestBuilder } from "@data/builders";
import { HttpRequestTypes } from "@data/enums";
import { HttpRepository } from "@data/repositories";
import { SetHourTreasuryService } from "@domain/services/treasury";

export class HttpSetHourTreasuryService implements SetHourTreasuryService {
  constructor(private readonly httpRepository: HttpRepository) {}

  async handle(payload: SetHourTreasuryService.Payload): Promise<void> {
    const request = HttpRequestBuilder.requestToUrl("/treasury/hour")
      .withMethod(HttpRequestTypes.POST)
      .withData(payload)
      .build();

    await this.httpRepository.makeRequest(request);
  }
}
