import React, { useMemo } from "react";
import UpdateUserPasswordView from "@ui/views/users/UpdateUserPasswordView";
import { makeUpdateUserPasswordPresenter } from "@main/factories/presenters/users";
import { UpdateUserPasswordPresenterContract } from "@ui/presenters/users";

interface UpdateUserPasswordProps {
  user?: UpdateUserPasswordPresenterContract.Payload | null;
  onDismiss(): void;
}

const UpdateUserPassword: React.FC<UpdateUserPasswordProps> = (props) => {
  const updateUserPasswordPresenter = useMemo(() => makeUpdateUserPasswordPresenter(), []);

  return <UpdateUserPasswordView {...props} updateUserPasswordPresenter={updateUserPasswordPresenter} />;
};

export default UpdateUserPassword;
