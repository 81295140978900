import { HttpRequestBuilder } from "@data/builders";
import { HttpRequestTypes } from "@data/enums";
import { HttpRepository } from "@data/repositories";
import { GroupedPlanning } from "@domain/entities";
import { GetGroupedPlanningService } from "@domain/services/planning";

export class HttpGetGroupedPlanningService implements GetGroupedPlanningService {
    constructor(private readonly httpRepository: HttpRepository) {}

    async handle(): Promise<GroupedPlanning[]> {
        const request = HttpRequestBuilder.requestToUrl("planning/grouped")
      .withMethod(HttpRequestTypes.GET)
      .build();

    const { data } = await this.httpRepository.makeRequest<any, GroupedPlanning[]>(
      request
    );

    return data;
    }
}
