import { HttpGetCashflowsService } from "@data/services/cashflows";
import { HttpGetAccountGroupsService } from "@data/services/account/HttpGetAccountGroupsService";
import { HttpEditSourcesService } from "@data/services/sources";
import { AxiosHttpRepository } from "@infra/http";
import { API_BASE_URL } from "@main/constants/config";
import { EditSourcePresenter } from "@presentation/presenters/sources";
import { FieldValidationBuilder } from "@presentation/validation/builders";
import { ValidationComposite } from "@presentation/validation/validators";
import { EditSourcePresenterContract } from "@ui/presenters/sources";

export const makeEditSourcesPresenter = (): EditSourcePresenterContract => {
  const validation = new ValidationComposite([
    ...FieldValidationBuilder.field("id").required().build(),
    ...FieldValidationBuilder.field("name").required().build(),
    ...FieldValidationBuilder.field("code").required().build(),
    ...FieldValidationBuilder.field("cashflow").required().build(),
    ...FieldValidationBuilder.field("group").required().build(),
  ]);

  const httpRepository = new AxiosHttpRepository(API_BASE_URL);
  const addSourcesService = new HttpEditSourcesService(httpRepository);
  const getCashflowsService = new HttpGetCashflowsService(httpRepository);
  const getGroupsService = new HttpGetAccountGroupsService(httpRepository);

  return new EditSourcePresenter(
    validation,
    addSourcesService,
    getCashflowsService,
    getGroupsService
  );
};
