import { LoginPresenter } from "@presentation/presenters";
import { LoginPresenterContract } from "@ui/presenters";
import { HttpLoginService } from "@data/services";
import { AxiosHttpRepository } from "@infra/http";
import { LocalStorageCacheRepository } from "@infra/cache";
import { HistoryNavigateRepository } from "@infra/navigate";
import { ValidationComposite } from "@presentation/validation/validators";
import { FieldValidationBuilder } from "@presentation/validation/builders";
import { API_BASE_URL } from "@main/constants/config";

export const makeLoginPresenter = (): LoginPresenterContract => {
  const httpRepository = new AxiosHttpRepository(API_BASE_URL);
  const cacheRepository = new LocalStorageCacheRepository();
  const navigateRepository = new HistoryNavigateRepository();
  const loginService = new HttpLoginService(httpRepository, cacheRepository, navigateRepository);

  const validation = new ValidationComposite([
    ...FieldValidationBuilder.field('email').required().build(),
    ...FieldValidationBuilder.field('password').required().build(),
  ]);

  return new LoginPresenter(loginService, validation);
};
