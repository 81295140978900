import React, { useEffect, useState } from "react";

import Loading from "@ui/components/Loading";

import {
    Container,
    Table,
    Row,
    Col
} from "reactstrap";

import { withTranslation } from "react-i18next";
import { EditPrefecturePresenterContract, PrefecturePresenterContract } from "@ui/presenters/prefecture";
import { Breadcrumbs } from "@ui/components/Common";
import { Prefecture } from "@domain/entities";
import { EventEmitter } from "@utils/events";
import { PrefectureEvents } from "@domain/events";
import { AddPrefecture, EditPrefecture } from "@main/pages/prefecture";
import AddActionButton from "@ui/components/AddActionButton";
import EditActionButton from "@ui/components/EditActionButton";
import RemoveActionButton from "@ui/components/RemoveActionButton";

interface PrefectureViewProps {
    t?: any;
    prefecturePresenter: PrefecturePresenterContract;
}

const PrefectureView: React.FC<PrefectureViewProps> = ({ t, prefecturePresenter }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [prefecture, setPrefectureList] = useState<Prefecture[]>([]);
    const [isAddingPrefecture, setIsAddingPrefecture] = useState<boolean>(false);
    const [prefectureToEdit, setPrefectureToEdit] = useState<EditPrefecturePresenterContract.Payload | null>(null);

    useEffect(() => {
        prefecturePresenter.setView({ setPrefectures, setLoadingState });
        prefecturePresenter.get();

        EventEmitter.subscribe(PrefectureEvents.PREFECTURE_ADDED, onPrefectureAdded);
        EventEmitter.subscribe(PrefectureEvents.PREFECTURE_EDITED, onPrefectureEdited);

        return () => {
            EventEmitter.unsubscribe(PrefectureEvents.PREFECTURE_ADDED, onPrefectureAdded);
            EventEmitter.unsubscribe(PrefectureEvents.PREFECTURE_EDITED, onPrefectureEdited);

        };
    }, []);

    const onPrefectureAdded = () => {
        setIsAddingPrefecture(false);
        prefecturePresenter.get();
    };

    const onPrefectureEdited = () => {
        setPrefectureToEdit(null);
        prefecturePresenter.get();
    };


    const setPrefectures = (prefecture: Prefecture[]): void => setPrefectureList(prefecture);
    const setLoadingState = (isLoading: boolean): void => setLoading(isLoading);

    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs breadcrumbItem={t("Prefectures")} title={t("Registrations")} />
                <Row>
                    {loading
                        ? (
                            <Col xl={12} className="mt-4">
                                <Loading />
                            </Col>
                        ) : (
                            <Col xl={12} className="mt-4">
                                <div className="table-responsive">
                                    <div className="d-sm-flex align-items-center justify-content-end">
                                        <AddActionButton
                                            type="button"
                                            className="btn btn-primary mb-3 ms-1"
                                            onClick={() => setIsAddingPrefecture(true)}
                                        />
                                    </div>

                                    <Table className="align-middle mb-4" bordered>
                                        <thead>
                                            <tr>
                                                <th style={{ width: '40%' }}>{t("City")}</th>
                                                <th style={{ width: '40%' }}>{t("State")}</th>
                                                <th style={{ width: '20%' }} className="text-center">{t("Actions")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {prefecture.map(({ id, cityName, stateName, cityCode }, key) => (
                                                <tr key={key}>
                                                    <td scope="row">{cityName}</td>
                                                    <td scope="row">{stateName}</td>
                                                    <td className="text-center">
                                                        <EditActionButton
                                                            onClick={() => setPrefectureToEdit({
                                                                id: id,
                                                                cityName: cityName,
                                                                stateName: stateName,
                                                                cityCode: cityCode
                                                            })}
                                                            isLastOfList={false}
                                                            label={t("Edit")}
                                                        />

                                                        <RemoveActionButton
                                                            onClick={() => prefecturePresenter.removePrefecture(id)}
                                                            isLastOfList
                                                            label={t("Remove")}
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            </Col>
                        )
                    }
                </Row>
            </Container>

            <AddPrefecture
                isOpen={isAddingPrefecture}
                onDismiss={() => setIsAddingPrefecture(false)}
            />

            <EditPrefecture
                prefectureToEdit={prefectureToEdit}
                onDismiss={() => setPrefectureToEdit(null)}
            />
        </div>
    );
};

export default withTranslation()(PrefectureView);
