import React, { useMemo } from "react";

import { AccountAdjustmentView } from "@ui/views/account";
import { makeAccountAdjustmentPresenter } from "@main/factories/presenters/accounts";
import { AccountAdjustmentPresenterContract } from "@ui/presenters/account";

interface AccountAdjustmentProps {
    onDismiss(): void;
    accountToAdjust: AccountAdjustmentPresenterContract.Payload | null;
}

export const AccountAdjustment: React.FC<AccountAdjustmentProps> = (props) => {
    const accountPresenter = useMemo(() => makeAccountAdjustmentPresenter(), []);

    return <AccountAdjustmentView {...props} addAccountAdjustmentPresenter={accountPresenter} />;
};
