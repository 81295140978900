import React, { Fragment } from "react";

import { Table } from "reactstrap";

import { getMonthsFromPeriod } from "@ui/utils/reports";
import { ConsolidatedReportTransaction, Predictions, Subgroup } from "@domain/entities";
import { MoneyFormatStrategy } from "@ui/strategy";
import { withTranslation } from "react-i18next";
import { DateStringToISO } from "@ui/utils";
import { MonthAndDayFormatStrategy } from "@ui/strategy/MonthAndDayFormatStrategy";

interface QuartelyConsolidatedTableProps {
  t: any;
  label: string;
  beginOfPeriod: Date;
  endOfPeriod: Date;
  subgroups: any[] ;
  predictions: Predictions[];
  transactions: ConsolidatedReportTransaction[];
}

const QuarterlyConsolidatedTable: React.FC<QuartelyConsolidatedTableProps> = ({
  t,
  label,
  subgroups,
  beginOfPeriod,
  endOfPeriod,
  predictions,
  transactions
}) => {
  const { months } = getMonthsFromPeriod(beginOfPeriod, endOfPeriod);

  const getPredictionFromMonthAndSubgroup = (
    month: string,
    year: string,
    subgroup: any
  ) => {
    const total = predictions
      .filter(({ mes, ano, transacoes_eventos_id }) => {
        const periodCondition = mes == parseInt(month) && ano == parseInt(year);

        return !subgroup
          ? periodCondition
          : periodCondition && transacoes_eventos_id == subgroup;
      })
      .reduce((prev, current) => prev + parseFloat(current.total), 0);

    return MoneyFormatStrategy.format(total);
  };

  const getTransactionFromMonthAndSubgroup = (
    month: string,
    year: string,
    subgroup: any
  ) => {
    const total = transactions
      .filter(({ mes, ano, transacoes_eventos_id }) => {
        const periodCondition = mes == parseInt(month) && ano == parseInt(year);

        return !subgroup
          ? periodCondition
          : periodCondition && transacoes_eventos_id == subgroup;
      })
      .reduce((prev, current) => {
        return prev + parseFloat(current.total);
      }, 0);

    return MoneyFormatStrategy.format(total);
  };

  return (
    <div className="table-responsive font-arial-rlt">
      <Table bordered className="mb-5">
        <tbody>
          <tr>
            <th style={{ width: "25%" }}>{label}</th>

            {months.map(({ value }) => (
              <Fragment key={value}>
                <th className="text-center">
                  {t("Foreseen") +
                    " - " +
                    `${MonthAndDayFormatStrategy.format(
                      DateStringToISO.toISO(value)
                    )}`}
                </th>
                <th className="text-center">
                  {t("Realized") +
                    " - " +
                    `${MonthAndDayFormatStrategy.format(
                      DateStringToISO.toISO(value)
                    )}`}
                </th>
              </Fragment>
            ))}
          </tr>

          {subgroups.map(({ id, name }) => (
            <tr key={id}>
              <td>{name}</td>
              {months.map(({ value, month, year }) => (
                <Fragment key={value}>
                  <td className="text-center">
                    {getPredictionFromMonthAndSubgroup(month, year, id)}
                  </td>
                  <td className="text-center">
                    {getTransactionFromMonthAndSubgroup(month, year, id)}
                  </td>
                </Fragment>
              ))}
            </tr>
          ))}

          <tr style={{ backgroundColor: "#f1f1f1" }}>
            <th style={{ width: "25%" }}>Total</th>

            {months.map(({ value, month, year, id }) => (
              <Fragment key={value}>
                <th className="text-center">
                  {getPredictionFromMonthAndSubgroup(month, year, id)}
                </th>
                <th className="text-center">
                  {getTransactionFromMonthAndSubgroup(month, year, id)}
                </th>
              </Fragment>
            ))}
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default withTranslation()(QuarterlyConsolidatedTable);
