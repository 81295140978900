import { SubgroupsEvents } from "@domain/events";
import { EditSubgroupService } from "@domain/services/revenues-expenses";
import { FormValidation } from "@presentation/validation/interfaces";

import { EditSubgroupViewContract } from "@presentation/views/revenues-expenses";

import { EditSubgroupPresenterContract } from "@ui/presenters/revenues-expenses";
import { EventEmitter } from "@utils/events";

export class EditSubgroupPresenter implements EditSubgroupPresenterContract {
  private view!: EditSubgroupViewContract;

  constructor(
    private readonly validation: FormValidation,
    private readonly editSubgroupService: EditSubgroupService
  ) { }

  setView(view: EditSubgroupViewContract): void {
    this.view = view;
    this.view.openSimilarityFeedback(false);
  }

  async edit(payload: EditSubgroupPresenterContract.Payload): Promise<boolean> {
    this.view.setLoading(true);

    const validationSuccessfull = this.validation.allValid(payload);

    if (!validationSuccessfull) {
      this.view.updateValidationState(this.validation.errors(payload));
      this.view.setLoading(false);
      return false;
    }

    const { ok: isSubgroupEdited, errors } = await this.editSubgroupService.handle(payload);

    if (isSubgroupEdited) {
      EventEmitter.emit(SubgroupsEvents.SUBGROUP_UPDATED);
    } else {
      this.view.setSimilarity(errors);
      this.view.openSimilarityFeedback(true);
    }

    this.view.setLoading(false);
    this.view.updateValidationState([]);
    return true;
  }
}
