import { HttpRequestBuilder } from "@data/builders";
import { HttpRequestTypes } from "@data/enums";
import { HttpRepository } from "@data/repositories";
import { Bank } from "@domain/entities/Bank";
import { GetBankService } from "@domain/services/account/GetBankService";

export class HttpGetBankService implements GetBankService {
   constructor(
     private readonly httpRepository: HttpRepository
   ) { }
 
   async handle(): Promise<Bank[]> {
     const request = HttpRequestBuilder
       .requestToUrl('/bank')
       .withMethod(HttpRequestTypes.GET)
       .build();
 
     const { data } = await this.httpRepository.makeRequest<any, Bank[]>(request);
 
     return data;
    return [];
   }
 }
