import React, { Fragment, useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";

import { Container, Table, Row, Button } from "reactstrap";
import ComponentToPrint from "@ui/components/Print";
import { useReactToPrint } from "react-to-print";

import { LatestUpdatePresenterContract } from "@ui/presenters/reports";
import { UpdateInterface } from "@domain/entities";
import { Breadcrumbs } from "@ui/components/Common";
import Loading from "@ui/components/Loading";
import { DateAndHourFormatStrategy } from "@ui/strategy/DateAndHoursFormatStrategy";

interface LatestUpdateViewProps {
  t?: any;
  latestUpdatePresenter: LatestUpdatePresenterContract;
}

const LatestUpdateView: React.FC<LatestUpdateViewProps> = ({
  t,
  latestUpdatePresenter,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [latestUpdate, setLatestUpdateState] = useState<UpdateInterface[]>([]);

  useEffect(() => {
    latestUpdatePresenter.setView({ setLoadingState, setLatestUpdate });
    latestUpdatePresenter.get();
  }, []);

  const printRef = useRef(null);

  useEffect(() => {
    latestUpdatePresenter.get();
  }, []);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `Saldo de Contas`,
    onPrintError: () => false,
    pageStyle: `
            .noPrint {
                display:none;
            }
        `,
  });

  const setLoadingState = (isLoading: boolean): void => setLoading(isLoading);
  const setLatestUpdate = (latestUpdate: UpdateInterface[]): void =>
    setLatestUpdateState(latestUpdate);

  const cityIdList = Array.from(
    new Set(latestUpdate.map(({ cidade_id }) => cidade_id))
  );
  const agroupedCities = cityIdList.map((cityId) => {
    const cityName = latestUpdate.find(
      ({ cidade_id }) => cidade_id === cityId
    )?.cidade;

    return { cidade_id: cityId, cidade: cityName };
  });

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs
          title={t("Updates")}
          breadcrumbItem={t("Latest Updates")}
        />

        <div className="d-sm-flex align-items-center justify-content-end">
          <Button
            color="primary"
            className="btn btn-primary float-right"
            onClick={handlePrint}
          >
            {t("Print")}
          </Button>
        </div>
        <br />

        <Row>
          {loading ? (
            <div className="mt-5">
              <Loading />
            </div>
          ) : (
            <Fragment>
              <ComponentToPrint ref={printRef}>
               <div>
                {agroupedCities.map(({ cidade, cidade_id }, key) => (
                  <div className="table-responsive" key={key}>
                    <Table bordered>
                      <tbody>
                        <tr>
                          <th className="text-center" colSpan={7}>
                            {cidade}
                          </th>
                        </tr>
                        <tr>
                          <th className="text-cennter">{t("Cashflow")}</th>
                          <th className="text-cennter">{t("Responsible")}</th>
                          <th className="text-cennter">{t("Updated in")}</th>
                        </tr>
                        {latestUpdate
                          .filter((update) => update.cidade_id === cidade_id)
                          .map(({ fluxo, user_email, last_update }, key) => (
                            <tr key={key}>
                              <td>{fluxo}</td>
                              <td>{user_email}</td>
                              <td>
                                {DateAndHourFormatStrategy.format(last_update)}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                ))}
              </div>
              </ComponentToPrint>
            </Fragment>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default withTranslation()(LatestUpdateView);
