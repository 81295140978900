import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Modal, Row, Col, Label, FormGroup } from "reactstrap";

import { useValidation } from "@ui/hooks";
import { EditAccountGroupPresenterContract } from "@ui/presenters/account";

interface EditAccountGroupViewProps {
  t?: any;
  groupToEdit: EditAccountGroupPresenterContract.Payload | null;
  onDismiss(): void;
  editAccountGroupPresenter: EditAccountGroupPresenterContract;
}

const INITIAL_FORM_STATE: EditAccountGroupPresenterContract.Payload = {
  id: "",
  name: ''
};

const EditAccountGroupView: React.FC<EditAccountGroupViewProps> = ({ editAccountGroupPresenter, groupToEdit, onDismiss, t }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [payload, setPayload] = useState<EditAccountGroupPresenterContract.Payload>(INITIAL_FORM_STATE);

  const { ValidationFeedback, ValidationInput, updateValidationState, validationState } = useValidation();

  useEffect(() => {
    editAccountGroupPresenter.setView({ setLoading, updateValidationState });
  }, []);

  useEffect(() => {
    if (!groupToEdit) {
      updateValidationState([]);
      setPayload(INITIAL_FORM_STATE);
    } else {
      setPayload(groupToEdit);
    }
  }, [groupToEdit]);

  const setLoading = (isLoading: boolean): void => setIsLoading(isLoading);

  const updateField = (fieldName: string, value: string) => {
    setPayload((oldState) => ({
      ...oldState,
      [fieldName]: value
    }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    editAccountGroupPresenter.edit(payload);
  };

  return (
    <Modal
      isOpen={Boolean(groupToEdit)}
      toggle={isLoading ? undefined : onDismiss}
      scrollable={true}
      id="staticBackdrop"
      size="lg"
    >
      <div className="modal-header">
        <h5 className="modal-title">{t("Add Account Group")}</h5>
        <button
          type="button"
          className="btn-close"
          onClick={isLoading ? undefined : onDismiss}
          aria-label="Close"
        />
      </div>

      <form onSubmit={handleSubmit}>
        <div className="modal-body">
          <Row>
            <Col md={12} className="mb-2">
              <FormGroup>
                <Label>{t("Account group")}</Label>
                <ValidationInput
                  field="name"
                  validationState={validationState}
                  name="name"
                  className="form-control"
                  placeholder={t("Account group")}
                  value={payload.name}
                  onChange={({ target: { value, name } }) => updateField(name, value)}
                />

                <ValidationFeedback field="name" />
              </FormGroup>
            </Col>
          </Row>
        </div>

        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-danger me-2"
            onClick={isLoading ? undefined : onDismiss}
            disabled={isLoading}
          >
            {t("Close")}
          </button>

          <button className="btn btn-primary" disabled={isLoading}>
            {t(isLoading ? "loading" : "Save")}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default withTranslation()(EditAccountGroupView);
