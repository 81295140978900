import React, { useMemo } from "react";
import { AddUserView } from "@ui/views/users";
import { makeAddUserPresenter } from "@main/factories/presenters/users";

interface AddUserProps {
  isOpen: boolean;
  onDismiss(): void;
}

const AddUser: React.FC<AddUserProps> = (props) => {
  const addUserPresenter = useMemo(() => makeAddUserPresenter(), []);

  return <AddUserView {...props} addUserPresenter={addUserPresenter} />;
};

export default AddUser;
