import { HttpRequestBuilder } from "@data/builders";
import { HttpRequestTypes } from "@data/enums";
import { HttpRepository } from "@data/repositories";
import { AddNonEffectiveBillsService } from "@domain/services/bills";

export class HttpAddNonEffectiveBillsService implements AddNonEffectiveBillsService {
    constructor(
      private readonly httpRepository: HttpRepository
    ) { }
  
    async handle(payload: AddNonEffectiveBillsService.Payload): Promise<boolean> {
      const httpRequest = HttpRequestBuilder.requestToUrl('/bills/noneffective')
        .withMethod(HttpRequestTypes.POST)
        .withData(payload)
        .build();
  
      const { status } = await this.httpRepository.makeRequest<AddNonEffectiveBillsService.Payload>(httpRequest);
  
      return status === 201;
    }
  }
