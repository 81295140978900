import { BillsEvents } from "@domain/events/BillsEvents";
import { GetEffectiveBillsService, RemoveEffectiveBillsService } from "@domain/services/bills";
import { EffectiveBillsViewContract } from "@presentation/views/bills";
import { EffectiveBillsPresenterContract } from "@ui/presenters/bills";
import { EventEmitter } from "@utils/events/EventEmitter";

export class EffectiveBillsPresenter implements EffectiveBillsPresenterContract {
    private view!: EffectiveBillsViewContract;

    constructor(
        private readonly getEffectiveBillsService: GetEffectiveBillsService,
        private readonly removeEffectiveBillsService: RemoveEffectiveBillsService
    ) { }

    async get(params: EffectiveBillsPresenterContract.Params): Promise<EffectiveBillsPresenterContract.Response[]> {
        this.view.setLoadingState(true);

        const bills = await this.getEffectiveBillsService.handle(params);

        this.view.setParams(params);
        this.view.setResponse(bills);
        this.view.setLoadingState(false);
        return bills;
    }

    setView(view: EffectiveBillsViewContract): void {
        this.view = view;
    }

    async removeBills(billsId: number): Promise<void> {
        this.view.setLoadingState(true);

        const isRemoved = await this.removeEffectiveBillsService.handle(billsId);

        if (isRemoved) {
            EventEmitter.emit(BillsEvents.BILLS_REMOVED);
        }
    }
}
