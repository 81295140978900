import { HttpRequestBuilder } from "@data/builders";
import { HttpRequestTypes } from "@data/enums";
import { HttpRepository } from "@data/repositories";
import { GetEffectiveBillsService } from "@domain/services/bills";

export class HttpGetEffectiveBillsService implements GetEffectiveBillsService {
    constructor(
        private readonly httpRepository: HttpRepository
    ) { }

    async handle(params: GetEffectiveBillsService.Params): Promise<GetEffectiveBillsService.Response[]> {
        const request = HttpRequestBuilder
            .requestToUrl('/bills/effective')
            .withMethod(HttpRequestTypes.GET)
            .withParams({
                start: params.start,
                end: params.end,
                transactionType: params.transactionType
            })
            .build();

        const { data } = await this.httpRepository.makeRequest<any, GetEffectiveBillsService.Response[]>(request);

        return data;
    }
}
