import { HttpRequestBuilder } from "@data/builders";
import { HttpRequestTypes } from "@data/enums";
import { HttpRepository } from "@data/repositories";
import { EditPrefectureService } from "@domain/services/prefecture";

export class HttpEditPrefectureService implements EditPrefectureService {
    constructor(
      private readonly httpRepository: HttpRepository
    ) { }
  
    async handle(payload: EditPrefectureService.Payload): Promise<boolean> {
      const httpRequest = HttpRequestBuilder.requestToUrl('/prefecture')
        .withMethod(HttpRequestTypes.PUT)
        .withData(payload)
        .build();
  
      const { status } = await this.httpRepository.makeRequest<EditPrefectureService>(httpRequest);
  
      return status === 200;
    }
  }
