import React, { useMemo } from "react";

import AddNonEffectiveBillsView from "@ui/views/bills/AddNonEffectiveBillsView";

import { makeAddNonEffectiveBillsPresenter } from "@main/factories/presenters/bills";

interface AddNonEffectiveBillsProps {
   isOpen: boolean;
   type: string;
   onDismiss(): void;
}

const AddNonEffectiveBills: React.FC<AddNonEffectiveBillsProps> = (props) => {
   const addNonEffectiveBillsPresenter = useMemo(() => makeAddNonEffectiveBillsPresenter(), []);

   return <AddNonEffectiveBillsView { ...props } addNonEffectiveBillsPresenter={addNonEffectiveBillsPresenter} />;
};

export default AddNonEffectiveBills;

