import { HttpRequestBuilder } from "@data/builders";
import { HttpRequestTypes } from "@data/enums";
import { HttpRepository } from "@data/repositories";
import { CashflowsTransaction, Foressen, Transaction } from "@domain/entities";
import { GetFlowsService } from "@domain/services/reports";
import { CashflowsPresenterContract } from "@ui/presenters/reports";

interface FlowRequest {
  tipo: string;
  tipos_transacoes_id: number;
  transacoes_eventos_id: number;
  valor: number;
  data: string;
  observacao: string;
  tipos_fluxos_id: number;
  fornecedor_id: number;
  grupo: number;
  evento: number;
  conta: number;
  dataInicio: string;
  dataFim: string;
}

interface HttpGetFlowsServiceResponse {
  dadosTransacoes: CashflowsTransaction[];
  previsto: Foressen[];
  transacoes: Transaction[];
}

export class HttpGetFlowsService implements GetFlowsService {
  constructor(private readonly httpRepository: HttpRepository) {}

  async handle(
    params: CashflowsPresenterContract.Params
  ): Promise<CashflowsPresenterContract.Response> {
    // const request = HttpRequestBuilder.requestToUrl(
    //   "api/auth/transacoesPorData"
    // )
    //   .withMethod(HttpRequestTypes.GET)
    //   .withParams<FlowRequest>({
    //     tipo: params.cashFlow,
    //     dataInicio: params.beginOfPeriod,
    //     dataFim: params.endOfPeriod,
    //     evento: params.subgroup,
    //     fornecedor_id: params.providerId,
    //     valor: params.value,
    //     tipos_transacoes_id: params.transactionsType,
    //     transacoes_eventos_id: params.transactionsEvents,
    //     data: params.date,
    //     observacao: params.observation,
    //     tipos_fluxos_id: params.flowsType,
    //     conta: params.accountId,
    //     grupo: params.groups
    //   })
    //   .build();

    // const { data } = await this.httpRepository.makeRequest<
    //   FlowRequest,
    //   HttpGetFlowsServiceResponse
    // >(request);

    // return {
    //   foressen: data.previsto,
    //   transaction: data.transacoes,
    //   transactionsData: data.dadosTransacoes,
    // };
    return {
      foressen: [],
      transaction: [],
      transactionsData: []
    };
  }
}
