import React, { Fragment, useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";

import {
  Container,
  Row,
  Col,
  TabContent,
  Table,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";

import ComponentToPrint from "@ui/components/Print";
import { useReactToPrint } from "react-to-print";

import { Breadcrumbs } from "@ui/components/Common";
import Loading from "@ui/components/Loading";
import { BankAccountPresenterContract } from "@ui/presenters/account/BankAccountPresenterContract";
import { AddAccount, AccountAdjustment } from "@main/pages/accounts";
import { EventEmitter } from "@utils/events";
import logo from "@ui/assets/images/logo.jpg";

import {
  AccountAdjustmentEvents,
  AccountsEvents,
  BankEvents,
} from "@domain/events";
import { EditBankAccount } from "@main/pages/accounts/EditBankAccount";
import { EditBankAccountPresenterContract } from "@ui/presenters/account/EditBankAccountPresenterContract";
import { GroupsByFlowsList } from "@domain/interfaces/accounts/GroupsByFlowsList";
import { DateFormatStrategy, MoneyFormatStrategy } from "@ui/strategy";
import { AccountAdjustmentPresenterContract } from "@ui/presenters/account";
import AddActionButton from "@ui/components/AddActionButton";
import EditActionButton from "@ui/components/EditActionButton";
import RemoveActionButton from "@ui/components/RemoveActionButton";
import AdjustBalanceButton from "@ui/components/AdjustBalanceButton";
import PrintActionButton from "@ui/components/PrintActionButton";
interface BankAccountViewProps {
  t: any;
  bankAccountPresenter: BankAccountPresenterContract;
}

const BankAccountView: React.FC<BankAccountViewProps> = ({
  t,
  bankAccountPresenter,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [activeCashflowId, setActiveCashflowId] = useState<string>("");
  const [bankAccount, setBankAccountList] = useState<GroupsByFlowsList[]>([]);
  const [isAddingAccount, setIsAddingAccount] = useState<boolean>(false);
  const [accountToAdjust, setAccountToAdjust] =
    useState<AccountAdjustmentPresenterContract.Payload | null>(null);
  const [bankToEdit, setBankToEdit] =
    useState<EditBankAccountPresenterContract.Payload | null>(null);

  useEffect(() => {
    bankAccountPresenter.setView({
      setLoadingState,
      setBankAccount,
    });

    bankAccountPresenter.get();

    EventEmitter.subscribe(AccountsEvents.ACCOUNT_ADDED, onAccountAdded);
    EventEmitter.subscribe(BankEvents.BANK_UPDATED, onBankUpdated);
    EventEmitter.subscribe(
      AccountAdjustmentEvents.ADJUSTMENT_ADDED,
      onAccountAdjusted
    );

    return () => {
      EventEmitter.unsubscribe(AccountsEvents.ACCOUNT_ADDED, onAccountAdded);
      EventEmitter.unsubscribe(BankEvents.BANK_UPDATED, onBankUpdated);
      EventEmitter.subscribe(
        AccountAdjustmentEvents.ADJUSTMENT_ADDED,
        onAccountAdjusted
      );
    };
  }, []);

  useEffect(() => {
    if (bankAccount.length && !activeCashflowId) {
      setActiveCashflowId(bankAccount[0].id);
    }
  }, [bankAccount]);

  const printRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `Saldo de Contas`,
    onPrintError: () => false,
    pageStyle: `
            .noPrint {
                display:none;
            }
        `,
  });

  const setLoadingState = (isLoading: boolean): void => setLoading(isLoading);
  const setBankAccount = (bankAccount: GroupsByFlowsList[]): void =>
    setBankAccountList(bankAccount);

  const onAccountAdded = () => {
    bankAccountPresenter.get();
    setIsAddingAccount(false);
  };

  const onBankUpdated = () => {
    setBankToEdit(null);
    bankAccountPresenter.get();
  };

  const onAccountAdjusted = () => {
    setAccountToAdjust(null);
    bankAccountPresenter.get();
  };

  const groups =
    bankAccount.find(({ id }) => id === activeCashflowId)?.groups || [];

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs
          title={t("Account balance")}
          breadcrumbItem={t("Bank accounts")}
        />
        <Row>
          {loading ? (
            <Col xl={12} className="mt-4">
              <Loading />
            </Col>
          ) : (
            <Col xl={12} className="mt-4">
              <div className="d-sm-flex align-items-center justify-content-end">
                <AddActionButton
                  onClick={() => setIsAddingAccount(true)}
                />
              </div>

              <Card>
                <CardBody>
                  <Nav className="nav-tabs-custom card-header-tabs">
                    {bankAccount.map(({ name, id }) => (
                      <NavItem key={id}>
                        <NavLink
                          to="#"
                          className={`px-3 nav-link ${
                            activeCashflowId === id ? "active" : ""
                          }`}
                          onClick={() => setActiveCashflowId(id)}
                        >
                          {name}
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>
                </CardBody>
              </Card>

              <div className="d-sm-flex align-items-center justify-content-end">
                <PrintActionButton
                  onClick={handlePrint}
                />
              </div>

              <ComponentToPrint ref={printRef}>
                <TabContent activeTab={activeCashflowId}>
                  <div className="d-sm-flex flex-column align-items-center justify-content-center mt-5 mb-3">
                    <img
                      style={{ objectFit: "contain" }}
                      className=""
                      src={logo}
                      height="150"
                      width="150"
                    />
                    <h4>{t("Bank accounts")}</h4>
                  </div>

                  {groups.map((group) => (
                    <Fragment key={group.id}>
                      <div className="d-sm-flex flex-column align-items-center justify-content-center mt-5 mb-3">
                        <h4>{t("Resource") + " - " + group.name}</h4>
                      </div>

                      <Table className="align-middle mb-4" bordered>
                        <thead>
                          <tr>
                            <th style={{ width: "20%" }}>{t("Account")}</th>
                            <th
                              style={{ width: "20%" }}
                              className="text-center"
                            >
                              {t("Bank")}
                            </th>
                            <th
                              style={{ width: "20%" }}
                              className="text-center"
                            >
                              {t("Opening balance")}
                            </th>
                            <th
                              style={{ width: "20%" }}
                              className="text-center"
                            >
                              {t("Opening date")}
                            </th>
                            <th
                              style={{ width: "20%" }}
                              className="text-center noPrint"
                            >
                              {t("Actions")}
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {groups
                            .find(({ id }) => id === group.id)
                            ?.accounts.map(
                              ({
                                id,
                                name,
                                Bank,
                                balance,
                                cashflow,
                                group,
                                Source,
                                initialBalance,
                                initialDate,
                              }) => (
                                <tr key={id}>
                                  <td>{name}</td>
                                  <td className="text-center">{Bank.name}</td>
                                  <td className="text-center">
                                    {MoneyFormatStrategy.format(initialBalance)}
                                  </td>
                                  <td className="text-center">
                                    {DateFormatStrategy.format(initialDate)}
                                  </td>
                                  <td
                                    style={{ width: "25%" }}
                                    className="text-center noPrint"
                                  >
                                    <AdjustBalanceButton
                                      onClick={() =>
                                        setAccountToAdjust({
                                          balance: balance,
                                          bankAccountId: id,
                                        })
                                      }
                                    />

                                    <EditActionButton
                                      label={t("Edit")}
                                      onClick={() =>
                                        setBankToEdit({
                                          bankId: Bank.id,
                                          cashflow,
                                          group,
                                          id,
                                          name,
                                          sourceId: Source.id,
                                          initialBalance,
                                          initialDate,
                                        })
                                      }
                                    />

                                    <RemoveActionButton
                                      onClick={() =>
                                        bankAccountPresenter.removeBankAccount(
                                          id
                                        )
                                      }
                                      isLastOfList
                                      label={t("Remove")}
                                    />
                                  </td>
                                </tr>
                              )
                            )}
                        </tbody>
                      </Table>
                    </Fragment>
                  ))}
                </TabContent>
              </ComponentToPrint>
            </Col>
          )}
        </Row>
      </Container>

      <AddAccount
        isOpen={isAddingAccount}
        onDismiss={() => setIsAddingAccount(false)}
      />

      <EditBankAccount
        bankToEdit={bankToEdit}
        onDismiss={() => setBankToEdit(null)}
      />

      <AccountAdjustment
        accountToAdjust={accountToAdjust}
        onDismiss={() => setAccountToAdjust(null)}
      />
    </div>
  );
};

export default withTranslation()(BankAccountView);
