import React, { useEffect, useState, Fragment, useMemo } from 'react';

import ReactApexChart from "react-apexcharts";
import { withTranslation } from 'react-i18next';

import { Card, CardBody, Col } from 'reactstrap';
import { ExpensesFromGroupsPresenterContract } from '@ui/presenters/dashboard';
import { MoneyFormatStrategy } from '@ui/strategy';
import { CashFlow } from '@domain/entities';

interface RevenuesFromGroupsViewProps {
  t: any;
  expensesFromGroupsPresenter: ExpensesFromGroupsPresenterContract;
}

const RevenuesFromGroupsView: React.FC<RevenuesFromGroupsViewProps> = ({ t, expensesFromGroupsPresenter }) => {
  const [activeCashflowId, setActiveCashflowId] = useState<number>(0);
  const [cashflows, setCashflowsList] = useState<any[]>([]);
  const [chartData, setChartDataState] = useState<ExpensesFromGroupsPresenterContract.Expenses[]>([]);

  useEffect(() => {
    expensesFromGroupsPresenter.setView({
      setChartData,
      setCashflows
    });

    expensesFromGroupsPresenter.getCashflows();
  }, []);

  useEffect(() => {
    if (cashflows.length)
      setActiveCashflowId(cashflows[0].id);
  }, [cashflows]);

  useEffect(() => {
    if (activeCashflowId)
      expensesFromGroupsPresenter.getChartData(activeCashflowId);
  }, [activeCashflowId]);

  const setChartData = (chartData: ExpensesFromGroupsPresenterContract.Expenses[]): void => setChartDataState(chartData);
  const setCashflows = (cashflows: any[]): void => setCashflowsList(cashflows);
  const randomColor = () => ('#' + ((Math.random() * 0xffffff) << 0).toString(16) + '000000').slice(0, 7);

  const series = useMemo(() => chartData.map(({ total }) => total), [chartData]);
  const options: ApexCharts.ApexOptions = useMemo(() => ({
    labels: chartData.map(({ groupName }) => groupName),
    colors: chartData.map(() => randomColor()),
    legend: {
      show: true,
      position: "right",
      horizontalAlign: "center",
      floating: false,
      fontSize: "14px",
      offsetX: 0,
      offsetY: -10,
    },
    chart: {

    },
    tooltip: {
      y: {
        formatter(val) {
          return MoneyFormatStrategy.format(val);
        },
      }
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 240,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  }), [chartData]);

  const activeCashflowName = cashflows.find(({ id }) => id == activeCashflowId)?.name;
  const cashflowName = activeCashflowName ? ' - ' + activeCashflowName : '';

  return (
    <>
      <Col sm={12} md={6}>
        <Card className="card-h-100">
          <CardBody>
            <div className="d-flex flex-wrap align-items-center mb-4 mt-2">
              <h5 className="card-title me-2">{t("Expenses From Groups")}{cashflowName}</h5>

              <div className="ms-auto">
                <div>
                  {cashflows.map(({ id, name }) => (
                    <Fragment key={id}>
                      <button
                        type="button"
                        className="btn btn-soft-primary btn-sm"
                        onClick={() => setActiveCashflowId(id)}
                      >
                        {name}
                      </button>{" "}
                    </Fragment>
                  ))}
                </div>
              </div>
            </div>

            <ReactApexChart
              options={options}
              series={series}
              type="donut"
              height={320}
              className="apex-charts"
            />
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default withTranslation()(RevenuesFromGroupsView);
