import { DateFormat } from "@ui/interfaces";
import { DateAndHourFormatBr } from "@ui/utils/DateAndHourFormatBr";

interface FormatterList {
   lang: string;
   formatter: DateFormat;
}

const formatters: FormatterList[] = [
   {
      lang: "pt-BR",
      formatter: new DateAndHourFormatBr()
   }
];

export class DateAndHourFormatStrategy implements DateFormat {
   handle(date: string): string {
      const chosenFormatter = formatters.find(
         ({ lang }) => lang === localStorage.getItem("I18N_LANGUAGE")
      );

      if (!chosenFormatter) {
         return new DateAndHourFormatBr().handle(date);
      }

      return chosenFormatter?.formatter.handle(date);
   }

   static format(value: string): string {
      return new DateAndHourFormatStrategy().handle(value);
   }
}
