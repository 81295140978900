import { HttpEditSubgroupService } from "@data/services/revenues-expenses";
import { AxiosHttpRepository } from "@infra/http";
import { API_BASE_URL } from "@main/constants/config";
import { EditSubgroupPresenter } from "@presentation/presenters/revenues-expenses";
import { FieldValidationBuilder } from "@presentation/validation/builders";
import { ValidationComposite } from "@presentation/validation/validators";
import { EditSubgroupPresenterContract } from "@ui/presenters/revenues-expenses";

export const makeEditSubgroupPresenter = (): EditSubgroupPresenterContract => {
  const validation = new ValidationComposite([
    ...FieldValidationBuilder.field("id").required().build(),
    ...FieldValidationBuilder.field("name").required().build(),
  ]);

  const httpRepository = new AxiosHttpRepository(API_BASE_URL);
  const editSubgroupService = new HttpEditSubgroupService(httpRepository);

  return new EditSubgroupPresenter(validation, editSubgroupService);
};
