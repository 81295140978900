import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import ReactApexChart from "react-apexcharts";

import { Card, CardBody, Col } from 'reactstrap';

import { LastThreeMonthsPresenterContract } from '@ui/presenters/dashboard';
import { GetLastQuarter } from '@presentation/utils';
import { MoneyFormatStrategy } from '@ui/strategy';
import { format, parseISO } from 'date-fns';

interface LastThreeMonthViewProps {
  t: any;
  lastThreeMonthPresenter: LastThreeMonthsPresenterContract;
}

const LastThreeMonthView: React.FC<LastThreeMonthViewProps> = ({ t, lastThreeMonthPresenter }) => {
  const [chartData, setChartDataState] = useState<LastThreeMonthsPresenterContract.ChartData>({
    expenses: [],
    revenues: []
  });

  useEffect(() => {
    lastThreeMonthPresenter.setView({ setChartData });

    lastThreeMonthPresenter.getChartData();
  }, []);

  const setChartData = (data: LastThreeMonthsPresenterContract.ChartData): void => setChartDataState(data);

  const { months } = GetLastQuarter.getPeriod();

  const revenues = months.map((monthOfPeriod) => {
    const total = chartData.revenues
      .filter(({ month }) => parseInt(format(parseISO(monthOfPeriod), 'MM')) == parseInt(month))
      .reduce((current, { total }) => total + current, 0);

    return total;
  });

  const expenses = months.map((monthOfPeriod) => {
    const total = chartData.expenses
      .filter(({ month }) => parseInt(format(parseISO(monthOfPeriod), 'MM')) == parseInt(month))
      .reduce((current, { total }) => total + current, 0);

    return total;
  });

  const series: any[] = [
    { name: t('Revenues'), data: revenues },
    { name: t('Expenses'), data: expenses },
  ];

  const options: ApexCharts.ApexOptions = {
    chart: {
      type: 'line',
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false
      }
    },
    colors: ["#34c38f", "#e65555"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [3, 3],
      curve: 'straight'
    },
    series: [
      { name: t('Revenues'), data: revenues },
      { name: t('Expenses'), data: expenses },
    ],
    title: {
      text: '',
      align: 'left',
      style: {
        fontWeight: '500',
      },
    },
    grid: {
      row: {
        colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.2
      },
      borderColor: '#f1f1f1'
    },
    xaxis: {
      categories: months.map((month) => format(parseISO(month), 'MM/yyyy')),
      title: {
        text: ''
      }
    },
    yaxis: {
      title: {
        text: ''
      },
      labels: {
        formatter(val) {
          return MoneyFormatStrategy.format(val);
        },
      }
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      floating: true,
      offsetY: -25,
      offsetX: -5
    },
    responsive: [{
      breakpoint: 600,
      options: {
        chart: {
          toolbar: {
            show: false
          }
        },
        legend: {
          show: false
        },
      }
    }],
    tooltip: {
      y: {
        formatter(val) {
          return MoneyFormatStrategy.format(val);
        },
      }
    }
  };

  return (
    <>
      <Col sm={12} md={12}>
        <Card className="card-h-100">
          <CardBody>
            <h5 className="card-title me-2 mb-5">{t("Last Three Months")}</h5>

            <ReactApexChart
              options={options}
              series={series}
              type="line"
              height="380"
              className="apex-charts"
            />
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default withTranslation()(LastThreeMonthView);
