import { ConsolidatedReportTransaction } from "@domain/entities";
import { GetConsolidatedReportService } from "@domain/services/consolidated-report/GetConsolidatedReportService";
import { GetLastQuarter } from "@presentation/utils";
import { LastThreeMonthsViewContract } from "@presentation/views/dashboard";
import { LastThreeMonthsPresenterContract } from "@ui/presenters/dashboard";

export class LastThreeMonthsPresenter implements LastThreeMonthsPresenterContract {
  private view!: LastThreeMonthsViewContract;

  constructor(
    private readonly getConsolidatedReportService: GetConsolidatedReportService
  ) { }

  setView(view: LastThreeMonthsViewContract): void {
    this.view = view;
  }

  async getChartData(): Promise<LastThreeMonthsPresenterContract.ChartData> {
    const { begin, end } = GetLastQuarter.getPeriod();

    const consolidatedReportData = await this.getConsolidatedReportService.handle({
      beginOfPeriod: begin,
      endOfPeriod: end
    });

    const chartData = {
      expenses: this.getExpenses(consolidatedReportData.transactionsCarriedOut),
      revenues: this.getRevenues(consolidatedReportData.transactionsCarriedOut)
    };

    this.view.setChartData(chartData);

    return chartData;
  }

  private getExpenses(transactions: ConsolidatedReportTransaction[]): LastThreeMonthsPresenterContract.Transaction[] {
    return transactions
      .filter(({ tipos_transacoes_id }) => tipos_transacoes_id != 1)
      .map(({ ano, mes, total, tipos_fluxos_id }) => ({
        cashflowId: tipos_fluxos_id ?? 0,
        month: String(mes),
        total: parseFloat(total),
        year: String(ano)
      }));
  }

  private getRevenues(transactions: ConsolidatedReportTransaction[]): LastThreeMonthsPresenterContract.Transaction[] {
    return transactions
      .filter(({ tipos_transacoes_id }) => tipos_transacoes_id == 1)
      .map(({ ano, mes, total, tipos_fluxos_id }) => ({
        cashflowId: tipos_fluxos_id ?? 0,
        month: String(mes),
        total: parseFloat(total),
        year: String(ano)
      }));
  }
}
