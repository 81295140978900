import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Modal, Row, Col, Label, FormGroup } from "reactstrap";

import { EditBankAccountPresenterContract } from "@ui/presenters/account";

import { useValidation } from "@ui/hooks";
import { SelectItem } from "@ui/interfaces";
import { AccountGroup, Bank, CashFlow, Source } from "@domain/entities";
import { NumericFormat } from "react-number-format";

interface EditBankAccountViewProps {
  t: any;
  bankToEdit: EditBankAccountPresenterContract.Payload | null;
  onDismiss(): void;
  editBankAccountPresenter: EditBankAccountPresenterContract;
}

const INITIAL_FORM_STATE: EditBankAccountPresenterContract.Payload = {
  id: 0,
  bankId: 0,
  cashflow: "",
  group: "",
  name: "",
  sourceId: 0,
  initialBalance: 0,
  initialDate: "",
};

const EditBankAccountView: React.FC<EditBankAccountViewProps> = ({
  t,
  bankToEdit,
  onDismiss,
  editBankAccountPresenter,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [accountGroups, setAccountGroupsList] = useState<AccountGroup[]>([]);
  const [banks, setBanksList] = useState<Bank[]>([]);
  const [cashflow, setCashflowList] = useState<CashFlow[]>([]);
  const [sources, setSourcesList] = useState<Source[]>([]);
  const [payload, setPayload] =
    useState<EditBankAccountPresenterContract.Payload>(INITIAL_FORM_STATE);

  const {
    ValidationFeedback,
    ValidationInput,
    updateValidationState,
    validationState,
    ValidationSelect,
  } = useValidation();

  useEffect(() => {
    editBankAccountPresenter.setView({
      setLoading,
      updateValidationState,
      setBanks,
      setAccountGroups,
      setCashflows,
      setSources,
    });
  }, []);

  useEffect(() => {
    if (!bankToEdit) {
      updateValidationState([]);
      setPayload(INITIAL_FORM_STATE);
    } else {
      editBankAccountPresenter.getAccountGroups();
      editBankAccountPresenter.getBanks();
      editBankAccountPresenter.getCashflows();
      editBankAccountPresenter.getSources();
    }
  }, [bankToEdit]);

  useEffect(() => {
    if (!bankToEdit) {
      updateValidationState([]);
      setPayload(INITIAL_FORM_STATE);
    } else {
      setPayload({
        bankId: bankToEdit.bankId,
        cashflow: bankToEdit.cashflow,
        group: bankToEdit.group,
        name: bankToEdit.name,
        sourceId: bankToEdit.sourceId,
        id: bankToEdit.id,
        initialBalance: bankToEdit.initialBalance,
        initialDate: bankToEdit.initialDate,
      });
    }
  }, [bankToEdit]);

  const setLoading = (isLoading: boolean): void => setIsLoading(isLoading);

  const setBanks = (banks: Bank[]): void => setBanksList(banks);

  const setAccountGroups = (accountGroups: AccountGroup[]): void =>
    setAccountGroupsList(accountGroups);

  const setCashflows = (cashflows: CashFlow[]): void =>
    setCashflowList(cashflows);

  const setSources = (sources: Source[]): void => setSourcesList(sources);

  const updateField = (fieldName: string, value: string) => {
    setPayload((oldState) => ({
      ...oldState,
      [fieldName]: value,
    }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    editBankAccountPresenter.edit({
      bankId: Number(payload.bankId),
      cashflow: payload.cashflow,
      group: payload.group,
      id: payload.id,
      initialBalance: payload.initialBalance,
      initialDate: payload.initialDate,
      name: payload.name,
      sourceId: Number(payload.sourceId),
    });
  };

  const optionsCashflows: SelectItem[] = cashflow.map(({ id, name }) => ({
    label: name,
    value: id,
  }));

  const optionsBanks: SelectItem[] = banks.map(({ id, name }) => ({
    label: name,
    value: id,
  }));

  const optionsAccountGroups: SelectItem[] = accountGroups.map(
    ({ id, name }) => ({ label: name, value: id })
  );

  const optionsSources: SelectItem[] = sources.map(({ id, name, code }) => ({
    label: code + " - " + name,
    value: id,
  }));

  return (
    <Modal
      isOpen={Boolean(bankToEdit)}
      toggle={isLoading ? undefined : onDismiss}
      scrollable={true}
      id="staticBackdrop"
      size="lg"
    >
      <div className="modal-header">
        <h5 className="modal-title">{t("Edit")}</h5>
        <button
          type="button"
          className="btn-close"
          onClick={isLoading ? undefined : onDismiss}
          aria-label="Close"
        />
      </div>

      <form onSubmit={handleSubmit}>
        <div className="modal-body">
          <Row>
            <Col md={12} className="mb-2">
              <FormGroup>
                <Label>{t("Account name")}</Label>
                <ValidationInput
                  field="name"
                  validationState={validationState}
                  name="name"
                  className="form-control"
                  placeholder={t("Account name")}
                  value={payload.name}
                  onChange={({ target: { value, name } }) =>
                    updateField(name, value)
                  }
                />

                <ValidationFeedback field="name" />
              </FormGroup>
            </Col>

            <Col md={6} className="mb-2">
              <FormGroup>
                <Label>{t("Bank Name")}</Label>
                <ValidationSelect
                  field="bankId"
                  name="bankId"
                  className="form-control"
                  placeholder={t("Bank Name")}
                  options={optionsBanks}
                  validationState={validationState}
                  value={payload.bankId}
                  onChange={({ target: { value, name } }) =>
                    updateField(name, value)
                  }
                />

                <ValidationFeedback field="bankId" />
              </FormGroup>
            </Col>

            <Col md={6} className="mb-2">
              <FormGroup>
                <Label>{t("Flow")}</Label>
                <ValidationSelect
                  field="cashflow"
                  name="cashflow"
                  className="form-control"
                  placeholder={t("Select")}
                  options={optionsCashflows}
                  validationState={validationState}
                  value={payload.cashflow}
                  onChange={({ target: { value, name } }) =>
                    updateField(name, value)
                  }
                />

                <ValidationFeedback field="cashflow" />
              </FormGroup>
            </Col>

            <Col md={6} className="mb-2">
              <FormGroup>
                <Label>{t("Source")}</Label>
                <ValidationSelect
                  field="sourceId"
                  options={optionsSources}
                  validationState={validationState}
                  name="sourceId"
                  className="form-control"
                  placeholder={t("Select")}
                  onChange={({ target: { value, name } }) =>
                    updateField(name, value)
                  }
                  value={payload.sourceId}
                />

                <ValidationFeedback field="sourceId" />
              </FormGroup>
            </Col>

            <Col md={6} className="mb-2">
              <FormGroup>
                <Label>{t("Group")}</Label>
                <ValidationSelect
                  field="group"
                  options={optionsAccountGroups}
                  validationState={validationState}
                  name="group"
                  className="form-control"
                  placeholder={t("Select")}
                  onChange={({ target: { value, name } }) =>
                    updateField(name, value)
                  }
                  value={payload.group}
                />

                <ValidationFeedback field="group" />
              </FormGroup>
            </Col>

            <Col md={6} className="mb-3">
              <FormGroup>
                <Label>{t("Opening date")}</Label>

                <ValidationInput
                  field="initialDate"
                  type="date"
                  name="initialDate"
                  className="form-control"
                  placeholder={`${t("Date placeholder")}`}
                  validationState={validationState}
                  onChange={({ target: { name, value } }) => {
                    updateField(name, value);
                  }}
                  value={payload.initialDate}
                />

                <ValidationFeedback field="initialDate" />
              </FormGroup>
            </Col>

            <Col md={6} className="mb-3">
              <FormGroup>
                <Label>{t("Opening balance")}</Label>

                <NumericFormat
                  field="balance"
                  validationState={validationState}
                  name="balance"
                  className="form-control"
                  placeholder={t("Balance")}
                  thousandSeparator="."
                  customInput={ValidationInput}
                  decimalSeparator=","
                  decimalScale={2}
                  fixedDecimalScale
                  onValueChange={({ floatValue }) => {
                    setPayload({
                      ...payload,
                      initialBalance: floatValue ?? 0,
                    });
                  }}
                  value={payload.initialBalance}
                />

                <ValidationFeedback field="balance" />
              </FormGroup>
            </Col>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger me-2"
                onClick={isLoading ? undefined : onDismiss}
                disabled={isLoading}
              >
                {t("Close")}
              </button>

              <button className="btn btn-primary" disabled={isLoading}>
                {t(isLoading ? "loading" : "Save")}
              </button>
            </div>
          </Row>
        </div>
      </form>
    </Modal>
  );
};

export default withTranslation()(EditBankAccountView);
