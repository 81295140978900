import { HttpRequestBuilder } from "@data/builders";
import { HttpRequestTypes } from "@data/enums";
import { HttpRepository } from "@data/repositories";
import { AddPrefectureService } from "@domain/services/prefecture";

export class HttpAddPrefectureService implements AddPrefectureService {
    constructor(
      private readonly httpRepository: HttpRepository
    ) { }
  
    async handle(payload: AddPrefectureService.Payload): Promise<boolean> {
      const httpRequest = HttpRequestBuilder.requestToUrl('/prefecture')
        .withMethod(HttpRequestTypes.POST)
        .withData(payload)
        .build();
  
      const { status } = await this.httpRepository.makeRequest<AddPrefectureService.Payload>(httpRequest);
  
      return status === 201;
    }
  }
