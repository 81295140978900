import React, { useEffect, useState } from 'react';

import ReactEcharts from "echarts-for-react";
import { withTranslation } from 'react-i18next';

import { Card, CardBody, Col } from 'reactstrap';

import { DebitRadarByFlowPresenterContract } from '@ui/presenters/dashboard';
import { MoneyFormatStrategy } from '@ui/strategy';

interface DebitRadarByFlowViewProps {
    t: any;
    debitRadarByFlowPresenter: DebitRadarByFlowPresenterContract;
}

interface Formatter {
    value: number;
    seriesName: string;
}

const DebitRadarByFlowView: React.FC<DebitRadarByFlowViewProps> = ({ debitRadarByFlowPresenter, t }) => {
    const [data, setData] = useState<DebitRadarByFlowPresenterContract.Debit[]>([]);

    useEffect(() => {
        debitRadarByFlowPresenter.setView({ setChartData });

        debitRadarByFlowPresenter.getChartData();
    });

    const setChartData = async (data: DebitRadarByFlowPresenterContract.Debit[]) => setData(data);

    const option = {
        title: {
            text: ''
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            },
            formatter: (itens: Formatter[]) => itens.reduce(
                (prev, { seriesName, value }) => prev + `${seriesName}: ${MoneyFormatStrategy.format(value)}<br />`
                , ''
            )
        },
        legend: {},
        grid: {
            left: '1%',
            right: '10%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: {
            type: 'value',
            boundaryGap: [0, 0.01],
            axisLabel: {
                formatter: (value: number) => MoneyFormatStrategy.format(value),
                fontSize: 8
            }
        },
        yAxis: {
            type: 'category',
            data: data.map(({ flowName }) => flowName)
        },
        series: [
            {
                name: t('Foreseen'),
                type: 'bar',
                data: data.map(({ totalForeseen }) => totalForeseen)
            },
            {
                name: t('Realized'),
                type: 'bar',
                data: data.map(({ total }) => total)
            }
        ]
    };

    return (
        <Col sm={12} md={6}>
            <Card className="card-h-100">
                <CardBody>
                    <h5 className="card-title me-2">{t("Debit by Flow")}</h5>

                    <div className="mt-5">
                        {data.length && (
                            <ReactEcharts
                                option={option}
                                style={{ height: '350px', width: '100%' }}
                            />
                        )}
                    </div>
                </CardBody>
            </Card>
        </Col>
    );
};

export default withTranslation()(DebitRadarByFlowView);
