import {
  HttpGetSubgroupsService,
  HttpRemoveSubgroupsService,
} from "@data/services/revenues-expenses";

import { AxiosHttpRepository } from "@infra/http";
import { API_BASE_URL } from "@main/constants/config";
import { RevenuesAndExpensesPresenter } from "@presentation/presenters/revenues-expenses";
import { RevenuesAndExpensesPresenterContract } from "@ui/presenters/revenues-expenses";

export const makeRevenuesAndExpensesPresenter =
  (): RevenuesAndExpensesPresenterContract => {
    const httpRepository = new AxiosHttpRepository(API_BASE_URL);
    const getSubgroupsService = new HttpGetSubgroupsService(httpRepository);
    const removeSubGroupsService = new HttpRemoveSubgroupsService(
      httpRepository
    );

    return new RevenuesAndExpensesPresenter(
      getSubgroupsService,
      removeSubGroupsService
    );
  };
