import { User } from "@domain/entities";
import { GetUsersService, RemoveUserService } from "@domain/services/users";
import { UsersListViewContract } from "@presentation/views/users";
import { UsersListPresenterContract } from "@ui/presenters/users";

export class UsersListPresenter implements UsersListPresenterContract {
  private view!: UsersListViewContract;

  constructor(
    private readonly getUsersService: GetUsersService,
    private readonly removeUserService: RemoveUserService
  ) { }

  async removeUser(userId: number): Promise<void> {
    this.view.setLoadingState(true);

    const isRemoved = await this.removeUserService.handle(userId);
    
    if(isRemoved) {
      this.get();
    } else {
      this.view.setLoadingState(false);
    }
  }

  setView(view: UsersListViewContract): void {
    this.view = view;
  }

  async get(): Promise<User[]> {
    this.view.setLoadingState(true);

    const users = await this.getUsersService.handle();

    this.view.setUsersList(users);
    this.view.setLoadingState(false);

    return users;
  }
}
