import { HttpGetBankAccountService } from "@data/services/account";

import {
  HttpGetTreasuryService,
  HttpPublishTreasuryService,
  HttpSetHourTreasuryService,
} from "@data/services/treasury";

import { AxiosHttpRepository } from "@infra/http";
import { API_BASE_URL } from "@main/constants/config";
import { TreasuryPresenter } from "@presentation/presenters/treasury";
import { TreasuryPresenterContract } from "@ui/presenters/treasury";

export const makeTreasuryPresenter = (): TreasuryPresenterContract => {
  const httpRepository = new AxiosHttpRepository(API_BASE_URL);
  const getBankAccountService = new HttpGetBankAccountService(httpRepository);
  const getTreasuryService = new HttpGetTreasuryService(httpRepository);
  const publishTreasuryService = new HttpPublishTreasuryService(httpRepository);
  const setHourTreasuryService = new HttpSetHourTreasuryService(httpRepository);

  return new TreasuryPresenter(
    getBankAccountService,
    getTreasuryService,
    publishTreasuryService,
    setHourTreasuryService
  );
};
