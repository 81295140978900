import { CashFlow, Group, Subgroup } from "@domain/entities";
import { GetCashflowsService } from "@domain/services/cashflows";
import { GetConsolidatedReportService } from "@domain/services/consolidated-report/GetConsolidatedReportService";
import { GetGroupsService } from "@domain/services/groups";
import { GetSubgroupsService } from "@domain/services/revenues-expenses";
import { GetMonthPeriod } from "@presentation/utils";
import { ExpensesFromGroupsViewContract } from "@presentation/views/dashboard";
import { ConsolidatedReportPresenterContract } from "@ui/presenters/consolidated-report/ConsolidatedReportPresenterContract";
import { ExpensesFromGroupsPresenterContract } from "@ui/presenters/dashboard";

export class ExpensesFromGroupsPresenter implements ExpensesFromGroupsPresenterContract {
  private view!: ExpensesFromGroupsViewContract;

  constructor(
    private readonly getConsolidatedReportService: GetConsolidatedReportService,
    private readonly getGroupsService: GetGroupsService,
    private readonly getSubgroupsService: GetSubgroupsService,
    private readonly getCashflowsService: GetCashflowsService
  ) { }

  setView(view: ExpensesFromGroupsViewContract): void {
    this.view = view;
  }

  async getChartData(cashflowId: number): Promise<ExpensesFromGroupsPresenterContract.Expenses[]> {
    const { begin, end } = GetMonthPeriod.getPeriod();

    const groups = await this.getGroupsService.handle();
    const subgroups = await this.getSubgroupsService.handle();
    const reportData = await this.getConsolidatedReportService.handle({ beginOfPeriod: begin, endOfPeriod: end });

    const chartData = this.organizeGroupsAndSubgroupsFromCashflow(
      cashflowId,
      groups,
      subgroups,
      reportData
    );

    this.view.setChartData(chartData);

    return chartData;
  }

  async getCashflows(): Promise<CashFlow[]> {
    const cashflows = await this.getCashflowsService.handle();
    this.view.setCashflows(cashflows);

    return cashflows;
  }

  private organizeGroupsAndSubgroupsFromCashflow(
    cashflowId: number,
    groups: Group[],
    subgroups: any[],
    reportData: ConsolidatedReportPresenterContract.ConsolidatedData
  ): ExpensesFromGroupsPresenterContract.Expenses[] {
    const groupsByCashflow = groups
      // .filter(({ tipos_fluxos_id, tipos_transacoes_id }) =>
      //   tipos_fluxos_id == cashflowId &&
      //   tipos_transacoes_id != 1
      // )
      .map((group) => {
        const { id } = group;

        const subgroupsFromGroup = subgroups
          .filter(({ transacoes_grupo_id }) => transacoes_grupo_id == id)
          .map(({ id }) => id);

        return { ...group, subgroups: subgroupsFromGroup };
      })
      .map(({ id, name, subgroups }) => ({
        groupId: id,
        groupName: name,
        cashflowId,
        subgroups
      }))
      .map(({ groupId, cashflowId, groupName, subgroups }): ExpensesFromGroupsPresenterContract.Expenses => {
        const total = reportData.transactionsCarriedOut
          .filter(({ transacoes_eventos_id }) => subgroups.includes(transacoes_eventos_id))
          .reduce((current, { total }) => parseFloat(total) + current, 0);

        return {
          cashflowId,
          groupId,
          groupName,
          total
        };
      });

    return groupsByCashflow;
  }
}
