import { HttpRequestBuilder } from "@data/builders";
import { HttpRequestTypes } from "@data/enums";
import { HttpRepository } from "@data/repositories";
import { Prefecture } from "@domain/entities";
import { GetPrefectureService } from "@domain/services/prefecture";

export class HttpGetPrefectureService implements GetPrefectureService {
    constructor(
      private readonly httpRepository: HttpRepository
    ) { }
  
    async handle(): Promise<Prefecture[]> {
      const request = HttpRequestBuilder
        .requestToUrl('/prefecture')
        .withMethod(HttpRequestTypes.GET)
        .build();
  
      const { data } = await this.httpRepository.makeRequest<any, Prefecture[]>(request);
  
      return data;
    }
  }
  
