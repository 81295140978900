import { HttpRequestBuilder } from "@data/builders";
import { HttpRequestTypes } from "@data/enums";
import { HttpRepository } from "@data/repositories";
import { Similarity } from "@domain/interfaces/similarity";
import { AddProviderService } from "@domain/services/provider/AddProviderService";

interface HttpAddProviderResponse {
   message?: Similarity[];
 }
 
 interface HttpAddProviderRequest {
   titulo: string;
   mensagem: string;
 }

export class HttpAddProviderService implements AddProviderService {
   constructor(
      private readonly httpRepository: HttpRepository
   ) { }

   async handle(payload: AddProviderService.Payload): Promise<AddProviderService.Response> {
      const request = HttpRequestBuilder
         .requestToUrl('/providers')
         .withMethod(HttpRequestTypes.POST)
         .withData(payload)
         .build();

      const { data, status } = await this.httpRepository.makeRequest<HttpAddProviderRequest, HttpAddProviderResponse>(request);

      console.log(data);

      return { 
         ok: status === 200,
         errors: Array.isArray(data.message) ? data.message : []
       };
   }
}
