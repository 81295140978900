import { useEffect, useState } from "react";
import { Col, Container, Row, Table } from "reactstrap";
import { withTranslation } from "react-i18next";

import Loading from "@ui/components/Loading";
import { Breadcrumbs } from "@ui/components/Common";
import SearchForm from "@ui/components/bills/SearchForm";
import AddActionButton from "@ui/components/AddActionButton";
import RemoveActionButton from "@ui/components/RemoveActionButton";

import { EditEffectiveBillsPresenterContract, EffectiveBillsPresenterContract } from "@ui/presenters/bills";
import { BillsEvents } from "@domain/events/BillsEvents";
import { EventEmitter } from "@utils/events/EventEmitter";

import AddEffectiveBills from "@main/pages/bills/AddEffectiveBills";
import { DateUtil } from "@ui/utils";
import { useParams } from "react-router-dom";
import { DateFormatStrategy, MoneyFormatStrategy } from "@ui/strategy";
import EditActionButton from "@ui/components/EditActionButton";
import EditEffectiveBills from "@main/pages/bills/EditEffectiveBills";

interface EffectiveBillsViewProps {
    t: any;
    effectiveBillsPresenter: EffectiveBillsPresenterContract;
}

const EffectiveBillsView: React.FC<EffectiveBillsViewProps> = ({ t, effectiveBillsPresenter }) => {
    const { type } = useParams<{ type: string }>();
    const [loading, setLoading] = useState<boolean>(false);
    const [isAddingBill, setIsAddingBill] = useState<boolean>(false);
    const [response, setResponseList] = useState<EffectiveBillsPresenterContract.Response[]>([]);
    const [params, setParamsList] = useState<EffectiveBillsPresenterContract.Params>({
        start: DateUtil.todayString(),
        end: DateUtil.todayString(),
        transactionType: type
    });

    const [billsToEdit, setBillsToEdit] = useState<EditEffectiveBillsPresenterContract.Payload | null>(null);

    useEffect(() => {
        effectiveBillsPresenter.setView({
            setParams,
            setResponse,
            setLoadingState
        });

        effectiveBillsPresenter.get(params);

        EventEmitter.subscribe(BillsEvents.BILLS_ADDED, onBillAdded);
        EventEmitter.subscribe(BillsEvents.BILLS_UPDATED, onBillsEdited);

        return () => {
            EventEmitter.unsubscribe(BillsEvents.BILLS_ADDED, onBillAdded);
            EventEmitter.subscribe(BillsEvents.BILLS_UPDATED, onBillsEdited);
        };
    }, []);

    const onBillAdded = () => {
        setIsAddingBill(false);
        effectiveBillsPresenter.get(params);
    };

    const onBillsEdited = () => {
        setBillsToEdit(null);
        effectiveBillsPresenter.get(params);
    };

    const setParams = (params: EffectiveBillsPresenterContract.Params) => setParamsList(params);
    const setResponse = (response: EffectiveBillsPresenterContract.Response[]) => setResponseList(response);
    const setLoadingState = (isLoading: boolean): void => setLoading(isLoading);

    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs
                    breadcrumbItem={type === "debit" ? t("bills.payed") : t("bills.received")}
                    title={type === "debit" ? t("bills.pay.title") : t("bills.receive.title")}
                />
                <Row>
                    {loading ? (
                        <Col xl={12} className="mt-4">
                            <Loading />
                        </Col>
                    ) : (
                        <Col xl={12} className="mt-4">
                            <div className="d-sm-flex align-items-center justify-content-end">
                                <AddActionButton
                                    onClick={() => setIsAddingBill(true)}
                                />
                            </div>

                            <SearchForm
                                type={type}
                                onSubmit={(params) => {
                                    effectiveBillsPresenter.get(params);
                                }}
                            />

                            <div className="table-responsive">
                                <h3 className="text-center">{type === "debit" ? t("bills.payed") : t("bills.received")}</h3>
                                <Table className="align-middle mb-4" bordered>
                                    <thead>
                                        <tr>
                                            <th> {t("Description")} </th>
                                            <th className="text-center"> {t("Balance")} </th>
                                            <th className="text-center"> {t("Date")} </th>
                                            <th className="text-center"> {t("Actions")} </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {response.map(({
                                            id,
                                            description,
                                            date,
                                            amount,
                                            cashflow,
                                            BankAccount,
                                            Provider,
                                            Source,
                                            TransactionGroup,
                                            TransactionSubgroup,
                                            nonEffectiveBillsId
                                        }, key) => (
                                            <tr key={key}>
                                                <td>{description}</td>
                                                <td className="text-center">{MoneyFormatStrategy.format(amount)}</td>
                                                <td className="text-center">{DateFormatStrategy.format(date)}</td>
                                                <td className="text-center">
                                                    <EditActionButton
                                                        isLastOfList={false}
                                                        label={t("Edit")}
                                                        onClick={() =>
                                                            setBillsToEdit({
                                                                id,
                                                                amount,
                                                                bankAccountId: BankAccount?.id,
                                                                cashflow,
                                                                date,
                                                                description,
                                                                nonEffectiveBillsId: nonEffectiveBillsId,
                                                                providerId: Provider?.id,
                                                                sourceId: Source?.id,
                                                                transactionGroupId: TransactionGroup?.id,
                                                                transactionSubgroupId: TransactionSubgroup?.id,
                                                                transactionType: type
                                                            })
                                                        }
                                                    />

                                                    <RemoveActionButton
                                                        onClick={() => {
                                                            effectiveBillsPresenter.removeBills(id);
                                                            effectiveBillsPresenter.get(params);
                                                        }}
                                                        isLastOfList
                                                        label={t("Remove")}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    )}
                </Row>
            </Container>

            <AddEffectiveBills
                isOpen={isAddingBill}
                type={type}
                onDismiss={() => setIsAddingBill(false)}
            />

            <EditEffectiveBills
                billsToEdit={billsToEdit}
                type={type}
                onDismiss={() => setBillsToEdit(null)}
            />

        </div>
    );
};

export default withTranslation()(EffectiveBillsView);
