import React, { useEffect, useState } from "react";

import { withTranslation } from "react-i18next";
import { Col, Container, FormGroup, Input, Row } from "reactstrap";
import { FaTrash, FaPlus, FaSave, FaCalendar } from "react-icons/fa";

import { Breadcrumbs } from "@ui/components/Common";
import { TreasuryPresenterContract } from "@ui/presenters/treasury";
import { Accounts, Treasury } from "@domain/entities";
import { GroupsByFlowsList } from "@domain/interfaces/accounts";
import Loading from "@ui/components/Loading";
import { NumericFormat } from "react-number-format";
import { DateFormatStrategy } from "@ui/strategy";
import SetHour from "@ui/components/treasury/SetHour";
import { DateUtil } from "@ui/utils";

interface TreasuryViewProps {
  t: any;
  treasuryPresenter: TreasuryPresenterContract;
}

const TreasuryView: React.FC<TreasuryViewProps> = ({
  t,
  treasuryPresenter,
}) => {
  const [date, setDate] = useState("");

  const [openHour, setOpenHour] = useState(false);
  const [loading, setLoadingState] = useState(false);
  const [, setTreasuryState] = useState<Treasury | null>(null);
  const [listAccounts, setListAccounts] = useState<GroupsByFlowsList[]>([]);
  const [items, setItems] = useState<TreasuryPresenterContract.Items[]>([]);

  useEffect(() => {
    treasuryPresenter.setView({ setLoading, setTreasury, setAccounts });

    treasuryPresenter.getAccounts();
  }, []);

  useEffect(() => {
    if (date) {
      treasuryPresenter.getTreasury(date).then((treasury) => {
        if (treasury?.items?.length) {
          const newItems = treasury.items.map(({ balance, BankAccount }) => ({
            balance,
            bankAccountId: Number(BankAccount?.id),
          }));

          setItems(newItems);
        } else {
          setItems([{ balance: 0, bankAccountId: 0 }]);
        }
      });
    }
  }, [date]);

  const setLoading = (isLoading: boolean) => setLoadingState(isLoading);
  const setTreasury = (treasury: Treasury) => setTreasuryState(treasury);
  const setAccounts = (list: GroupsByFlowsList[]) => setListAccounts(list);

  const mountAccountsList = (): Accounts[] => {
    const list: Accounts[] = [];

    listAccounts.forEach(({ groups }) => {
      groups.forEach(({ accounts }) => {
        accounts.forEach((account) => {
          list.push(account);
        });
      });
    });

    return list;
  };

  const accountsList = mountAccountsList();

  const getAvailableAccounts = (currentIndex: number) => {
    const selectedAccountIds = items
      .map((item) => item.bankAccountId)
      .filter((id, index) => id !== 0 && index !== currentIndex);

    return accountsList.filter(
      (account) => !selectedAccountIds.includes(account.id)
    );
  };

  const allAccountsSelected = (): boolean => {
    const selectedAccountIds = items.map((item) => item.bankAccountId);
    return selectedAccountIds.length >= accountsList.length;
  };

  const removeItem = (itemKey: number) => {
    const newItems = [...items].filter((_, key) => key !== itemKey);

    setItems(newItems);
  };

  const handlePublish = () => {
    treasuryPresenter.publish({
      day: date,
      items: items,
    });
  };

  const onSubmitHour = (payload: TreasuryPresenterContract.SetHourPayload) => {
    treasuryPresenter.setHour({
      hour: `${DateUtil.todayString()} ${payload.hour}`,
    });
  };

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs
          title={t("treasury.title")}
          breadcrumbItem={t("treasury.menu")}
        />

        <Row>
          {loading ? (
            <Col xl={12} className="mt-4">
              <Loading />
            </Col>
          ) : (
            <Col xl={12}>
              <button
                type="button"
                className="btn btn-primary mt-3 ms-1 d-sm-flex align-items-center justify-content-start"
                onClick={() => setOpenHour(true)}
              >
                {t("treasury.setHour")}
                <FaCalendar style={{ marginLeft: "10px" }} />
              </button>

              <div className="d-sm-flex align-items-end justify-content-end mb-5">
                <Input
                  field="date"
                  type="date"
                  name="date"
                  className="form-control"
                  placeholder={`${t("Date placeholder")}`}
                  onChange={({ target: { value } }) => {
                    setDate(value);
                  }}
                  value={date}
                  style={{ width: "20%", marginRight: 10 }}
                />

                <button
                  type="button"
                  className="btn btn-primary mt-3 ms-1"
                  onClick={handlePublish}
                >
                  <FaSave />
                </button>
              </div>

              {date && (
                <div className="d-sm-flex align-items-end justify-content-between mb-5">
                  <h5 className="mb-3">
                    Saldo tesouraria{" "}
                    {date ? " - " + DateFormatStrategy.format(date) : ""}
                  </h5>

                  <button
                    type="button"
                    className="btn btn-primary ms-1"
                    disabled={allAccountsSelected()}
                    onClick={() => {
                      setItems([...items, { balance: 0, bankAccountId: 0 }]);
                    }}
                  >
                    <FaPlus />
                  </button>
                </div>
              )}

              {date &&
                items.map(({ balance, bankAccountId }, itemKey) => {
                  return (
                    <Row key={itemKey}>
                      <Col sm={6}>
                        <FormGroup>
                          <select
                            className="form-control"
                            onChange={({ target: { value } }) => {
                              const newItems = [...items];

                              newItems[itemKey].bankAccountId = Number(value);

                              setItems(newItems);
                            }}
                            defaultValue={bankAccountId}
                          >
                            <option value="">{t("Account placeholder")}</option>

                            {getAvailableAccounts(itemKey).map(
                              ({ id, name }) => (
                                <option value={id} key={id}>
                                  {name}
                                </option>
                              )
                            )}
                          </select>
                        </FormGroup>
                      </Col>

                      <Col sm={5}>
                        <FormGroup>
                          <NumericFormat
                            name="balance"
                            className="form-control"
                            placeholder={t("Balance")}
                            thousandSeparator="."
                            customInput={Input}
                            decimalSeparator=","
                            decimalScale={2}
                            fixedDecimalScale
                            defaultValue={balance}
                            onValueChange={({ floatValue }) => {
                              const newItems = [...items];

                              newItems[itemKey].balance = Number(
                                floatValue ?? 0
                              );

                              setItems(newItems);
                            }}
                          />
                        </FormGroup>
                      </Col>

                      <Col sm={1}>
                        <div className="d-sm-flex align-items-end justify-content-center">
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => {
                              removeItem(itemKey);
                            }}
                          >
                            <FaTrash />
                          </button>
                        </div>
                      </Col>
                    </Row>
                  );
                })}
            </Col>
          )}
        </Row>
      </Container>

      <SetHour
        isOpen={openHour}
        onDismiss={() => setOpenHour(false)}
        onSubmit={onSubmitHour}
      />
    </div>
  );
};

export default withTranslation()(TreasuryView);
