import React, { useMemo } from "react";
import UserListView from "@ui/views/users/UserListView";
import { makeUsersListPresenter } from "@main/factories/presenters/users";

const UserList: React.FC = () => {
   const usersListPresenter = useMemo(() => makeUsersListPresenter(), []);

   return <UserListView usersListPresenter={usersListPresenter} />;
};

export default UserList;
