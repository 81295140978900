import { Alert } from "reactstrap";
import { Error } from "@domain/interfaces/errors";

import { withTranslation } from "react-i18next";
import { Fragment } from "react";

interface ErrorAlertProps {
    t: any;
    errors: Error[];
    isOpen: boolean;
}

const ErrorAlert: React.FC<ErrorAlertProps> = ({ t, isOpen }) => {
    return !isOpen ? null : (
        <Fragment>
            <Alert color="danger">
                {
                    <div>
                        <h6 className="texte-center">{t("alert.message.error.password")}</h6>
                    </div>
                }
            </Alert>
        </Fragment>
    );
};

export default withTranslation()(ErrorAlert);
