import { AddUserService } from "@domain/services/users";
import { HttpRepository } from "@data/repositories";
import { HttpRequestBuilder } from "@data/builders";
import { HttpRequestTypes } from "@data/enums";

export class HttpAddUserService implements AddUserService {
  constructor(
    private readonly httpRepository: HttpRepository
  ) { }

  async handle(payload: AddUserService.Payload): Promise<boolean> {
    const request = HttpRequestBuilder
      .requestToUrl('/users')
      .withMethod(HttpRequestTypes.POST)
      .withData(payload)
      .build();

      const { status } = await this.httpRepository.makeRequest<AddUserService.Payload>(request);

      return status === 201;
  }
}
