import { OtpEmailStatus } from "@domain/enums";
import { GetOtpEmailStatusMessageStrategy } from "@data/interfaces";
import { CacheRepository } from "@data/repositories";
import { PtBrGetOtpEmailStatusMessageStrategy } from "./PtBrGetOtpEmailStatusMessageStrategy";

const listStrategies: Record<string, GetOtpEmailStatusMessageStrategy> = {
  'pt-BR': new PtBrGetOtpEmailStatusMessageStrategy(),
};

export class GetOtpEmailStatusMessageHelper {
  constructor(
    private readonly cacheRepository: CacheRepository,
  ) { }

  public async get(status: OtpEmailStatus) {
    const lang = await this.cacheRepository.get('I18N_LANGUAGE');
    const strategy = listStrategies[lang];
    const message = await strategy.get(status);

    return message;
  }
}
