import React, { useMemo } from "react";

import { LoginView } from "@ui/views/auth";
import { makeLoginPresenter } from "@main/factories";

export const Login: React.FC = () => {

  const loginPresenter = useMemo(() => makeLoginPresenter(), []);

  return <LoginView loginPresenter={loginPresenter} />;
};
