import { HttpGetBankAccountService } from "@data/services/account/HttpGetBankAccountService";
import { HttpRemoveBankAccountsService } from "@data/services/account/HttpRemoveBankAccountService";
import { AxiosHttpRepository } from "@infra/http";
import { API_BASE_URL } from "@main/constants/config";
import { BankAccountPresenter } from "@presentation/presenters/account/BankAccountPresenter";
import { BankAccountPresenterContract } from "@ui/presenters/account/BankAccountPresenterContract";

export const makeBankAccountPresenter = (): BankAccountPresenterContract => {
   const httpRepository = new AxiosHttpRepository(API_BASE_URL);
   const getBankAccountService = new HttpGetBankAccountService(httpRepository);
   const removeBankAccountServive = new HttpRemoveBankAccountsService(httpRepository);

   return new BankAccountPresenter(
      getBankAccountService,
      removeBankAccountServive
   );
};
