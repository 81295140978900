import { Source } from "@domain/entities";
import {
  GetSourcesService,
  RemoveSourcesService,
} from "@domain/services/sources";
import { SourceViewContract } from "@presentation/views/sources";
import { SourcesPresenterContract } from "@ui/presenters/sources";

export class SourcesPresenter implements SourcesPresenterContract {
  private view!: SourceViewContract;

  constructor(
    private readonly getSourceService: GetSourcesService,
    private readonly removeSourceService: RemoveSourcesService
  ) {}

  async removeSource(sourceId: number): Promise<void> {
    this.view.setLoadingState(true);

    const isRemoved = await this.removeSourceService.handle(sourceId);

    if (isRemoved) {
      this.get();
    } else {
      this.view.setLoadingState(false);
    }
  }

  setView(view: SourceViewContract): void {
    this.view = view;
  }

  async get(): Promise<Source[]> {
    this.view.setLoadingState(true);

    const source = await this.getSourceService.handle();

    this.view.setSource(source);
    this.view.setLoadingState(false);
    return source;
  }
}
