import { GroupsEvents } from "@domain/events";
import { AddGroupService } from "@domain/services/groups";
import { FormValidation } from "@presentation/validation/interfaces";
import { AddGroupViewContract } from "@presentation/views/groups";
import { AddGroupPresenterContract } from "@ui/presenters/groups";
import { EventEmitter } from "@utils/events";

export class AddGroupPresenter implements AddGroupPresenterContract {
  private view!: AddGroupViewContract;

  constructor(
    private readonly validation: FormValidation,
    private readonly addGroupService: AddGroupService
  ) { }

  setView(view: AddGroupViewContract): void {
    this.view = view;
  }

  async add(payload: AddGroupPresenterContract.Payload): Promise<boolean> {
    this.view.setLoading(true);
    this.view.openSimilarityFeedback(false);

    const validationSuccessfull = this.validation.allValid(payload);

    if (!validationSuccessfull) {
      this.view.updateValidationState(this.validation.errors(payload));
      this.view.setLoading(false);
      return false;
    }

    const { ok: isGroupAdded, errors } = await this.addGroupService.handle(payload);

    if (isGroupAdded) {
      EventEmitter.emit(GroupsEvents.GROUP_ADDED);
    } else {
      this.view.setSimilarity(errors);
      this.view.openSimilarityFeedback(true);
    }

    this.view.updateValidationState([]);
    this.view.setLoading(false);

    return isGroupAdded;
  }
}
