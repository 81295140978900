import { HttpRequestBuilder } from "@data/builders";
import { HttpRequestTypes } from "@data/enums";
import { HttpRepository } from "@data/repositories";
import { Similarity } from "@domain/interfaces/similarity";
import { EditProviderService } from "@domain/services/provider/EditProviderService";

interface HttpEditProviderResponse {
   message?: Similarity[];
}

interface HttpEditProviderRequest {
   titulo: string;
   mensagem: string;
}

export class HttpEditProviderService implements EditProviderService {
   constructor(
      private readonly httpRepository: HttpRepository
   ) { }

   async handle(payload: EditProviderService.Payload): Promise<EditProviderService.Response> {
      const request = HttpRequestBuilder
         .requestToUrl('/providers')
         .withMethod(HttpRequestTypes.PUT)
         .withData(payload)
         .build();

      const { data, status } =
         await this.httpRepository.makeRequest<HttpEditProviderRequest, HttpEditProviderResponse>(request);

      return {
         ok: status === 200,
         errors: Array.isArray(data.message) ? data.message : []
      };
   }
}
