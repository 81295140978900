import React, { Fragment, useState } from "react";

import { withTranslation } from "react-i18next";
import { Card, CardBody, Col, FormGroup, Label, Row } from "reactstrap";

import { FieldValidationBuilder } from "@presentation/validation/builders";
import { ValidationComposite } from "@presentation/validation/validators";
import { useValidation } from "@ui/hooks";
import { SelectItem } from "@ui/interfaces";
import { CashFlow } from "@domain/entities";
import { BalanceCheckPresenterContract } from "@ui/presenters/account";
import { GroupsByFlowsList } from "@domain/interfaces/accounts";

interface FormSearchPeriodProps {
  t: any;
  cashflows: CashFlow[];
  accounts: GroupsByFlowsList[];
  onSubmit(period: BalanceCheckPresenterContract.Payload): void;
}

const validation = new ValidationComposite([
  ...FieldValidationBuilder.field("requested_date").required().build(),
  ...FieldValidationBuilder.field("cashFlow").required().build(),
  ...FieldValidationBuilder.field("accountId").required().build(),
]);

const BalanceCheckFormSearch: React.FC<FormSearchPeriodProps> = ({
  t,
  cashflows,
  accounts,
  onSubmit,
}) => {
  const {
    ValidationFeedback,
    validationState,
    ValidationSelect,
    ValidationInput,
    updateValidationState,
  } = useValidation();

  
  const [payload, setPayload] = useState<BalanceCheckPresenterContract.Payload>({
    cashFlow: 0,
    accountId: 0,
    requested_date: "",
    requested_value: 0
  });

  const updateField = (fieldName: string, value: string) => {
    setPayload((oldState) => ({
      ...oldState,
      [fieldName]: value,
    }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (validation.allValid(payload)) {
      updateValidationState([]);
      onSubmit(payload);
    } else {
      updateValidationState(validation.errors(payload));
    }
  };

  const optionsCashflows: SelectItem[] = cashflows.map(({ id, name }) => ({
    label: name,
    value: id,
  }));
  const optionsAccounts: SelectItem[] = accounts
    .map(({ id, name }) => ({ label: name, value: id }));

  return (
    <Fragment>
      <form onSubmit={handleSubmit}>
        <Card>
          <CardBody>
            <Row>
              <Col md={6} className="mb-1">
              <FormGroup>
                  <Label>{t("Cashflows")}</Label>

                  <ValidationSelect
                    field="cashFlow"
                    options={optionsCashflows}
                    validationState={validationState}
                    name="cashFlow"
                    className="form-control"
                    placeholder={t("Select")}
                    onChange={({ target: { name, value } }) =>
                      updateField(name, value)
                    }
                  />
                  <ValidationFeedback field="cashFlow" />
                </FormGroup>
              </Col>

              <Col md={6} className="mb-1">
                <FormGroup>
                  <Label>{t("Bank accounts")}</Label>

                  <ValidationSelect
                    field="accountId"
                    options={optionsAccounts}
                    validationState={validationState}
                    name="accountId"
                    className="form-control"
                    placeholder={t("Account placeholder")}
                    onChange={({ target: { value, name } }) =>
                      updateField(name, value)
                    }
                  />

                  <ValidationFeedback field="accountId" />
                </FormGroup>
              </Col>

              <Col className="mb-3" md={6} sm={12}>
                <Label htmlFor="example-date-input" className="form-Label">
                  {t("Period")}
                </Label>

                <ValidationInput
                  field="requested_date"
                  validationState={validationState}
                  className="form-control"
                  type="date"
                  name="requested_date"
                  onChange={({ target: { name, value } }) =>
                    updateField(name, value)
                  }
                />

                <ValidationFeedback field="requested_date" />
              </Col>
              <Col sm={11} />
              <Col sm={1}>
                <button className="btn btn-primary">{t("Search")}</button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </form>
    </Fragment>
  );
};

export default withTranslation()(BalanceCheckFormSearch);
