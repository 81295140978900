import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Modal, Row, Col, Label, FormGroup } from "reactstrap";

import { useValidation } from "@ui/hooks";
import { AccountAdjustmentPresenterContract } from "@ui/presenters/account";
import { NumericFormat } from "react-number-format";

interface AccountAdjustmentViewProps {
    t: any;
    accountToAdjust: AccountAdjustmentPresenterContract.Payload | null;
    onDismiss(): void;
    addAccountAdjustmentPresenter: AccountAdjustmentPresenterContract;
}

const INITIAL_FORM_STATE: AccountAdjustmentPresenterContract.Payload = {
    bankAccountId: 0,
    balance: 0
};

const AccountAdjustmentView: React.FC<AccountAdjustmentViewProps> = ({ t, onDismiss, addAccountAdjustmentPresenter, accountToAdjust }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [payload, setPayload] = useState<AccountAdjustmentPresenterContract.Payload>(INITIAL_FORM_STATE);

    const { ValidationFeedback, ValidationInput, updateValidationState, validationState } = useValidation();

    useEffect(() => {
        addAccountAdjustmentPresenter.setView({ setLoadingState, updateValidationState });
    }, []);

    useEffect(() => {
        if (!accountToAdjust) {
            updateValidationState([]);
            setPayload(INITIAL_FORM_STATE);
        } else {
            setPayload({
               balance: accountToAdjust.balance,
               bankAccountId: accountToAdjust.bankAccountId
            });
         }
    }, [accountToAdjust]);

    const setLoadingState = (isLoading: boolean): void => setIsLoading(isLoading);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        addAccountAdjustmentPresenter.add(payload);
    };

    return (
        <Modal
            isOpen={Boolean(accountToAdjust)}
            toggle={isLoading ? undefined : onDismiss}
            scrollable={true}
            id="staticBackdrop"
            size="lg"
        >
            <div className="modal-header">
                <h5 className="modal-title">{t("Adjust Account")}</h5>
                <button
                    type="button"
                    className="btn-close"
                    onClick={isLoading ? undefined : onDismiss}
                    aria-label="Close"
                />
            </div>

            <form onSubmit={handleSubmit}>
                <div className="modal-body">
                    <Row>
                        <Col md={6} className="mb-1">
                            <FormGroup>
                                <Label>{t("Balance")}</Label>
                                <NumericFormat
                                    field="balance"
                                    validationState={validationState}
                                    name="balance"
                                    className="form-control"
                                    value={payload.balance}
                                    placeholder={t("Value placeholder")}
                                    thousandSeparator='.'
                                    customInput={ValidationInput}
                                    decimalSeparator=","
                                    decimalScale={2}
                                    fixedDecimalScale
                                    onValueChange={({ floatValue }) => {
                                        setPayload({
                                            ...payload,
                                            balance: floatValue ?? 0
                                        });
                                    }}
                                />

                                <ValidationFeedback field="balance" />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>

                <div className="modal-footer">
                    <button
                        type="button"
                        className="btn btn-danger me-2"
                        onClick={isLoading ? undefined : onDismiss}
                        disabled={isLoading}
                    >
                        {t("Close")}
                    </button>

                    <button className="btn btn-primary" disabled={isLoading}>
                        {t(isLoading ? "loading" : "Save")}
                    </button>
                </div>
            </form>
        </Modal>
    );
};

export default withTranslation()(AccountAdjustmentView);
