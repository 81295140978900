import { PercentFormat } from "@ui/interfaces";

export class PercentFormatBr implements PercentFormat {
   handle(value: number): string {
      return new Intl.NumberFormat(
         'pt-br',
         {
            style: 'decimal',
            maximumFractionDigits: 2,
            minimumFractionDigits: 2
         }
      ).format(value);
   }
}
