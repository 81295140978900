import { AccessProfile, CashFlow } from "@domain/entities";
import { UserEvents } from "@domain/events";
import { GetAccessProfilesService } from "@domain/services/accessProfiles";
import { GetCashflowsService } from "@domain/services/cashflows";
import { EditUserService } from "@domain/services/users";
import { FormValidation } from "@presentation/validation/interfaces";
import { EditUserViewContract } from "@presentation/views/users";
import { EditUserPresenterContract } from "@ui/presenters/users";
import { EventEmitter } from "@utils/events";

export class EditUserPresenter implements EditUserPresenterContract {
  private view!: EditUserViewContract;

  constructor(
    private readonly validation: FormValidation,
    private readonly getAccessProfilesService: GetAccessProfilesService,
    private readonly getCashflowsService: GetCashflowsService,
    private readonly editUserService: EditUserService,
  ) { }

  setView(view: EditUserViewContract): void {
    this.view = view;
  }

  async edit(payload: EditUserViewContract.Payload): Promise<void> {
    this.view.setLoading(true);

    const validationSuccessfull = this.validation.allValid(payload);

    if (!validationSuccessfull) {
      this.view.updateValidationState(this.validation.errors(payload));
      this.view.setLoading(false);
      return;
    }

    const isUserUpdated = await this.editUserService.handle(payload);

    if (isUserUpdated) {
      EventEmitter.emit(UserEvents.USER_UPDATED);
    }

    this.view.updateValidationState([]);
    this.view.setLoading(false);
  }

  async getAccessProfiles(): Promise<AccessProfile[]> {
    const accessProfiles = await this.getAccessProfilesService.handle();
    this.view.setAccessProfiles(accessProfiles);
    return accessProfiles;
  }

  async getCashflows(): Promise<CashFlow[]> {
    const cashflows = await this.getCashflowsService.handle();
    this.view.setCashflows(cashflows);
    return cashflows;
  }
}
