import { LoginService } from "@domain/services/auth";
import { FormValidation } from "@presentation/validation/interfaces";
import { LoginViewContract } from "@presentation/views";
import { LoginPresenterContract } from "@ui/presenters";

export class LoginPresenter implements LoginPresenterContract {
  private view!: LoginViewContract;

  constructor(
    private readonly loginService: LoginService,
    private readonly validation: FormValidation
  ) { }

  setView(view: LoginViewContract): void {
    this.view = view;
  }

  public async login(loginPayload: LoginPresenterContract.Payload): Promise<void> {
    this.view.setLoadingState(true);

    const validationSuccessfull = this.validation.allValid(loginPayload);

    if (!validationSuccessfull) {
      this.view.updateValidationState(this.validation.errors(loginPayload));
      this.view.setLoadingState(false);
      return;
    }

    const { isLoginSuccessfull } = await this.loginService.execute(loginPayload);

    if (!isLoginSuccessfull) {
      this.view.showErrorMessage();

      setTimeout(() => this.view.removeErrorMessage(), 5000);
    }

    this.view.setLoadingState(false);
  }
}
