import React, { Fragment, useEffect, useRef, useState } from "react";

import { GeneralBankStatementPrensenterContract } from "@ui/presenters/reports";

import { Button, Col, Container, Row, Table } from "reactstrap";
import ComponentToPrint from "@ui/components/Print";
import { useReactToPrint } from "react-to-print";

import Loading from "@ui/components/Loading";
import logo from "@ui/assets/images/logo.jpg";
import { Breadcrumbs } from "@ui/components/Common";

import { withTranslation } from "react-i18next";
import { AccountGroup, CashFlow } from "@domain/entities";
import SearchForm from "@ui/components/reports/general_bank_statement/SearchForm";
import { joinGeneralBankStatementTransactions } from "@utils/reports/GeneralBankStatement";
import { DateFormatStrategy, MoneyFormatStrategy } from "@ui/strategy";
import { TypeStatusColor } from "@ui/components/TypeStatusColor";
import { GroupsByFlowsList } from "@domain/interfaces/accounts";

interface GeneralBankStatementViewProps {
  t: any;
  generalBankStatementPresenter: GeneralBankStatementPrensenterContract;
}

const GeneralBankStatementView: React.FC<GeneralBankStatementViewProps> = ({
  t,
  generalBankStatementPresenter,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [accounts, setAccounts] = useState<GroupsByFlowsList[]>([]);
  const [cashflows, setCashflowsList] = useState<CashFlow[]>([]);
  const [accountGroups, setAccountGroupsList] = useState<AccountGroup[]>([]);
  const [statement, setBankStatement] =
    useState<GeneralBankStatementPrensenterContract.Response | null>(null);

  useEffect(() => {
    generalBankStatementPresenter.setView({
      setAccountGroups,
      setBankAccounts,
      setCashflows,
      setLoadingState,
      setGeneralBankStatementList,
    });

    generalBankStatementPresenter.getAccountGroups();
    generalBankStatementPresenter.getCashflows();
    generalBankStatementPresenter.getAccounts();
  }, []);

  const printRef = useRef(null);

  useEffect(() => {
    generalBankStatementPresenter.getAccountGroups();
    generalBankStatementPresenter.getCashflows();
    generalBankStatementPresenter.getAccounts();
  }, []);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `Saldo de Contas`,
    onPrintError: () => false,
    pageStyle: `
            .noPrint {
                display:none;
            }
        `,
  });

  const setLoadingState = (isLoading: boolean): void => setLoading(isLoading);

  const setAccountGroups = (accounts: AccountGroup[]): void =>
    setAccountGroupsList(accounts);

  const setBankAccounts = (bankAccounts: GroupsByFlowsList[]): void =>
    setAccounts(bankAccounts);

  const setCashflows = (cashflows: any[]): void =>
    setCashflowsList(cashflows);

  const setGeneralBankStatementList = (
    statement: GeneralBankStatementPrensenterContract.Response
  ): void => setBankStatement(statement);

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs
          title={t("Bank accounts")}
          breadcrumbItem={t("General bank statement")}
        />

        <Row>
          <Col xl={12} className="mt-4">
            <SearchForm
              accounts={accounts}
              cashflows={cashflows}
              onSubmit={(params) => {
                generalBankStatementPresenter.get(params);
              }}
            />

            <div className="d-sm-flex align-items-center justify-content-end">
              <Button
                color="primary"
                className="btn btn-primary float-right"
                onClick={handlePrint}
              >
                {t("Print")}
              </Button>
            </div>

            {loading ? (
              <div className="mt-5">
                <Loading />
              </div>
            ) : (
              <Fragment>
                <ComponentToPrint ref={printRef}>
                  <div className="font-arial-rlt">
                    <div className="d-sm-flex flex-column align-items-center justify-content-center mt-5 mb-3">
                      <img
                        style={{ objectFit: "contain" }}
                        className=""
                        src={logo}
                        height="150"
                        width="150"
                      />

                      <h4>{t("General bank statement")}</h4>
                    </div>

                    {statement && (
                      <Table bordered>
                        <thead>
                          <tr>
                            <th>{t("Flow")}</th>
                            <th>{t("Group")}</th>
                            <th>{t("Description")}</th>
                            <th>{t("Operation")}</th>
                            <th>{t("Date")}</th>
                            <th>{t("Value")}</th>
                            <th>{t("Type")}</th>
                            <th>{t("Account")}</th>
                            <th>{t("Provider")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {joinGeneralBankStatementTransactions(
                            statement,
                            accountGroups
                          ).map(
                            (
                              {
                                account,
                                date,
                                description,
                                flow,
                                group,
                                operation,
                                provider,
                                type,
                                value,
                              },
                              key
                            ) => (
                              <tr key={key}>
                                <td>{flow}</td>
                                <td>{group}</td>
                                <td>{description}</td>
                                <td>{operation}</td>
                                <td>{DateFormatStrategy.format(date)}</td>
                                <td>
                                  {MoneyFormatStrategy.format(
                                    parseFloat(value)
                                  )}
                                </td>
                                <td>{<TypeStatusColor type={type} />}</td>
                                <td>{account}</td>
                                <td>{provider}</td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </Table>
                    )}
                  </div>
                </ComponentToPrint>
              </Fragment>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default withTranslation()(GeneralBankStatementView);
