import { HttpGetCashflowsService } from "@data/services/cashflows";
import { HttpGetConsolidatedReportService } from "@data/services/consolidated-report/HttpGetConsolidatedReportService";
import { HttpGetGroupsService } from "@data/services/groups";
import { HttpGetSubgroupsService } from "@data/services/revenues-expenses";
import { AxiosHttpRepository } from "@infra/http";
import { API_BASE_URL } from "@main/constants/config";
import { RevenuesFromGroupsPresenter } from "@presentation/presenters/dashboard";
import { RevenuesFromGroupsPresenterContract } from "@ui/presenters/dashboard";

export const makeRevenuesFromGroupsPresenter = (): RevenuesFromGroupsPresenterContract => {
  const httpRepository = new AxiosHttpRepository(API_BASE_URL);
  const getConsolidatedReportService = new HttpGetConsolidatedReportService(httpRepository);
  const getGroupsService = new HttpGetGroupsService(httpRepository);
  const getSubgroupsService = new HttpGetSubgroupsService(httpRepository);
  const getCashflowsService = new HttpGetCashflowsService(httpRepository);

  return new RevenuesFromGroupsPresenter(
    getConsolidatedReportService,
    getGroupsService,
    getSubgroupsService,
    getCashflowsService
  );
};
