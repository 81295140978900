import { HttpAddAccountService } from "@data/services/account";
import { HttpGetAccountGroupsService } from "@data/services/account/HttpGetAccountGroupsService";
import { HttpGetBankService } from "@data/services/account/HttpGetBankService";
import { HttpGetCashflowsService } from "@data/services/cashflows";
import { HttpGetSourcesService } from "@data/services/sources";
import { AxiosHttpRepository } from "@infra/http";
import { API_BASE_URL } from "@main/constants/config";
import { AddAccountPresenter } from "@presentation/presenters/account";
import { FieldValidationBuilder } from "@presentation/validation/builders";
import { ValidationComposite } from "@presentation/validation/validators";
import { AddAccountPresenterContract } from "@ui/presenters/account";

export const makeAddAccountPresenter = (): AddAccountPresenterContract => {
  const validation = new ValidationComposite([
    ...FieldValidationBuilder.field("name").required().build(),
    ...FieldValidationBuilder.field("group").required().build(),
    ...FieldValidationBuilder.field("cashflow").required().build(),
    ...FieldValidationBuilder.field("bankId").required().build(),
    ...FieldValidationBuilder.field("balance").required().build(),
    ...FieldValidationBuilder.field("sourceId").required().build(),
    ...FieldValidationBuilder.field("initialDate").required().build(),
  ]);

  const httpRepository = new AxiosHttpRepository(API_BASE_URL);
  const getBanksService = new HttpGetBankService(httpRepository);
  const getAccountGroupsService = new HttpGetAccountGroupsService(
    httpRepository
  );
  const getCashflowsService = new HttpGetCashflowsService(httpRepository);
  const getSourcesService = new HttpGetSourcesService(httpRepository);
  const addAccountService = new HttpAddAccountService(httpRepository);

  return new AddAccountPresenter(
    validation,
    getBanksService,
    getAccountGroupsService,
    getCashflowsService,
    getSourcesService,
    addAccountService
  );
};
