import { AxiosHttpRepository } from "@infra/http";
import { API_BASE_URL } from "@main/constants/config";
import { EditPrefecturePresenter } from "@presentation/presenters/prefecture";
import { FieldValidationBuilder } from "@presentation/validation/builders";
import { ValidationComposite } from "@presentation/validation/validators";
import { HttpGetStatesService } from "@data/services/states";
import { EditPrefecturePresenterContract } from "@ui/presenters/prefecture";
import { HttpEditPrefectureService } from "@data/services/prefecture";
import { HttpGetCitiesService } from "@data/services/cities";

export const makeEditPrefecturePresenter = (): EditPrefecturePresenterContract => {
    const validation = new ValidationComposite([
        ...FieldValidationBuilder.field('cityName').required().build(),
        ...FieldValidationBuilder.field('stateName').required().build()
    ]);

    const httpRepository = new AxiosHttpRepository(API_BASE_URL);
    const editPrefecturePresenter = new HttpEditPrefectureService(httpRepository);
    const getStatesService = new HttpGetStatesService(httpRepository);
    const getCitiesService = new HttpGetCitiesService(httpRepository);

    return new EditPrefecturePresenter(
        validation,
        editPrefecturePresenter,
        getStatesService,
        getCitiesService
    );
};
