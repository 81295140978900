import { Validation } from "@presentation/validation/interfaces";

export class PasswordValidation implements Validation {
  private readonly MINIMUM_OF_CHARACTERS = 6;

  constructor(
    private readonly fieldName: string,
    private readonly lessThanMinimumMessage: string,
    private readonly notAlphaNumericMessage: string,
  ) {}

  private hasAtLeastTheMinimumOfCharacters(input: any): Validation.ValidationResult {
    const isValid = input[this.fieldName].length >= this.MINIMUM_OF_CHARACTERS;
    const message = this.lessThanMinimumMessage.replace(':min', String(this.MINIMUM_OF_CHARACTERS));

    return { isValid, fieldName: this.fieldName, message };
  }

  private isAlphaNumeric(input: any): Validation.ValidationResult {
    const regex = /((^[0-9]+[a-z]+)|(^[a-z]+[0-9]+))+[0-9a-z]+$/i;
    const isValid = regex.test(input[this.fieldName]);

    return { isValid, fieldName: this.fieldName, message: this.notAlphaNumericMessage };
  }

  private tryAllRules(input: any): Validation.ValidationResult[] {
    const listOfVerifiedRules = [this.hasAtLeastTheMinimumOfCharacters(input), this.isAlphaNumeric(input)];

    return listOfVerifiedRules.filter(({isValid}) => !isValid);
  }

  validate(input: any): Validation.ValidationResult[] {
    const defaultResult = [{ isValid: true, fieldName: this.fieldName, message: 'valid_password' }];
    const results = this.tryAllRules(input);

    if(results.length) return results; 
    
    return defaultResult;
  }
}
