import { format, lastDayOfMonth } from 'date-fns';

interface Period {
  begin: string;
  end: string;
}

export class GetMonthPeriod {
  static getPeriod(): Period {
    const today = new Date();
    const end = lastDayOfMonth(today);

    return {
      begin: format(today, 'yyyy-MM') + '-01',
      end: format(end, 'yyyy-MM-dd'),
    };
  }
}
