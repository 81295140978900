import React, { useMemo } from 'react';

import { ForgotPasswordRecoverView } from '@ui/views/auth';
import { makeForgotPasswordRecoverPresenter } from '@main/factories';

export const ForgotPasswordRecover = () => {
  const forgotPasswordRecoverPresenter = useMemo(() => makeForgotPasswordRecoverPresenter(), []);

  return <ForgotPasswordRecoverView forgotPasswordRecoverPresenter={forgotPasswordRecoverPresenter} />;
};
