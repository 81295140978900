import React, { Fragment, useEffect, useRef, useState } from "react";

import { Button, Col, Container, Row, Table } from "reactstrap";
import ComponentToPrint from "@ui/components/Print";
import { useReactToPrint } from "react-to-print";

import { ReconcileBalancePresenterContract } from "@ui/presenters/account";
import { ReconcileBalanceFormSearch } from "@ui/components/accounts";
import { CashFlow } from "@domain/entities";

import { Breadcrumbs } from "@ui/components/Common";
import logo from "@ui/assets/images/logo.jpg";

import Loading from "@ui/components/Loading";
import { withTranslation } from "react-i18next";
import { DateFormatStrategy, MoneyFormatStrategy } from "@ui/strategy";
import { GroupsByFlowsList } from "@domain/interfaces/accounts";

interface ReconcileBalanceViewProps {
  t: any;
  reconcileBalancePresenter: ReconcileBalancePresenterContract;
}

const ReconcileBalanceView: React.FC<ReconcileBalanceViewProps> = ({
  t,
  reconcileBalancePresenter,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [cashflows, setCashflow] = useState<CashFlow[]>([]);
  const [bankAccounts, setBankAccount] = useState<GroupsByFlowsList[]>([]);
  const [reconcileBalance, setReconcileBalance] =
    useState<ReconcileBalancePresenterContract.Data | null>(null);

  useEffect(() => {
    reconcileBalancePresenter.setView({
      setBankAccounts,
      setCashflows,
      setLoadingState,
      setReconcileBalances,
    });

    reconcileBalancePresenter.getBankAccounts();
    reconcileBalancePresenter.getCashflows();
  }, []);

  const printRef = useRef(null);

  useEffect(() => {
    reconcileBalancePresenter.getBankAccounts();
    reconcileBalancePresenter.getCashflows();
  }, []);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `Saldo de Contas`,
    onPrintError: () => false,
    pageStyle: `
            .noPrint {
                display:none;
            }
        `,
  });

  const setLoadingState = (isLoading: boolean): void => setLoading(isLoading);
  const setBankAccounts = (bankAccount: GroupsByFlowsList[]): void =>
    setBankAccount(bankAccount);
  const setCashflows = (cashflows: CashFlow[]): void => setCashflow(cashflows);
  const setReconcileBalances = (
    balance: ReconcileBalancePresenterContract.Data
  ): void => setReconcileBalance(balance);

  return (
    <div>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={t("Bank accounts")}
            breadcrumbItem={t("Reconcile balance")}
          />
        </Container>
        <Row>
          <Col xl={12} className="mt-4">
            <ReconcileBalanceFormSearch
              cashflows={cashflows}
              accounts={bankAccounts}
              onSubmit={(formData) => {
                reconcileBalancePresenter.get(formData);
              }}
            />

            <div className="d-sm-flex align-items-center justify-content-end">
              <Button
                color="primary"
                className="btn btn-primary float-right"
                onClick={handlePrint}
              >
                {t("Print")}
              </Button>
            </div>

            {loading ? (
              <div className="mt-5">
                <Loading />
              </div>
            ) : (
              <Fragment>
                <ComponentToPrint ref={printRef}>
                  <div className="font-arial-rlt">
                    <div className="d-sm-flex flex-column align-items-center justify-content-center mt-5 mb-3">
                      <img
                        style={{ objectFit: "contain" }}
                        className=""
                        src={logo}
                        height="150"
                        width="150"
                      />
                      <h4>{t("Reconcile balance")}</h4>
                    </div>

                    {reconcileBalance && (
                      <Table bordered>
                        <thead>
                          <tr>
                            <th style={{ width: "30%" }}>{t("Account")}</th>
                            <th className="text-center">{t("Date")}</th>
                            <th className="text-center">
                              {t("Searched balance")}
                            </th>
                            <th className="text-center">
                              {t("Balance in the system")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td style={{ width: "30%" }}>
                              {reconcileBalance.account.name}
                            </td>
                            <td className="text-center">
                              {DateFormatStrategy.format(
                                reconcileBalance.requested_date
                              )}
                            </td>
                            <td className="text-center">
                              {MoneyFormatStrategy.format(
                                reconcileBalance.requested_value
                              )}
                            </td>
                            <td className="text-center">
                              {MoneyFormatStrategy.format(
                                reconcileBalance.final_value
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    )}
                  </div>
                </ComponentToPrint>
              </Fragment>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default withTranslation()(ReconcileBalanceView);
