import React, { useMemo } from "react";

import { EditGroupPresenterContract } from "@ui/presenters/groups";
import { EditGroupView } from "@ui/views/groups";
import { makeEditGroupPresenter } from "@main/factories/presenters/groups";

interface EditGroupProps {
  groupToEdit: EditGroupPresenterContract.Payload | null;
  onDismiss(): void;
}

export const EditGroup: React.FC<EditGroupProps> = (props) => {
  const editGroupPresenter = useMemo(() => makeEditGroupPresenter(), []);

  return <EditGroupView {...props} editGroupPresenter={editGroupPresenter} />;
};
