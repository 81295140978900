import React, { useMemo } from "react";

import NonEffectiveBillsView from "@ui/views/bills/NonEffectiveBillsView";

import { makeNonEffectiveBillsPresenter } from "@main/factories/presenters/bills";

const NonEffectiveBills: React.FC = () => {
   const nonEffectiveBillsPresenter = useMemo(() => makeNonEffectiveBillsPresenter(), []);

   return <NonEffectiveBillsView nonEffectiveBillsPresenter={nonEffectiveBillsPresenter} />;
};

export default NonEffectiveBills;
