import { HttpRequestBuilder } from "@data/builders";
import { HttpRequestTypes } from "@data/enums";
import { HttpRepository } from "@data/repositories";
import { City } from "@domain/entities";
import { GetCitiesService } from "@domain/services/cities";

interface IBGECity {
  id: number;
  nome: string;
}

export class HttpGetCitiesService implements GetCitiesService {
  constructor(
    private readonly httpRepository: HttpRepository
  ) { }

  async handle(state: number): Promise<City[]> {
    const request = HttpRequestBuilder
      .requestToUrl(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state}/municipios`)
      .withMethod(HttpRequestTypes.GET)
      .build();

    const { data } = await this.httpRepository.makeRequest<any, IBGECity[]>(request);

    return data.map(({ id, nome }) => ({
      id: id,
      name: nome
    }));
  }
}
