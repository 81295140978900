import { useEffect, useState } from "react";

import {
  EditSourcePresenterContract,
  SourcesPresenterContract,
} from "@ui/presenters/sources";
import { withTranslation } from "react-i18next";
import { Source } from "@domain/entities";
import { EventEmitter } from "@utils/events";
import { SourceEvents } from "@domain/events/SourcesEvents";
import { Col, Container, Row, Table } from "reactstrap";
import { Breadcrumbs } from "@ui/components/Common";
import Loading from "@ui/components/Loading";
import AddSources from "@main/pages/sources/AddSources";
import EditSources from "@main/pages/sources/EditSources";
import AddActionButton from "@ui/components/AddActionButton";
import EditActionButton from "@ui/components/EditActionButton";
import RemoveActionButton from "@ui/components/RemoveActionButton";

interface SourcesViewProp {
  t: any;
  sourcesPresenter: SourcesPresenterContract;
}

const SourcesView: React.FC<SourcesViewProp> = ({ t, sourcesPresenter }) => {
  const [loading, setLoading] = useState(false);
  const [sources, setSources] = useState<Source[]>([]);
  const [isAddingSources, setIsAddingSources] = useState<boolean>(false);
  const [sourceToEdit, setSourcesToEdit] =
    useState<EditSourcePresenterContract.Payload | null>(null);

  useEffect(() => {
    const setSource = (sources: Source[]) => setSources(sources);
    const setLoadingState = (isLoading: boolean) => setLoading(isLoading);

    sourcesPresenter.setView({ setSource, setLoadingState });
    sourcesPresenter.get();

    const onSourcesAdded = () => {
      setIsAddingSources(false);
      sourcesPresenter.get();
    };

    const onSourcesUpdated = () => {
      setSourcesToEdit(null);
      sourcesPresenter.get();
    };

    EventEmitter.subscribe(SourceEvents.SOURCE_ADDED, onSourcesAdded);
    EventEmitter.subscribe(SourceEvents.SOURCE_EDITED, onSourcesUpdated);

    return () => {
      EventEmitter.unsubscribe(SourceEvents.SOURCE_ADDED, onSourcesAdded);
      EventEmitter.unsubscribe(SourceEvents.SOURCE_EDITED, onSourcesUpdated);
    };
  }, [sourcesPresenter]);

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title={t("records")} breadcrumbItem={t("Sources")} />
        <Row>
          {loading ? (
            <Col xl={12} className="mt-4">
              <Loading />
            </Col>
          ) : (
            <Col xl={12} className="mt-4">
              <div className="d-sm-flex align-items-center justify-content-end">
                <AddActionButton
                  type="button"
                  className="btn btn-primary mb-3"
                  onClick={() => setIsAddingSources(true)}
                />
              </div>

              <div className="table-responsive">
                <Table className="align-middle mb-0" bordered>
                  <thead>
                    <tr>
                      <th>{t("Source")}</th>
                      <th>{t("Flow")}</th>
                      <th>{t("Group")}</th>
                      <th>{t("Source Description") + " " + t("Source")}</th>
                      <th style={{ width: "20%" }} className="text-center">
                        {t("Actions")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {sources.map(
                      ({ name, id, cashflow, code, cashflowData, group }, key) => (
                        <tr key={key}>
                          <td scope="row">{code}</td>
                          <td scope="row">{cashflowData?.name}</td>
                          <td scope="row">{group}</td>
                          <td scope="row">{name}</td>
                          <td className="text-center">
                            <EditActionButton
                              isLastOfList={false}
                              label={t("Edit")}
                              onClick={() =>
                                setSourcesToEdit({
                                  id,
                                  cashflow,
                                  code,
                                  name,
                                  group
                                })
                              }
                            />

                            <RemoveActionButton
                              onClick={() => {
                                sourcesPresenter.removeSource(id);
                                sourcesPresenter.get();
                              }}
                              isLastOfList={true}
                              label={t("Remove")}
                            />
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </Table>
              </div>
            </Col>
          )}
        </Row>
      </Container>

      <AddSources
        isOpen={isAddingSources}
        onDismiss={() => setIsAddingSources(false)}
      />

      <EditSources
        sourcesToEdit={sourceToEdit}
        onDismiss={() => setSourcesToEdit(null)}
      />
    </div>
  );
};

export default withTranslation()(SourcesView);
