import { HttpRequestBuilder } from "@data/builders";
import { HttpRequestTypes } from "@data/enums";
import {
  HttpRepository,
  CacheRepository,
  NavigateRepository,
} from "@data/repositories";
import { AppRoutes } from "@domain/enums";
import { LoginService } from "@domain/services/auth";

interface LoginResponseData {
  ok: boolean;
  token: string;
}

export class HttpLoginService implements LoginService {
  constructor(
    private readonly httpRepository: HttpRepository,
    private readonly cacheRepository: CacheRepository,
    private readonly navigateRepository: NavigateRepository
  ) {}

  async execute(payload: LoginService.Payload): Promise<LoginService.Result> {
    const httpRequest = HttpRequestBuilder.requestToUrl("/auth/login")
      .withData(payload)
      .withMethod(HttpRequestTypes.POST)
      .build();

    const { data, status } = await this.httpRepository.makeRequest<
      LoginService.Payload,
      LoginResponseData
    >(httpRequest);

    const isLoginSuccessfull = this.isSuccessFull(status);

    if (isLoginSuccessfull) {
      await this.cacheRepository.add("authUser", data.token);
      await this.navigateRepository.navigate(AppRoutes.DASHBOARD);
    }

    return {
      data,
      statusCode: status,
      isLoginSuccessfull,
    };
  }

  private isSuccessFull(status: number) {
    return status >= 200 && status <= 299;
  }
}
