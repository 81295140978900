import { HttpCopyPlanningService } from "@data/services/planning/HttpCopyPlanningService";
import { HttpGetPrefectureService } from "@data/services/prefecture";
import { AxiosHttpRepository } from "@infra/http";
import { API_BASE_URL } from "@main/constants/config";
import { CopyPlanningPresenter } from "@presentation/presenters/planning/CopyPlanningPresenter";
import { FieldValidationBuilder } from "@presentation/validation/builders";
import { ValidationComposite } from "@presentation/validation/validators";
import { CopyPlanningPresenterContract } from "@ui/presenters/planning";

export const makeCopyPlanningPresenter = (): CopyPlanningPresenterContract => {
    const validation = new ValidationComposite([
        ...FieldValidationBuilder.field('prefectureToBeCopied').required().build(),
        ...FieldValidationBuilder.field('prefectureToInsert').required().build()
    ]);

    const httpRepository = new AxiosHttpRepository(API_BASE_URL);
    const copyPlanningService = new HttpCopyPlanningService(httpRepository);
    const getPrefectureService = new HttpGetPrefectureService(httpRepository);

    return new CopyPlanningPresenter(
        validation,
        getPrefectureService,
        copyPlanningService
    );
};
