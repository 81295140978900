import React, { useMemo } from "react";

import { EditSubgroupView } from "@ui/views/revenues-expenses";
import { makeEditSubgroupPresenter } from "@main/factories/presenters/revenues-expenses";
import { EditSubgroupPresenterContract } from "@ui/presenters/revenues-expenses";

interface EditSubgroupProps {
  subgroupToEdit: EditSubgroupPresenterContract.Payload | null;
  onDismiss(): void;
}

export const EditSubgroup: React.FC<EditSubgroupProps> = (props) => {
  const editSubgroupPresenter = useMemo(() => makeEditSubgroupPresenter(), []);

  return <EditSubgroupView {...props} editSubgroupPresenter={editSubgroupPresenter} />;
};
