export class DocumentValidator {
    static isCPFValid(cpf: string): boolean {
        cpf = cpf.replace(/[^\d]/g, "");

        if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) return false;

        const digitCalculator = (factor: number) => {
            const sum = cpf
                .slice(0, factor - 1)
                .split('')
                .reduce((sum, num, index) => sum + parseInt(num) * (factor - index), 0);

            const result = sum % 11;
            return result < 2 ? 0 : 11 - result;
        };

        const digit1 = digitCalculator(10);
        const digit2 = digitCalculator(11);

        return digit1 === parseInt(cpf[9]) && digit2 === parseInt(cpf[10]);
    }

    static isCNPJValid(cnpj: string): boolean {
        const cleanedCNPJ = cnpj.replace(/[^\d]+/g, '');

        if (cleanedCNPJ.length !== 14) return false;

        if (/^(\d)\1+$/.test(cleanedCNPJ)) return false;

        const calculateDigit = (cnpj: string, weights: number[]) => {
            const sum = cnpj
                .split('')
                .slice(0, weights.length)
                .reduce((acc, curr, i) => acc + parseInt(curr) * weights[i], 0);

            const remainder = sum % 11;
            return remainder < 2 ? 0 : 11 - remainder;
        };

        const firstDigit = calculateDigit(cleanedCNPJ, [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]);
        const secondDigit = calculateDigit(cleanedCNPJ, [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]);

        return (
            firstDigit === parseInt(cleanedCNPJ[12]) &&
            secondDigit === parseInt(cleanedCNPJ[13])
        );
    }
}
