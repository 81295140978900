import { HttpRequestBuilder } from "@data/builders";
import { HttpRequestTypes } from "@data/enums";
import { HttpRepository } from "@data/repositories";
import { AccessProfile } from "@domain/entities";
import { GetUserAccessService } from "@domain/services/users";

export class HttpGetUserAccessService implements GetUserAccessService {
    constructor(
      private readonly httpRepository: HttpRepository
    ) { }
  
    async handle(): Promise<AccessProfile[]> {
      const request = HttpRequestBuilder
        .requestToUrl('/users/access')
        .withMethod(HttpRequestTypes.GET)
        .build();
  
      const { data } = await this.httpRepository.makeRequest<any, AccessProfile[]>(request);
  
      return data;
    }
  }
