import { CashFlow } from "@domain/entities";
import { GroupsByFlowsList } from "@domain/interfaces/accounts";
import { GetBankAccountService } from "@domain/services/account/GetBankAccountService";
import { GetCashflowsService } from "@domain/services/cashflows";
import { AccountsBalanceViewContract } from "@presentation/views/dashboard";
import { AccountsBalancePresenterContract } from "@ui/presenters/dashboard";

interface FlowsColors {
  [key: string]: string;
}

const FLOWS_COLORS: FlowsColors = {
  '1': '#5e70ef',
  '2': '#f37777',
  '3': '#ffa362',
  '4': '#55d054',
};

export class AccountsBalancePresenter implements AccountsBalancePresenterContract {
  private view!: AccountsBalanceViewContract;

  constructor(
    private readonly getBankAccountService: GetBankAccountService,
    private readonly getCashflowsService: GetCashflowsService
  ) { }

  setView(view: AccountsBalanceViewContract): void {
    this.view = view;
  }

  async getData(): Promise<AccountsBalancePresenterContract.ChartData[]> {
    const cashflows = await this.getCashflowsService.handle();
    const accounts = await this.getBankAccountService.handle();
    const chartData = this.mountChartData(cashflows, accounts);

    this.view.setAccountBalanceData(chartData);

    return chartData;
  }

  private mountChartData(cashflows: CashFlow[], accounts: GroupsByFlowsList[]) {
    const chartData = cashflows.map(({ name, id }) => {
      const totalBalance = 0;
  
      return {
        cashflow: name,
        cashflowColor: FLOWS_COLORS[id],
        total: totalBalance.toString(),
      };
    });
  
    return chartData;
  }
}
