import React, { useMemo } from "react";

import EffectiveBillsView from "@ui/views/bills/EffectiveBillsView";

import { makeEffectiveBillsPresenter } from "@main/factories/presenters/bills";

const EffectiveBills: React.FC = () => {
   const effectiveBillsPresenter = useMemo(() => makeEffectiveBillsPresenter(), []);

   return <EffectiveBillsView effectiveBillsPresenter={effectiveBillsPresenter} />;
};

export default EffectiveBills;
