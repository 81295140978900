import React, { useMemo } from "react";

import RevenuesAndExpensesView from "@ui/views/revenues-expenses/RevenuesAndExpensesView";
import { makeRevenuesAndExpensesPresenter } from "@main/factories/presenters/revenues-expenses";

const RevenuesAndExpenses: React.FC = () => {
   const revenuesAndExpensesPresenter = useMemo(() => makeRevenuesAndExpensesPresenter(), []);

   return <RevenuesAndExpensesView revenuesAndExpensesPresenter={revenuesAndExpensesPresenter} />;
};

export default RevenuesAndExpenses;
