import { HttpRequestBuilder } from "@data/builders";
import { HttpRequestTypes } from "@data/enums";
import { HttpRepository } from "@data/repositories";
import { EditSourcesService } from "@domain/services/sources/EditSourcesService";

export class HttpEditSourcesService implements EditSourcesService {
   constructor(
      private readonly httpRepository: HttpRepository
   ) { }

   async handle(payload: EditSourcesService.Payload): Promise<boolean> {
      const request = HttpRequestBuilder
         .requestToUrl('/sources')
         .withMethod(HttpRequestTypes.PUT)
         .withData(payload)
         .build();

      const { status } = await this.httpRepository.makeRequest<EditSourcesService.Payload>(request);

      return status === 200;
   }
}
