import { HttpGetNonEffectiveBillsService, HttpRemoveNonEffectiveBillsService } from "@data/services/bills";
import { AxiosHttpRepository } from "@infra/http";
import { API_BASE_URL } from "@main/constants/config";
import { NonEffectiveBillsPresenter } from "@presentation/presenters/bills";
import { NonEffectiveBillsPresenterContract } from "@ui/presenters/bills";

export const makeNonEffectiveBillsPresenter = (): NonEffectiveBillsPresenterContract => {
    const httpRepository = new AxiosHttpRepository(API_BASE_URL);
    const getNonEffectiveBillsService = new HttpGetNonEffectiveBillsService(httpRepository);
    const removeEffectiveBillsService = new HttpRemoveNonEffectiveBillsService(httpRepository);
    return new NonEffectiveBillsPresenter(
        getNonEffectiveBillsService,
        removeEffectiveBillsService
    );
};
