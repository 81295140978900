import { AccessProfile, Prefecture, CashFlow } from "@domain/entities";
import { UserEvents } from "@domain/events";
import { GetAccessProfilesService } from "@domain/services/accessProfiles";
import { GetCashflowsService } from "@domain/services/cashflows";
import { GetPrefectureService } from "@domain/services/prefecture";
import { AddUserService } from "@domain/services/users";
import { FormValidation } from "@presentation/validation/interfaces";
import { AddUserViewContract } from "@presentation/views/users";
import { AddUserPresenterContract } from "@ui/presenters/users";
import { EventEmitter } from "@utils/events";

export class AddUserPresenter implements AddUserPresenterContract {
  private view!: AddUserViewContract;

  constructor(
    private readonly validation: FormValidation,
    private readonly getPrefectureService: GetPrefectureService,
    private readonly getCashflowService: GetCashflowsService,
    private readonly getAccessProfilesService: GetAccessProfilesService,
    private readonly addUserService: AddUserService
  ) { }

  setView(view: AddUserViewContract): void {
    this.view = view;
  }

  async add(payload: AddUserPresenterContract.Payload): Promise<void> {
    this.view.setLoading(true);

    const validationSuccessfull = this.validation.allValid(payload);

    if (!validationSuccessfull) {
      this.view.updateValidationState(this.validation.errors(payload));
      this.view.setLoading(false);
      return;
    }

    const isUserAdded = await this.addUserService.handle(payload);

    if (isUserAdded) {
      EventEmitter.emit(UserEvents.USER_ADDED);
    }

    this.view.updateValidationState([]);
    this.view.setLoading(false);
  }

  async getPrefectures(): Promise<Prefecture[]> {
    const prefecture = await this.getPrefectureService.handle();
    this.view.setPrefectures(prefecture);
    return prefecture;
  }

  async getCashflows(): Promise<CashFlow[]> {
    const cashflow = await this.getCashflowService.handle();
    this.view.setCashflows(cashflow);
    return cashflow;
  }

  async getAccessProfiles(): Promise<AccessProfile[]> {
    const accessProfiles = await this.getAccessProfilesService.handle();
    this.view.setAccessProfiles(accessProfiles);
    return accessProfiles;
  }
}
