import { HttpSendEmailForgotPasswordService } from "@data/services";
import { AxiosHttpRepository } from "@infra/http";
import { HistoryNavigateRepository } from "@infra/navigate";
import { API_BASE_URL } from "@main/constants/config";
import { ForgotPasswordPresenter } from "@presentation/presenters";
import { FieldValidationBuilder } from "@presentation/validation/builders";
import { ValidationComposite } from "@presentation/validation/validators";
import { ForgotPasswordPresenterContract } from "@ui/presenters";

export const makeForgotPasswordPresenter = (): ForgotPasswordPresenterContract => {
  const validation = new ValidationComposite([
    ...FieldValidationBuilder.field('email').email().build(),
  ]);

  const navigateRepository = new HistoryNavigateRepository();
  const httpRepository = new AxiosHttpRepository(API_BASE_URL);

  const sendEmailForgotPasswordService = new HttpSendEmailForgotPasswordService(
    httpRepository,
    navigateRepository
  );

  return new ForgotPasswordPresenter(
    validation,
    sendEmailForgotPasswordService
  );
};
