import { HttpAccountAdjustmentService } from "@data/services/account";
import { AxiosHttpRepository } from "@infra/http";
import { API_BASE_URL } from "@main/constants/config";
import { AccountAdjustmentPresenterContract } from "@ui/presenters/account";
import { AccountAdjustmentPresenter } from "@presentation/presenters/account";
import { FieldValidationBuilder } from "@presentation/validation/builders";
import { ValidationComposite } from "@presentation/validation/validators";

export const makeAccountAdjustmentPresenter = (): AccountAdjustmentPresenterContract => {
    const validation = new ValidationComposite([
      ...FieldValidationBuilder.field('bankAccountId').required().build(),
      ...FieldValidationBuilder.field('balance').required().build()
    ]);
  
    const httpRepository = new AxiosHttpRepository(API_BASE_URL);
    const addAccountAdjustmentService = new HttpAccountAdjustmentService(httpRepository);
  
    return new AccountAdjustmentPresenter(
      validation,
      addAccountAdjustmentService
    );
  };
