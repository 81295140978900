import { CacheRepository } from "@data/repositories";

export class LocalStorageCacheRepository implements CacheRepository {
  public async add(key: string, value: string): Promise<void> {
    localStorage.setItem(key, value);
  }

  public async remove(key: string): Promise<void> {
    localStorage.removeItem(key);
  }

  public async get(key: string): Promise<any> {
    return localStorage.getItem(key);
  }
}
