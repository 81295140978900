import { CopyPlanningService } from "@domain/services/planning";
import { CopyPlanningViewContract } from "@presentation/views/planning";
import { CopyPlanningPresenterContract } from "@ui/presenters/planning";
import { FormValidation } from "@presentation/validation/interfaces";
import { EventEmitter } from "@utils/events/EventEmitter";
import { PlanningEvents } from "@domain/events";
import { Prefecture } from "@domain/entities";
import { GetPrefectureService } from "@domain/services/prefecture";

export class CopyPlanningPresenter implements CopyPlanningPresenterContract {
    private view!: CopyPlanningViewContract;

    constructor(
        private readonly validation: FormValidation,
        private readonly getPrefectureService: GetPrefectureService,
        private readonly copyPlanningService: CopyPlanningService
    ) { }

    setView(view: CopyPlanningViewContract): void {
        this.view = view;
    }

    async copy(payload: CopyPlanningPresenterContract.Payload): Promise<boolean> {
        this.view.setLoadingState(true);

        const validationSuccessfull = this.validation.allValid(payload);

        if (!validationSuccessfull) {
            this.view.updateValidationState(this.validation.errors(payload));
            this.view.setLoadingState(false);
            return false;
        }

        const isPrefecturepAdded = await this.copyPlanningService.handle(payload);

        if (isPrefecturepAdded) {
            EventEmitter.emit(PlanningEvents.PLANNING_COPIED);
        }

        this.view.updateValidationState([]);
        this.view.setLoadingState(false);

        return isPrefecturepAdded;
    }

    async getPrefecture(): Promise<Prefecture[]> {
        const prefectures = await this.getPrefectureService.handle();
    
        this.view.setPrefectures(prefectures);
    
        return prefectures;
      }
}
