import { HttpGetSourcesService, HttpRemoveSourcesService } from "@data/services/sources";
import { AxiosHttpRepository } from "@infra/http";
import { API_BASE_URL } from "@main/constants/config";
import { SourcesPresenter } from "@presentation/presenters/sources";
import { SourcesPresenterContract } from "@ui/presenters/sources";

export const makeSourcesPresenter = (): SourcesPresenterContract => {
   const httpRepository = new AxiosHttpRepository(API_BASE_URL);
   const getSourcesService = new HttpGetSourcesService(httpRepository);
   const removeSourcesService = new HttpRemoveSourcesService(httpRepository);
   return new SourcesPresenter(getSourcesService, removeSourcesService);
 };
