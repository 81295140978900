import { ProfileMenuPresenter } from "@presentation/presenters/profiles";
import { LocalStorageCacheRepository } from "@infra/cache";
import { HistoryNavigateRepository } from "@infra/navigate";
import { ProfileMenuPresenterContract } from "@ui/presenters/profiles";
import { HttpGetUserInfoService, HttpLogoutService } from "@data/services/profile";
import { AxiosHttpRepository } from "@infra/http";
import { API_BASE_URL } from "@main/constants/config";

export const makeProfileMenuPresenter = (): ProfileMenuPresenterContract => {
   const httpRepository = new AxiosHttpRepository(API_BASE_URL);
   const cacheRepository = new LocalStorageCacheRepository();
   const navigateRepository = new HistoryNavigateRepository();
   const logoutService = new HttpLogoutService(cacheRepository, navigateRepository);
   const getUserInfoService = new HttpGetUserInfoService(httpRepository);
   return new ProfileMenuPresenter(logoutService, getUserInfoService);
};
