import { Group } from "@domain/entities";
import { GetGroupsService, RemoveGroupService } from "@domain/services/groups";
import { GroupsViewContract } from "@presentation/views/groups";
import { GroupsPresenterContract } from "@ui/presenters/groups";

export class GroupsPresenter implements GroupsPresenterContract {
  private view!: GroupsViewContract;

  constructor(
    private readonly getGroupsService: GetGroupsService,
    private readonly removeGroupService: RemoveGroupService,
  ) {}

  async removeGroup(groupId: number): Promise<void> {
    this.view.setLoadingState(true);

    const isRemoved = await this.removeGroupService.handle(groupId);

    if (isRemoved) {
      this.get();
    } else {
      this.view.setLoadingState(false);
    }
  }

  setView(view: GroupsViewContract): void {
    this.view = view;
  }

  async get(): Promise<Group[]> {
    this.view.setLoadingState(true);

    const groups = await this.getGroupsService.handle();

    this.view.setGroups(groups);
    this.view.setLoadingState(false);
    return groups;
  }
}
