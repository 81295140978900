import React, { useEffect, useState } from 'react';

import ReactApexChart from "react-apexcharts";
import { withTranslation } from 'react-i18next';

import { Card, CardBody, Col } from 'reactstrap';

import { AccountsBalancePresenterContract } from '@ui/presenters/dashboard';
import { MoneyFormatStrategy } from '@ui/strategy';

interface AccountsBalanceViewProps {
  t: any;
  accountsBalacePresenter: AccountsBalancePresenterContract;
}

const AccountsBalanceView: React.FC<AccountsBalanceViewProps> = ({ t, accountsBalacePresenter }) => {
  const [chartData, setChartData] = useState<AccountsBalancePresenterContract.ChartData[]>([]);

  useEffect(() => {
    accountsBalacePresenter.setView({ setAccountBalanceData });

    accountsBalacePresenter.getData();
  }, []);

  const setAccountBalanceData = (data: AccountsBalancePresenterContract.ChartData[]): void =>
    setChartData(data);

  const piechartColors = chartData.map(({ cashflowColor }) => cashflowColor);
  const series = 0;
  const options: ApexCharts.ApexOptions = {
    chart: {
      width: 227,
      height: 227,
      type: "pie",
    },
    tooltip: {
      y: {
        formatter(value) {
          return MoneyFormatStrategy.format(value);
        },
      }
    },
    labels: chartData.map(({ cashflow }) => cashflow),
    colors: piechartColors,
    stroke: {
      width: 0,
    },
    legend: {
      show: true,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
        },
      },
    ],
  };

  return (
    <>
      <Col sm={12} md={6}>
        <Card className="card-h-100">
          <CardBody>
            <h5 className="card-title me-2">{t("Account Balance")}</h5>

            <div className="mt-5">
              <ReactApexChart
                options={options}
                // series={series}
                type='pie'
                height={400}
              />
            </div>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default withTranslation()(AccountsBalanceView);
