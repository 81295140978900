import { HttpUpdateUserPasswordService } from "@data/services/users";
import { AxiosHttpRepository } from "@infra/http";
import { API_BASE_URL } from "@main/constants/config";
import { UpdateUserPasswordPresenter } from "@presentation/presenters/users";
import { FieldValidationBuilder } from "@presentation/validation/builders";
import { ValidationComposite } from "@presentation/validation/validators";
import { UpdateUserPasswordPresenterContract } from "@ui/presenters/users";

export const makeUpdateUserPasswordPresenter = (): UpdateUserPasswordPresenterContract => {
  const validation = new ValidationComposite([
    ...FieldValidationBuilder.field('oldPassword').required().build(),
    ...FieldValidationBuilder.field('password').required().build()
  ]);

  const httpRepository = new AxiosHttpRepository(API_BASE_URL);
  const updateUserPasswordService = new HttpUpdateUserPasswordService(httpRepository);

  return new UpdateUserPasswordPresenter(
    validation,
    updateUserPasswordService
  );
};
