import { UpdateUserPasswordService } from "@domain/services/users";
import { HttpRepository } from "@data/repositories";
import { HttpRequestBuilder } from "@data/builders";
import { HttpRequestTypes } from "@data/enums";
import { Error } from "@domain/interfaces/errors";

interface HttpUpdateUserPasswordResponse {
  message?: Error[];
}

interface HttpUpdateUserPasswordRequest {
  titulo: string;
  mensagem: string;
}

export class HttpUpdateUserPasswordService implements UpdateUserPasswordService {
  constructor(
    private readonly httpRepository: HttpRepository
  ) { }

  async handle(payload: UpdateUserPasswordService.Payload): Promise<UpdateUserPasswordService.Response> {
    const httpRequest = HttpRequestBuilder
      .requestToUrl('/users/password')
      .withMethod(HttpRequestTypes.PUT)
      .withData(payload)
      .build();

    const { data, status } =
      await this.httpRepository.makeRequest<HttpUpdateUserPasswordRequest, HttpUpdateUserPasswordResponse>(httpRequest);

    return {
      ok: status === 200,
      errors: Array.isArray(data.message) ? data.message : []
    };
  }
}
