import { HttpRequestBuilder } from "@data/builders";
import { HttpRequestTypes } from "@data/enums";
import { HttpRepository } from "@data/repositories";
import { AddAccountService } from "@domain/services/account";

export class HttpAddAccountService implements AddAccountService {
  constructor(
    private readonly httpRepository: HttpRepository
  ) { }

  async handle(payload: AddAccountService.Payload): Promise<boolean> {
    const httpRequest = HttpRequestBuilder
      .requestToUrl('/bank/account')
      .withMethod(HttpRequestTypes.POST)
      .withData(payload)
      .build();

      const { status } = await this.httpRepository.makeRequest<AddAccountService.Payload>(httpRequest);
  
      return status === 201;
  }
}
