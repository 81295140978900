import { State, City } from "@domain/entities";
import { UserEvents } from "@domain/events";
import { GetCitiesService } from "@domain/services/cities";
import { UpdateProfileService } from "@domain/services/profile";
import { GetStatesService } from "@domain/services/states";
import { FormValidation } from "@presentation/validation/interfaces";
import { UpdateProfileViewContract } from "@presentation/views/profiles";
import { UpdateProfilePresenterContract } from "@ui/presenters/profiles";
import { EventEmitter } from "@utils/events";

export class UpdateProfilePresenter implements UpdateProfilePresenterContract {
  private view!: UpdateProfileViewContract;

  constructor(
    private readonly validation: FormValidation,
    private readonly getStatesService: GetStatesService,
    private readonly getCitiesService: GetCitiesService,
    private readonly updateProfileService: UpdateProfileService
  ) { }

  setView(view: UpdateProfileViewContract): void {
    this.view = view;
  }

  async updateProfile(payload: UpdateProfilePresenterContract.Payload): Promise<boolean> {
    this.view.setLoading(true);

    const validationSuccessfull = this.validation.allValid(payload);

    if (!validationSuccessfull) {
      this.view.updateValidationState(this.validation.errors(payload));
      this.view.setLoading(false);
      return false;
    }

    const isProfileUpdated = await this.updateProfileService.handle({
      email: payload.email,
      name: payload.name,
      city: payload.city,
      state: payload.state
    });

    if (isProfileUpdated) {
      EventEmitter.emit(UserEvents.PROFILE_UPDATED);
    }

    this.view.setLoading(false);
    this.view.updateValidationState([]);
    return isProfileUpdated;
  }

  async getStates(): Promise<State[]> {
    const states = await this.getStatesService.handle();
    this.view.setStates(states);
    return states;
  }

  async getCities(state: number): Promise<City[]> {
    if (!state) {
      this.view.setCities([]);
      return [];
    }

    const cities = await this.getCitiesService.handle(state);
    this.view.setCities(cities);
    return cities;
  }
}
