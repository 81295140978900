import React, { useMemo } from "react";
import { EditUserView } from "@ui/views/users";
import { EditUserViewContract } from "@presentation/views/users";
import { makeEditUserPresenter } from "@main/factories/presenters/users";

interface EditUserProps {
  user?: EditUserViewContract.Payload | null;
  onDismiss(): void;
}

const AddUser: React.FC<EditUserProps> = (props) => {
  const editUserPresenter = useMemo(() => makeEditUserPresenter(), []);

  return <EditUserView {...props} editUserPresenter={editUserPresenter} />;
};

export default AddUser;
