import { CacheRepository, NavigateRepository } from "@data/repositories";
import { AppRoutes } from "@domain/enums";
import { LogoutService } from "@domain/services/profile";

export class HttpLogoutService implements LogoutService {
   constructor(
      private readonly cacheRepository: CacheRepository,
      private readonly navigateRepository: NavigateRepository,
   ) { }

   async handle(): Promise<void> {
      await this.cacheRepository.remove('authUser');
      await this.cacheRepository.remove('user_logged');
      await this.cacheRepository.remove('user_group');
      await this.navigateRepository.navigate(AppRoutes.LOGIN);
   }
}
